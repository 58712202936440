import LocationSelection from "@/components/LocationSelection";
import { ContentBlock } from "@/components/AppBlocks";
import Page from "@/components/Page";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { updateSearch } from "@/state/actions/searchActions";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { connect } from "react-redux";
import BannerMailchimp from "./BannerMailchimp";
import EmailCampaignsList from "./EmailCampaignsList";
import AuthService from "@/services/AuthService";
import H2 from "@/components/text/H2";
import P from "@/components/text/P";

const Location = ({ onLocationChange }) => (
	<LocationSelection componentName="email-campaigns" defaultLevel="user" onLocationChange={onLocationChange} />
);

const Blocked = () => (
	<ContentBlock>
		<H2>Feature disabled</H2>
		<P>
			Please contact our support team to enable this feature,
			<a onClick={OpenHelpHandler(HelpIds.contactEmail)}>here</a>
		</P>
	</ContentBlock>
);

const EmailCampaigns = ({ updateSearch }) => {
	const onLocationChange = (loc) => {
		updateSearch({ loc }, true);
	};

	const isFraudSupect = AuthService.isFraudSuspect();

	return (
		<Page
			pageName="Email campaigns"
			sibling={<Location onLocationChange={onLocationChange} />}
			help={{
				link: HelpIds.emailCampaigns,
				title: "Help - Email campaigns"
			}}
		>
			<BannerMailchimp />
			{isFraudSupect ? <Blocked /> : <EmailCampaignsList />}
		</Page>
	);
};

export default connect(
	(state) => ({
		currentUser: state.auth
	}),
	{
		updateSearch
	}
)(withRouterHooks(EmailCampaigns));
