import { Alert } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import AuthService from "@/services/AuthService";
import { reset } from "@/state/actions/bulkUploadActions";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import BulkUploadSteps from "./BulkUploadSteps";
import { LoadFile, MapFields, UploadTickets } from "./pages";
const defaultValues = [
	{
		name: "Order Id",
		orderLevel: true,
		default: true
	},
	{
		name: "Ticket Type",
		orderLevel: true,
		default: true
	},
	{
		name: "Buyer First Name",
		orderLevel: true,
		default: true
	},
	{
		name: "Buyer Last Name",
		orderLevel: true,
		default: true
	},
	{
		name: "Buyer Email",
		orderLevel: true,
		default: true
	},
	{
		name: "Buyer Mobile",
		orderLevel: true,
		default: true
	}
];
class BulkUpload extends Component {
	constructor(props) {
		super(props);
		const additionalQuestions = props.currentEvent.event.additionalQuestions;
		const filteredAdditionalQuestions = additionalQuestions.filter((q) => !q.deleted && !q.disabled);
		const columns = defaultValues.concat(
			filteredAdditionalQuestions.map((q) => {
				return {
					name: q.question,
					orderLevel: q.perOrder,
					default: false
				};
			})
		);
		this.state = {
			columns,
			step: 0
		};
	}

	componentDidMount() {
		this.props.reset();
	}

	onNextStep = (data) => {
		const newStep = this.state.step + 1;
		this.setState({
			step: newStep,
			...data
		});
	};

	getPage(step) {
		switch (step) {
			case 0:
				return LoadFile;
			case 1:
				return MapFields;
			case 2:
				return UploadTickets;
			default:
				return LoadFile;
		}
	}

	render() {
		const { order, user } = this.props;
		const { loading } = order.bulkUpload;
		const step = this.state.step;
		const Page = this.getPage(step);

		const verified = AuthService.isAdmin() || user.verified;
		return (
			<div>
				<PageHeading
					title="Bulk Upload"
					helpButton={{
						link: HelpIds.bulkUpload,
						title: "Help - Bulk Upload"
					}}
				/>

				{verified ? (
					<>
						<BulkUploadSteps currentStep={step} />
						<LoadErrorView loading={loading}>
							<Page {...this.state} onNextStep={this.onNextStep} />
						</LoadErrorView>
					</>
				) : (
					<ContentBlock>
						<Alert
							style={{ marginBottom: 12 }}
							message="Get verified"
							description={
								<div>
									To use the bulk upload feature you will need to be verified by our team.{" "}
									<a onClick={OpenHelpHandler(8893220)}>Get verified</a>
								</div>
							}
							type="warning"
							showIcon
						/>
						With the "Bulk Upload" feature, you can easily upload hundreds of attendees at once to your event.
					</ContentBlock>
				)}
			</div>
		);
	}
}

export default connect(
	(state) => ({
		currentEvent: state.currentEvent,
		user: state.auth.user,
		order: state.order
	}),
	{ reset }
)(BulkUpload);
