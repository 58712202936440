import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import { Alert } from "@/ui/antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Field, formValueSelector, isDirty, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import { CheckboxField, DateField, LegacyInputField, RichTextAreaField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H1, H4, P } from "@/components/text/Text";
import AuthService from "@/services/AuthService";
import LocationService from "@/services/LocationService";
import {
	clearRefundRequest,
	getOrder,
	getRefundFundsAvailable,
	openGiftCardsRefundsModal,
	refundOrder,
	sendOrder
} from "@/state/actions/orderActions";
import { currency } from "@/utils/Format";
import DonationTable from "./DonationTable";
import InsuranceTable from "./InsuranceTable";
import MerchandiseTable from "./MerchandiseTable";
import RefundConfirmationModal from "./RefundConfirmationModal";
import TicketsTable from "./TicketsTable";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const formName = "orderRefund";

const detailsWrapper = css`
	display: flex;
	justify-content: space-between;
	div {
		display: flex;
		flex-direction: column;
		P {
			margin-bottom: 2px;
		}
	}
`;

let Refund = (props) => {
	const {
		totalRefundAmount,
		sendEmails,
		dispatch,
		change,
		merchandiseToCancel,
		ticketsToCancel,
		allMerchandiseSelected,
		allTicketsSelected,
		handleSubmit,
		maximumRefund,
		fundsAvailable,
		fundsAvailableValue,
		clientDonation,
		clientDonationTicketType,
		navigate,
		isReviewable,
		refundOrder,
		manualRefund,
		clientDonationRefund,
		refundInsurance,
		forceInsuranceRefund
	} = props;
	const { order } = props.order;
	const { loading, error } = props.order.refund;
	const isAdmin = AuthService.isAdmin();

	const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

	const isInsured = order?.xCover?.status === "purchased";

	const load = () => {
		const { params } = props;
		const { orderId } = params;
		props.clearRefundRequest();
		props.getOrder(orderId);
		props.getRefundFundsAvailable(orderId);
	};

	const refund = (values) => {
		setConfirmationModalOpen(false);
		refundOrder(order._id, { ...values, refundInsurance, forceInsuranceRefund }, formName);
	};

	const selectAllTickets = () => {
		dispatch(
			change(
				"ticketsToCancel",
				Object.keys(ticketsToCancel).reduce((tickets, key) => {
					tickets[key] = {
						...ticketsToCancel[key],
						cancel: !allTicketsSelected,
						amount: allTicketsSelected ? 0 : fundsAvailable.tickets[key]
					};
					return tickets;
				}, {})
			)
		);
	};

	const selectAllMerchandise = () => {
		dispatch(
			change(
				"merchandiseToCancel",
				Object.keys(merchandiseToCancel).reduce((merchandise, key) => {
					merchandise[key] = {
						...merchandiseToCancel[key],
						cancel: !allMerchandiseSelected,
						amount: allMerchandiseSelected ? 0 : fundsAvailable.merchandise[key]
					};
					return merchandise;
				}, {})
			)
		);
	};

	const setRefundAmount = (itemType, id, isCancelChecked, maxAvailable) => {
		if (isCancelChecked) {
			dispatch(change(`${itemType}.${id}.amount`, maxAvailable));
		} else if (maxAvailable === props[itemType][id].amount) {
			dispatch(change(`${itemType}.${id}.amount`, 0));
		}
	};

	useEffect(() => {
		load();
	}, []);
	if (!order) return null;
	return (
		<>
			<H1>{order.type} Order Refund</H1>
			<LoadErrorView loading={loading} error={error} retryAction={load}>
				<form>
					<RefundConfirmationModal
						confirmationModalOpen={confirmationModalOpen}
						setConfirmationModalOpen={setConfirmationModalOpen}
						submit={handleSubmit(refund)}
						ticketsToCancel={ticketsToCancel}
						merchandiseToCancel={merchandiseToCancel}
						totalRefundAmount={totalRefundAmount}
						clientDonation={clientDonation}
						clientDonationTicketType={clientDonationTicketType}
						sendEmails={sendEmails}
						refundInsurance={refundInsurance || forceInsuranceRefund}
						insurance={order.xCover}
					/>
					<ContentBlock>
						<div className={detailsWrapper}>
							<div>
								<P>
									<b>Order number:</b>{" "}
									<Link to={`/console/my-events/${order.eventId}/orders/${order._id}`}>{order.orderName}</Link>
								</P>
								<P>
									<b>Customer:</b> {order.firstName || ""} {order.lastName || ""}
								</P>
								<P>{order.email}</P>
								<P>{order.mobile}</P>
							</div>
							<div>
								<P>
									<b>Order date:</b>{" "}
									{new HumaniDate(order.completedAt, "guess", LocationService.getLocation()).formatting.dateTime()}
								</P>
								<P>
									<b>Event date:</b> {order.eventDisplayDate}
								</P>
							</div>
						</div>
						<hr />
						{fundsAvailable.order > fundsAvailable.event && (
							<Alert
								style={{ marginBottom: 12 }}
								message="Funds unavailable for full refund"
								description={
									<>
										There is currently not enough funds within this event to fully refund this order. Funds remaining in
										event: {currency(fundsAvailable.event)}.{" "}
										<Link to={`/console/my-events/${order.eventId}/payments/payouts`}>
											See payouts page for more details.
										</Link>
									</>
								}
								type="warning"
								showIcon
							/>
						)}
						{isInsured && (
							<Alert
								style={{ marginBottom: 12 }}
								message="Order includes refund protection"
								description={
									<>
										The buyer purchased refund protection. This means that they can claim a refund via their refund
										protection policy. In most cases this will negate the need for you to refund on their behalf.
										<div>
											<Field
												name="acknowledgeInsurance"
												label="I acknowledge and still wish to refund this order."
												component={CheckboxField}
												inline
												labelAfter
											/>
										</div>
									</>
								}
								type="warning"
								showIcon
							/>
						)}
						<P>
							Enter your desired refund amount and select which ticket you would like to cancel. Unselected tickets will
							not be cancelled.
						</P>
						<TicketsTable
							ticketsToCancel={ticketsToCancel}
							selectAllTickets={selectAllTickets}
							allTicketsSelected={allTicketsSelected}
							fundsAvailable={fundsAvailable.tickets}
							setRefundAmount={setRefundAmount}
							maximumRefund={maximumRefund}
						/>
						<MerchandiseTable
							merchandiseToCancel={merchandiseToCancel}
							selectAllMerchandise={selectAllMerchandise}
							allMerchandiseSelected={allMerchandiseSelected}
							fundsAvailable={fundsAvailable.merchandise}
							setRefundAmount={setRefundAmount}
							maximumRefund={maximumRefund}
						/>
						<DonationTable
							clientDonationV2={order.clientDonationV2}
							clientDonationTicketType={clientDonationTicketType}
							fundsAvailable={fundsAvailable.donation}
							clientDonationRefund={clientDonationRefund}
							maximumRefund={maximumRefund}
						/>
						<InsuranceTable
							insurance={order.xCover}
							canRefundInsurance={order.canRefundInsurance}
							refundInsurance={refundInsurance}
							forceInsuranceRefund={forceInsuranceRefund}
						/>
						<div title={`Funds available: ${currency(fundsAvailableValue)}`} style={{ textAlign: "right" }}>
							<H4>Total to refund: {currency(totalRefundAmount)}</H4>
						</div>
						<Field
							name="reason"
							label="Reason for refund (optional)"
							placeholder="Reason"
							component={LegacyInputField}
							labelAfter
						/>
						<Field
							name="sendEmails"
							label="Send refund confirmation email"
							component={CheckboxField}
							inline
							labelAfter
						/>
						{sendEmails ? (
							<Field
								name="customMessage"
								label="Send a custom refund message in the refund email"
								component={RichTextAreaField}
								basicEditor
								required
							/>
						) : null}
						{isAdmin ? (
							<>
								<br />
								<Field
									name="manualRefund"
									label="Funds have been returned to user (eg. chargeback)."
									component={CheckboxField}
									defaultChecked
									inline
									labelAfter
								/>
								{manualRefund ? (
									<Field
										name="chargeBackAt"
										label="When were the funds removed from our account"
										component={DateField}
									/>
								) : null}
							</>
						) : null}
					</ContentBlock>
					<FormBar>
						<LegacyButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="button"
							onClick={() => setConfirmationModalOpen(true)}
							ariaLabel="Refund"
							disabled={!isReviewable}
						>
							Review...
						</LegacyButton>
						<LegacyButton
							style={{
								float: "left",
								width: "100px"
							}}
							ariaLabel="Go back"
							onClick={() => {
								navigate(`/console/my-events/${order.eventId}/orders/${order._id}`);
							}}
						>
							Go back
						</LegacyButton>
					</FormBar>
				</form>
			</LoadErrorView>
		</>
	);
};

Refund = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(Refund);

export default connect(
	(state) => {
		const initialValues = { sendEmails: true, clientDonation: 0 };
		const order = state?.order?.order;
		if (order) {
			const { tickets, merchandise } = order;
			if (tickets && tickets.length) {
				initialValues.ticketsToCancel = tickets.reduce((tickets, ticket) => {
					tickets[ticket._id] = {
						amount: 0,
						cancel: false,
						totalsV2: ticket.totalsV2,
						_id: ticket._id,
						seat: ticket.seatingLocation?.name,
						firstName: ticket.firstName,
						lastName: ticket.lastName,
						ticketTypeName: ticket.ticketTypeName,
						status: ticket.status
					};
					return tickets;
				}, {});
			}
			if (merchandise && merchandise.length) {
				initialValues.merchandiseToCancel = merchandise.reduce((merchandise, merch) => {
					merchandise[merch._id] = {
						amount: 0,
						cancel: false,
						totalsV2: merch.totalsV2,
						_id: merch._id,
						firstName: merch.firstName,
						lastName: merch.lastName,
						merchandiseTypeName: merch.merchandiseTypeName,
						status: merch.status
					};
					return merchandise;
				}, {});
			}
		}
		const selector = formValueSelector(formName);
		const sendEmails = selector(state, "sendEmails");
		const clientDonation = selector(state, "clientDonation");
		const ticketsToCancel = selector(state, "ticketsToCancel");
		const manualRefund = selector(state, "manualRefund");
		const merchandiseToCancel = selector(state, "merchandiseToCancel");
		const forceInsuranceRefund = selector(state, "forceInsuranceRefund");
		const allTicketsSelected = ticketsToCancel
			? Object.keys(ticketsToCancel).every((key) => ticketsToCancel[key].cancel)
			: false;
		const atLeastOneTicketSelected = ticketsToCancel
			? Object.keys(ticketsToCancel).some((key) => ticketsToCancel[key].cancel)
			: false;

		const allMerchandiseSelected = merchandiseToCancel
			? Object.keys(merchandiseToCancel).every((key) => merchandiseToCancel[key].cancel)
			: false;

		const atLeastOneMerchandiseSelected = merchandiseToCancel
			? Object.keys(merchandiseToCancel).some((key) => merchandiseToCancel[key].cancel)
			: false;

		const totalValueOfTicketsToRefund = ticketsToCancel
			? Object.keys(ticketsToCancel).reduce((ticketValue, key) => ticketValue + ticketsToCancel[key].amount, 0)
			: 0;
		const totalValueOfMerchandiseToRefund = merchandiseToCancel
			? Object.keys(merchandiseToCancel).reduce(
					(merchandiseValue, key) => merchandiseValue + merchandiseToCancel[key].amount,
					0
			  )
			: 0;

		const orderAmountNotIncludingInsurance = (order?.totalsV2?.total || 0) - (order?.insurance?.totalsV2?.total || 0);

		const refundAmountNotIncludingInsurance =
			totalValueOfTicketsToRefund + totalValueOfMerchandiseToRefund + clientDonation;

		const refundInsurance =
			orderAmountNotIncludingInsurance - refundAmountNotIncludingInsurance <= 0 && order?.canRefundInsurance;

		const totalRefundAmount =
			refundAmountNotIncludingInsurance +
			(forceInsuranceRefund || refundInsurance ? order?.xCover?.totalsV2?.total : 0);
		const fundsAvailableValue = Math.min(state.order?.fundsAvailable.event, state.order?.fundsAvailable.order);
		const maximumRefund = Math.max(fundsAvailableValue - totalRefundAmount, 0);
		const clientDonationTicketType = state?.currentEvent?.event?.ticketTypes?.find((tt) => tt.isDonation);

		const acknowledgeInsurance = selector(state, "acknowledgeInsurance");
		const isInsured = order?.xCover?.status === "purchased";

		const isReviewable =
			isDirty(formName)(state) &&
			(!isInsured || acknowledgeInsurance) &&
			(totalRefundAmount > 0 || manualRefund || atLeastOneMerchandiseSelected || atLeastOneTicketSelected);

		return {
			order: state.order,
			initialValues,
			sendEmails,
			merchandiseToCancel,
			ticketsToCancel,
			allMerchandiseSelected,
			allTicketsSelected,
			totalRefundAmount,
			fundsAvailable: state.order?.fundsAvailable,
			fundsAvailableValue,
			maximumRefund,
			clientDonation,
			clientDonationTicketType,
			clientDonationRefund: clientDonation,
			manualRefund,
			isReviewable,
			refundInsurance,
			forceInsuranceRefund
		};
	},
	{
		getOrder,
		getRefundFundsAvailable,
		sendOrder,
		openGiftCardsRefundsModal,
		refundOrder,
		clearRefundRequest
	}
)(withRouterHooks(Refund));
