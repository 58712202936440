import { ContentBlock } from "@/components/AppBlocks";
import FormBar from "@/components/FormBar";
import PageHeading from "@/components/PageHeading";
import Zapier from "@/components/Zapier";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { saveEvent } from "@/state/actions/eventActions";
import { IntegrationsFields } from "@/users/integrations/IntegrationsFields";
import { HelpUrls } from "@/utils/Help";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { Tabs } from "@/ui/antd";
import { Fragment, useState } from "react";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";

let Integrations = ({ params, saveEvent, currentEvent, handleSubmit, googleAnalyticsId, googleTagManagerId }) => {
	const [key, tabChange] = useState("tracking");

	const save = (values) => {
		const eventId = params?.eventId;
		saveEvent(eventId, { integrations: values });
	};

	return (
		<Fragment>
			<PageHeading
				title="Integrations"
				helpButton={{
					link: HelpUrls.integrations,
					title: "Help - Integrations"
				}}
			/>
			<ContentBlock>
				<Tabs
					activeKey={key}
					onChange={tabChange}
					items={[
						{
							children: (
								<form onSubmit={handleSubmit(save)}>
									<IntegrationsFields
										loading={currentEvent.save.loading}
										error={currentEvent.save.error}
										googleAnalyticsId={googleAnalyticsId}
										googleTagManagerId={googleTagManagerId}
									/>
								</form>
							),
							key: "tracking",
							label: "Tracking IDs"
						},
						{
							children: <Zapier />,
							key: "zapier",
							label: "Zapier"
						}
					]}
				/>
			</ContentBlock>
			{key === "tracking" ? (
				<FormBar>
					<LegacyButton
						style={{
							float: "right"
						}}
						type="primary"
						htmlType="submit"
						ariaLabel="Save"
						size="large"
						onClick={handleSubmit(save)}
					>
						Save
					</LegacyButton>
				</FormBar>
			) : null}
		</Fragment>
	);
};

const formName = "integrationsForm";

Integrations = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(Integrations);

const selector = formValueSelector(formName);

export default connect(
	(state) => {
		return {
			initialValues: state.currentEvent.event.integrations ? state.currentEvent.event.integrations : {},
			currentEvent: state.currentEvent,
			googleAnalyticsId: selector(state, "googleAnalyticsId"),
			googleTagManagerId: selector(state, "googleTagManagerId")
		};
	},
	{ saveEvent }
)(withRouterHooks(Integrations));
