import type { PaymentPlanSettingSchema } from "@hx/console";
import type { Artist } from "@hx/console/types";
import {
	ARCHIVE_EVENT_ERROR,
	ARCHIVE_EVENT_REQUEST,
	ARCHIVE_EVENT_SUCCESS,
	ARCHIVE_MODAL_CLOSE,
	ARCHIVE_MODAL_OPEN,
	CLEAR_ERRORS,
	DELETE_ADDITIONAL_QUESTION_SUCCESS,
	DUPLICATE_EVENT_ERROR,
	DUPLICATE_EVENT_REQUEST,
	DUPLICATE_EVENT_SUCCESS,
	GET_EVENT_ERROR,
	GET_EVENT_ORDER_EDIT_ERROR,
	GET_EVENT_ORDER_EDIT_REQUEST,
	GET_EVENT_ORDER_EDIT_SUCCESS,
	GET_EVENT_REQUEST,
	GET_EVENT_SUCCESS,
	GET_EVENT_USER_ERROR,
	GET_EVENT_USER_REQUEST,
	GET_EVENT_USER_SUCCESS,
	GET_FEE_STRUCTURE_ERROR,
	GET_FEE_STRUCTURE_REQUEST,
	GET_FEE_STRUCTURE_SUCCESS,
	IMPORT_CLEAR,
	IMPORT_CLOSE,
	IMPORT_ERROR,
	IMPORT_OPEN,
	IMPORT_REQUEST,
	IMPORT_SUCCESS,
	NOTIFY_FOLLOWERS_ERROR,
	NOTIFY_FOLLOWERS_MODAL_CLOSE,
	NOTIFY_FOLLOWERS_MODAL_OPEN,
	NOTIFY_FOLLOWERS_REQUEST,
	NOTIFY_FOLLOWERS_SUCCESS,
	ONLINE_MODAL_CLOSE,
	ONLINE_MODAL_OPEN,
	PREVIEW_ADDITIONAL_QUESTIONS_ERROR,
	PREVIEW_ADDITIONAL_QUESTIONS_REQUEST,
	PREVIEW_ADDITIONAL_QUESTIONS_SUCCESS,
	PRICE_PREVIEW_ERROR,
	PRICE_PREVIEW_REQUEST,
	PRICE_PREVIEW_SUCCESS,
	PUBLISH_CHECK_ERROR,
	PUBLISH_CHECK_REQUEST,
	PUBLISH_CHECK_SUCCESS,
	PUBLISH_MODAL_CLOSE,
	PUBLISHED_MODAL_CLOSE,
	PUBLISHED_MODAL_OPEN,
	RESET_EVENT,
	SAVE_EVENT_ERROR,
	SAVE_EVENT_REQUEST,
	SAVE_EVENT_SUCCESS,
	TRANSFER_EVENT_ERROR,
	TRANSFER_EVENT_MODAL_CLOSE,
	TRANSFER_EVENT_MODAL_OPEN,
	TRANSFER_EVENT_REQUEST,
	TRANSFER_EVENT_SUCCESS,
	UNPUBLISH_EVENT_ERROR,
	UPDATE_EVENT_STEP_ERROR,
	UPDATE_EVENT_STEP_REQUEST,
	UPDATE_EVENT_STEP_SUCCESS,
	UPDATE_SLUG_ERROR,
	UPDATE_SLUG_REQUEST,
	UPDATE_SLUG_SUCCESS,
	VERIFY_ACCOUNT_MODAL_CLOSE,
	VERIFY_ACCOUNT_MODAL_OPEN
} from "../actions/eventActions";

type Image = {
	canvaDesignId?: string;
	handle: string;
	height?: string;
	name: string;
	size?: number;
	source?: string;
	url: string;
	width?: string;
};

type DateRange = {
	_id: string;
	deleted?: boolean;
	disabled?: boolean;
	display?: {
		time: string;
		date: string;
		combined: string;
	};
	endDate: Date;
	hasPreviouslySoldOut?: {
		_id?: string;
		event?: boolean;
		ticketIds?: string[];
	};
	maxOrdersPerDate?: number;
	scheduleId?: string;
	startDate: Date;
};

export type TicketType = {
	_id?: string;
	deleted?: boolean;
	disabled?: boolean;
	hasBeenSold?: boolean;
	isDonation?: boolean;
	isNew?: boolean;
	isMappedToSeatingMap?: boolean;
} & any; // TODO wtf

type ResaleSettings = {
	enabled: boolean;
	ticketTypeIds?: string[];
	packagedTicketIds?: string[];
	preventTicketSplitForPackages: boolean;
	startResaleDate?: string;
	endResaleDate?: string;
	hidePromotion: boolean;
};

export type EventDetails = {
	_id?: string;
	accessibility?: any;
	additionalQuestions?: any;
	affiliateCodes?: any;
	artists?: Artist[];
	autoDiscounts?: any;
	bannerImage?: Image;
	currency?: string;
	customCapacity?: boolean;
	dates?: DateRange[];
	datesUseCalendar?: boolean;
	description?: string;
	discountCodes?: any;
	emailAlerts?: any;
	endDate?: any;
	engagements?: any;
	eventCreationSteps?: any;
	eventLocation?: any;
	facebookEvent?: any;
	facebookEvents?: any;
	featureImage?: Image;
	fees?: any;
	integrations?: any;
	isArchived?: boolean;
	isPrivate?: boolean;
	isRecurring?: boolean;
	linkedInSettings?: any;
	location?: string;
	logoImage?: Image;
	markedAsSoldOut?: boolean;
	merchandise?: any[];
	messages?: any;
	metadata?: any;
	name?: string;
	notifyFollowers?: boolean;
	organiserId?: string;
	packagedTickets?: any;
	paymentPlansSettings?: PaymentPlanSettingSchema[];
	paymentOptions?: any;
	payoutCompleted?: boolean;
	pricing?: any;
	promotingProgress?: any;
	public?: boolean;
	published?: boolean;
	publishedAt?: Date;
	referral?: any;
	referredById?: string;
	resaleSettings?: ResaleSettings;
	scheduleId?: string;
	scheduledPublishDate?: Date;
	seatingMaps?: any;
	settings?: EventSettings;
	sharingDescription?: string;
	slug?: string;
	socialImage?: Image;
	startDate?: any;
	suspendSales?: boolean;
	theme?: any;
	ticketAssigningSettings?: any;
	ticketFees?: any;
	ticketGroups?: any;
	ticketOrderOn?: boolean;
	ticketSold?: boolean;
	ticketTypes?: TicketType[];
	timezone?: string;
	totalCapacity?: number;
	totalMappedSeats?: number;
	totalNetSales?: number;
	totalTicketsSold?: number;
	upcoming?: any;
	usTaxCache?: any;
	userId?: string;
	waitlistSettings?: any;
	webHooks?: any;
	reportingGroups?: ReportingGroups[];

	// TODO Custom fields for the frontend. These should become part of the model or be stored elsewhere... not in the model
	isFree?: boolean;
	isFreeOnly?: boolean;
	stripeConnectRequired?: boolean;
};

type EventSettings = {
	_id: string;
	allowTicketAndMerchSwaps: boolean;
	allowUserEdit: boolean;
	customTimeout: number;
	datePickerLayout: string;
	disableHumanitixGTM: boolean;
	disallowRefundFees: boolean;
	disallowTrending: boolean;
	enableThreeDS: boolean;
	featured: boolean;
	featuredPriority: number;
	hideAddToCalendar: boolean;
	hideIsBusinessQuestion: boolean;
	isInsuranceEnabled: boolean;
	mailList: boolean;
	maximumTicketsPerOrder: any;
	minimumTicketsPerOrder: any;
	mobileRequired: boolean;
	paymentPlansOptIn?: boolean;
	removeImpactCommunication: boolean;
	requireEmailConfirmation: boolean;
	sendTickets: boolean;
	showOrganiserMailListOptIn: boolean;
	showPricesBeforeSalesTax: boolean;
	ticketPriceRangeDisplay: boolean;
	ticketsRemainingDisplay: boolean;
	isSecondaryTicketsEnabled?: boolean;
};

type EventWithOrderInfo = EventDetails & {
	settings: EventSettings;
};

type ErrorResponse = null | Error | string;

type ReportingGroups = {
	name: string;
	ticketIds: string[];
};

export type EventReducerState = {
	archive: {
		error?: ErrorResponse;
		loading: boolean;
		modalVisible: boolean;
	};
	cache: {
		error?: ErrorResponse;
		loading: boolean;
	};
	duplicate: {
		error?: ErrorResponse;
		loading: boolean;
	};
	error: ErrorResponse;
	event: null | EventDetails;
	eventUser: {
		error?: ErrorResponse;
		loading: boolean;
		user: Record<string, unknown>;
	};
	eventWithOrderInfo: null | EventWithOrderInfo;
	feeStructure: {
		data: null | any;
		error?: ErrorResponse;
		loading: boolean;
	};
	get: { error?: ErrorResponse; loading: boolean };
	getOrderEdit: { error?: ErrorResponse; loading: boolean };
	import: {
		open: boolean;
		loading: boolean;
		data: null | any;
		error: boolean;
	};
	loading: boolean;
	notifyFollowersState: {
		error?: ErrorResponse;
		isModalOpen: boolean;
		loading: boolean;
	};
	onlineModalOpen: boolean;
	pricePreviewState: {
		error?: ErrorResponse;
		loading: boolean;
		tableData: null | any;
	};
	publish: {
		check: {
			loading: boolean;
			verification?: null | { result: "allowed" | "captchaRequired" | "verificationRequired" };
			error?: ErrorResponse;
		};
		publishModalOpen: boolean;
		publishedModalOpen: boolean;
	};
	save: { error?: ErrorResponse; loading: boolean };
	ticketAssigningState: { error: boolean; lastSent: any; loading: boolean };
	total: number;
	transferEventState: {
		data: null | any;
		error: boolean;
		eventId?: null | string;
		loading: boolean;
		modalOpen: boolean;
	};
	verifyAccountModalOpen: {
		publishedLastOne: boolean;
		visible: boolean;
	};
};

const initialState: EventReducerState = {
	archive: { error: null, loading: false, modalVisible: false },
	cache: { error: null, loading: false },
	duplicate: { error: null, loading: false },
	error: null,
	event: null,
	eventUser: { error: null, loading: false, user: false },
	eventWithOrderInfo: null,
	feeStructure: { data: null, error: null, loading: false },
	get: { error: null, loading: false },
	getOrderEdit: { error: null, loading: false },
	import: { data: null, error: false, loading: false, open: false },
	loading: false,
	notifyFollowersState: { error: null, isModalOpen: false, loading: false },
	onlineModalOpen: false,
	pricePreviewState: { error: null, loading: false, tableData: null },
	publish: {
		check: { error: null, loading: false, verification: null },
		publishModalOpen: false,
		publishedModalOpen: false
	},
	save: { error: null, loading: false },
	ticketAssigningState: { error: false, lastSent: {}, loading: false },
	total: 1,
	transferEventState: { data: null, error: false, eventId: null, loading: false, modalOpen: false },
	verifyAccountModalOpen: { publishedLastOne: false, visible: false }
};

type PublishedVerification = {
	result: "allowed" | "captchaRequired" | "verificationRequired";
};

const mergeResponseAndSetEventFlags = <T extends EventReducerState["event"]>(
	event: EventReducerState["event"],
	eventActionPayload?: T
): T | null => {
	if (!eventActionPayload) return null;

	const updatedEvent = { ...event, ...eventActionPayload };

	// Update stripeConnectRequired and isFreeOnly
	// TODO: This should be done in the backend or not stored on the Event model
	const { paymentOptions } = updatedEvent;
	const selectedPaymentId = paymentOptions ? paymentOptions.paymentId : "humanitix";
	const stripeUserId = paymentOptions?.stripeInfo?.stripeUserId;
	const stripeConnectMissing = selectedPaymentId === "stripe" && !stripeUserId;
	updatedEvent.stripeConnectRequired = stripeConnectMissing && !updatedEvent.isFree;
	updatedEvent.isFreeOnly = stripeConnectMissing && updatedEvent.published;

	return updatedEvent;
};

type EventAction =
	| { type: typeof IMPORT_OPEN }
	| { type: typeof IMPORT_CLOSE }
	| { type: typeof IMPORT_REQUEST }
	| { type: typeof IMPORT_SUCCESS; data: unknown }
	| { type: typeof IMPORT_ERROR }
	| { type: typeof IMPORT_CLEAR }
	| { type: typeof PUBLISH_MODAL_CLOSE }
	| { type: typeof PUBLISH_CHECK_REQUEST }
	| { type: typeof PUBLISH_CHECK_SUCCESS; publishVerification: PublishedVerification }
	| { type: typeof PUBLISH_CHECK_ERROR; error: ErrorResponse }
	| { type: typeof PUBLISHED_MODAL_OPEN }
	| { type: typeof PUBLISHED_MODAL_CLOSE }
	| { type: typeof ONLINE_MODAL_OPEN }
	| { type: typeof ONLINE_MODAL_CLOSE }
	| { type: typeof VERIFY_ACCOUNT_MODAL_OPEN; publishedLastOne?: boolean }
	| { type: typeof VERIFY_ACCOUNT_MODAL_CLOSE }
	| { type: typeof GET_EVENT_REQUEST }
	| { type: typeof GET_EVENT_SUCCESS; event: EventReducerState["event"] }
	| { type: typeof GET_EVENT_ERROR; error: ErrorResponse }
	| { type: typeof GET_EVENT_ORDER_EDIT_REQUEST }
	| { type: typeof GET_EVENT_ORDER_EDIT_SUCCESS; event: EventReducerState["eventWithOrderInfo"] }
	| { type: typeof GET_EVENT_ORDER_EDIT_ERROR; error: ErrorResponse }
	| { type: typeof GET_EVENT_USER_REQUEST }
	| { type: typeof GET_EVENT_USER_SUCCESS; user: any }
	| { type: typeof GET_EVENT_USER_ERROR; error: ErrorResponse }
	| { type: typeof SAVE_EVENT_REQUEST }
	| { type: typeof SAVE_EVENT_SUCCESS; event: EventReducerState["event"] }
	| { type: typeof SAVE_EVENT_ERROR; error: ErrorResponse }
	| { type: typeof UPDATE_SLUG_REQUEST }
	| { type: typeof UPDATE_SLUG_SUCCESS; slug: string }
	| { type: typeof UPDATE_SLUG_ERROR }
	| { type: typeof DUPLICATE_EVENT_REQUEST }
	| { type: typeof DUPLICATE_EVENT_SUCCESS }
	| { type: typeof DUPLICATE_EVENT_ERROR; error: ErrorResponse }
	| { type: typeof ARCHIVE_MODAL_OPEN }
	| { type: typeof ARCHIVE_MODAL_CLOSE }
	| { type: typeof NOTIFY_FOLLOWERS_MODAL_OPEN }
	| { type: typeof NOTIFY_FOLLOWERS_MODAL_CLOSE }
	| { type: typeof NOTIFY_FOLLOWERS_REQUEST }
	| { type: typeof NOTIFY_FOLLOWERS_SUCCESS; notifyFollowers: boolean }
	| { type: typeof NOTIFY_FOLLOWERS_ERROR; error: ErrorResponse }
	| { type: typeof ARCHIVE_EVENT_REQUEST }
	| { type: typeof ARCHIVE_EVENT_SUCCESS; event: EventReducerState["event"] }
	| { type: typeof ARCHIVE_EVENT_ERROR; error: ErrorResponse }
	| { type: typeof UNPUBLISH_EVENT_ERROR }
	| { type: typeof RESET_EVENT }
	| { type: typeof CLEAR_ERRORS }
	| { type: typeof GET_FEE_STRUCTURE_REQUEST }
	| { type: typeof GET_FEE_STRUCTURE_SUCCESS; feeStructure: unknown }
	| { type: typeof GET_FEE_STRUCTURE_ERROR; error: ErrorResponse }
	| { type: typeof PREVIEW_ADDITIONAL_QUESTIONS_REQUEST }
	| { type: typeof PREVIEW_ADDITIONAL_QUESTIONS_SUCCESS }
	| { type: typeof PREVIEW_ADDITIONAL_QUESTIONS_ERROR }
	| { type: typeof PRICE_PREVIEW_REQUEST }
	| { type: typeof PRICE_PREVIEW_SUCCESS; tableData: unknown }
	| { type: typeof PRICE_PREVIEW_ERROR; error: ErrorResponse }
	| { type: typeof TRANSFER_EVENT_MODAL_OPEN; eventId: string }
	| { type: typeof TRANSFER_EVENT_MODAL_CLOSE }
	| { type: typeof TRANSFER_EVENT_REQUEST }
	| { type: typeof TRANSFER_EVENT_SUCCESS; result: EventReducerState["event"] }
	| { type: typeof TRANSFER_EVENT_ERROR; error: ErrorResponse }
	| { type: typeof DELETE_ADDITIONAL_QUESTION_SUCCESS; event: EventReducerState["event"] }
	| { type: typeof UPDATE_EVENT_STEP_REQUEST }
	| { type: typeof UPDATE_EVENT_STEP_SUCCESS; event: EventReducerState["event"] }
	| { type: typeof UPDATE_EVENT_STEP_ERROR; error: ErrorResponse };

export default (state = initialState, action: EventAction): EventReducerState => {
	switch (action.type) {
		case ARCHIVE_EVENT_ERROR:
			return {
				...state,
				archive: {
					error: action.error,
					loading: false,
					modalVisible: false
				}
			};
		case ARCHIVE_EVENT_REQUEST:
			return {
				...state,
				archive: { error: null, loading: true, modalVisible: false }
			};
		case ARCHIVE_EVENT_SUCCESS:
			return {
				...state,
				archive: { error: null, loading: false, modalVisible: false },
				event: {
					...state.event,
					isArchived: action.event?.isArchived,
					published: action.event?.published
				}
			};
		case ARCHIVE_MODAL_CLOSE:
			return {
				...state,
				archive: { loading: false, modalVisible: false }
			};
		case ARCHIVE_MODAL_OPEN:
			return {
				...state,
				archive: { loading: false, modalVisible: true }
			};
		case CLEAR_ERRORS:
			return {
				...state,
				duplicate: { error: null, loading: false },
				get: { error: null, loading: false },
				save: { error: null, loading: false }
			};
		case DELETE_ADDITIONAL_QUESTION_SUCCESS:
			return {
				...state,
				event: {
					...state.event,
					additionalQuestions: action.event?.additionalQuestions
				}
			};
		case DUPLICATE_EVENT_ERROR:
			return {
				...state,
				duplicate: { error: action.error, loading: false }
			};
		case DUPLICATE_EVENT_REQUEST:
			return {
				...state,
				duplicate: { error: null, loading: true }
			};
		case DUPLICATE_EVENT_SUCCESS:
			return {
				...state,
				duplicate: { error: null, loading: false }
			};
		case GET_EVENT_ERROR:
			return {
				...initialState,
				event: null,
				get: { error: action.error, loading: false }
			};
		case GET_EVENT_ORDER_EDIT_ERROR:
			return {
				...initialState,
				eventWithOrderInfo: null,
				getOrderEdit: { error: action.error, loading: false }
			};
		case GET_EVENT_ORDER_EDIT_REQUEST:
			return {
				...state,
				eventWithOrderInfo: null,
				getOrderEdit: { error: null, loading: true }
			};
		case GET_EVENT_ORDER_EDIT_SUCCESS:
			return {
				...state,
				eventWithOrderInfo: mergeResponseAndSetEventFlags(state.event, action.event),
				getOrderEdit: { error: null, loading: false }
			};
		case GET_EVENT_REQUEST:
			return {
				...state,
				event: null,
				get: { error: null, loading: true }
			};
		case GET_EVENT_SUCCESS:
			return {
				...state,
				event: mergeResponseAndSetEventFlags(state.event, action.event),
				get: { error: null, loading: false }
			};
		case GET_EVENT_USER_ERROR:
			return {
				...state,
				eventUser: { error: action.error, loading: false, user: false }
			};
		case GET_EVENT_USER_REQUEST:
			return {
				...state,
				eventUser: { error: null, loading: true, user: false }
			};
		case GET_EVENT_USER_SUCCESS:
			return {
				...state,
				eventUser: {
					error: null,
					loading: true,
					user: action.user
				}
			};
		case GET_FEE_STRUCTURE_ERROR:
			return {
				...state,
				feeStructure: { data: false, error: action.error, loading: false }
			};
		case GET_FEE_STRUCTURE_REQUEST:
			return {
				...state,
				feeStructure: { data: false, error: null, loading: true }
			};
		case GET_FEE_STRUCTURE_SUCCESS:
			return {
				...state,
				feeStructure: { data: action.feeStructure, error: null, loading: false }
			};
		case IMPORT_CLEAR:
			return {
				...state,
				import: { ...state.import, data: null }
			};
		case IMPORT_CLOSE:
			return {
				...state,
				import: { ...state.import, open: false }
			};
		case IMPORT_ERROR:
			return {
				...state,
				import: {
					...state.import,
					data: null,
					error: true,
					loading: false
				}
			};
		case IMPORT_OPEN:
			return {
				...state,
				import: { ...state.import, open: true }
			};
		case IMPORT_REQUEST:
			return {
				...state,
				import: {
					...state.import,
					data: null,
					error: false,
					loading: true
				}
			};
		case IMPORT_SUCCESS:
			return {
				...state,
				import: {
					...state.import,
					data: action.data,
					error: false,
					loading: false
				}
			};
		case NOTIFY_FOLLOWERS_ERROR:
			return {
				...state,
				notifyFollowersState: {
					...state.notifyFollowersState,
					error: action.error,
					loading: false
				}
			};
		case NOTIFY_FOLLOWERS_MODAL_CLOSE:
			return {
				...state,
				notifyFollowersState: {
					...state.notifyFollowersState,
					isModalOpen: false
				}
			};
		case NOTIFY_FOLLOWERS_MODAL_OPEN:
			return {
				...state,
				notifyFollowersState: {
					...state.notifyFollowersState,
					isModalOpen: true
				}
			};
		case NOTIFY_FOLLOWERS_REQUEST:
			return {
				...state,
				notifyFollowersState: {
					...state.notifyFollowersState,
					error: null,
					loading: true
				}
			};
		case NOTIFY_FOLLOWERS_SUCCESS:
			return {
				...state,
				event: {
					...state.event,
					notifyFollowers: action.notifyFollowers ?? false
				},
				notifyFollowersState: {
					...state.notifyFollowersState,
					error: null,
					loading: false
				}
			};
		case ONLINE_MODAL_CLOSE:
			return {
				...state,
				onlineModalOpen: false
			};
		case ONLINE_MODAL_OPEN:
			return {
				...state,
				onlineModalOpen: true
			};
		case PREVIEW_ADDITIONAL_QUESTIONS_ERROR:
			return {
				...state,
				cache: { error: null, loading: false }
			};
		case PREVIEW_ADDITIONAL_QUESTIONS_REQUEST:
			return {
				...state,
				cache: { error: null, loading: true }
			};
		case PREVIEW_ADDITIONAL_QUESTIONS_SUCCESS:
			return {
				...state,
				cache: { error: null, loading: false }
			};
		case PRICE_PREVIEW_ERROR:
			return {
				...state,
				pricePreviewState: {
					...state.pricePreviewState,
					error: action.error,
					loading: false
				}
			};
		case PRICE_PREVIEW_REQUEST:
			return {
				...state,
				pricePreviewState: {
					...state.pricePreviewState,
					error: null,
					loading: true
				}
			};
		case PRICE_PREVIEW_SUCCESS:
			return {
				...state,
				pricePreviewState: {
					...state.pricePreviewState,
					error: null,
					loading: false,
					tableData: action.tableData
				}
			};
		case PUBLISH_CHECK_ERROR:
			return {
				...state,
				publish: {
					...state.publish,
					check: {
						error: action.error,
						loading: false,
						verification: null
					}
				}
			};
		case PUBLISH_CHECK_REQUEST:
			return {
				...state,
				publish: {
					...state.publish,
					check: { error: null, loading: true, verification: null },
					publishModalOpen: true,
					publishedModalOpen: false
				}
			};
		case PUBLISH_CHECK_SUCCESS:
			return {
				...state,
				publish: {
					...state.publish,
					check: {
						error: null,
						loading: false,
						verification: action.publishVerification
					}
				}
			};
		case PUBLISH_MODAL_CLOSE:
			return {
				...state,
				publish: {
					...state.publish,
					publishModalOpen: false
				}
			};
		case PUBLISHED_MODAL_CLOSE:
			return {
				...state,
				publish: {
					...state.publish,
					publishedModalOpen: false
				}
			};
		case PUBLISHED_MODAL_OPEN:
			return {
				...state,
				publish: {
					...state.publish,
					publishedModalOpen: true
				}
			};
		case RESET_EVENT:
			return initialState;
		case SAVE_EVENT_ERROR:
			return {
				...state,
				loading: false,
				save: { error: action.error, loading: false }
			};
		case SAVE_EVENT_REQUEST:
			return {
				...state,
				loading: true,
				save: { error: null, loading: true }
			};
		case SAVE_EVENT_SUCCESS:
			return {
				...state,
				event: mergeResponseAndSetEventFlags(state.event, action.event),
				loading: false,
				save: { error: null, loading: false }
			};
		case TRANSFER_EVENT_ERROR:
			return {
				...state,
				transferEventState: {
					...state.transferEventState,
					loading: false
				}
			};
		case TRANSFER_EVENT_MODAL_CLOSE:
			return {
				...state,
				transferEventState: {
					...state.transferEventState,
					modalOpen: false
				}
			};
		case TRANSFER_EVENT_MODAL_OPEN:
			return {
				...state,
				transferEventState: {
					...state.transferEventState,
					eventId: action.eventId,
					modalOpen: true
				}
			};
		case TRANSFER_EVENT_REQUEST:
			return {
				...state,
				transferEventState: {
					...state.transferEventState,
					loading: true
				}
			};
		case TRANSFER_EVENT_SUCCESS:
			return {
				...state,
				event: action.result,
				transferEventState: {
					...state.transferEventState,
					loading: false,
					modalOpen: false
				}
			};
		case UNPUBLISH_EVENT_ERROR:
			return {
				...state,
				loading: false
			};
		case UPDATE_EVENT_STEP_ERROR:
			return { ...state, loading: false };
		case UPDATE_EVENT_STEP_REQUEST:
			return { ...state, error: null, loading: true };
		case UPDATE_EVENT_STEP_SUCCESS:
			return {
				...state,
				error: null,
				event: mergeResponseAndSetEventFlags(state.event, action.event),
				loading: false
			};
		case UPDATE_SLUG_ERROR:
			return {
				...state,
				save: { loading: false }
			};
		case UPDATE_SLUG_REQUEST:
			return {
				...state,
				save: { error: null, loading: true }
			};
		case UPDATE_SLUG_SUCCESS:
			return {
				...state,
				event: { ...state.event, slug: action.slug },
				save: { error: null, loading: false }
			};
		case VERIFY_ACCOUNT_MODAL_CLOSE:
			return {
				...state,
				verifyAccountModalOpen: {
					publishedLastOne: false,
					visible: false
				}
			};
		case VERIFY_ACCOUNT_MODAL_OPEN:
			return {
				...state,
				publish: {
					...state.publish,
					publishModalOpen: false
				},
				verifyAccountModalOpen: {
					publishedLastOne: !!action.publishedLastOne,
					visible: true
				}
			};

		default:
			return state;
	}
};
