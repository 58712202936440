import { Component } from "react";
import { ContentBlock } from "../components/AppBlocks";
import Page from "../components/Page";
import { H3 } from "../components/text/Text";
import AuthService from "../services/AuthService";
import EventsService from "../services/EventsService";

export const CATEGORIES = {
	FINANCIAL_AND_REPORTING: "financialAndReporting",
	ORDERS: "orders",
	ATTENDEES: "attendees",
	CHECKIN: "checkin",
	EVENT_INFORMATION: "eventInformation",
	DISCOUNT_CODES: "discountCodes",
	PAY_OUTS: "payOuts",
	APP: "app",
	MY_EVENTS: "myEvents"
};

export class PermissionWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			verified: false
		};
		this.verifyAccess = this.verifyAccess.bind(this);
		this.verifyAccess();
	}
	async verifyAccess() {
		const { eventId, category, adminReq } = this.props;
		const allowed = await verifyAccessByEventId(eventId, category, adminReq);
		this.setState({
			loading: false,
			verified: allowed
		});
	}
	render() {
		const { children } = this.props;
		const { loading, verified } = this.state;
		if (loading) {
			return null;
		}
		return verified ? children : <PermissionDenied />;
	}
}

async function verifyAccessByEventId(eventId, category, adminReq = false) {
	const event = await EventsService.get(eventId);
	const userId = AuthService.user.id;
	if ((event.userId === userId && !adminReq) || AuthService.isAdmin()) {
		return true;
	} else if (!adminReq) {
		return fullAccessCheck(event, category);
	}
	return false;
}

function fullAccessCheck(event, category) {
	const user = AuthService.user;
	const userPermissions = user.permissions || [];
	let hasPermission = false;
	if (userPermissions.length > 0) {
		userPermissions.forEach((permission) => {
			if (permission.fullAccess) {
				if (permission.fullAccess.userId === event.userId) {
					hasPermission = true;
				}
			}
		});
	}
	if (hasPermission) {
		return true;
	}
	return partialAccessCheck(event, category, userPermissions);
}

function partialAccessCheck(event, category, userPermissions) {
	let hasPermission = false;
	if (userPermissions.length > 0) {
		userPermissions.forEach((permission) => {
			if (permission.eventAccess) {
				if (
					permission.eventAccess.userId === event.userId &&
					(permission.eventAccess.events.length === 0 ||
						permission.eventAccess.events.find(
							(eventPermission) => String(eventPermission.eventId) === String(event._id)
						)) &&
					permission.eventAccess.access[category]
				) {
					hasPermission = true;
				}
			}
		});
	}
	if (hasPermission) {
		return true;
	}
	return false;
}

class PermissionDenied extends Component {
	render() {
		return (
			<Page pageName="Permission Denied">
				<ContentBlock>
					<H3>You don't have permission to visit this page.</H3>
				</ContentBlock>
			</Page>
		);
	}
}
