import googleAnalyticsIcon from "@/assets/ic_google_analytics.svg";
import googleTagManagerIcon from "@/assets/ic_google_tag_manager.svg";
import metaPixelIcon from "@/assets/ic_meta_pixel.svg";
import tiktokPixelIcon from "@/assets/ic_tiktok_pixel.svg";
import { LegacyInputField } from "@/components/Fields";
import LoadErrorView from "@/components/LoadErrorView";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { validateFBId, validateGAId, validateGoogleTagManger, validateTTId } from "@/utils/Validators";
import styled from "@emotion/styled";
import { Alert } from "@/ui/antd";
import { FC } from "react";
import { Field } from "redux-form";

const fieldStyle = {
	maxWidth: 395,
	height: 36
};

const Icon = styled.img`
	vertical-align: text-bottom;
	width: 24px;
	height: 24px;
`;

const Label = styled.div`
	font-size: 16px;
`;

const GoogleAnalyticsFieldLabel = () => (
	<Label>
		<Icon src={googleAnalyticsIcon} alt="Google Analytics Icon" /> Google Analytics ID
	</Label>
);

const GoogleTagManagerFieldLabel = () => (
	<Label>
		<Icon src={googleTagManagerIcon} alt="Google Tag Manager Icon" /> Google Tag Manager ID
	</Label>
);

const MetaPixelFieldLabel = () => (
	<Label>
		<Icon src={metaPixelIcon} alt="Meta Pixel Icon" /> Meta Pixel ID
	</Label>
);

const TikTokPixelFieldLabel = () => (
	<Label>
		<Icon src={tiktokPixelIcon} alt="TikTok Pixel Icon" /> TikTok Pixel ID
	</Label>
);

export type IntegrationSettings = {
	googleAnalyticsId?: string;
	googleTagManagerId?: string;
	facebookPixelId?: string;
	tiktokPixelId?: string;
};

type IntegrationsFieldsProps = IntegrationSettings & {
	isGlobal?: boolean;
	loading?: boolean;
	error?: any;
};

const IntegrationsFields: FC<IntegrationsFieldsProps> = ({
	isGlobal,
	loading,
	error,
	googleAnalyticsId,
	googleTagManagerId
}) => {
	const hasGTMAndGAEntered = !!(googleAnalyticsId && googleTagManagerId);

	return (
		<>
			<Alert
				showIcon
				type="info"
				message={
					<>
						Need help, see our <a onClick={OpenHelpHandler(HelpIds.analysingEventTraffic)}>Analytics Integrations</a>{" "}
						and <a onClick={OpenHelpHandler(HelpIds.advanceWidgetGuide)}>Advanced Widget Guides</a>
					</>
				}
				style={{ marginBottom: "20px" }}
			/>
			<LoadErrorView loading={!!loading} error={error}>
				<Field
					name="googleAnalyticsId"
					label={<GoogleAnalyticsFieldLabel />}
					description={`Track your event${isGlobal ? "s" : ""} through Google Analytics`}
					placeholder="G-XXXXXXXXXX"
					component={LegacyInputField}
					style={fieldStyle}
					validate={validateGAId}
				/>
				<Field
					name="googleTagManagerId"
					label={<GoogleTagManagerFieldLabel />}
					description={`Track your event${isGlobal ? "s" : ""}  using Google Tag Manager`}
					placeholder="GTM-XXXX"
					component={LegacyInputField}
					style={fieldStyle}
					validate={validateGoogleTagManger}
				/>
				{hasGTMAndGAEntered ? (
					<Alert
						type="info"
						showIcon
						message="Are you sure?"
						description="If you’re integrating Google Tag Manager, you should typically integrate your Google Analytics Tag directly via Google Tag Manager. Avoid duplicate events by removing your Google Analytics ID."
						style={{ marginBottom: 12 }}
					/>
				) : null}
				<Field
					name="facebookPixelId"
					label={<MetaPixelFieldLabel />}
					description={`Track your event${isGlobal ? "s" : ""}  using Meta Pixel`}
					placeholder="XXXXXXXXXXXXXXX"
					component={LegacyInputField}
					style={fieldStyle}
					validate={validateFBId}
				/>
				<Field
					name="tiktokPixelId"
					label={<TikTokPixelFieldLabel />}
					description={`Track your event${isGlobal ? "s" : ""} using TikTok Pixel`}
					placeholder="XXXXXXXXXXXXXXXXXXXX"
					component={LegacyInputField}
					style={fieldStyle}
					validate={validateTTId}
				/>
			</LoadErrorView>
		</>
	);
};

export { IntegrationsFields };
