import { Button, Responsive } from "@/ui";
import { CloseOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { FC, Fragment, ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

type ModalStyleProps = {
	fullscreen?: boolean;
	padding?: string;
	width?: string;
	isVisible?: boolean;
};

const StyledBackdrop = styled.div<{ isVisible: boolean }>`
	background-color: rgba(0, 0, 0, 0.5);
	display: block;
	height: 100dvh;
	left: 0;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	pointer-events: ${({ isVisible }) => (isVisible ? "auto" : "none")};
	position: fixed;
	top: 0;
	transition: all 0.15s ease-in-out;
	width: 100dvw;
	z-index: 1000;
`;

const StyledDialog = styled.dialog<ModalStyleProps>`
	${({ fullscreen, isVisible, width }) => css`
		background-color: #fff;
		border: none;
		box-shadow: ${fullscreen ? "none" : "0px 4px 12px rgba(0, 0, 0, 0.15)"};
		display: flex;
		flex-direction: column;
		height: ${fullscreen ? "100dvh" : "auto"};
		left: 0;
		margin: auto;
		max-height: ${fullscreen ? "100dvh" : "80dvh"};
		max-width: 100dvw;
		opacity: ${isVisible ? 1 : 0};
		overflow: hidden;
		padding: 0;
		pointer-events: ${isVisible ? "auto" : "none"};
		position: fixed;
		top: 50dvh;
		transform: translateY(-50%) ${isVisible ? "scale(1)" : "scale(0)"};
		transition: all 0.15s ease-in-out;
		width: ${fullscreen ? "100dvw" : width || "100dvw"};
		z-index: 1000;
	`}
`;

const StyledModalHeader = styled.div<{ small?: boolean }>`
	align-items: center;
	border-bottom: 1px solid #e8e8e8;
	display: flex;
	gap: 8px;
	justify-content: space-between;
	font-weight: bold;
	padding: ${({ small }) => (small ? "8px 16px" : "16px")};
	width: 100%;
`;

const StyledModalBody = styled.div<{ padding: string }>`
	flex-grow: 1;
	overflow-y: auto;
	padding: ${({ padding }) => padding};
`;

const ModalFooter = styled.div`
	background: transparent;
	border-radius: 0 0 2px 2px;
	border-top: 1px solid #f0f0f0;
	display: flex;
	gap: 8px;
	justify-content: flex-end;
	padding: 10px 16px;
`;

type ResponsiveModalProps = {
	closable?: boolean;
	closeButtonStyle?: "sm" | "lg";
	compactHeader?: boolean;
	fullscreen?: boolean;
	header?: ReactNode;
	onCancel?: () => void;
	open: boolean;
	padding?: string | false;
	primaryButtonAction?: () => void;
	primaryButtonText?: string;
	secondaryButtonAction?: () => void;
	secondaryButtonText?: string;
	footer?: ReactNode | false;
	top?: string;
	width?: string;
	children: ReactNode;
};

/**
 * A modal that is styled to be full screen on mobile and desktop. If width is provided, the desktop modal will be that width.
 */
export const ResponsiveModal: FC<ResponsiveModalProps> = ({
	closable,
	closeButtonStyle = "sm",
	compactHeader,
	footer,
	fullscreen = false,
	header,
	onCancel,
	primaryButtonText,
	secondaryButtonText,
	primaryButtonAction,
	secondaryButtonAction,
	open,
	padding = "24px",
	width = "100dvw",
	...props
}) => {
	const dialogRef = useRef<HTMLDialogElement>(null);
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		if (open) {
			setTimeout(() => setIsVisible(true), 10);

			const handleEscape = (event: KeyboardEvent) => {
				if (event.key === "Escape") {
					onCancel?.();
				}
			};

			window.addEventListener("keydown", handleEscape);

			return () => {
				window.removeEventListener("keydown", handleEscape);
			};
		} else {
			setIsVisible(false);
		}
	}, [open]);

	const finalFooter = footer ? (
		<ModalFooter>{footer}</ModalFooter>
	) : footer === false || footer === null ? null : (
		<ModalFooter>
			{secondaryButtonAction && (
				<Button onClick={secondaryButtonAction} variant="ghost">
					{secondaryButtonText ?? "Cancel"}
				</Button>
			)}
			<Button onClick={primaryButtonAction} variant="secondary">
				{primaryButtonText ?? "Ok"}
			</Button>
		</ModalFooter>
	);

	const closeButton = closable ? (
		<Button
			aria-label="Close"
			iconOnly={closeButtonStyle === "sm"}
			onClick={onCancel}
			style={{ marginLeft: "auto" }}
			variant={closeButtonStyle === "lg" ? "ghost" : "text"}
		>
			{closeButtonStyle === "lg" ? "Close" : <CloseOutlined />}
		</Button>
	) : null;

	const modalHeader = header ? (
		<>
			{header}
			{closeButton}
		</>
	) : (
		closeButton ?? null
	);

	return open
		? createPortal(
				<Fragment>
					<Responsive
						desktop={
							<>
								<StyledBackdrop isVisible={isVisible} onClick={onCancel} />
								<StyledDialog fullscreen={fullscreen} isVisible={isVisible} ref={dialogRef} width={width}>
									{modalHeader && <StyledModalHeader small={compactHeader}>{modalHeader}</StyledModalHeader>}
									<StyledModalBody padding={padding || "0"}>{props.children}</StyledModalBody>
									{finalFooter}
								</StyledDialog>
							</>
						}
						mobile={
							<>
								<StyledBackdrop isVisible={isVisible} onClick={onCancel} />
								<StyledDialog fullscreen={true} isVisible={isVisible} ref={dialogRef} width="100vw">
									{modalHeader && <StyledModalHeader>{modalHeader}</StyledModalHeader>}
									<StyledModalBody padding={padding || "0"}>{props.children}</StyledModalBody>
									{finalFooter}
								</StyledDialog>
							</>
						}
					/>
				</Fragment>,
				document.body
		  )
		: null;
};
