import { connect } from "react-redux";
import { Tabs } from "@/ui/antd";
import type { TabsProps } from "@/ui/antd";
import PageHeading from "@/components/PageHeading";
import type { ReduxState as GlobalReduxState } from "@/state/store";
import React, { useEffect, useState } from "react";
import AnalyticsService from "@/services/AnalyticsService";
import BarChart from "./components/BarChart";
import PieChart from "./components/PieChart";
import BarTable, { BarData } from "./components/BarTable";
import { ContentBlock } from "@/components/AppBlocks";
import { Col, Row, Select } from "@/ui/antd";
import ScoreCards from "./components/ScoreCards";
import { css } from "@emotion/css";
import LoadErrorView from "@/components/LoadErrorView";
import Funnel from "./components/Funnel";
import AuthService from "@/services/AuthService";
import H3 from "@/components/text/H3";
import { HelpIds } from "@/utils/Help";

const groupItem = css({
	minHeight: 333
});

interface AnalyticsState {
	loading: boolean;
	error: boolean;
	dateRange: string;
	data: any;
}

const Analytics = ({ currentEvent }: any) => {
	const isAdmin = AuthService.isAdmin();
	const [analyticsState, updateAnalyticsState] = useState({
		loading: true,
		error: false,
		dateRange: "last30Days",
		data: {}
	} as AnalyticsState);

	const dateRangeChange = (dateRange: string) => {
		loadAnalytics(dateRange);
	};

	useEffect(() => {
		loadAnalytics(analyticsState.dateRange);
	}, []);

	async function loadAnalytics(dateRange: string) {
		updateAnalyticsState({ ...analyticsState, loading: true, dateRange });
		const eventId = currentEvent.event._id;
		const results = await AnalyticsService.getAnalytics(eventId, currentEvent.event.timezone, dateRange);
		updateAnalyticsState({ ...analyticsState, error: false, loading: false, data: results });
	}

	const mapData = (data: any, labelKey: string, labelStr = "label", sort = true, toPercent = false): BarData[] => {
		if (!data?.length) return [];

		let onePercent = 0;
		if (toPercent) {
			const total = data.reduce((acc: number, row: any) => acc + row.total, 0);
			onePercent = total / 100;
		}

		let results = data.map((row: any) => {
			const rowData: any = { value: row.total };
			rowData[labelStr] = row[labelKey];
			if (toPercent) {
				rowData.value = Number((rowData.value / onePercent).toFixed(2));
			}
			return rowData;
		});
		if (sort) {
			results = results.sort((a: any, b: any) => b.value - a.value);
		}
		return results;
	};

	const trafficSources: BarData[] = mapData(analyticsState.data.trafficSources, "referrer");
	const viewsByCity: BarData[] = mapData(analyticsState.data.viewsByCity, "location");
	const viewsByPostcode: BarData[] = mapData(analyticsState.data.viewsByPostcode, "location");
	const viewsByCountry: BarData[] = mapData(analyticsState.data.viewsByCountry, "location");
	const channelData: BarData[] = mapData(analyticsState.data.viewByChannelGroup, "trafficSource");
	const deviceType: BarData[] = mapData(analyticsState.data.deviceType, "deviceType", "label", false, true);
	const browserType: BarData[] = mapData(analyticsState.data.browserType, "browser", "label", false, true);
	const inferredGenderPercent: BarData[] = mapData(
		analyticsState.data.inferredGenderPercent,
		"gender",
		"label",
		false,
		false
	);
	const completeByHour: BarData[] = mapData(analyticsState.data.completeByHour, "hourFormatted", "hour", false);
	const completeByDay: BarData[] = mapData(analyticsState.data.completeByDay, "dayName", "day", false);
	const eventsByUTMMedium: BarData[] = mapData(analyticsState.data.eventsByUTMMedium, "label");
	const eventsByUTMSource: BarData[] = mapData(analyticsState.data.eventsByUTMSource, "label");
	const eventsByUTMCampaign: BarData[] = mapData(analyticsState.data.eventsByUTMCampaign, "label");
	const eventsByUTMTerms: BarData[] = mapData(analyticsState.data.eventsByUTMTerms, "label");
	const eventsByUTMContent: BarData[] = mapData(analyticsState.data.eventsByUTMContent, "label");
	const hxChannelCodes: BarData[] = isAdmin ? mapData(analyticsState.data.hxChannelCodes, "hxChl") : [];

	const sourceItems = [
		{
			key: "1",
			label: "Traffic sources",
			children: (
				<div className={groupItem}>
					<BarTable colLabel="Traffic sources" valueLabel="views" data={trafficSources} />
				</div>
			)
		},
		{
			key: "2",
			label: "Channel groups",
			children: (
				<div className={groupItem}>
					<BarTable colLabel="Channel groups" valueLabel="views" data={channelData} />
				</div>
			)
		},
		{
			key: "3",
			label: "UTM source",
			children: (
				<div className={groupItem}>
					<BarTable colLabel="UTM Source" valueLabel="views" data={eventsByUTMSource} />
				</div>
			)
		},
		{
			key: "4",
			label: "UTM medium",
			children: (
				<div className={groupItem}>
					<BarTable colLabel="UTM Medium" valueLabel="views" data={eventsByUTMMedium} />
				</div>
			)
		},

		{
			key: "5",
			label: "UTM campaign",
			children: (
				<div className={groupItem}>
					<BarTable colLabel="UTM campaign" valueLabel="views" data={eventsByUTMCampaign} />
				</div>
			)
		},
		{
			key: "6",
			label: "UTM terms",
			children: (
				<div className={groupItem}>
					<BarTable colLabel="UTM terms" valueLabel="views" data={eventsByUTMTerms} />
				</div>
			)
		},
		{
			key: "7",
			label: "UTM content",
			children: (
				<div className={groupItem}>
					<BarTable colLabel="UTM content" valueLabel="views" data={eventsByUTMContent} />
				</div>
			)
		}
	];
	const postcodeLabel = currentEvent.event.location === "US" ? "Zip codes" : "Postcodes";
	const locationItems: TabsProps["items"] = [
		{
			key: "1",
			label: postcodeLabel,
			children: (
				<div className={groupItem}>
					<BarTable colLabel={postcodeLabel} valueLabel="views" data={viewsByPostcode} />
				</div>
			)
		},
		{
			key: "2",
			label: "Cities",
			children: (
				<div className={groupItem}>
					<BarTable colLabel="Cities" valueLabel="views" data={viewsByCity} />
				</div>
			)
		},
		{
			key: "3",
			label: "Countries",
			children: (
				<div className={groupItem}>
					<BarTable colLabel="Countries" valueLabel="views" data={viewsByCountry} />
				</div>
			)
		}
	];

	return (
		<>
			<PageHeading
				title="Analytics"
				helpButton={{
					link: HelpIds.eventAnalytics,
					title: "Help - Analytics"
				}}
			>
				<Select
					defaultValue="last30Days"
					onChange={dateRangeChange}
					options={[
						{ label: "Today", value: "today" },
						{ label: "Yesterday", value: "yesterday" },
						{ label: "Last 7 days", value: "last7Days" },
						{ label: "Last 30 days", value: "last30Days" },
						{ label: "Last month", value: "lastMonth" },
						{ label: "Year to date", value: "yearToDate" }
					]}
				/>
			</PageHeading>
			<LoadErrorView loading={analyticsState.loading} renderChildren={false}>
				<ScoreCards analyticsData={analyticsState.data || null} />
				<Row gutter={16}>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<ContentBlock>
							<Tabs defaultActiveKey="1" items={sourceItems} />
						</ContentBlock>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<ContentBlock>
							<Tabs defaultActiveKey="1" items={locationItems} />
						</ContentBlock>
					</Col>
				</Row>
				<Row gutter={16} wrap={true}>
					<Col sm={24} md={12} xl={8}>
						<ContentBlock>
							<PieChart title="Devices" colLabel="Type" valueLabel="views" data={deviceType} />
						</ContentBlock>
					</Col>
					<Col sm={24} md={12} xl={8}>
						<ContentBlock>
							<PieChart title="Browser" colLabel="Browser" valueLabel="views" data={browserType} />
						</ContentBlock>
					</Col>
					<Col sm={24} md={12} xl={8}>
						<ContentBlock>
							<PieChart
								title="Inferred Gender"
								colLabel="Inferred Gender"
								valueLabel="views"
								data={inferredGenderPercent}
							/>
						</ContentBlock>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<ContentBlock>
							<BarChart title="Orders by hour" data={completeByHour} dataKey="hour" />
						</ContentBlock>
					</Col>
					<Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<ContentBlock>
							<BarChart title="Orders by day" data={completeByDay} dataKey="day" />
						</ContentBlock>
					</Col>
				</Row>
				{isAdmin ? (
					<>
						{" "}
						<hr />
						<H3>Admin beta graphs</H3>
						<Row gutter={16}>
							<Col xs={24} sm={24} md={8}>
								<ContentBlock>
									<BarTable colLabel="Humanitix channels" valueLabel="views" data={hxChannelCodes} />
								</ContentBlock>
							</Col>
							<Col xs={24} sm={24} md={16}>
								<ContentBlock>
									<Funnel funnelSteps={analyticsState.data.funnel} />
								</ContentBlock>
							</Col>
						</Row>
					</>
				) : null}
			</LoadErrorView>
		</>
	);
};

export default connect((state: GlobalReduxState) => ({
	currentEvent: state.currentEvent
}))(Analytics);
