import { dayjs } from "@/libs/dayjs";
import AuthService from "@/services/AuthService";
import MetricService from "@/services/MetricService";
import { getDateRange } from "@/utils/metrics";
import { getLocationFromState } from "./locationActions";

export const UPDATE_DASHBOARD_PERIOD = "UPDATE_DASHBOARD_PERIOD";
export const UPDATE_DASHBOARD_CHANNEL = "UPDATE_DASHBOARD_CHANNEL";
export const UPDATE_DASHBOARD_CUSTOM_DATE_RANGE = "UPDATE_DASHBOARD_CUSTOM_DATE_RANGE";
export const UPDATE_DASHBOARD_CUSTOM_DATE_RANGE_PICKED = "UPDATE_DASHBOARD_CUSTOM_DATE_RANGE_PICKED";

export const GET_DASHBOARD_METRICS_REQUEST = "GET_DASHBOARD_METRICS_REQUEST";
export const GET_DASHBOARD_METRICS_SUCCESS = "GET_DASHBOARD_METRICS_SUCCESS";
export const GET_DASHBOARD_METRICS_ERROR = "GET_DASHBOARD_METRICS_ERROR";

export const GET_EVENT_METRICS_REQUEST = "GET_EVENT_METRICS_REQUEST";
export const GET_EVENT_METRICS_SUCCESS = "GET_EVENT_METRICS_SUCCESS";
export const GET_EVENT_METRICS_ERROR = "GET_EVENT_METRICS_ERROR";

export const GET_HISTORICAL_SALES_REQUEST = "GET_HISTORICAL_SALES_REQUEST";
export const GET_HISTORICAL_SALES_SUCCESS = "GET_HISTORICAL_SALES_SUCCESS";
export const GET_HISTORICAL_SALES_ERROR = "GET_HISTORICAL_SALES_ERROR";

export const GET_USERS_CHART_REQUEST = "GET_USERS_CHART_REQUEST";
export const GET_USERS_CHART_SUCCESS = "GET_USERS_CHART_SUCCESS";
export const GET_USERS_CHART_ERROR = "GET_USERS_CHART_ERROR";

export const GET_SALES_CHART_REQUEST = "GET_SALES_CHART_REQUEST";
export const GET_SALES_CHART_SUCCESS = "GET_SALES_CHART_SUCCESS";
export const GET_SALES_CHART_ERROR = "GET_SALES_CHART_ERROR";

export const GET_TICKET_SALES_REQUEST = "GET_TICKET_SALES_REQUEST";
export const GET_TICKET_SALES_SUCCESS = "GET_TICKET_SALES_SUCCESS";
export const GET_TICKET_SALES_ERROR = "GET_TICKET_SALES_ERROR";

export const GET_EVENT_SALES_OVERVIEW_REQUEST = "GET_EVENT_SALES_OVERVIEW_REQUEST";
export const GET_EVENT_SALES_OVERVIEW_SUCCESS = "GET_EVENT_SALES_OVERVIEW_SUCCESS";
export const GET_EVENT_SALES_OVERVIEW_ERROR = "GET_EVENT_SALES_OVERVIEW_ERROR";

export const GET_DASHBOARD_SUMMARY_REQUEST = "GET_DASHBOARD_SUMMARY_REQUEST";
export const GET_DASHBOARD_SUMMARY_SUCCESS = "GET_DASHBOARD_SUMMARY_SUCCESS";
export const GET_DASHBOARD_SUMMARY_ERROR = "GET_DASHBOARD_SUMMARY_ERROR";

export const GET_DASHBOARD_OVERVIEW_REQUEST = "GET_DASHBOARD_OVERVIEW_REQUEST";
export const GET_DASHBOARD_OVERVIEW_SUCCESS = "GET_DASHBOARD_OVERVIEW_SUCCESS";
export const GET_DASHBOARD_OVERVIEW_ERROR = "GET_DASHBOARD_OVERVIEW_ERROR";

export const getDashboardMetrics = (timeScale, channel) => {
	return (dispatch, state) => {
		dashboardMetrics(dispatch, timeScale, channel, state());
	};
};

export const getSalesByTicketType = (eventId, dateRange, eventDateId) => {
	return (dispatch) => {
		dispatch({ type: GET_TICKET_SALES_REQUEST });
		dateRange.startDate = dateRange.startDate ? dayjs(dateRange.startDate).startOf("day") : undefined;
		dateRange.endDate = dateRange.endDate ? dayjs(dateRange.endDate).endOf("day") : undefined;
		MetricService.getSalesByTicketType(eventId, dateRange, eventDateId)
			.then((data) => {
				dispatch({ type: GET_TICKET_SALES_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: GET_TICKET_SALES_ERROR, error: err });
			});
	};
};

export const getHistoricalSales = (timeScale, eventId) => {
	return (dispatch) => {
		const location = !eventId ? getLocationFromState(true) : false;
		dispatch({ type: GET_HISTORICAL_SALES_REQUEST, dashboard: !eventId });
		const dateRange = getDateRange(timeScale);
		MetricService.historicalSales(dateRange, eventId, AuthService.isAdmin(), location)
			.then((data) => {
				dispatch({
					type: GET_HISTORICAL_SALES_SUCCESS,
					dashboard: !eventId,
					data
				});
			})
			.catch((err) => {
				dispatch({
					type: GET_HISTORICAL_SALES_ERROR,
					dashboard: !eventId,
					error: err
				});
			});
	};
};

export const getUsersChart = (timeScale) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: GET_USERS_CHART_REQUEST });
		const dateRange = getDateRange(timeScale);
		MetricService.users(dateRange, location)
			.then((data) => {
				dispatch({ type: GET_USERS_CHART_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: GET_USERS_CHART_ERROR, error: err });
			});
	};
};

export const getSales = (timeScale, eventId) => {
	return (dispatch) => {
		const location = !eventId ? getLocationFromState(true) : false;
		dispatch({ type: GET_SALES_CHART_REQUEST, dashboard: !eventId });
		const dateRange = getDateRange(timeScale);
		MetricService.sales(dateRange, eventId, AuthService.isAdmin(), location)
			.then((data) => {
				dispatch({
					type: GET_SALES_CHART_SUCCESS,
					dashboard: !eventId,
					data
				});
			})
			.catch((err) => {
				dispatch({
					type: GET_SALES_CHART_ERROR,
					dashboard: !eventId,
					error: err
				});
			});
	};
};

export const getEventSalesOverview = (eventId, timeScale, channel, eventDateId) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: GET_EVENT_SALES_OVERVIEW_REQUEST });
		let dateRange = getDateRange(timeScale);
		dateRange = timeScale === "allTime" ? { timezone: dateRange.timezone } : dateRange;
		MetricService.getEventSalesOverviewByDate(eventId, dateRange, channel, location, eventDateId)
			.then((data) => {
				dispatch({ type: GET_EVENT_SALES_OVERVIEW_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: GET_EVENT_SALES_OVERVIEW_ERROR, error: err });
			});
	};
};

export const updateDashboardPeriod = (period) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_DASHBOARD_PERIOD, data: { period } });
	};
};

export const updateDashboardChannel = (channel) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_DASHBOARD_CHANNEL, data: { channel } });
	};
};

export const updateDashboardCustomDateRange = (customDateRange) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_DASHBOARD_CUSTOM_DATE_RANGE, data: { customDateRange } });
	};
};

export const updateDashboardCustomDateRangePicked = (isCustomRangePicked) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_DASHBOARD_CUSTOM_DATE_RANGE_PICKED, data: { isCustomRangePicked } });
	};
};

export const getDashboardSummary = (timeScale) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: GET_DASHBOARD_SUMMARY_REQUEST });
		const dateRange = getDateRange(timeScale);

		MetricService.getDashboardSummaryByDate(dateRange, location)
			.then((data) => {
				dispatch({ type: GET_DASHBOARD_SUMMARY_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: GET_DASHBOARD_SUMMARY_ERROR, error: err });
			});
	};
};

export const getDashboardOverview = (timeScale, channel) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: GET_DASHBOARD_OVERVIEW_REQUEST });

		const dateRange = getDateRange(timeScale);
		MetricService.getDashboardOverviewByDate(dateRange, channel, AuthService.isAdmin(), location)
			.then((data) => {
				dispatch({ type: GET_DASHBOARD_OVERVIEW_SUCCESS, data });
			})
			.catch((err) => {
				dispatch({ type: GET_DASHBOARD_OVERVIEW_ERROR, error: err });
			});
	};
};

export const getEventMetrics = (eventId, timeScale, channel) => {
	return (dispatch) => {
		eventMetrics(dispatch, eventId, timeScale, channel);
	};
};

const eventMetrics = (dispatch, eventId, timeScale, channel) => {
	const location = getLocationFromState(true);
	dispatch({ type: GET_EVENT_METRICS_REQUEST });
	const dateRange = getDateRange(timeScale);
	MetricService.event(eventId, dateRange, channel, location)
		.then((data) => {
			dispatch({ type: GET_EVENT_METRICS_SUCCESS, data });
		})
		.catch((err) => {
			dispatch({ type: GET_EVENT_METRICS_ERROR, error: err });
		});
};

const dashboardMetrics = (dispatch, timeScale, channel) => {
	const location = getLocationFromState(true);
	dispatch({ type: GET_DASHBOARD_METRICS_REQUEST });
	const dateRange = getDateRange(timeScale);
	MetricService.home(dateRange, channel, AuthService.isAdmin(), location)
		.then((data) => {
			dispatch({ type: GET_DASHBOARD_METRICS_SUCCESS, data });
		})
		.catch((err) => {
			dispatch({ type: GET_DASHBOARD_METRICS_ERROR, error: err });
		});
};
