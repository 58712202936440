import { TaxCalculations, TaxSettings } from "@hx/utilities";
import { Alert } from "@/ui/antd";
import { Component } from "react";
import { Link } from "react-router-dom";
import { Field } from "redux-form";
import { LegacyInputField, InputNumberField, RadioField, SelectField } from "@/components/Fields";
import AuthService from "@/services/AuthService";
import { currency } from "@/utils/Format";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { percentFormat, percentParse } from "@/utils/InputFormatters";
import { checkLength, customTaxNameValidator, required } from "@/utils/Validators";
import { isEventInNexusState } from "@/utils/Tax";
import Label from "@/components/text/Label";
import P from "@/components/text/P";

const MAX_USER_ENTERED_CUSTOM_TAX = 10;

const limitCustomSalesTaxNameLength = checkLength("Name needs to be less than 15 characters", 16, "<");
const customSalesTaxIdRequired = required("A tax id is required");

class TaxSettingsForm extends Component {
	constructor(props) {
		super(props);
		this.salesTaxRateRequired = this.salesTaxRateRequired.bind(this);
		this.customTaxNameRequired = this.customTaxNameRequired.bind(this);
	}

	salesTaxRateRequired = required("A sales tax rate is required");
	customTaxNameRequired = required("A custom sales tax name is required");

	render() {
		const { salesTaxSettings, fromPath = "", location, customSalesTaxSettings, tickets, eventLocation } = this.props;
		const taxInfo = TaxSettings.getEventTaxInfo(salesTaxSettings, location);
		const totalWithTax = TaxCalculations.addTax(100, taxInfo.rate);
		const isAdmin = AuthService.isAdmin();
		const ticketOptions = tickets
			?.filter((t) => !t.deleted && !t.isDonation)
			?.map((ticketType) => {
				return {
					label: ticketType.name,
					value: ticketType._id
				};
			});

		const taxExemptInformation = (
			<>
				<Label>Is your event exempt from sales tax?</Label>
				<P>
					If your event is sales tax exempt then you will need to apply for a sales tax exemption. To do so please
					follow{" "}
					<a
						href="https://help.humanitix.com/en/articles/9114293-how-to-apply-for-us-sales-tax-exemption"
						target="_blank"
						rel="noopener noreferrer"
					>
						this guide
					</a>
					.
				</P>
			</>
		);

		const eventIsInNexusInfo = (
			<>
				<Label>Let's talk sales tax</Label>
				<P>
					Your event is located in a state where Humanitix is considered a marketplace facilitator. For events located
					in{" "}
					<a
						href="https://help.humanitix.com/en/articles/8906163-about-us-state-sales-tax#h_23472c8683"
						target="_blank"
						rel="noopener noreferrer"
					>
						one of these states,
					</a>{" "}
					Humanitix will automatically calculate, collect and remit sales tax on your behalf.{" "}
					<a
						href="https://help.humanitix.com/en/articles/8906163-about-us-state-sales-tax"
						target="_blank"
						rel="noopener noreferrer"
					>
						Here
					</a>{" "}
					is a more detailed breakdown of marketplace facilitator tax laws and how we handle sales tax.
				</P>
				<br />
			</>
		);

		const eventIsInNexusState = isEventInNexusState(eventLocation, location);

		const salesTaxSettingsFields = (
			<>
				<Field name={`${fromPath}salesTaxSettings.companyName`} label="Company name" component={LegacyInputField} />
				<Field name={`${fromPath}salesTaxSettings.taxId`} label={taxInfo.taxIdName} component={LegacyInputField} />
				<Field
					name={`${fromPath}salesTaxSettings.companyAddress`}
					label="Company address"
					component={LegacyInputField}
				/>
			</>
		);

		const salesTaxSettingsEnabled = salesTaxSettings && salesTaxSettings.enabled && !eventIsInNexusState;

		const salesTaxSettingsEnabledFields = salesTaxSettingsEnabled ? (
			<div>
				<Field
					name={`${fromPath}salesTaxSettings.passOn`}
					label="Pass on taxes"
					description={
						<span>
							Example of a ticket priced at {currency(100)} (based on {taxInfo.rate}% {taxInfo.name}):
							<br />
							If passing on, customers will see the final price of "{currency(totalWithTax)} (inc. {taxInfo.name})"
							<br />
							If absorbing, customers will see the final price of "{currency(100)} (inc. {taxInfo.name})"
							<br />
						</span>
					}
					component={RadioField}
					button
					options={[
						{
							label: `Pass on ${taxInfo.name}`,
							value: true
						},
						{
							label: `Absorb ${taxInfo.name}`,
							value: false
						}
					]}
				/>
				{!taxInfo.locked ? (
					<Field
						name={`${fromPath}salesTaxSettings.rate`}
						label="Sales tax rate"
						component={InputNumberField}
						min={0}
						max={100}
						precision={2}
						formatter={percentFormat}
						parser={percentParse}
						validate={this.salesTaxRateRequired}
					/>
				) : null}
			</div>
		) : null;

		const customSalesTaxSettingsEnabledFields = customSalesTaxSettings?.enabled ? (
			<>
				<Field
					label="Pass on or absorb custom tax"
					name={`${fromPath}customSalesTaxSettings.distribution`}
					description="Should the custom tax be passed on or absorbed?"
					component={RadioField}
					button
					options={[
						{
							label: "Pass on",
							value: "passOn"
						},
						{
							label: "Absorb",
							value: "absorb"
						}
					]}
				/>
				<Field
					name={`${fromPath}customSalesTaxSettings.percentage`}
					label="Custom tax rate"
					description="Maximum allowed custom tax rate is 10%, please contact Humanitix if you require a higher rate."
					component={InputNumberField}
					min={0}
					max={
						isAdmin || customSalesTaxSettings.percentage > MAX_USER_ENTERED_CUSTOM_TAX
							? 100
							: MAX_USER_ENTERED_CUSTOM_TAX
					}
					precision={2}
					formatter={percentFormat}
					parser={percentParse}
					validate={this.salesTaxRateRequired}
					disabled={customSalesTaxSettings.percentage > MAX_USER_ENTERED_CUSTOM_TAX && !isAdmin}
					required
				/>
				<Field
					name={`${fromPath}customSalesTaxSettings.name`}
					label="Custom tax name"
					description="Will be visible to ticket buyers"
					component={LegacyInputField}
					required
					validate={[this.customTaxNameRequired, limitCustomSalesTaxNameLength, customTaxNameValidator]}
				/>
				<Field
					name={`${fromPath}customSalesTaxSettings.salesTaxId`}
					label="Custom tax Id"
					component={LegacyInputField}
					required
					validate={customSalesTaxIdRequired}
				/>

				<Field
					label="Which tickets does this custom tax apply to?"
					name={`${fromPath}customSalesTaxSettings.appliesToAll`}
					component={RadioField}
					button
					options={[
						{
							label: "All",
							value: true
						},
						{
							label: "Select ticket types",
							value: false
						}
					]}
				/>
				{!customSalesTaxSettings.appliesToAll ? (
					<Field
						name={`${fromPath}customSalesTaxSettings.appliesToTicketTypeIds`}
						label="Select ticket type(s)"
						component={SelectField}
						mode="multiple"
						options={ticketOptions}
						placeholder="All ticket types"
					/>
				) : null}
			</>
		) : null;

		const isANZ = location === "AU" || location === "NZ";
		const shouldShowSalesTaxSettingsFields = isANZ || salesTaxSettingsEnabled;

		return (
			<>
				{!eventIsInNexusState ? (
					<Field
						label={taxInfo.name}
						name={`${fromPath}salesTaxSettings.enabled`}
						description={`Is your event subject to ${taxInfo.name}?`}
						component={RadioField}
						button
						options={[
							{
								label: "No",
								value: false
							},
							{
								label: "Yes",
								value: true
							}
						]}
					/>
				) : (
					eventIsInNexusInfo
				)}
				{shouldShowSalesTaxSettingsFields && salesTaxSettingsFields}
				{salesTaxSettingsEnabledFields}
				{location === "US" && !fromPath?.startsWith("defaultPaymentOptions") && eventIsInNexusState
					? taxExemptInformation
					: null}
				{fromPath === "" ? (
					<Alert
						message={
							<>
								Please also set your default {taxInfo.taxIdName} for all your events in your{" "}
								<Link to="/console/account/profile/default-payment-setting">Default payment settings</Link>{" "}
							</>
						}
						type="info"
						showIcon
					/>
				) : null}
				{location === "US" && !fromPath?.startsWith("defaultPaymentOptions") ? (
					<>
						<hr />
						<Field
							label="Custom tax"
							name={`${fromPath}customSalesTaxSettings.enabled`}
							description="Is your event subject to other special tax?"
							component={RadioField}
							button
							options={[
								{
									label: "No",
									value: false
								},
								{
									label: "Yes",
									value: true
								}
							]}
						/>
						{customSalesTaxSettingsEnabledFields}
						<Alert
							type="info"
							showIcon
							message={
								<>
									For more information regarding custom tax please click{" "}
									<a onClick={OpenHelpHandler(HelpIds.tax)}>here</a>{" "}
								</>
							}
						/>
					</>
				) : null}
			</>
		);
	}
}
export default TaxSettingsForm;
