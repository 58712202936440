import icon from "@/assets/menu/ic_user.svg";
import AuthService from "../../AuthService";

const evaluateSettingsChildren = () => {
	const MyAccount = {
		label: "My account",
		key: "account-myaccount",
		subMenu: true,
		path: `/console/account`,
		children: [
			{
				label: "Profile",
				path: `/console/account`,
				key: "account-myaccount-account"
			},
			{
				label: "Default settings",
				path: `/console/account/profile/default-setting`,
				key: "account-myaccount-default-setting"
			},
			{
				label: "Default payment settings",
				path: `/console/account/profile/default-payment-setting`,
				key: "account-myaccount-default-payment-setting"
			},
			{
				label: "Default gateways",
				path: `/console/account/profile/default-gateways`,
				key: "account-myaccount-default-gateways"
			},
			{
				label: "Default notifications",
				path: `/console/account/profile/alerts`,
				key: "account-myaccount-alerts"
			},
			{
				label: AuthService.hasEventInTheUS() ? "Tax & bank details" : "Bank accounts",
				path: `/console/account/profile/bank-accounts`,
				key: "account-myaccount-bank-accounts"
			}
		]
	};

	const Advanced = {
		label: "Advanced",
		key: "account-advanced",
		subMenu: true,
		path: `/console/account/advanced/integrations`,
		children: [
			{
				label: "Integrations",
				path: "/console/account/advanced/integrations",
				key: "account-advanced-integrations"
			},
			{
				label: "Public API key",
				path: `/console/account/advanced/api-key`,
				key: "account-advanced-api-key"
			},
			{
				label: "Global widgets",
				path: `/console/account/advanced/global-widgets`,
				key: "account-advanced-global-widgets"
			},
			{
				label: "Templates",
				path: `/console/account/advanced/templates`,
				key: "account-advanced-templates"
			}
		]
	};

	if (AuthService.hasTagAccess()) {
		Advanced.children.push({
			label: "Tags",
			path: "/console/account/advanced/tags",
			key: "account-advanced-tags"
		});
	}

	if (AuthService.hasReferralProgram()) {
		Advanced.children.splice(3, 0, {
			label: "Ambassador program",
			path: `/console/account/advanced/referral-program`,
			key: "account-advanced-referral-program"
		});
	}

	const settingsChildren = [
		MyAccount,
		{
			label: "Permissions",
			path: "/console/account/authorization",
			key: "account-authorization"
		},
		{
			label: "Hosts",
			path: "/console/account/hosts",
			key: "account-hosts"
		},
		Advanced
	];

	return settingsChildren;
};

const Account = () => {
	return [
		{
			label: "Account",
			subMenu: true,
			key: "account",
			path: "/console/account",
			children: evaluateSettingsChildren(),
			icon
		}
	];
};

export default Account;
