import { css } from "@emotion/css";
import { Component } from "react";
import { connect } from "react-redux";
import { change, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { P } from "@/components/text/Text";
import { BankAccountSelectionDropdown } from "@/events/event/payments/BankAccountSelectionDropdown";
import AuthService from "@/services/AuthService";
import { getUserReferral, updateUserReferralBankAccount } from "@/state/actions/referralActions";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { checkLength, onlyNumbers, required } from "@/utils/Validators";

const formName = "referralPaymentSettings";

const spacer = css({
	margin: "12px 0",
	borderTop: "1px solid #dedede"
});
/**
 * Referral program payment settings
 *
 * @class Payment
 * @extends {Component}
 */
class Payment extends Component {
	componentWillMount() {
		this.load();
	}

	load = () => {
		const { getUserReferral } = this.props;
		const userId = AuthService.user.id;
		getUserReferral(userId);
	};

	save = (values) => {
		const { updateUserReferralBankAccount } = this.props;
		const userId = AuthService.user.id;
		updateUserReferralBankAccount(userId, values);
	};

	//form validators
	nameRequired = required("An account name is required");
	fieldRequired = required("This is a required field");
	accountOnlyNumbers = onlyNumbers("This field can only contain numbers");
	bsbCheck = checkLength("BSB needs to contain six digits", 6, "==");
	selectedProjectIdRequired = required("A project is required");

	changeFieldValue = (field, value) => {
		const { dispatch } = this.props;
		dispatch(change(formName, field, value));
	};

	render() {
		const { userReferrals, handleSubmit, authUser } = this.props;

		return (
			<LoadErrorView loading={userReferrals.loading} error={userReferrals.error} retryAction={() => this.load()}>
				<form onSubmit={handleSubmit(this.save)}>
					<ContentBlock>
						<P>Please select a bank account to payout the money you have earned</P>
						<div>
							<div className={spacer} />
							<BankAccountSelectionDropdown
								userId={authUser.id}
								location={authUser.location}
								changeFieldValue={this.changeFieldValue}
							/>
						</div>
					</ContentBlock>
					<FormBar>
						<LegacyButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							id="submitButton"
							ariaLabel="Save"
							size="large"
						>
							Save
						</LegacyButton>
					</FormBar>
				</form>
			</LoadErrorView>
		);
	}
}

Payment = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(Payment);

export default connect(
	(state) => {
		const initialValues = {};
		if (state?.userReferrals?.referral?.bankAccountId) {
			initialValues.userBankAccountId = state.userReferrals.referral.bankAccountId;
		}

		return {
			initialValues,
			userReferrals: state.userReferrals,
			authUser: state.auth.user
		};
	},
	{
		getUserReferral,
		updateUserReferralBankAccount
	}
)(Payment);
