import AuthService from "@/services/AuthService";
import { CypressService, IS_REQUEST, IS_SUCCESS } from "@/services/CypressService";
import { notification } from "@/ui/antd";
import { toggleApplyToAllModal } from "./applyToAllActions";
import { onUserLocationSet } from "./locationActions";

export const SIGN_UP_REFRESH = "SIGN_UP_REFRESH";
export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";

const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const CLEAR_STATE = "CLEAR_STATE";
export const SET_USER = "SET_USER";
export const ON_USER_EMAIL_VERIFIED = "ON_USER_EMAIL_VERIFIED";

// Cypress use
export const AUTO_PASS_RECAPTCHA = "AUTO_PASS_RECAPTCHA";

export const afterLoginActions = (user, dispatch, navigate, from) => {
	dispatch({ type: CLEAR_STATE });
	onUserLocationSet(user.location)(dispatch);
	setUser(user)(dispatch);
	const path = from && from !== "/console" ? from : "/console/dashboard";
	navigate(path);
};

export const afterSignupActions = (createdUser, dispatch, navigate) => {
	//signUpSuccess can trigger the organiser onBoarding page.
	notification.success({
		message: "Welcome",
		description: "Welcome to Humanitix!"
	});
	dispatch({ type: SIGN_UP_SUCCESS, user: createdUser });
	CypressService.signUp(IS_SUCCESS);
	setTimeout(() => {
		// Delay to allow the state to update
		createdUser.purpose === "organiser"
			? navigate("/onboarding-organiser/1?newuser=true")
			: navigate("/console/dashboard?newuser=true");
	}, 100);
};

export const setUser = (user) => {
	return (dispatch) => {
		onUserLocationSet(user.location)(dispatch);
		dispatch({ type: SET_USER, user });
	};
};

export const update = (userData, showNotification = true) => {
	return (dispatch) => {
		dispatch({ type: UPDATE_USER_REQUEST });
		AuthService.update(userData)
			.then((user) => {
				onUserLocationSet(user.location)(dispatch);
				dispatch({ type: UPDATE_USER_SUCCESS, user });
				if (userData.applyToAllRequired) {
					dispatch(toggleApplyToAllModal({ stateToApplyToModal: true }));
				} else if (showNotification) {
					notification.success({
						message: "Updated",
						description: "Your details have been updated"
					});
				}
			})
			.catch((err) => {
				dispatch({ type: UPDATE_USER_ERROR });
				notification.error({ message: "Error", description: err.message });
			});
	};
};

export function launchMicrosoftSignIn(email, config) {
	AuthService.launchMicrosoftSignIn(email, config);
}

export const refreshSignUp = () => {
	return (dispatch) => {
		dispatch({ type: SIGN_UP_REFRESH });
	};
};

export const signUp = (values, navigate, onError) => {
	return (dispatch) => {
		dispatch({ type: SIGN_UP_REQUEST });
		CypressService.signUp(IS_REQUEST);
		AuthService.signUp(values)
			.then((createdUser) => {
				onUserLocationSet(createdUser.location)(dispatch);
				afterSignupActions(createdUser, dispatch, navigate);
			})
			.catch((err) => {
				notification.error({ message: "Oops", description: err.message });
				dispatch({ type: SIGN_UP_ERROR, error: err.message });
				onError();
			});
	};
};

export const logout = () => {
	return (dispatch) => {
		AuthService.signOut();
		dispatch({ type: CLEAR_STATE });
	};
};
