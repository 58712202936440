import { notification } from "@/ui/antd";
import AuthService from "../../services/AuthService";
import UsersService from "../../services/UsersService";
import { toggleApplyToAllModal } from "./applyToAllActions";
import { setUser } from "./authActions";
import { savePaymentOptions } from "./eventActions";
import { endEditingBankAccount } from "./bankAccountsActions";
import { goToPathWithSearchState } from "@/utils/Urls";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const CLEAR_USER = "CLEAR_USER";

export const SAVE_USER_REQUEST = "SAVE_USER_REQUEST";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_ERROR = "SAVE_USER_ERROR";
export const SAVE_USER_CANCEL = "SAVE_USER_CANCEL";

export const FIND_USER_REQUEST = "FIND_USER_REQUEST";
export const FIND_USER_SUCCESS = "FIND_USER_SUCCESS";
export const FIND_USER_ERROR = "FIND_USER_ERROR";

export const PUT_API_KEY_REQUEST = "PUT_API_KEY_REQUEST";
export const PUT_API_KEY_SUCCESS = "PUT_API_KEY_SUCCESS";
export const PUT_API_KEY_ERROR = "PUT_API_KEY_ERROR";

export const TRANSFER_ACCOUNT_REQUEST = "TRANSFER_ACCOUNT_REQUEST";
export const TRANSFER_ACCOUNT_SUCCESS = "TRANSFER_ACCOUNT_SUCCESS";
export const TRANSFER_ACCOUNT_ERROR = "TRANSFER_ACCOUNT_REQUEST";
export const TRANSFER_ACCOUNT_OPEN = "TRANSFER_ACCOUNT_OPEN";
export const TRANSFER_ACCOUNT_CLOSE = "TRANSFER_ACCOUNT_CLOSE";

export const REQUEST_OWNER_REQUEST = "REQUEST_OWNER_REQUEST";
export const REQUEST_OWNER_SUCCESS = "REQUEST_OWNER_SUCCESS";
export const REQUEST_OWNER_ERROR = "REQUEST_OWNER_REQUEST";

export const GET_USER_FEE_STRUCTURE_REQUEST = "GET_USER_FEE_STRUCTURE_REQUEST";
export const GET_USER_FEE_STRUCTURE_SUCCESS = "GET_USER_FEE_STRUCTURE_SUCCESS";
export const GET_USER_FEE_STRUCTURE_ERROR = "GET_USER_FEE_STRUCTURE_ERROR";

export const WARNING_MODAL_OPEN = "WARNING_MODAL_OPEN";
export const WARNING_MODAL_CLOSE = "WARNING_MODAL_CLOSE";

export const OPEN_FEE_APPLIED_MODAL = "OPEN_FEE_APPLIED_MODAL";
export const CLOSE_FEE_APPLIED_MODAL = "CLOSE_FEE_APPLIED_MODAL";

const CONNECT_CALLBACK_REQUEST = "CONNECT_CALLBACK_REQUEST";
const CONNECT_CALLBACK_SUCCESS = "CONNECT_CALLBACK_SUCCESS";
const CONNECT_CALLBACK_ERROR = "CONNECT_CALLBACK_ERROR";

export const HUBSPOT_DISCONNECT_REQUEST = "HUBSPOT_DISCONNECT_REQUEST";
export const HUBSPOT_DISCONNECT_SUCCESS = "HUBSPOT_DISCONNECT_SUCCESS";
export const HUBSPOT_DISCONNECT_ERROR = "HUBSPOT_DISCONNECT_ERROR";

export const HUBSPOT_SETTINGS_REQUEST = "HUBSPOT_SETTINGS_REQUEST";
export const HUBSPOT_SETTINGS_SUCCESS = "HUBSPOT_SETTINGS_SUCCESS";
export const HUBSPOT_SETTINGS_ERROR = "HUBSPOT_SETTINGS_ERROR";

export const getUser = (id) => {
	return (dispatch) => {
		dispatch({ type: GET_USER_REQUEST });
		UsersService.get(id)
			.then((user) => {
				dispatch({ type: GET_USER_SUCCESS, user });
			})
			.catch((err) => {
				dispatch({ type: GET_USER_ERROR, error: err });
			});
	};
};

export const getFeeStructure = (id, location) => {
	return (dispatch) => {
		dispatch({ type: GET_USER_FEE_STRUCTURE_REQUEST });
		UsersService.getFeeStructure(id, location)
			.then((feeStructure) => {
				dispatch({ type: GET_USER_FEE_STRUCTURE_SUCCESS, feeStructure });
			})
			.catch((error) => {
				dispatch({ type: GET_USER_FEE_STRUCTURE_ERROR, error });
			});
	};
};

export const updateUser = (id) => {
	return (dispatch) => {
		UsersService.get(id).then((user) => {
			dispatch({ type: GET_USER_SUCCESS, user });
		});
	};
};

export const createNewApiKey = (id) => {
	return (dispatch) => {
		dispatch({ type: PUT_API_KEY_REQUEST });
		UsersService.createNewApiKey(id)
			.then((userData) => {
				setUser(userData)(dispatch);
				dispatch({ type: PUT_API_KEY_SUCCESS });
			})
			.catch((err) => {
				dispatch({ type: PUT_API_KEY_ERROR, error: err.message });
				notification.error({
					message: "Error",
					description: "Failed to put new api key"
				});
			});
	};
};

export const clearUser = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_USER });
	};
};

export const forgotPassword = (email) => {
	return () => {
		AuthService.forgotPassword(email)
			.then(() => {
				notification.success({
					message: "Sent",
					description: "A reset password link has been sent"
				});
			})
			.catch((err) => {
				notification.error({ message: "Error", description: err.message });
			});
	};
};

export const saveUser = (id, userData, navigate, location) => {
	return (dispatch) => {
		dispatch({ type: SAVE_USER_REQUEST, user: userData });
		UsersService.update(id, userData, location)
			.then((user) => {
				dispatch({ type: SAVE_USER_SUCCESS, user });
				if (navigate) {
					navigate("/console/admin/users");
				}
				if (userData.applyToAllRequired) {
					dispatch(toggleApplyToAllModal({ stateToApplyToModal: true }));
				} else {
					notification.success({
						message: "Saved",
						description: `User settings have been saved`
					});
				}
			})
			.catch((err) => {
				dispatch({ type: SAVE_USER_ERROR, error: err.messsage });
				notification.error({
					message: "Error",
					description: `Failed to save user`
				});
			});
	};
};

export const saveUserOnBoardingData = (id, organiserOnboardingInfo, location) => {
	console.log(organiserOnboardingInfo);
	return (dispatch) => {
		dispatch({ type: SAVE_USER_REQUEST, user: { id } });
		UsersService.updateUserOnboardingData(id, organiserOnboardingInfo, location)
			.then((user) => {
				dispatch({ type: SAVE_USER_SUCCESS, user });
				notification.success({
					message: "Saved",
					description: `User settings have been saved`
				});

				user.fees &&
				user.fees[location] &&
				user.fees[location].type &&
				(user.fees[location].type === "charity" || user.fees[location].type === "education")
					? dispatch({ type: OPEN_FEE_APPLIED_MODAL })
					: dispatch({ type: CLOSE_FEE_APPLIED_MODAL });
			})
			.catch((err) => {
				dispatch({ type: SAVE_USER_ERROR, error: err.message });
				notification.error({
					message: "Error",
					description: `Failed to save user`
				});
			});
	};
};

export const manuallyVerifyEmail = (id, navigate) => {
	return () => {
		UsersService.manuallyVerifyEmail(id)
			.then(() => {
				if (navigate) {
					navigate("/console/admin/users");
				}
				notification.success({
					message: "Success",
					description: `Email has been verified`
				});
			})
			.catch(() => {
				notification.error({
					message: "Error",
					description: `Failed to verify email`
				});
			});
	};
};

export const saveBankAccounts = ({ userId, bankAccount, eventId, redirectPath }) => {
	return async (dispatch) => {
		dispatch({ type: SAVE_USER_REQUEST });
		try {
			const user = await UsersService.saveBankAccounts(userId, bankAccount);
			dispatch({ type: SAVE_USER_SUCCESS, user });
			notification.success({
				message: "Saved",
				description: `Bank account has been saved`
			});
			const hasJustAddedFirstBankAccount = user.bankAccounts.length === 1;
			if (eventId && hasJustAddedFirstBankAccount) {
				savePaymentOptions(eventId, { userBankAccountId: user.bankAccounts[0]._id })(dispatch);
			}
			if (redirectPath) {
				goToPathWithSearchState(redirectPath);
			}
			endEditingBankAccount()(dispatch);
		} catch (err) {
			dispatch({ type: SAVE_USER_ERROR, error: err.message });
			notification.error({
				message: "Error",
				description: `Failed to save bank accounts `
			});
		}
	};
};

export const getConfirmationToDeleteBankAccount = (id, bankAccountId) => {
	return (dispatch) => {
		UsersService.getConfirmationToDeleteBankAccount(id, bankAccountId)
			.then((result) => {
				const confirmationRequest = result;
				if (!confirmationRequest.allowToDelete) {
					dispatch({
						type: WARNING_MODAL_OPEN,
						confirmationRequest
					});
				} else {
					deleteBankAccount(id, bankAccountId)(dispatch);
				}
			})
			.catch(() => {
				notification.error({
					message: "Error",
					description: `Failed to get confirmation to delete bank account `
				});
			});
	};
};

export const deleteBankAccount = (id, bankAccountId) => {
	return (dispatch) => {
		dispatch({ type: SAVE_USER_REQUEST });
		UsersService.deleteBankAccount(id, bankAccountId)
			.then((result) => {
				dispatch({ type: SAVE_USER_SUCCESS, user: result });
				notification.success({
					message: "Saved",
					description: `Bank accounts has been deleted`
				});
			})
			.catch((err) => {
				dispatch({ type: SAVE_USER_ERROR, error: err.message });
				notification.error({
					message: "Error",
					description: `Failed to delete bank accounts `
				});
			});
	};
};

export const find = (search, page = 1) => {
	return (dispatch) => {
		dispatch({ type: FIND_USER_REQUEST, page });
		UsersService.find(search, page)
			.then((users) => {
				dispatch({ type: FIND_USER_SUCCESS, users });
			})
			.catch((err) => {
				dispatch({ type: FIND_USER_ERROR, error: err });
			});
	};
};

export const disableUser = (id, reason) => {
	return (dispatch) => {
		UsersService.disableUser(id, reason)
			.then((user) => {
				dispatch({ type: SAVE_USER_SUCCESS, user });
				notification.success({
					message: "Success",
					description: "User disabled"
				});
			})
			.catch(() => {
				notification.error({
					message: "Error",
					description: `Failed to disabled user`
				});
			});
	};
};

export const enableUser = (id, reason) => {
	return (dispatch) => {
		UsersService.enableUser(id, reason)
			.then((user) => {
				dispatch({ type: SAVE_USER_SUCCESS, user });
				notification.success({
					message: "Success",
					description: "User enabled"
				});
			})
			.catch(() => {
				notification.error({
					message: "Error",
					description: `Failed to enabled user`
				});
			});
	};
};

export const openTransferAccount = () => {
	return (dispatch) => {
		dispatch({ type: TRANSFER_ACCOUNT_OPEN });
	};
};

export const closeTransferAccount = () => {
	return (dispatch) => {
		dispatch({ type: TRANSFER_ACCOUNT_CLOSE });
	};
};

export const transferAccount = (fromUserId, toUserId) => {
	return (dispatch) => {
		dispatch({ type: TRANSFER_ACCOUNT_REQUEST });
		UsersService.transferAccount(fromUserId, toUserId)
			.then((result) => {
				dispatch({ type: TRANSFER_ACCOUNT_SUCCESS, result });
				notification.success({
					message: "Success",
					description: "Account has been transferred"
				});
			})
			.catch(() => {
				dispatch({ type: TRANSFER_ACCOUNT_ERROR });
				notification.error({
					message: "Error",
					description: `Failed to transfer`
				});
			});
	};
};

export const requestOwner = (ownerId) => {
	return (dispatch, getState) => {
		const state = getState();
		const location = state.locations.overrideLocation;
		dispatch({ type: REQUEST_OWNER_REQUEST });
		UsersService.requestOwner(ownerId, location)
			.then((result) => {
				dispatch({ type: REQUEST_OWNER_SUCCESS, owner: result });
			})
			.catch(() => {
				dispatch({ type: REQUEST_OWNER_ERROR });
			});
	};
};

export const closeWarningModal = () => {
	return (dispatch) => {
		dispatch({ type: WARNING_MODAL_CLOSE });
	};
};

export const closeFeeAppliedModal = () => {
	return (dispatch) => {
		dispatch({ type: CLOSE_FEE_APPLIED_MODAL });
	};
};

export const connectCallBack = (code, service, navigate) => {
	return (dispatch) => {
		dispatch({ type: CONNECT_CALLBACK_REQUEST });
		UsersService.connectCallBack(code, service)
			.then(() => {
				dispatch({ type: CONNECT_CALLBACK_SUCCESS });
				navigate(`/console/account/advanced/integrations/${service}`);
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: CONNECT_CALLBACK_ERROR, error: err });
				notification.error({
					message: "Error",
					description: `Failed to connect`
				});
			});
	};
};

export const disconnectHubspot = (userId) => {
	return (dispatch) => {
		dispatch({ type: HUBSPOT_DISCONNECT_REQUEST });
		UsersService.disconnectHubspot()
			.then(() => {
				dispatch({ type: HUBSPOT_DISCONNECT_SUCCESS });
				getUser(userId)(dispatch);
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: HUBSPOT_DISCONNECT_ERROR, error: err.message });
				notification.error({
					message: "Error",
					description: `Failed to disconnect`
				});
			});
	};
};

export const saveHubspotSettings = (saveHubspotSettings) => {
	return (dispatch) => {
		dispatch({ type: HUBSPOT_SETTINGS_REQUEST });
		UsersService.saveHubspotSettings(saveHubspotSettings)
			.then(() => {
				dispatch({ type: HUBSPOT_SETTINGS_SUCCESS });
				notification.success({
					message: "Success",
					description: `Your HubSpot settings have been saved`
				});
			})
			.catch((err) => {
				console.log(err);
				dispatch({ type: HUBSPOT_SETTINGS_ERROR, error: err.message });
				notification.error({
					message: "Error",
					description: `Failed to save HubSpot settings`
				});
			});
	};
};

export const updateUserAdminPermissions = (id, values) => {
	return (dispatch) => {
		dispatch({ type: SAVE_USER_REQUEST });
		UsersService.updateAdminPermiissions(id, values)
			.then((user) => {
				dispatch({ type: SAVE_USER_SUCCESS, user });
				notification.success({
					message: "Success",
					description: "Permissions have been updated!"
				});
			})
			.catch((err) => {
				dispatch({ type: SAVE_USER_ERROR, error: err?.message });
			});
	};
};

export const addSelfServicePayoutThreshold = (id, values, location) => {
	return async (dispatch) => {
		dispatch({ type: SAVE_USER_REQUEST });
		try {
			const user = await UsersService.addSelfServicePayoutThreshold(id, values, location);
			dispatch({ type: SAVE_USER_SUCCESS, user });
			notification.success({
				message: "Success",
				description: "Self-serve payout threshold set"
			});
		} catch (err) {
			dispatch({ type: SAVE_USER_ERROR, error: err?.message });
		}
	};
};
