import { css } from "@emotion/css";
import { Component } from "react";
import { connect } from "react-redux";
import { H1, P } from "../components/text/Text";

class Welcome extends Component {
	render() {
		const { user } = this.props;

		const wrapper = css`
			display: flex;
		`;

		const contentsCol = css`
			flex: 1;
			@media (max-width: 600px) {
				padding: 12px;
			}
		`;

		const h3 = css`
			@media (max-width: 600px) {
				margin: 12px 0 4px 0 !important;
			}
		`;

		return (
			<div className={wrapper}>
				<div className={contentsCol}>
					<H1 className={h3}>Hi {user?.firstName || ""} &#128075;</H1>
					<P marginBottom={0}>Here's the lowdown on your events.</P>
				</div>
			</div>
		);
	}
}

export default connect((state) => {
	return {
		user: state.auth.user
	};
})(Welcome);
