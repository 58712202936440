import ApplyToAllSave from "@/components/ApplyToAllSave";
import FormBar from "@/components/FormBar";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H3, H4 } from "@/components/text/Text";
import AuthService from "@/services/AuthService";
import { applyDefaultGateways, toggleApplyToAllModal } from "@/state/actions/applyToAllActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Alert } from "@/ui/antd";
import { InjectedFormProps, reduxForm } from "redux-form";
import AfterpayForm from "@/events/event/payments/components/gateways/AfterpayForm";
import HumanitixPayments from "@/events/event/payments/components/gateways/HumanitixPayments";
import Invoicing from "@/events/event/payments/components/gateways/Invoicing";
import Pin from "@/events/event/payments/components/gateways/Pin";
import Stripe from "@/events/event/payments/components/gateways/Stripe";
import ZipForm from "@/events/event/payments/components/gateways/ZipForm";
import PaymentPlansCard from "@/events/event/payments/components/gateways/paymentPlans/card";
import { Grid } from "@/ui";
import { FC } from "react";
import styled from "@emotion/styled";

const formName = "gatewayOptionsSettingsForm";

const StyledGrid = styled(Grid)`
	grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
`;

type OrgPaymentOptions = {
	gatewaysLocked?: boolean;
	paymentId?: string;
	paymentProcessed?: boolean;
};
type PaymentGatewayOptions = {
	afterpay: boolean;
	humanitixPayments: boolean;
	invoice: boolean;
	stripe: boolean;
	zipMoney: boolean;
};

type GatewayOption = {
	name?: string;
	paymentId?: string;
	gatewaysLocked?: boolean;
};

type GatewayOptionsComponentProps = {
	id?: string;
	location?: string;
	orgPaymentOptions?: OrgPaymentOptions;
	paymentGatewayOptions?: PaymentGatewayOptions;
	paymentOptionsStr: "paymentOptions" | "defaultPaymentOptions";
	save: (values: GatewayOption) => void;
	type: "event" | "user";
	userId?: string;
	invoicePayLinkAllowed?: boolean;
} & InjectedFormProps;

const GatewayOptionsComponent: FC<GatewayOptionsComponentProps> = ({
	handleSubmit,
	id,
	location,
	orgPaymentOptions,
	paymentGatewayOptions,
	paymentOptionsStr,
	save,
	type,
	invoicePayLinkAllowed
}) => {
	const isAdmin = AuthService.isAdmin();

	const dispatch = useAppDispatch();
	const event = useAppSelector((state) => state.currentEvent);
	const user = useAppSelector((state) => state.auth.user);
	const currentUser = useAppSelector((state) => state.currentUser);

	const selectPaymentMethod = (paymentId: string) => {
		const updateObj: Record<string, GatewayOption> = {
			[paymentOptionsStr]: {
				...orgPaymentOptions,
				name: paymentId,
				paymentId
			}
		};
		save(updateObj);
	};

	const toggledLocked = () => {
		const gatewaysLocked = orgPaymentOptions ? orgPaymentOptions.gatewaysLocked : false;
		const updateObj: Record<string, GatewayOption> = {
			[paymentOptionsStr]: {
				...orgPaymentOptions,
				gatewaysLocked: !gatewaysLocked
			}
		};
		save(updateObj);
	};

	const handleApplyToAllEvents = () => {
		if (type === "event") return;
		dispatch(applyDefaultGateways(isAdmin ? currentUser?.user?.id : undefined));
	};

	const gatewaysThatConsidersAsHumanitixGateway = [
		"humanitix",
		"bpoint",
		"stripe-payments",
		"worldpay",
		"till",
		"braintree"
	];

	const orgPaymentOptionsPaymentId = orgPaymentOptions?.paymentId || "humanitix";
	const selectedMainGateway = gatewaysThatConsidersAsHumanitixGateway.includes(orgPaymentOptionsPaymentId)
		? "humanitix"
		: orgPaymentOptionsPaymentId;

	const paymentProcessed = orgPaymentOptions ? orgPaymentOptions?.paymentProcessed : false;
	const gatewaysLocked = orgPaymentOptions ? orgPaymentOptions?.gatewaysLocked : false;
	return (
		<form onSubmit={handleSubmit(() => dispatch(toggleApplyToAllModal({ stateToApplyToModal: true })))}>
			<div>
				<div style={{ paddingBottom: 60 }}>
					<div>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<H3>Main gateway</H3>
							{isAdmin ? (
								<LegacyButton
									ariaLabel={gatewaysLocked ? "Unlock main gateway" : "Lock main gateway"}
									onClick={toggledLocked}
									style={{ float: "right", marginBottom: 8 }}
									type="secondary"
								>
									{gatewaysLocked ? "Unlock" : "Lock"}
								</LegacyButton>
							) : null}
						</div>
						{paymentProcessed ? (
							<Alert
								description="Payments have been processed. Main gateway selection is no longer allowed."
								showIcon
								style={{ margin: "0 0 12px 0" }}
								type="info"
							/>
						) : null}
						{gatewaysLocked && !paymentProcessed ? (
							<Alert
								description="Your main payment gateway has been locked"
								showIcon
								style={{ margin: "0 0 12px 0" }}
								type="info"
							/>
						) : null}
						<StyledGrid>
							<HumanitixPayments
								allowed={paymentGatewayOptions?.humanitixPayments ?? false}
								gatewaysLocked={gatewaysLocked ?? true}
								paymentProcessed={paymentProcessed ?? false}
								selectPaymentMethod={selectPaymentMethod}
								selected={selectedMainGateway}
							/>
							<Stripe
								allowed={paymentGatewayOptions?.stripe ?? false}
								gatewaysLocked={gatewaysLocked ?? true}
								id={id ?? ""}
								isAdmin={isAdmin}
								location={location ?? ""}
								orgPaymentOptions={orgPaymentOptions}
								paymentProcessed={paymentProcessed ?? false}
								selected={selectedMainGateway}
								type={type}
							/>
							<Pin
								allowed={
									paymentGatewayOptions?.humanitixPayments &&
									(isAdmin || selectedMainGateway === "pin" || user?.pinGatewayEnabled)
								}
								gatewaysLocked={gatewaysLocked}
								orgPaymentOptions={orgPaymentOptions}
								paymentOptionsStr={paymentOptionsStr}
								paymentProcessed={paymentProcessed}
								save={save}
								selected={selectedMainGateway}
							/>
						</StyledGrid>
					</div>
					<div style={{ clear: "both" }} />
					<H4 style={{ marginTop: "25px" }}>Additional payment gateways</H4>
					<StyledGrid>
						<ZipForm
							allowed={paymentGatewayOptions?.zipMoney ?? false}
							event={event}
							id={id}
							location={location ?? ""}
							orgPaymentOptions={orgPaymentOptions}
							paymentOptionsStr={paymentOptionsStr}
							save={save}
							type={type}
						/>
						<AfterpayForm
							allowed={paymentGatewayOptions?.afterpay}
							id={id}
							location={location}
							orgPaymentOptions={orgPaymentOptions}
							paymentOptionsStr={paymentOptionsStr}
							save={save}
						/>
						<Invoicing
							allowed={paymentGatewayOptions?.invoice}
							invoicePayLinkAllowed={invoicePayLinkAllowed}
							id={id}
							location={location}
							orgPaymentOptions={orgPaymentOptions}
							paymentOptionsStr={paymentOptionsStr}
							save={save}
							type={type}
							selected={selectedMainGateway}
						/>

						<PaymentPlansCard
							allowed={selectedMainGateway === "stripe" && !!event.event?.settings?.paymentPlansOptIn}
							eventId={id ?? ""}
							enabled={!!event.event?.paymentPlansSettings?.some((paymentPlan) => paymentPlan.enabled)}
						/>
					</StyledGrid>
				</div>
				{type !== "event" ? (
					<FormBar>
						<ApplyToAllSave onOk={handleApplyToAllEvents} buttonText="Apply to all" />
					</FormBar>
				) : null}
			</div>
		</form>
	);
};

const GatewayOptions = reduxForm<unknown, any, string>({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(GatewayOptionsComponent);

export { GatewayOptions };
