import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { Link } from "react-router-dom";

import { css, keyframes } from "@emotion/css";
import { Actions, MenuItem, MenuItemGroup } from ".";
import icArrow from "@/assets/ic_arrow_down.svg";
import { IMenuItemWithHighlights } from "../Header";

const animations = {
	enterFromLeft: keyframes`
    from {
      opacity: 0;
      transform: translateX(-200px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  `,
	enterFromRight: keyframes`
    from {
      opacity: 0;
      transform: translateX(200px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  `,
	exitToLeft: keyframes`
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(-200px);
    }
  `,
	exitToRight: keyframes`
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(200px);
    }
  `,
	fadeIn: keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `,
	fadeOut: keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  `,
	scaleIn: keyframes`
    from {
      opacity: 0;
      transform: rotateX(-30deg) scale(0.9);
    }
    to {
      opacity: 1;
      transform: rotateX(0deg) scale(1);
    }
  `,
	scaleOut: keyframes`
    from {
      opacity: 1;
      transform: rotateX(0deg) scale(1);
    }
    to {
      opacity: 0;
      transform: rotateX(-10deg) scale(0.95);
    }
  `
};

const styles = {
	navMenuRoot: css`
		position: relative;
		display: flex;
		min-height: 48px;
		flex-basis: auto;

		button,
		p {
			border: none;
			background: transparent;
		}
	`,
	viewportPosition: css`
		position: absolute;
		display: flex;
		justify-content: center;
		width: 120%;
		top: 100%;
		left: 0;
		perspective: 2000px;
	`,

	navMenuViewport: css`
		position: relative;
		transform-origin: top center;
		margin-top: 10px;
		width: 120%;
		background-color: white;
		border-radius: 6px;
		overflow: hidden;
		box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
		height: var(--radix-navigation-menu-viewport-height);
		transition: width, height, 300ms ease;

		&[data-state="open"] {
			animation: ${animations.scaleIn} 200ms ease;
		}
		&[data-state="closed"] {
			animation: ${animations.scaleOut} 200ms ease;
		}
		@media only screen and (min-width: 600px) {
			width: var(--radix-navigation-menu-viewport-width);
		}
	`,
	navMenuList: css`
		display: flex;
		padding: 0px;
		list-style: none;
		margin: 0;
		min-height: 48px;
	`,
	link: css`
		height: 100%;
		display: flex;
		align-items: center;
		color: #323232;
		:hover {
			color: #323232;
		}
		img {
			margin-left: 4px;
		}
	`,
	navbarWrapper: css`
		border-bottom: 1px solid #e8e8e8;
		background-color: white;
		height: 48px;
		display: flex;
		padding-left: 50px;
		padding-right: 8px;
		justify-content: space-between;
		@media (max-width: 1024px) {
			display: none;
		}
	`,
	navMenuItem: (showBottomBar: boolean) =>
		css({
			fontSize: 14,
			display: "flex",
			alignItems: "center",
			borderBottom: showBottomBar ? "2px solid #323232" : "2px solid transparent",
			transition: "border 0.5s ease-in-out",
			":hover": {
				borderBottom: "2px solid #323232"
			}
		}),

	navMenuTrigger: css`
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 2px;
		height: 100%;

		outline: none;
		user-select: none;
		line-height: 1;
		border-radius: 4px;
		font-size: 14px;
		color: #323232;
		padding: 0 16px;
	`,
	navMenuContent: css`
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		animation-duration: 250ms;
		animation-timing-function: ease;

		&[data-motion="from-start"] {
			animation-name: ${animations.enterFromLeft};
		}
		&[data-motion="from-end"] {
			animation-name: ${animations.enterFromRight};
		}
		&[data-motion="to-start"] {
			animation-name: ${animations.exitToLeft};
		}
		&[data-motion="to-end"] {
			animation-name: ${animations.exitToRight};
		}

		@media only screen and (min-width: 600px) {
			width: auto;
		}
	`,
	tooth: css`
		position: relative;
		top: 70%;
		background-color: white;
		width: 10px;
		height: 10px;
		transform: rotate(45deg);
		border-top-left-radius: 2px;
	`,
	navMenuIndicator: css`
		display: flex;
		align-items: flex-end;
		justify-content: center;
		height: 10px;
		top: 100%;
		overflow: hidden;
		z-index: 1;
		transition: width, transform 250ms ease;
		&[data-state="visible"] {
			animation: fadeIn 200ms ease;

			#menu-indicator-tooth {
				opacity: 1;
			}
		}

		&[data-state="hidden"] {
			animation: fadeOut 200ms ease;

			#menu-indicator-tooth {
				opacity: 0;
			}
		}
	`
};

const MenuItemOrPanel = ({
	menuItem,
	showBottomBar
}: {
	menuItem: IMenuItemWithHighlights;
	showBottomBar: boolean;
}) => {
	return (
		<NavigationMenu.Item className={styles.navMenuItem(showBottomBar)}>
			{menuItem.children ? (
				<>
					<NavigationMenu.Trigger className={styles.navMenuTrigger}>
						<Link to={menuItem.path} className={styles.link}>
							{menuItem.label} <img src={icArrow} alt="" />
						</Link>
					</NavigationMenu.Trigger>
					<NavigationMenu.Content className={styles.navMenuContent}>
						<MenuItemGroup
							heading={menuItem.label?.toString() ?? ""}
							icon={menuItem.icon}
							columns={menuItem.cols}
							items={menuItem.children}
						/>
						{menuItem.highlights && (
							<MenuItemGroup
								heading={menuItem.highlights.label?.toString() ?? ""}
								icon={menuItem.highlights.icon}
								columns={menuItem.highlights.cols}
								items={menuItem.highlights.children ?? []}
								highlight
							/>
						)}
					</NavigationMenu.Content>
				</>
			) : (
				<MenuItem item={menuItem} isInHeader />
			)}
		</NavigationMenu.Item>
	);
};

const Navbar = ({
	menu,
	handleCreateEventClicked,
	handleImportEventClicked,
	showImportEvent,
	showCreateEvent
}: {
	menu: IMenuItemWithHighlights[];
	handleCreateEventClicked: () => void;
	handleImportEventClicked: () => void;
	showCreateEvent: boolean;
	showImportEvent: boolean;
}) => {
	return (
		<div className={styles.navbarWrapper}>
			<NavigationMenu.Root className={styles.navMenuRoot}>
				<div className={styles.viewportPosition}>
					<NavigationMenu.Viewport className={styles.navMenuViewport} />
				</div>
				<NavigationMenu.List className={styles.navMenuList}>
					{menu?.map((menuItem) => (
						<MenuItemOrPanel
							key={menuItem.key}
							menuItem={menuItem}
							showBottomBar={location.pathname.includes(menuItem.path)}
						/>
					))}

					<NavigationMenu.Indicator className={styles.navMenuIndicator}>
						<div id="menu-indicator-tooth" className={styles.tooth} />
					</NavigationMenu.Indicator>
				</NavigationMenu.List>
			</NavigationMenu.Root>

			<Actions
				handleCreateEventClicked={handleCreateEventClicked}
				handleImportEventClicked={handleImportEventClicked}
				showImportEvent={showImportEvent}
				showCreateEvent={showCreateEvent}
			/>
		</div>
	);
};

export default Navbar;
