import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import SearchRefinements from "@/components/SearchRefinements";
import SimplePagination from "@/components/SimplePagination";
import ResponsiveTable from "@/components/table/ResponsiveTable";
import P from "@/components/text/P";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import LocationService from "@/services/LocationService";
import { deleteCampaign, listCampaigns, showPreviewEmail } from "@/state/actions/emailCampaignsActions";
import { findEvents, getEventsById } from "@/state/actions/eventActions";
import { updateSearch } from "@/state/actions/searchActions";
import { Button } from "@/ui";
import { Dropdown } from "@/ui";
import { Responsive } from "@/ui";
import { Spacer } from "@/ui";
import { Alert, Flex, Menu, Radio, Select, Spin, Tag } from "@/ui/antd";
import { max } from "@/utils/CssUtils";
import { CaretDownOutlined, ReloadOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import { DotsThreeVertical, SlidersHorizontal } from "@phosphor-icons/react";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "../../ui/DropdownItem";
import DetailsModal from "./DetailsModal";
import PreviewModel from "./PreviewModal";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const statusConversions = {
	pending: {
		label: "scheduled",
		color: "#f4ad42"
	},
	sending: {
		label: "sending...",
		color: "#1ab87e"
	},
	sent: {
		label: "sent",
		color: "#1ab87e"
	},
	"requires-approval": {
		label: "requires approval",
		color: "#ff0707c2"
	},
	failed: {
		label: "failed",
		color: "#c0212c"
	}
};

const selectFieldProps = {
	width: 300,
	fontWeight: "bold",
	display: "block",
	".ant-select": {
		marginTop: 4,
		fontWeight: "normal ",
		display: "block"
	}
};

const searchBarWrapper = css({
	display: "flex",
	justifyContent: "space-between",
	maxHeight: 36,
	marginBottom: 10,
	[max(500)]: {
		flexDirection: "column-reverse",
		maxHeight: "none",
		marginBottom: 0,
		"#new-campaign-wrapper": {
			marginBottom: 12,
			width: "100%",
			button: {
				width: "100%"
			}
		}
	}
});

class Emails extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
		this.loadPage = this.loadPage.bind(this);
		this.handleEventOnSearch = this.handleEventOnSearch.bind(this);
		this.handleEventsOnBlur = this.handleEventsOnBlur.bind(this);
		this.handleEventOnChange = this.handleEventOnChange.bind(this);
		this.debouncedFindEvents = this.debouncedFindEvents.bind(this);
		this.state = {
			detailsModalOpen: false,
			campaign: null
		};
	}
	defaultSearch = {
		query: "",
		page: 1,
		status: "all"
	};

	load(page = 1) {
		const { currentUser, listCampaigns, search } = this.props;
		const userId = currentUser.user.id;
		listCampaigns(userId, page, search.status, search.query, search.eventIds);
	}

	loadPage(page) {
		const { updateSearch } = this.props;
		updateSearch({ page }, true);
	}

	delete = (email) => {
		const { currentUser, deleteCampaign } = this.props;
		const userId = currentUser.user.id;
		deleteCampaign(userId, email._id);
	};

	preview = (campaign) => {
		const { showPreviewEmail } = this.props;
		showPreviewEmail(campaign);
	};

	newCampaign = (evt) => {
		const { navigate } = this.props;
		navigate(`/console/comms/email-campaigns/new/${evt.key}`);
	};

	duplicate = (campaign) => {
		const { navigate } = this.props;
		navigate(`/console/comms/email-campaigns/new/${campaign._id}`);
	};

	openDetailsModal = (campaign) => {
		const { getEventsById } = this.props;
		const eventIds = campaign?.queues?.map((q) => q.eventId);
		getEventsById(eventIds);
		this.setState({
			detailsModalOpen: true,
			campaign
		});
	};

	closeDetailsModal = () => {
		this.setState({
			detailsModalOpen: false,
			campaign: null
		});
	};

	handleEventOnChange(eventIds) {
		const { updateSearch } = this.props;
		updateSearch({ eventIds });
	}

	handleEventOnScroll = (e) => {
		const { eventSearchState, loading } = this.props;
		const { page } = eventSearchState;
		let target = e.target;
		if (!loading && Math.round(target.scrollTop + target.offsetHeight) >= target.scrollHeight) {
			const nextPage = Math.round(parseInt(page) + 1);
			this.debouncedFindEvents(nextPage, "", true);
			target.scrollTo(0, target.scrollHeight);
		}
	};

	debouncedFindEvents(page = 1, query, toAppend = false) {
		clearTimeout(this.searchEventTimeout);
		this.searchEventTimeout = setTimeout(() => {
			this.props.findEvents(
				page,
				{
					query,
					sortOrder: "relevant",
					filter: "all"
				},
				toAppend
			);
		}, 200);
	}

	handleEventOnSearch(query) {
		if (query.trim().length > 2) {
			this.debouncedFindEvents(1, query);
		}
	}

	handleEventsOnBlur() {
		this.debouncedFindEvents(1, "");
	}

	render() {
		const { emailCampaigns, updateSearch, search, eventSearchState } = this.props;
		const { detailsModalOpen, campaign } = this.state;
		const { loading, error, data } = emailCampaigns.list;

		if (!eventSearchState.events) {
			eventSearchState.events = [];
		}

		const selectEventChildren = eventSearchState.events.map((item) => {
			return {
				label: item.name,
				value: item._id
			};
		});
		const messages = loading ? { emptyText: "Loading" } : { emptyText: "No Emails" };

		const createRowActionItems = (campaign) => [
			campaign?.queues?.length ? (
				<DropdownItem key="details" onClick={() => this.openDetailsModal(campaign)} variant="text">
					Details
				</DropdownItem>
			) : null,
			<DropdownItem key="preview" onClick={() => this.preview(campaign)} variant="text">
				Preview
			</DropdownItem>,
			<DropdownItem key="duplicate" onClick={() => this.duplicate(campaign)} variant="text">
				Duplicate
			</DropdownItem>,
			<DropdownItem key="delete" onClick={() => this.delete(campaign)} variant="danger-text">
				Delete
			</DropdownItem>
		];

		const tableColumns = [
			{
				dataIndex: "name",
				ellipsis: true,
				key: "name",
				render: (value, email) => (
					<Link style={{ color: "#196097" }} to={`/console/comms/email-campaigns/${email._id}`}>
						{value}
					</Link>
				),
				title: "Name"
			},
			{
				dataIndex: "subject",
				ellipsis: true,
				key: "subject",
				render: (value) => value,
				title: "Subject"
			},
			{
				dataIndex: "statusSummary",
				key: "statusSummary",
				title: "Campaign status",
				render: (value, campaign) => {
					if (value && Object.keys(value).length) {
						return Object.keys(value).map((key) => (
							<div style={{ marginBottom: 2 }} key={key}>
								<Tag
									style={{ cursor: "pointer" }}
									color={statusConversions[key].color}
									onClick={() => this.openDetailsModal(campaign)}
								>
									{value[key]} campaign{value[key] > 1 ? "s" : ""} {statusConversions[key].label}
								</Tag>
							</div>
						));
					}
					return <Tag color="#67717e">Draft</Tag>;
				}
			},
			{
				dataIndex: "sendAtStr",
				key: "sendAtStr",
				render: (value) => value,
				title: "Send at"
			},
			{
				key: "actions",
				title: "Actions",
				width: 140,
				render: (campaign) => (
					<Dropdown items={createRowActionItems(campaign)}>
						<Button iconOnly variant="text">
							<DotsThreeVertical size={18} weight="bold" />
						</Button>
					</Dropdown>
				)
			}
		];
		const campaigns = data
			? data.map((campaign, index) => {
					campaign.key = index;
					campaign.sendAtStr = campaign.queues?.length
						? new HumaniDate(
								campaign.queues[0].sendAt,
								"guess",
								LocationService.getLocation(true)
						  ).formatting.dateTime()
						: "";
					if (campaign.schedule.type === "after" || campaign.schedule.type === "before") {
						const hours = campaign.schedule.hours ? campaign.schedule.hours : 0;
						const days = campaign.schedule.days ? campaign.schedule.days : 0;
						campaign.sendAtStr = `${days ? `${days} days` : ""} ${hours ? `${hours} hours` : ""} ${
							campaign.schedule.type
						} your event`;
					}
					return campaign;
			  })
			: [];

		const newCampaignItems = [
			{ key: "blank", label: "New blank campaign" },
			{ key: "reminder", label: "Event reminder email" },
			{ key: "postponed", label: "Event postponed email" },
			{ key: "rescheduled", label: "Event rescheduled email" },
			{ key: "attendees-details", label: "Attendees details reminder" },
			{ key: "feedback", label: "Feedback email" },
			{ key: "cancelled", label: "Event cancelled email" },
			{ key: "payment", label: "Invoice payment reminder" }
		];

		const NewCampaignButtonOptions = newCampaignItems.map((item) => (
			<DropdownItem key={item.key} onClick={() => this.newCampaign(item)} variant="text">
				{item.label}
			</DropdownItem>
		));

		return (
			<>
				<DetailsModal
					isOpen={detailsModalOpen}
					campaign={campaign}
					events={eventSearchState.events}
					closeAction={this.closeDetailsModal}
				/>
				<LoadErrorView loading={loading} error={error} retryAction={this.load}>
					<ContentBlock>
						<Menu
							defaultSelectedKeys={["email-campaigns"]}
							items={[
								{
									key: "email-campaigns",
									label: (
										<>
											<span>Campaigns</span>
											<Link to="/console/comms/email-campaigns" />
										</>
									)
								},
								{
									key: "unsubscriber-list",
									label: (
										<>
											<span>Unsubscriber list</span>
											<Link to="/console/comms/email-campaigns-unsubscriptions" />
										</>
									)
								}
							]}
							mode="horizontal"
							style={{ marginBottom: 12 }}
						/>
						<P style={{ maxWidth: "100%" }}>
							Use the Email Campaigns tool to send a bulk email to your attendees right from your Humanitix console.
						</P>
						<Alert
							style={{ margin: "12px 0" }}
							showIcon
							message={
								<>
									Please note, Email Campaigns should never be used for Marketing and Promotional material. Email
									Campaigns should only be used to communicate service-level info related to a customer's purchase.
								</>
							}
							type="warning"
						/>

						<div className={searchBarWrapper}>
							<SearchRefinements load={this.load} defaultSearch={this.defaultSearch} wrapperMarginBottom={0} />
							<div id="new-campaign-wrapper">
								<Dropdown items={NewCampaignButtonOptions}>
									<Button iconRight={<CaretDownOutlined />} variant="secondary">
										New Campaign
									</Button>
								</Dropdown>
							</div>
						</div>
						<Flex gap="xs">
							<Responsive
								desktop={
									<RadioGroup
										buttonStyle="solid"
										defaultValue="all"
										onChange={(e) => updateSearch({ status: e.target.value }, true)}
									>
										<RadioButton value="all">All</RadioButton>
										<RadioButton value="draft">Draft</RadioButton>
										<RadioButton value="scheduled">Scheduled</RadioButton>
										<RadioButton value="sent">Sent</RadioButton>
										<RadioButton value="requires-approval">Requires approval</RadioButton>
									</RadioGroup>
								}
								mobile={
									<Select
										defaultValue="all"
										onChange={(value) => updateSearch({ status: value }, true)}
										options={[
											{ value: "all", label: "All" },
											{ value: "draft", label: "Draft" },
											{ value: "scheduled", label: "Scheduled" },
											{ value: "sent", label: "Sent" }
										]}
									/>
								}
							/>
							<Dropdown
								persist
								title="Filter by event name"
								onClose={() => this.load()}
								items={
									<div>
										<label className={selectFieldProps}>
											Select events
											<Select
												onChange={this.handleEventOnChange}
												onSearch={this.handleEventOnSearch}
												onBlur={this.handleEventsOnBlur}
												filterOption={false}
												mode="multiple"
												popupMatchSelectWidth={false}
												notFoundContent={eventSearchState.loading ? <Spin size="small" /> : false}
												loading={eventSearchState.loading}
												value={search.eventIds}
												placeholder="All events"
												onPopupScroll={this.handleEventOnScroll}
												style={{ width: "100%" }}
												options={
													!eventSearchState.loading
														? selectEventChildren
														: [
																...selectEventChildren,
																{
																	label: (
																		<>
																			<Spin size="small" /> &nbsp;&nbsp;
																			<span style={{ color: "#323232" }}>Loading...</span>
																		</>
																	)
																}
														  ]
												}
											/>
										</label>
									</div>
								}
							>
								<Button iconOnly variant="text">
									<SlidersHorizontal size={20} />
								</Button>
							</Dropdown>
							<Button aria-label="refresh" iconOnly variant="ghost" onClick={() => this.load()}>
								<ReloadOutlined />
							</Button>
						</Flex>
						<Spacer size="md" />
						<ResponsiveTable
							columns={tableColumns}
							dataSource={campaigns}
							rowKey="key"
							locale={messages}
							scroll={{ x: 800 }}
							expandIconColumnIndex={99}
							expandIconAsCell={false}
							rowClassName={(record) => {
								return record._id ? record._id : "";
							}}
							pagination={false}
						/>
						<SimplePagination pageAction={this.loadPage} currentPage={Number(search.page || 1)} />
					</ContentBlock>
					<PreviewModel />
				</LoadErrorView>
			</>
		);
	}
}

export default connect(
	(state) => ({
		currentUser: state.auth,
		currentEvent: state.currentEvent,
		emailCampaigns: state.emailCampaigns,
		search: state.search,
		eventSearchState: state.events
	}),
	{
		updateSearch,
		listCampaigns,
		deleteCampaign,
		showPreviewEmail,
		findEvents,
		getEventsById
	}
)(withRouterHooks(Emails));
