import { ContentBlock } from "@/components/AppBlocks";
import IconButton from "@/components/IconButton";
import H2 from "@/components/text/H2";
import AuthService from "@/services/AuthService";
import { updateStatus } from "@/state/actions/newPayoutActions";
import { DateBreakdown as IDateBreakdown, PayoutHeaderInformation } from "@/state/reducers/newPayoutReducer";
import { ReduxState } from "@/state/store";
import { currency } from "@/utils/Format";
import { HumaniDate } from "@hx/dates";
import { Dropdown, Table, Tag } from "@/ui/antd";
import { ColumnType } from "antd/lib/table";
import { useState } from "react";
import { connect } from "react-redux";
import AdjustmentModal from "../modals/AdjustmentModal";
import CreditModal from "../modals/CreditModal";
import PaymentModal from "../modals/PaymentModal";

const tableCurrencyRender = (value: number, isCredit: boolean) => (isCredit ? currency(value) : `(${currency(value)})`);

const columns: ColumnType<IDateBreakdown>[] = [
	{
		title: "Event date",
		key: "displayDate",
		dataIndex: "displayDate",
		fixed: "left"
	},
	{
		title: "Maturity date",
		key: "maturityDate",
		dataIndex: "maturityDate"
	},
	{
		title: "Sales via Humanitix payments",
		key: "salesViaHumanitixGateways",
		dataIndex: "salesViaHumanitixGateways",
		render: (value: number) => tableCurrencyRender(value, true)
	},
	{
		title: "Sales via Afterpay",
		key: "salesViaAfterpay",
		dataIndex: "salesViaAfterpay",
		render: (value: number) => tableCurrencyRender(value, true)
	},
	{
		title: "Sales via Zip Pay",
		key: "salesViaZip",
		dataIndex: "salesViaZip",
		render: (value: number) => tableCurrencyRender(value, true)
	},
	{
		title: "Sales via Box office card payments",
		key: "salesViaBoxOfficeCard",
		dataIndex: "salesViaBoxOfficeCard",
		render: (value: number) => tableCurrencyRender(value, true)
	},
	{
		title: "Fee rebates",
		key: "feeRebates",
		dataIndex: "feeRebates",
		render: (value: number) => tableCurrencyRender(value, true)
	},
	{
		title: "Absorbed Humanitix fees",
		key: "absorbedHumanitixFees",
		dataIndex: "absorbedHumanitixFees",
		render: (value: number) => tableCurrencyRender(value, false)
	},
	{
		title: "Refunds",
		key: "refunds",
		dataIndex: "refunds",
		render: (value: number) => tableCurrencyRender(value, false)
	},
	{
		title: "Box Office cash fees",
		key: "boxOfficeCashFees",
		dataIndex: "boxOfficeCashFees",
		render: (value: number) => tableCurrencyRender(value, false)
	},
	{
		title: "Pay by invoice fees",
		key: "payByInvoiceFees",
		dataIndex: "payByInvoiceFees",
		render: (value: number) => tableCurrencyRender(value, false)
	},
	{
		title: "Zip Pay fees",
		key: "zipFees",
		dataIndex: "zipFees",
		render: (value: number) => tableCurrencyRender(value, false)
	},
	{
		title: "Afterpay fees",
		key: "afterpayFees",
		dataIndex: "afterpayFees",
		render: (value: number) => tableCurrencyRender(value, false)
	},
	{
		title: "Credits",
		key: "credits",
		dataIndex: "credits",
		render: (value: number) => tableCurrencyRender(value, true)
	},
	{
		title: "Positive Adjustments",
		key: "positiveAdjustments",
		dataIndex: "positiveAdjustments",
		render: (value: number) => tableCurrencyRender(value, true)
	},
	{
		title: "Negative Adjustments",
		key: "negativeAdjustments",
		dataIndex: "negativeAdjustments",
		render: (value: number) => tableCurrencyRender(value, false)
	},
	{
		title: "Estimated payout",
		key: "owed",
		dataIndex: "owed",
		render: (value: number) => tableCurrencyRender(value, true)
	},
	{
		title: "Paid",
		key: "paid",
		dataIndex: "paid",
		render: (value: number) => tableCurrencyRender(value, false)
	},
	{
		title: "Balance",
		key: "due",
		dataIndex: "due",
		render: (value: number) => tableCurrencyRender(value, true)
	}
];

const statusColumn: ColumnType<IDateBreakdown> = {
	title: "Status",
	key: "status",
	dataIndex: "status",
	fixed: "right",
	render: (value: string) => {
		switch (value) {
			case "paused":
				return <Tag color="#EAA200">Paused</Tag>;
			case "stale":
				return <Tag color="#67717E">Stale</Tag>;
			case "nil":
				return "Nil";
			case "overpaid":
				return <Tag color="#C0212C">Overpaid</Tag>;
			case "paid":
				return <Tag color="#1ab87e">Paid</Tag>;
			case "partial":
				return <Tag color="#EAA200">Partially paid</Tag>;
			default:
				return "";
		}
	}
};

const menu = ({
	breakdown,
	eventId,
	updateStatus,
	onModalOpen,
	header
}: {
	breakdown: IDateBreakdown;
	eventId: string;
	updateStatus: (eventId: string, dateId: string, status: string) => void;
	onModalOpen: (dateId: string, status: string) => void;
	header: PayoutHeaderInformation;
}) => {
	return [
		{
			key: "pay",
			label: header.preventPayout ? (
				<div>Payout is prevented</div>
			) : (
				<div
					onClick={() => {
						onModalOpen(breakdown.dateId, "payment");
					}}
				>
					Add payment
				</div>
			)
		},
		{
			key: "credit",
			label: (
				<div
					onClick={() => {
						onModalOpen(breakdown.dateId, "credit");
					}}
				>
					Add credit
				</div>
			)
		},
		{
			key: "adjustment",
			label: (
				<div
					onClick={() => {
						onModalOpen(breakdown.dateId, "adjustment");
					}}
				>
					Add adjustment
				</div>
			)
		},
		...(breakdown.status !== "stale"
			? [
					{
						key: "mark-as-stale",
						label: <div onClick={() => updateStatus(eventId, breakdown.dateId, "stale")}>Mark as stale</div>
					}
			  ]
			: []),
		...(breakdown.status !== "paused"
			? [
					{
						key: "mark-as-paused",
						label: <div onClick={() => updateStatus(eventId, breakdown.dateId, "paused")}>Mark as paused</div>
					}
			  ]
			: []),
		...(breakdown.status === "paused" || breakdown.status === "stale"
			? [
					{
						key: "remove-status",
						label: <div onClick={() => updateStatus(eventId, breakdown.dateId, "remove")}>Remove status</div>
					}
			  ]
			: [])
	];
};

const DateBreakdown = ({
	datesBreakdown,
	datesTableColumns,
	header,
	updateStatus,
	eventId
}: IInjectedProps & IInjectedFunctions) => {
	const [focusedDate, setFocusedDate] = useState<string | null>(null);

	const [openedModelName, setOpenModelName] = useState("none");
	const onModalClose = () => {
		setFocusedDate(null);
		setOpenModelName("none");
	};
	const onModalOpen = (focusedDate: string, modalName: string) => {
		setFocusedDate(focusedDate);
		setOpenModelName(modalName);
	};

	if (!datesBreakdown.length || !datesTableColumns || !eventId || !header) return null;

	const actionColumn: ColumnType<IDateBreakdown> = {
		title: "Actions",
		key: "actions",
		fixed: "right",
		render: (row: IDateBreakdown) => {
			return (
				<Dropdown
					menu={{
						items: menu({ breakdown: row, eventId, updateStatus, onModalOpen, header })
					}}
					trigger={["click"]}
				>
					<IconButton icon="see_more" ariaLabel="actions" dataCy={`payout-actions-${row.dateId}`} />
				</Dropdown>
			);
		}
	};

	const userColumns = columns.filter((column) => datesTableColumns.findIndex((key) => key === column.key) !== -1);

	const adminColumns = userColumns.concat([statusColumn, actionColumn]);
	return (
		<>
			<PaymentModal isOpen={openedModelName === "payment"} close={onModalClose} focusedDate={focusedDate} />
			<CreditModal isOpen={openedModelName === "credit"} close={onModalClose} focusedDate={focusedDate} />
			<AdjustmentModal isOpen={openedModelName === "adjustment"} close={onModalClose} focusedDate={focusedDate} />
			<ContentBlock>
				<H2>Breakdown</H2>
				<Table
					dataSource={datesBreakdown.sort((a, b) =>
						new HumaniDate(a.startDate).toDate() > new HumaniDate(b.startDate).toDate() ? 1 : -1
					)}
					columns={AuthService.isAdmin() ? adminColumns : userColumns}
					scroll={{ x: "max-content" }}
				/>
			</ContentBlock>
		</>
	);
};

interface IInjectedProps {
	datesBreakdown: IDateBreakdown[];
	datesTableColumns?: string[];
	header?: PayoutHeaderInformation;
	eventId?: string;
}

interface IInjectedFunctions {
	updateStatus: (eventId: string, dateId: string, status: string) => void;
}

export default connect(
	(state: ReduxState): IInjectedProps => {
		const datesBreakdown = state.payouts.eventPayout.payoutInformation?.datesBreakdown || [];
		const filters = state.payouts.filters;
		const filteredBreakdown =
			AuthService.isAdmin() && filters.hideNilPayouts
				? datesBreakdown.filter((breakdown) => breakdown.owed)
				: datesBreakdown;
		return {
			datesBreakdown: filteredBreakdown,
			datesTableColumns: state.payouts.eventPayout.payoutInformation?.datesTableColumns,
			header: state.payouts.eventPayout.payoutInformation?.header,
			eventId: state.payouts.eventPayout.payoutInformation?.header.eventId
		};
	},
	{ updateStatus }
)(DateBreakdown);
