import { BuyTicketsOrHelp } from "@/auth/components/BuyTicketsOrHelp";
import { TwoColumnPageWrap } from "@/auth/components/TwoColumnPageWrap";
import { H1, P } from "@/components/text/Text";
import AuthService from "@/services/AuthService";
import UsersService from "@/services/UsersService";
import { launchMicrosoftSignIn } from "@/state/actions/authActions";
import { Form, Input, notification } from "@/ui/antd";
import { Button } from "@/ui/Button";
import { FormItem } from "@/ui/FormItem";
import { UserOutlined } from "@ant-design/icons";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

const buildRedirectUri = ({
	email,
	token,
	referralcode,
	exists,
	from
}: {
	email: string;
	token?: string | null;
	referralcode?: string | null;
	exists: boolean;
	from?: string | null;
}) => {
	const params = new URLSearchParams({ email });
	if (token) params.append("token", token);
	if (referralcode) params.append("referralcode", referralcode);
	if (from) params.append("from", from);
	return `${exists ? "/login" : "/signup"}?${params.toString()}`;
};

export const Signin: FC = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [signinForm] = Form.useForm();

	const token = searchParams?.get("token");
	const referralcode = searchParams?.get("referralcode");
	const from = searchParams?.get("from");
	const isDemo = searchParams?.get("demo");
	const emailParam = searchParams?.get("email");
	const email = emailParam ? emailParam : localStorage.getItem("email");

	useEffect(() => {
		if (email) {
			signinForm.setFieldsValue({ email });
		}
	}, []);

	const handleSubmit = async () => {
		setIsSubmitting(true);
		const formValues = signinForm.getFieldsValue(true);

		const { exists, isUts } = await AuthService.checkEmailStatus(formValues.email).catch((err) => {
			notification.error({ message: "Oops", description: err.message });
			return { exists: false, isUts: false };
		});

		if (isUts) {
			const accountCheckResult = await UsersService.getUtsConfig();
			launchMicrosoftSignIn(formValues.email, accountCheckResult);
			return;
		}
		const path = buildRedirectUri({ email: formValues.email, token, referralcode, exists, from });
		navigate(path);
	};

	return (
		<TwoColumnPageWrap>
			<H1>{isDemo ? "Thank you for booking a demo" : "Get started creating events"}</H1>

			<P center>
				{isDemo
					? "Sign up to save time during our call"
					: "Enter your email address below to log in to an existing account or sign up with a new one."}
			</P>

			<Form
				style={{ width: "100%", marginTop: 24, marginBottom: 48 }}
				layout="vertical"
				form={signinForm}
				onFinish={handleSubmit}
			>
				<FormItem
					changeTriggerOnBlur
					label={<b>Email Address</b>}
					name="email"
					required={false}
					validateFirst
					rules={[
						{
							min: 1,
							required: true,
							whitespace: true,
							message: "Please enter an email address"
						},
						{
							min: 1,
							type: "email",
							whitespace: true,
							message: "Please enter a valid email address"
						}
					]}
				>
					<Input prefix={<UserOutlined />} type="email" placeholder="Email address" />
				</FormItem>
				<Button
					disabled={isSubmitting}
					fullWidth
					htmlType="submit"
					aria-label="Continue"
					isLoading={isSubmitting}
					variant="primary"
				>
					Continue
				</Button>
			</Form>

			{!isDemo ? <BuyTicketsOrHelp /> : null}
		</TwoColumnPageWrap>
	);
};
