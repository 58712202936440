const isBrowser = typeof window !== "undefined";

export const IS_REQUEST = "IS_REQUEST";
export const IS_SUCCESS = "IS_SUCCESS";
export const IS_ERROR = "IS_ERROR";

export const CypressService = {
	// authActions
	signUp(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_SIGN_UP_SUCCESS;
					break;
				case IS_SUCCESS:
					window.CYPRESS_SIGN_UP_SUCCESS = true;
					break;
				default:
					break;
			}
		}
	},
	// eventActions
	getEvent(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_GET_EVENT_SUCCESS;
					break;
				case IS_SUCCESS:
					window.CYPRESS_GET_EVENT_SUCCESS = true;
					break;
				default:
					break;
			}
		}
	},
	createEvent(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_CREATE_EVENT_SUCCESS;
					break;
				case IS_SUCCESS:
					window.CYPRESS_CREATE_EVENT_SUCCESS = true;
					break;
				default:
					break;
			}
		}
	},
	saveEvent(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_SAVE_EVENT_SUCCESS;
					break;
				case IS_SUCCESS:
					window.CYPRESS_SAVE_EVENT_SUCCESS = true;
					break;
				default:
					break;
			}
		}
	},
	deleteEvent(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_DELETE_EVENT_SUCCESS;
					break;
				case IS_SUCCESS:
					window.CYPRESS_DELETE_EVENT_SUCCESS = true;
					break;
				default:
					break;
			}
		}
	},

	// TicketTypes mount
	ticketTypesMount(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_GET_TICKET_SUCCESS;
					break;
				case IS_SUCCESS:
					window.CYPRESS_GET_TICKET_SUCCESS = true;
					break;
				default:
					break;
			}
		}
	},

	// discountCodesActions
	getDiscountCodes(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_GET_DISCOUNT_SUCCESS;
					break;
				case IS_SUCCESS:
					window.CYPRESS_GET_DISCOUNT_SUCCESS = true;
					break;
				default:
					break;
			}
		}
	},
	saveDiscountCodes(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_SAVE_DISCOUNT_SUCCESS;
					delete window.CYPRESS_SAVE_DISCOUNT_ERROR;
					break;
				case IS_SUCCESS:
					delete window.CYPRESS_SAVE_DISCOUNT_ERROR;
					window.CYPRESS_SAVE_DISCOUNT_SUCCESS = true;
					break;
				case IS_ERROR:
					delete window.CYPRESS_SAVE_DISCOUNT_SUCCESS;
					window.CYPRESS_SAVE_DISCOUNT_ERROR = true;
					break;
				default:
					break;
			}
		}
	},
	deleteDiscountCodes(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_DELETE_DISCOUNT_SUCCESS;
					break;
				case IS_SUCCESS:
					window.CYPRESS_DELETE_DISCOUNT_SUCCESS = true;
					break;
				default:
					break;
			}
		}
	},
	duplicateDiscountCode(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_DUPLICATE_DISCOUNT_SUCCESS;
					delete window.CYPRESS_DUPLICATE_DISCOUNT_ERROR;
					break;
				case IS_SUCCESS:
					delete window.CYPRESS_DUPLICATE_DISCOUNT_ERROR;
					window.CYPRESS_DUPLICATE_DISCOUNT_SUCCESS = true;
					break;
				case IS_ERROR:
					delete window.CYPRESS_DUPLICATE_DISCOUNT_SUCCESS;
					window.CYPRESS_DUPLICATE_DISCOUNT_ERROR = true;
					break;
				default:
					break;
			}
		}
	},

	// accessCode action
	getAccessCodes(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_GET_ACCESS_SUCCESS;
					break;
				case IS_SUCCESS:
					window.CYPRESS_GET_ACCESS_SUCCESS = true;
					break;
				default:
					break;
			}
		}
	},
	saveAccessCodes(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_SAVE_ACCESS_SUCCESS;
					delete window.CYPRESS_SAVE_ACCESS_ERROR;
					break;
				case IS_SUCCESS:
					delete window.CYPRESS_SAVE_ACCESS_ERROR;
					window.CYPRESS_SAVE_ACCESS_SUCCESS = true;
					break;
				case IS_ERROR:
					delete window.CYPRESS_SAVE_ACCESS_SUCCESS;
					window.CYPRESS_SAVE_ACCESS_ERROR = true;
					break;
				default:
					break;
			}
		}
	},
	duplicateAccessCode(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_DUPLICATE_ACCESS_SUCCESS;
					delete window.CYPRESS_DUPLICATE_ACCESS_ERROR;
					break;
				case IS_SUCCESS:
					delete window.CYPRESS_DUPLICATE_ACCESS_ERROR;
					window.CYPRESS_DUPLICATE_ACCESS_SUCCESS = true;
					break;
				case IS_ERROR:
					delete window.CYPRESS_DUPLICATE_ACCESS_SUCCESS;
					window.CYPRESS_DUPLICATE_ACCESS_ERROR = true;
					break;
				default:
					break;
			}
		}
	},

	//additional questions
	saveAdditionalQuestion(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_SAVE_ACCESS_SUCCESS;
					delete window.CYPRESS_SAVE_ACCESS_ERROR;
					break;
				case IS_SUCCESS:
					delete window.CYPRESS_SAVE_ACCESS_ERROR;
					window.CYPRESS_SAVE_ACCESS_SUCCESS = true;
					break;
				case IS_ERROR:
					delete window.CYPRESS_SAVE_ACCESS_SUCCESS;
					window.CYPRESS_SAVE_ACCESS_ERROR = true;
					break;
				default:
					break;
			}
		}
	},
	deleteAdditionalQuestion(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_DELETE_ADDITIONAL_QUESTION_SUCCESS;
					break;
				case IS_SUCCESS:
					window.CYPRESS_DELETE_ADDITIONAL_QUESTION_SUCCESS = true;
					break;
				default:
					break;
			}
		}
	},

	// tour actions
	getTours(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_GET_TOURS_SUCCESS;
					break;
				case IS_SUCCESS:
					window.CYPRESS_GET_TOURS_SUCCESS = true;
					break;
				default:
					break;
			}
		}
	},
	getTour(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_GET_TOUR_SUCCESS;
					break;
				case IS_SUCCESS:
					window.CYPRESS_GET_TOUR_SUCCESS = true;
					break;
				default:
					break;
			}
		}
	},
	saveTour(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_SAVE_TOUR_SUCCESS;
					break;
				case IS_SUCCESS:
					window.CYPRESS_SAVE_TOUR_SUCCESS = true;
					break;
				default:
					break;
			}
		}
	},
	updateTour(whichOne) {
		if (isBrowser) {
			switch (whichOne) {
				case IS_REQUEST:
					delete window.CYPRESS_UPDATE_TOUR_SUCCESS;
					break;
				case IS_SUCCESS:
					window.CYPRESS_UPDATE_TOUR_SUCCESS = true;
					break;
				default:
					break;
			}
		}
	}
};
