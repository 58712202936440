import PreviewService from "../../services/PreviewService";

export const STORE_PREVIEW_DATA_REQUEST = "STORE_PREVIEW_DATA_REQUEST";
export const STORE_PREVIEW_DATA_SUCCESS = "STORE_PREVIEW_DATA_SUCCESS";
export const STORE_PREVIEW_DATA_ERROR = "STORE_PREVIEW_DATA_ERROR";

export const CLOSE_MODAL = "CLOSE_MODAL";

export const closeModal = () => {
	return async (dispatch) => {
		dispatch({ type: CLOSE_MODAL });
	};
};

const storeData = async (informationToStore, storeFunction, dispatch) => {
	dispatch({ type: STORE_PREVIEW_DATA_REQUEST });
	try {
		await storeFunction(informationToStore);
		dispatch({
			type: STORE_PREVIEW_DATA_SUCCESS
		});
	} catch (err) {
		dispatch({ type: STORE_PREVIEW_DATA_ERROR, error: err });
	}
};

export const storeEmailMessage = (informationToStore) => {
	return async (dispatch) => storeData(informationToStore, PreviewService.storeEmailMessage, dispatch);
};
