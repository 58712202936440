import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/state/hooks";

import { css } from "@emotion/css";
import { EditOutlined, DeleteOutlined, PlusOutlined, UnlockOutlined, LockOutlined } from "@ant-design/icons";

import { Alert, Table, Tooltip, Row, Tag, Modal, Flex } from "@/ui/antd";
import { Button } from "@/ui/Button";
import AuthService from "@/services/AuthService";
import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import PageHeading from "@/components/PageHeading";
import TableActions from "@/components/table/TableActions";
import { H4, P } from "@/components/text/Text";
import { updateSearch } from "@/state/actions/searchActions";
import { goToPathWithSearchState } from "@/utils/Urls";

import { editBankAccount, openLockBankAccountModal } from "@/state/actions/bankAccountsActions";
import { getConfirmationToDeleteBankAccount, getUser, saveBankAccounts } from "@/state/actions/userActions";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import BankAccountWarningModal from "@/users/BankAccountWarningModal";
import LockBankAccountModal from "@/users/LockBankAccountModal";
import { AdminUSTaxpayerInfo } from "./AdminUSTaxpayerInfo";

const hideRow = css({
	display: "none"
});

type BankAccountsListProps = {
	userId: string;
};

export const BankAccountsList: FC<BankAccountsListProps> = ({ userId }) => {
	const dispatch = useAppDispatch();
	const currentUser = useAppSelector((state) => state.currentUser);
	const authUser = useAppSelector((state) => state.auth.user);
	const bankAccounts = useAppSelector((state) => state.bankAccounts);
	const overrideLocation = useAppSelector((state) => state.locations.overrideLocation);

	useEffect(() => {
		loadUser();
	}, []);

	const openAdminOnlyModal = ({ usTaxpayerInfo }: any) => {
		if (usTaxpayerInfo) {
			Modal.success({
				content: <AdminUSTaxpayerInfo usTaxpayerInfo={usTaxpayerInfo} />
			});
		} else {
			Modal.error({
				content: "User is required to fill out a 1099-K"
			});
		}
	};

	const loadUser = () => {
		dispatch(getUser(userId));
	};

	const handleDelete = (bankAccount: any) => {
		const { _id: bankAccountId } = bankAccount;
		dispatch(getConfirmationToDeleteBankAccount(userId, bankAccountId));
	};

	const makeDefault = (bankAccount: any) => {
		const defaultBankAccount = { ...bankAccount, default: true };

		dispatch(saveBankAccounts({ userId, bankAccount: defaultBankAccount, eventId: null, redirectPath: "" }));
	};

	const handleAddNewAccount = () => {
		goToPathWithSearchState("/console/account/profile/bank-accounts/new");
	};

	const { bankAccountLockedByAdmin, unlockBankAccountProcedure } = currentUser?.user || {};

	const isAdmin = AuthService.isAdmin();
	const authUserIsAlsoTheEventOwner = currentUser?.user?.id === authUser?.id;

	const canEditBankAccounts = !!(
		!currentUser ||
		!currentUser.user ||
		(authUserIsAlsoTheEventOwner && !bankAccountLockedByAdmin)
	);

	const canMakeDefaultAccounts = authUserIsAlsoTheEventOwner;

	let addAccountTooltipText = "";
	if (!canEditBankAccounts)
		addAccountTooltipText = `Only the event owner (${currentUser.user.email}) can add new bank accounts`;
	else if (bankAccountLockedByAdmin) addAccountTooltipText = "Bank accounts have been locked by Humanitix admin";

	const columns = [
		{
			title: "Name",
			dataIndex: "firstName",
			key: "name",
			render: (_: any, { name, firstName, lastName, companyName, accountHolderType }: any) => {
				const usDisplayName = accountHolderType === "business" ? companyName : `${firstName} ${lastName}`;
				const anyOtherLocationName = name;
				const displayName = overrideLocation === "US" ? usDisplayName : anyOtherLocationName;
				return displayName;
			}
		},
		{
			title: "Account",
			dataIndex: "account",
			key: "account",
			render: (_: any, { bankName }: any) => {
				return bankName;
			}
		},
		{
			dataIndex: "account",
			key: "account",
			render: (_: any, { bsb, number }: any) => {
				const accountLast4 = number.slice(-4);
				return (
					<Row>
						*****{accountLast4} / {bsb}
					</Row>
				);
			}
		},
		...(overrideLocation === "US"
			? [
					{
						title: "TIN",
						dataIndex: "tin",
						key: "tin",
						render: (_: any, { usTaxpayerInfo }: any) => {
							const tinLast4 = usTaxpayerInfo?.tin?.slice(-4);

							if (tinLast4 && authUserIsAlsoTheEventOwner) {
								return <>*****{tinLast4}</>;
							} else if (tinLast4) {
								return <Tag color="geekblue">Provided</Tag>;
							} else {
								return <Tag color="red">Not provided</Tag>;
							}
						}
					}
			  ]
			: []),

		{
			dataIndex: "default",
			key: "default",
			render: (value: any, item: any) => {
				return value ? (
					<Tag color="green">Default</Tag>
				) : canMakeDefaultAccounts ? (
					<Button onClick={() => makeDefault(item)} aria-label="Make Default" variant="tertiary">
						Make default
					</Button>
				) : null;
			}
		}
	];

	if (canEditBankAccounts) {
		columns.push({
			dataIndex: "actions",
			key: "actions",
			render: (_, item) => {
				return (
					<Row>
						<Button variant="text" onClick={() => dispatch(editBankAccount(item, true))} iconOnly>
							<EditOutlined />
						</Button>
						<Button variant="text" onClick={() => handleDelete(item)} iconOnly>
							<DeleteOutlined />
						</Button>
					</Row>
				);
			}
		});
	} else if (isAdmin && currentUser.user.id !== authUser.id) {
		columns.push({
			dataIndex: "adminOnlyModal",
			key: "actions",
			render: (_, item) => {
				return (
					<TableActions>
						<Button variant="text" onClick={() => openAdminOnlyModal(item)} iconOnly>
							See more (Admin only)
						</Button>
					</TableActions>
				);
			}
		});
	}

	return (
		<LoadErrorView loading={currentUser.loading} error={currentUser.error as any} retryAction={loadUser}>
			<PageHeading
				title="Banking Accounts"
				helpButton={{
					link: HelpIds.bankAccounts,
					title: "Help"
				}}
			>
				<LocationSelection
					componentName="bankAccounts"
					defaultLevel="user"
					onLocationChange={(loc: string) => {
						dispatch(updateSearch({ loc }, true));
					}}
				/>
			</PageHeading>
			<ContentBlock>
				<Flex gap="12px" justify="space-between">
					<Flex vertical>
						<P style={{ maxWidth: "none" }}>
							Save your bank accounts and set the default which will be used on all new events.
						</P>

						<P style={{ maxWidth: "none" }}>
							Please make sure you <a onClick={OpenHelpHandler(HelpIds.bankAccounts)}>follow this guide</a> when
							updating your bank account details
						</P>
						<Alert
							showIcon
							message="Event payouts must be paid to a bank account local to the event’s selected currency."
							type="warning"
							style={{ margin: "0 0 12px 0", maxWidth: 700 }}
						/>
						<Alert
							showIcon
							message={
								<>
									<b>Does your team need to be notified of event payouts? </b>
									<div>Automatically send copies of payout receipts to your co-hosts or accounts team.</div>
									<div>
										<Link to="/console/account/profile/alerts">Edit your notifications</Link> or{" "}
										<a onClick={OpenHelpHandler(HelpIds.eventNotifications)}>learn more</a> about notification settings.
									</div>
								</>
							}
							type="warning"
							style={{ margin: "12px 0", maxWidth: 700 }}
						/>
						{bankAccountLockedByAdmin ? (
							<Alert
								message="Your bank accounts have been locked. Please contact Humanitix if you wish to unlock."
								type="info"
								showIcon
								style={{ margin: "0 0 12px 0", maxWidth: 700 }}
							/>
						) : null}
					</Flex>
					<Flex style={{ marginBottom: 12 }} gap="8px" justify="flex-end" align="flex-end">
						{isAdmin ? (
							<Button
								variant="tertiary"
								onClick={() => dispatch(openLockBankAccountModal())}
								aria-label={bankAccountLockedByAdmin ? "Unlock" : "Lock"}
								iconLeft={bankAccountLockedByAdmin ? <UnlockOutlined /> : <LockOutlined />}
							>
								{bankAccountLockedByAdmin ? "Unlock" : "Lock"}
							</Button>
						) : !bankAccountLockedByAdmin ? (
							<Button
								variant="tertiary"
								onClick={() => dispatch(openLockBankAccountModal())}
								aria-label="Lock"
								iconLeft={<LockOutlined />}
							>
								Lock
							</Button>
						) : null}
						<Tooltip title={addAccountTooltipText}>
							<Button
								onClick={handleAddNewAccount}
								disabled={!canEditBankAccounts}
								variant="tertiary"
								iconLeft={<PlusOutlined />}
							>
								{overrideLocation === "US" ? "Add new tax & bank details" : "Add new bank details"}
							</Button>
						</Tooltip>
					</Flex>
				</Flex>

				<div>
					<Table
						columns={columns}
						dataSource={currentUser?.user?.bankAccounts?.filter(
							(bankAccount: any) => bankAccount.location === overrideLocation
						)}
						rowKey="_id"
						pagination={false}
						rowClassName={(record) => {
							return record.deleted || record.isDonation ? hideRow : "";
						}}
					/>
				</div>
				{isAdmin && unlockBankAccountProcedure ? (
					<Alert
						description={
							<>
								<H4>Procedures to unlock bank account given by user</H4>
								{unlockBankAccountProcedure.split("\n").map((line: string, index: number) => (
									<P key={`line_${index}`}>{line}</P>
								))}
							</>
						}
						key="unlockProcedure"
						type="warning"
						showIcon
						style={{ margin: "12px 0", maxWidth: 700 }}
					/>
				) : null}

				{currentUser.warningModalOpen ? <BankAccountWarningModal userId={userId} /> : null}
				{bankAccounts.showLockBankAccountModal ? <LockBankAccountModal /> : null}
			</ContentBlock>
		</LoadErrorView>
	);
};
