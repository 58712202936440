import icon from "@/assets/menu/ic_admin.svg";
import AuthService from "../../AuthService";

const adminMenu = [
	{
		label: "Admin",
		key: "admin",
		icon,
		subMenu: true,
		path: "/console/admin/reports/control",
		children: [
			{
				label: "Reports",
				key: "admin-reports",
				subMenu: true,
				path: "/console/admin/reports/control",
				children: [
					{
						label: "Generated Reports",
						path: "/console/admin/reports/generated",
						key: "generated-reports"
					},
					{
						label: "Financial",
						path: `/console/admin/reports/board`,
						key: "admin-reports-financial",
						subMenu: true,
						children: [
							{
								label: "Board Report",
								path: "/console/admin/reports/board",
								key: "board-reports"
							},
							{
								label: "Control Report",
								path: "/console/admin/reports/control",
								key: "control-reports"
							},
							{
								label: "Ticket Sales Summary",
								path: "/console/admin/reports/ticket-sales-summary",
								key: "ticket-sales-summary"
							},
							{
								label: "Refunds",
								path: "/console/admin/reports/refunds",
								key: "refunds"
							},
							{
								label: "Fees",
								path: "/console/admin/reports/fees",
								key: "fees"
							},
							{
								label: "Fees Details",
								path: "/console/admin/reports/fees/details",
								key: "fees-details"
							},
							{
								label: "Fees Crystalisation",
								path: "/console/admin/reports/fees-crystalisation",
								key: "fees-crystalisation"
							},
							{
								label: "Payouts",
								path: "/console/admin/reports/payouts",
								key: "payouts"
							},
							{
								label: "Payout Details",
								path: "/console/admin/reports/payouts/details",
								key: "payouts-details"
							},
							{
								label: "Reversed Payouts",
								path: "/console/admin/reports/payouts/reversed",
								key: "payouts-reversed"
							},
							{
								label: "Adjustments",
								path: "/console/admin/reports/adjustments",
								key: "adjustments"
							},
							{
								label: "Adjustment Details",
								path: "/console/admin/reports/adjustments/details",
								key: "adjustments-details"
							},
							{
								label: "Money Held",
								path: "/console/admin/reports/money-held",
								key: "money-held"
							},
							{
								label: "Gift cards Held",
								path: "/console/admin/reports/gift-cards-held",
								key: "gift-cards-held"
							},
							{
								label: "Referral Payouts Report",
								path: "/console/admin/reports/referral-payout-report",
								key: "referral-payout-report"
							},
							{
								label: "Compare Payments",
								path: "/console/admin/reports/compare-payments",
								key: "compare-payments"
							}
						]
					},
					{
						label: "Events",
						path: `/console/admin/reports/events/sales-by-event-location`,
						key: "admin-reports-events",
						subMenu: true,
						children: [
							{
								label: "Top Events",
								path: "/console/admin/reports/events/top",
								key: "top-events"
							},
							{
								label: "Forecasted Top Events",
								path: "/console/admin/reports/events/forecasted",
								key: "forecasted-top-events"
							},
							{
								label: "Sales by Location",
								path: "/console/admin/reports/events/sales-by-event-location",
								key: "sales-by-event-location"
							}
						]
					},
					{
						label: "Users",
						path: `/console/admin/reports/users/activity`,
						key: "admin-reports-users",
						subMenu: true,
						children: [
							{
								label: "Top Users",
								path: "/console/admin/reports/users/top",
								key: "top-users"
							},
							{
								label: "Activity",
								path: "/console/admin/reports/users/activity",
								key: "activity"
							}
						]
					},
					{
						label: "Attendees",
						path: `/console/admin/reports/attendees/top-purchasers`,
						key: "admin-reports-attendees",
						subMenu: true,
						children: [
							{
								label: "Top purchasers",
								path: "/console/admin/reports/attendees/top-purchasers",
								key: "top-purchasers"
							}
						]
					},
					{
						label: "Integrations",
						path: `/console/admin/reports/integrations/mailchimp`,
						key: "admin-reports-integrations",
						subMenu: true,
						children: [
							{
								label: "Mailchimp",
								path: "/console/admin/reports/integrations/mailchimp",
								key: "mailchimp"
							}
						]
					}
				]
			},
			{
				label: "Mass Payout",
				path: "/console/admin/mass-payouts",
				key: "mass-payouts"
			},
			{
				label: "Email Logs",
				path: "/console/admin/email-logs",
				key: "email-logs"
			},
			{
				label: "Audit Logs",
				path: "/console/admin/audit-logs",
				key: "audit-logs"
			},
			{
				label: "Payouts",
				key: "admin-payouts",
				subMenu: true,
				path: "/console/admin/payouts",
				children: [
					{
						label: "Payouts Report",
						path: "/console/admin/payouts",
						key: "payouts"
					},
					{
						label: "Payments",
						path: "/console/admin/payouts/payments",
						key: "payments"
					}
				]
			},
			{
				label: "Box Office Terminals",
				key: "boxOfficeTerminals",
				path: "/console/admin/box-office/terminals"
			},
			{
				label: "Utilities",
				key: "admin-utilities",
				subMenu: true,
				path: "/console/admin/utilities/payouts",
				children: [
					{
						label: "Payouts",
						path: "/console/admin/utilities/payouts",
						key: "utilities-payouts"
					},
					{
						label: "DnD",
						path: "/console/admin/utilities/dnd",
						key: "utilities-dnd"
					},
					{
						label: "Email signature generator",
						path: "/console/admin/utilities/email-signature-generator",
						key: "email-signature-generator"
					}
				]
			},
			{
				label: "Shak's Place",
				key: "shaks-place",
				path: "/console/shaks-place"
			}
		]
	}
];

const Admin = () => {
	return AuthService.isAdmin() ? adminMenu : [];
};

export default Admin;
