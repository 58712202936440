import { getConfig } from "@/config";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Alert, Tabs } from "@/ui/antd";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { ContentBlock } from "../components/AppBlocks";
import LoadErrorView from "../components/LoadErrorView";
import PageHeading from "../components/PageHeading";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import AuthService from "../services/AuthService";
import { getOrganiser } from "../state/actions/organiserActions";
import { HelpIds, OpenHelpHandler } from "../utils/Help";
import OrganiserDeletionModal from "./OrganiserDeletionModal";
import OrganiserHeader from "./OrganiserHeader";
import OrganiserStyles from "./OrganiserStyles";
import Followers from "./followers/Followers";
import OrganiserDetails from "./organiserDetails";

const viewButton = {
	marginRight: 8,
	"@media (max-width: 900px)": {
		marginRight: 8
	}
};

const Organiser = () => {
	const dispatch = useAppDispatch();
	const organisers = useAppSelector((state) => state.organisers);
	const params = useParams();
	const navigate = useNavigate();

	const organiserId = params?.id;
	const tab = params?.tab || "details";

	useEffect(() => {
		dispatch(getOrganiser(organiserId));
	}, []);

	return (
		<LoadErrorView
			loading={organisers.loading || !organisers.selected._id}
			error={organisers.error}
			renderChildren={false}
		>
			<PageHeading
				title={<OrganiserHeader organiser={organisers.selected} />}
				helpButton={{
					link: HelpIds.organisers,
					title: "Help - Hosts"
				}}
			>
				{AuthService.isAdmin() ? (
					<Link to={`/console/admin/users/${organisers.selected.userId}/`}>
						<LegacyButton style={viewButton} ariaLabel="User">
							User
						</LegacyButton>
					</Link>
				) : null}
				<a
					href={`${getConfig("TICKETING_URL")}/host/${organisers.selected.slug}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					<LegacyButton style={viewButton} ariaLabel="View">
						View
					</LegacyButton>
				</a>
			</PageHeading>
			<ContentBlock allowOverflow>
				{organisers.selected.disabled ? (
					<Alert
						type="warning"
						showIcon
						message="Content flagged"
						description={
							<>
								The host content has been flagged and will not be visible to the public. Please{" "}
								<a onClick={OpenHelpHandler(HelpIds.verifyToPublish)}>contact support</a> for more information.
							</>
						}
					/>
				) : null}
				<Tabs
					activeKey={tab}
					onChange={(activeKey) => {
						navigate(`/console/account/hosts/${organiserId}/${activeKey}`);
					}}
					items={[
						{
							key: "details",
							label: "Details",
							children: (
								<>
									<OrganiserDetails />
									<OrganiserDeletionModal />
								</>
							)
						},
						{
							key: "styling",
							label: "Styling",
							children: <OrganiserStyles />
						},
						{
							key: "followers",
							label: "Followers",
							children: <Followers organiserId={organiserId} />
						}
					]}
				/>
			</ContentBlock>
		</LoadErrorView>
	);
};

export default Organiser;
