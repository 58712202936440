import { ContentBlock, Loading } from "@/components/AppBlocks";
import ErrorMsg from "@/components/ErrorMsg";
import { FC, ReactNode } from "react";

type LoadErrorViewProps = {
	children?: ReactNode;
	error?: { title: string; message: string } | null;
	loading: boolean;
	renderChildren?: boolean;
	retryAction?: () => void;
};

const LoadErrorView: FC<LoadErrorViewProps> = ({ children, loading, error, renderChildren = true, retryAction }) => {
	if (error) {
		return (
			<ContentBlock>
				<ErrorMsg
					title={error.title}
					message="Oops, something went wrong. Please try again"
					retryAction={() => retryAction?.()}
				/>
			</ContentBlock>
		);
	}

	return (
		<div>
			{loading ? <Loading /> : ""}
			{renderChildren || !loading ? children : null}
		</div>
	);
};

export default LoadErrorView;
