import { locationDataByCountryCode } from "@hx/locations";
import { connect } from "react-redux";
import AuthService from "../../services/AuthService";
import { onOverrideLocationSet } from "../../state/actions/locationActions";
import { H3 } from "../text/Text";

const locationOptions = Object.values(locationDataByCountryCode).map((option) => {
	return {
		value: option.location,
		label: option.currency
	};
});

const NoResultsInCurrency = ({ children, locations, user, locationSelection }) => {
	const locationValue = locations.overrideLocation || user.location;
	const selectionKey = locationValue ? locationValue.split("-")[0] : "";
	const availableOptions = AuthService.isAdmin()
		? locationOptions
		: locationOptions.filter(({ value }) => user.accessibleLocations[value]);
	const userHasMultiLocations = availableOptions.length > 1;
	const selected = locationOptions.find((loc) => loc.value === selectionKey);
	return userHasMultiLocations && locationSelection ? (
		<div style={{ textAlign: "center" }}>
			<H3>{`No results in ${selected.label}`}</H3>
			Try searching in a different currency
			<div style={{ width: 250, margin: "10px auto 0" }}>{locationSelection}</div>
		</div>
	) : (
		children
	);
};

export default connect(
	(state) => ({
		locations: state.locations,
		user: state.auth.user
	}),
	{ onOverrideLocationSet }
)(NoResultsInCurrency);
