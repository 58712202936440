import { css } from "@emotion/css";
import { Component } from "react";
import ReactGoogleMapLoader from "react-google-maps-loader";
import { Field } from "redux-form";
import { Heading } from "@/components/AppBlocks";
import { LegacyInputField, RadioField } from "@/components/Fields";
import { P } from "@/components/text/Text";
import { getConfig } from "@/config";
("@/config");
import { required } from "@/utils/Validators";
import LocationSearch from "./location/LocationSearch";
import Map from "./location/Map";
import NoLocation from "./location/NoLocation";
import OnlineInfo from "./location/OnlineInfo";

const typeRequired = required("A location type is required");
const addressRequired = required("Please add a address for your location");
const venueRequired = required("Please add a venue for your location");

const locationWrapper = css({
	display: "flex",
	"@media(max-width: 600px)": {
		display: "block"
	}
});

const leftColumn = css({
	flex: 1,
	marginRight: 5,
	"@media(max-width: 600px)": {
		marginRight: 0,
		display: "block"
	}
});

const rightColumn = css({
	flex: 1,
	marginLeft: 5,
	"@media(max-width: 600px)": {
		marginLeft: 0,
		display: "block"
	}
});

class Location extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { eventLocation, googleMaps, currentLocation, changeFieldValue, currency } = this.props;

		let address = eventLocation && eventLocation.address ? eventLocation.address : "";
		let locationType = eventLocation && eventLocation.type ? eventLocation.type : null;
		return (
			<>
				<Heading title="Location" description="Where is your event?" />
				<Field
					name="eventLocation.type"
					component={RadioField}
					hinttext="Name"
					validate={typeRequired}
					button
					options={[
						{
							label: "Address",
							value: "address"
						},
						{
							label: "Custom",
							value: "custom"
						},
						{
							label: "Online",
							value: "online"
						},
						{
							label: "To be announced",
							value: "toBeAnnounced"
						}
					]}
				/>

				<div className={locationWrapper}>
					<div className={leftColumn}>
						<LocationSearch
							hidden={locationType !== "address"}
							address={address}
							googleMaps={googleMaps}
							changeFieldValue={changeFieldValue}
							currentLocation={currentLocation}
							currency={currency}
						/>
						{locationType === "custom" ? (
							<Field
								name="eventLocation.address"
								label="Address"
								placeholder="Address"
								component={LegacyInputField}
								hinttext="Address"
								validate={addressRequired}
								disabled={locationType === "address"}
								required
							/>
						) : null}
						{locationType === "address" || locationType === "custom" ? (
							<Field
								name="eventLocation.venueName"
								label="Venue Name"
								placeholder="Venue name"
								component={LegacyInputField}
								hinttext="Venue Name"
								validate={venueRequired}
								required
							/>
						) : null}
						{locationType === "online" ? <OnlineInfo /> : null}
					</div>
					<div className={rightColumn}>
						{locationType === "address" ? (
							<Map googleMaps={googleMaps} eventLocation={eventLocation} currentLocation={currentLocation} />
						) : null}
						{locationType === "online" || locationType === "custom" ? <NoLocation /> : null}
					</div>
				</div>
			</>
		);
	}
}

const LocationGoogleMaps = (props) => {
	return (
		<ReactGoogleMapLoader
			params={{
				key: getConfig("GOOGLE_API_KEY"), // Define your api key here
				libraries: "places"
			}}
			render={(googleMaps, error) =>
				googleMaps ? (
					<div>
						{/*Show a custom error if SDK Authentication Error. See N/B 2 below.*/}
						{error ? error : <Location {...props} googleMaps={googleMaps} />}
					</div>
				) : (
					<div>
						{/*Check for network error so loading state ends if user lost connection.*/}
						{error === "Network Error" ? <P>{error}</P> : <P>isLoading...</P>}
					</div>
				)
			}
		/>
	);
};

export default LocationGoogleMaps;
