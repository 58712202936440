import { Component } from "react";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import EventSettingsFields from "@/components/EventSettingsFields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { H1 } from "@/components/text/Text";
import withSaveCheck from "@/hoc/withSaveCheck";
import AuthService from "@/services/AuthService";
import { updateEventSettings } from "@/state/actions/eventActions";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { withRouterHooks } from "@/hoc/withRouterHooks";

class Settings extends Component {
	constructor(props) {
		super(props);
		this.save = this.save.bind(this);
	}

	save(values) {
		const { params, updateEventSettings } = this.props;
		const eventId = params?.eventId;
		let saveObject = {
			settings: {
				...values.settings,
				customTimeout: values.settings.customTimeout !== 0 ? values.settings.customTimeout : null,
				maximumTicketsPerOrder:
					values.settings.maximumTicketsPerOrder !== 0 ? values.settings.maximumTicketsPerOrder : null
			}
		};
		if (values.settings.datePickerLayout !== "auto") {
			saveObject.datesUseCalendar = values.settings.datePickerLayout === "calendar";
		}
		if (values.ticketTypes && values.ticketTypes.length) {
			saveObject.ticketTypes = values.ticketTypes;
		}
		saveObject.ticketAssigningSettings = values.ticketAssigningSettings;
		updateEventSettings(eventId, saveObject);
	}

	render() {
		const {
			handleSubmit,
			currentEvent,
			sendTickets,
			ticketAssigningSettings,
			afterCheckoutRedirectEnabled,
			uniqueAdditionalQuestionAnswerFields,
			enableCustomQrData,
			disableAdditionalParameters,
			isResaleAllowed
		} = this.props;

		const isAdmin = AuthService.isAdmin();
		return (
			<LoadErrorView loading={currentEvent.save.loading} error={currentEvent.save.error} retryAction={this.save}>
				<form onSubmit={handleSubmit(this.save)}>
					<H1>Settings</H1>
					<ContentBlock>
						<EventSettingsFields
							isAdmin={isAdmin}
							currentEvent={currentEvent}
							showSendTicketPerTicket
							sendTickets={sendTickets}
							ticketAssigningSettings={ticketAssigningSettings}
							afterCheckoutRedirectEnabled={afterCheckoutRedirectEnabled}
							uniqueAdditionalQuestionAnswerFields={uniqueAdditionalQuestionAnswerFields}
							enableCustomQrData={enableCustomQrData}
							disableAdditionalParameters={disableAdditionalParameters}
							formName={formName}
							isResaleAllowed={isResaleAllowed}
						/>
					</ContentBlock>

					<FormBar formLayout="horizontal">
						<LegacyButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							ariaLabel="Save"
							size="large"
						>
							Save
						</LegacyButton>
					</FormBar>
				</form>
			</LoadErrorView>
		);
	}
}

const formName = "eventSettings";

Settings = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(Settings);

const selector = formValueSelector(formName);

export default connect(
	(state) => {
		const event = state.currentEvent.event;
		event.settings.allowUserEdit =
			event.settings.allowUserEdit === null || event.settings.allowUserEdit === undefined
				? true
				: event.settings.allowUserEdit;

		event.ticketTypes = event.ticketTypes.map((ticketType) => {
			if (ticketType.sendTicket !== false) {
				ticketType.sendTicket = true;
			}
			return ticketType;
		});

		event.ticketAssigningSettings = event.ticketAssigningSettings || {};
		event.settings.datePickerLayout = event.settings.datePickerLayout || "auto";
		return {
			initialValues: event,
			currentEvent: state.currentEvent,
			isResaleAllowed: AuthService.isResaleAllowed(),
			sendTickets: selector(state, "settings.sendTickets"),
			afterCheckoutRedirectEnabled: selector(state, "settings.afterCheckoutRedirect.enabled"),
			disableAdditionalParameters: selector(state, "settings.afterCheckoutRedirect.disableAdditionalParameters"),
			uniqueAdditionalQuestionAnswerFields: selector(state, "settings.uniqueAdditionalQuestionAnswerFields"),
			enableCustomQrData: selector(state, `settings.associateCustomQrData.enabled`)
		};
	},
	{ updateEventSettings }
)(withSaveCheck(withRouterHooks(Settings), formName));
