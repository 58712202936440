import styled from "@emotion/styled";
import { FC } from "react";
import { HumanitixForHostsHeader } from "./ForHostsHeader";

const Wrapper = styled.div`
	a {
		text-decoration: underline;
	}
`;

const Columns = styled.div`
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 48px;
	width: 650px;

	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const SingleColumnPageWrap: FC<{ showBuyTicketLink?: boolean }> = ({ children, showBuyTicketLink }) => {
	return (
		<Wrapper>
			<HumanitixForHostsHeader showBuyTicketLink={showBuyTicketLink} />
			<Columns>{children}</Columns>
		</Wrapper>
	);
};
