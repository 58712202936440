import { AlreadyHaveAccount } from "@/auth/components/Links";
import { SingleColumnPageWrap } from "@/auth/components/SingleColumnPageWrap";
import { InternationalPhoneField } from "@/components/fields/InternationalPhoneField";
import { RecaptchaField } from "@/components/fields/RecaptchaField";
import { H1, P } from "@/components/text/Text";
import { getConfig } from "@/config";
import { signUp } from "@/state/actions/authActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Form, Input } from "@/ui/antd";
import { Button } from "@/ui/Button";
import { mobile } from "@/utils/Validators";
import styled from "@emotion/styled";
import { Location, locationDataByCountryCode } from "@hx/locations";
import { FC, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { LinkWrapper } from "../components/Links";
import { CustomerTypeOptions } from "./CustomerTypeOptions";
import LocationField from "./LocationField";

const viewerCountry = (window.viewerCountry || "AU") as Location;
const defaultLocation = locationDataByCountryCode[viewerCountry]?.location || "AU";

const StyledForm = styled(Form)`
	.ant-form-item {
		margin-bottom: 12px;
	}
	.ant-form-item-label {
		padding-bottom: 0px;
	}
`;

const customerOptions = [
	{
		value: "organiser",
		label: "I’m an event host"
	},
	{
		value: "helper",
		label: "I’m helping out with an event"
	},
	{
		value: "buyer",
		label: "I’m a ticket buyer"
	}
];

const getReferralDetails = (searchParams: URLSearchParams) => {
	const referralCode = searchParams?.get("referralcode");
	const referralEmail = searchParams?.get("referralemail");
	const adSourceId = localStorage.getItem("adsourceid");

	const referralDetails = {
		adSourceId
	};

	if (referralCode) {
		return { ...referralDetails, referralCode };
	} else if (referralEmail) {
		return { ...referralDetails, referralEmail };
	}

	return referralDetails;
};

const validMobile = mobile("Please enter a valid mobile");

const ColumnSection = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 4px;
	margin-top: 24px;
	text-align: center;
`;

const TicketBuyerSection = () => {
	const WEBSITE_URL = getConfig("WEBSITE_URL");

	return (
		<ColumnSection>
			<P>
				Ticket buyers don't need a Humanitix account to buy tickets or manage orders. Your tickets are delivered and
				managed via email.
			</P>
			<LinkWrapper>
				<Link to={`${WEBSITE_URL}/search`}>Explore our events</Link>
			</LinkWrapper>
			<LinkWrapper>
				<Link to={`${WEBSITE_URL}/faqs`}>Ticket buyer FAQs</Link>
			</LinkWrapper>
		</ColumnSection>
	);
};

export const Signup: FC = () => {
	const [signupForm] = Form.useForm();
	const formValues = Form.useWatch([], signupForm);
	const [formStep, setFormStep] = useState<"selectCustomerType" | "customerDetails">("selectCustomerType");
	const recaptchaPass = useAppSelector((state) => state.auth?.recaptchaPass);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [searchParams] = useSearchParams();
	const email = searchParams?.get("email")?.split("%2E").join(".") || undefined;
	const firstName = decodeURIComponent(searchParams?.get("first_name") || "");
	const lastName = decodeURIComponent(searchParams?.get("last_name") || "");
	const mobile = decodeURIComponent(searchParams?.get("mobile") || "");
	const organisation = decodeURIComponent(searchParams?.get("organisation") || "");
	const token = searchParams?.get("token");

	const handleSave = () => {
		setIsSubmitting(true);
		const formValues = signupForm.getFieldsValue(true);
		const referralDetails = getReferralDetails(searchParams);

		signUp(
			{
				...formValues,
				...referralDetails,
				token
			},
			navigate,
			() => setIsSubmitting(false)
		)(dispatch);
	};

	return (
		<SingleColumnPageWrap showBuyTicketLink>
			<H1>Sign up to create your event</H1>

			<StyledForm
				autoComplete="off"
				form={signupForm}
				initialValues={{
					email,
					firstName,
					lastName,
					location: defaultLocation,
					mobile,
					organisation
				}}
				layout="vertical"
				style={{
					width: "100%",
					marginTop: 24
				}}
				onFinish={handleSave}
			>
				<Form.Item name="customerType" hidden={formStep === "customerDetails"}>
					<CustomerTypeOptions options={customerOptions} />
				</Form.Item>

				{formValues?.customerType === "buyer" ? <TicketBuyerSection /> : null}

				{formStep === "selectCustomerType" ? (
					<>
						{formValues?.customerType !== "buyer" ? (
							<Button
								onClick={() => setFormStep("customerDetails")}
								variant="primary"
								style={{ margin: "auto", marginTop: "40px" }}
								disabled={!formValues?.customerType || formValues?.customerType === "buyer"}
							>
								Continue
							</Button>
						) : null}
						<ColumnSection>
							<AlreadyHaveAccount />
						</ColumnSection>
					</>
				) : null}

				{formStep === "customerDetails" ? (
					<>
						{formValues?.customerType !== "buyer" ? (
							<>
								<Form.Item name="email" label={<b>Email Address</b>}>
									<Input disabled />
								</Form.Item>

								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										gap: 8
									}}
								>
									<Form.Item
										name="firstName"
										label={<b>First name</b>}
										required
										rules={[
											{
												message: "Please input your first name",
												min: 1,
												required: true,
												type: "string",
												whitespace: true
											}
										]}
										style={{ width: "100%" }}
									>
										<Input placeholder="First name" />
									</Form.Item>

									<Form.Item
										name="lastName"
										label={<b>Last name</b>}
										required
										rules={[
											{
												message: "Please input your last name",
												min: 1,
												required: true,
												type: "string",
												whitespace: true
											}
										]}
										style={{ width: "100%" }}
									>
										<Input placeholder="Last name" />
									</Form.Item>
								</div>

								<LocationField country={formValues?.location} />

								<Form.Item
									name="mobile"
									label={<b>Mobile</b>}
									required
									rules={[
										{
											validator: (_, value) => {
												if (!value) {
													return Promise.reject("Please input your mobile");
												}

												const err = validMobile(value);
												if (err) {
													return Promise.reject(err);
												}

												return Promise.resolve();
											}
										}
									]}
								>
									<InternationalPhoneField defaultCountry={formValues?.location} />
								</Form.Item>

								<Form.Item name="organization" label={<b>Company</b>} required={false}>
									<Input placeholder="Company" />
								</Form.Item>

								<Form.Item
									name="password"
									label={<b>Password</b>}
									required
									rules={[
										{
											message: "Please input your password",
											min: 1,
											required: true,
											type: "string",
											whitespace: true
										}
									]}
								>
									<Input.Password placeholder="Password" />
								</Form.Item>

								{!recaptchaPass ? (
									<Form.Item
										name="recaptchaVerificationCode"
										required={false}
										rules={[
											{
												message: "Please prove you are human",
												min: 1,
												required: true,
												type: "string"
											}
										]}
									>
										<RecaptchaField />
									</Form.Item>
								) : null}

								<LinkWrapper>
									<P style={{ fontSize: 14, marginBottom: "40px", maxWidth: "unset" }}>
										By signing up, I agree to the{" "}
										<a
											href="https://static.humanitix.com/pdfs/organiser_terms.pdf"
											target="_blank"
											aria-label="terms and conditions"
											rel="noopener noreferrer"
										>
											terms and conditions
										</a>{" "}
										and have read the{" "}
										<a
											href="https://static.humanitix.com/pdfs/privacy_and_cookie_policy.pdf"
											target="_blank"
											aria-label="privacy policy"
											rel="noopener noreferrer"
										>
											privacy policy
										</a>
									</P>
								</LinkWrapper>

								<Button
									variant="primary"
									style={{ margin: "auto", marginBottom: "16px" }}
									htmlType="submit"
									isLoading={isSubmitting}
									disabled={isSubmitting}
								>
									Sign up
								</Button>

								<AlreadyHaveAccount />
							</>
						) : null}
					</>
				) : null}
			</StyledForm>
		</SingleColumnPageWrap>
	);
};
