import H2 from "@/components/text/H2";
import { Breakdown } from "@/state/reducers/newPayoutReducer";
import { ReduxState } from "@/state/store";
import { currency } from "@/utils/Format";
import { css } from "@emotion/css";
import { Progress } from "@/ui/antd";
import { connect } from "react-redux";

const tableStyling = css({
	width: "100%",
	tr: {
		backgroundColor: "#f9f9fa",
		"&:nth-child(even)": {
			backgroundColor: "white"
		},
		border: "none"
	},
	th: {
		textAlign: "left",
		border: "none"
	},
	td: {
		textAlign: "right",
		border: "none"
	},
	marginBottom: 16
});

const TableRow = ({
	title,
	amount,
	isCredit,
	hideIfZero,
	bottomBorder,
	topBorder
}: {
	title: string;
	amount: number;
	isCredit: boolean;
	hideIfZero: boolean;
	bottomBorder: boolean;
	topBorder: boolean;
}) => {
	if (hideIfZero && !amount) return null;
	const displayAmount = isCredit ? currency(amount) : `(${currency(amount)})`;
	return (
		<tr
			style={{
				borderBottom: bottomBorder ? "var(--border1)" : "",
				borderTop: topBorder ? "var(--border1)" : ""
			}}
		>
			<th>{title}</th>
			<td>{displayAmount}</td>
		</tr>
	);
};

const ReconciliationTable = ({ eventBreakdown }: IInjectedProps) => {
	if (!eventBreakdown) return null;
	return (
		<div style={{ width: "50%" }}>
			<H2>Payout Summary</H2>
			<Progress
				strokeWidth={15}
				strokeColor="#57CFC7"
				percent={eventBreakdown.percentPaid}
				style={{ marginBottom: 24 }}
			/>
			<table className={tableStyling}>
				<tbody>
					<TableRow
						title="Sales via Humanitix payments"
						amount={eventBreakdown.salesViaHumanitixGateways}
						isCredit={true}
						hideIfZero={false}
						bottomBorder={false}
						topBorder={false}
					/>
					<TableRow
						title="Sales via Afterpay"
						amount={eventBreakdown.salesViaAfterpay}
						isCredit={true}
						hideIfZero={true}
						bottomBorder={false}
						topBorder={false}
					/>
					<TableRow
						title="Sales via Zip Pay"
						amount={eventBreakdown.salesViaZip}
						isCredit={true}
						hideIfZero={true}
						bottomBorder={false}
						topBorder={false}
					/>
					<TableRow
						title="Sales via Box office card payments"
						amount={eventBreakdown.salesViaBoxOfficeCard}
						isCredit={true}
						hideIfZero={true}
						bottomBorder={false}
						topBorder={false}
					/>
					<TableRow
						title="Fee rebates"
						amount={eventBreakdown.feeRebates}
						isCredit={true}
						hideIfZero={true}
						bottomBorder={false}
						topBorder={false}
					/>
					<TableRow
						title="Absorbed Humanitix fees"
						amount={eventBreakdown.absorbedHumanitixFees}
						isCredit={false}
						hideIfZero={false}
						bottomBorder={false}
						topBorder={false}
					/>
					<TableRow
						title="Refunds"
						amount={eventBreakdown.refunds}
						isCredit={false}
						hideIfZero={false}
						bottomBorder={false}
						topBorder={false}
					/>
					<TableRow
						title="Box Office cash fees (collected by you)"
						amount={eventBreakdown.boxOfficeCashFees}
						isCredit={false}
						hideIfZero={true}
						bottomBorder={false}
						topBorder={false}
					/>
					<TableRow
						title="Pay by invoice fees (collected by you)"
						amount={eventBreakdown.payByInvoiceFees}
						isCredit={false}
						hideIfZero={true}
						bottomBorder={false}
						topBorder={false}
					/>
					<TableRow
						title="Zip Pay fees"
						amount={eventBreakdown.zipFees}
						isCredit={false}
						hideIfZero={true}
						bottomBorder={false}
						topBorder={false}
					/>
					<TableRow
						title="Afterpay fees"
						amount={eventBreakdown.afterpayFees}
						isCredit={false}
						hideIfZero={true}
						bottomBorder={false}
						topBorder={false}
					/>
					<TableRow
						title="Credits"
						amount={eventBreakdown.credits}
						isCredit={true}
						hideIfZero={true}
						bottomBorder={false}
						topBorder={false}
					/>
					<TableRow
						title="Positive Adjustments"
						amount={eventBreakdown.positiveAdjustments}
						isCredit={true}
						hideIfZero={true}
						bottomBorder={false}
						topBorder={false}
					/>
					<TableRow
						title="Negative Adjustments"
						amount={eventBreakdown.negativeAdjustments}
						isCredit={false}
						hideIfZero={true}
						bottomBorder={false}
						topBorder={false}
					/>
					<TableRow
						title="Estimated payouts due from Humanitix"
						amount={eventBreakdown.owed}
						isCredit={true}
						hideIfZero={false}
						bottomBorder={false}
						topBorder={true}
					/>
					<TableRow
						title="Paid to date"
						amount={eventBreakdown.paid}
						isCredit={false}
						hideIfZero={false}
						bottomBorder={false}
						topBorder={false}
					/>
					<TableRow
						title="Current balance"
						amount={eventBreakdown.due}
						isCredit={true}
						hideIfZero={false}
						bottomBorder={true}
						topBorder={false}
					/>
				</tbody>
			</table>
		</div>
	);
};

interface IInjectedProps {
	eventBreakdown?: Breakdown;
}

export default connect(
	(state: ReduxState): IInjectedProps => ({
		eventBreakdown: state.payouts.eventPayout.payoutInformation?.eventBreakdown
	}),
	{}
)(ReconciliationTable);
