import { HumaniDate } from "@hx/dates";
import Axios, { AxiosError, AxiosInstance } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import AuthService from "./AuthService";
const AUTH_FAILURE_LIMIT = 10;

class AxiosWrapper {
	static instance: AxiosInstance;
	static unAuthCount = 0;

	static createInstance() {
		this.instance = Axios.create({
			baseURL: "/api",
			headers: {
				"x-browser-timezone": HumaniDate.getBrowserTimezone()
			}
		});
		this.useApplyTokenHeaderInterceptor = this.useApplyTokenHeaderInterceptor.bind(this);
		this.refreshAuthLogic = this.refreshAuthLogic.bind(this);
		this.useApplyUnAuthCountInterceptor = this.useApplyUnAuthCountInterceptor.bind(this);

		this.useApplyTokenHeaderInterceptor();
		createAuthRefreshInterceptor(this.instance, this.refreshAuthLogic);
		this.useApplyUnAuthCountInterceptor();
		return this.instance;
	}

	static getInstance() {
		if (!this.instance) {
			this.instance = this.createInstance();
		}
		return this.instance;
	}

	static refreshAuthLogic(failedRequest: AxiosError) {
		return new Promise((resolve, reject) => {
			if (this.unAuthCount >= AUTH_FAILURE_LIMIT) {
				AuthService.signOut();
				return reject("Hit the max 401 errors, signing out");
			}
			AuthService.refresh()
				.then((token) => {
					if (!failedRequest.response) return; // throw an error here instead ?
					failedRequest.response.config.headers["x-token"] = token;
					this.unAuthCount = this.unAuthCount + 1;
					setTimeout(() => {
						return resolve(false);
					}, 500);
				})
				.catch((err) => {
					console.log("Auth refresh error", err);
				});
		});
	}

	static useApplyTokenHeaderInterceptor() {
		this.instance.interceptors.request.use(async (config) => {
			try {
				const token = await AuthService.checkRefresh();
				config.headers["x-token"] = token;
				return config;
			} catch (err) {
				return config;
			}
		});
	}

	static useApplyUnAuthCountInterceptor() {
		this.instance.interceptors.response.use(
			(response) => {
				this.unAuthCount = 0;
				// Do something with response data
				return response;
			},
			(error) => {
				if (error) {
					const status = error?.response?.status ?? "?";
					if (status === 403) {
						if (window) {
							window.open(`/console/unauthorised`, "_self");
						}
					}
					if (status !== 401) {
						this.unAuthCount = 0;
					}
				}
				return Promise.reject(error);
			}
		);
	}
}

export default AxiosWrapper.getInstance();
