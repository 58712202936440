import { css } from "@emotion/css";
import { Modal } from "@/ui/antd";
import { Component } from "react";
import { Field } from "redux-form";
import { ColourField, ImageUploadField } from "@/components/Fields";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import { getConfig } from "@/config";
import AuthService from "@/services/AuthService";
import { max } from "@/utils/CssUtils";
import { getLocalisedText } from "@/utils/Format";
import TicketPreview from "@/events/event/design/TicketPreview";

const wrapper = css({
	display: "flex",
	justifyContent: "space-between",
	[max(1100)]: {
		flexDirection: "column"
	}
});

const formWrapper = css({
	minWidth: 360,
	width: "100%",
	marginRight: 80
});

const logoWrapper = css({
	maxWidth: 360
});

/**
 * Make iframe to preview ticketing from ticketing server
 *
 * @param {string} { fullUrl }
 * @returns ticket preview iframe
 */
//eslint-disable-next-line
const Preview = ({ eventId, ticketMessage, banner, logo, hideCharity = false, ticketBackgroundColor }) => {
	const token = AuthService.token;
	const bannerParams = banner && banner.handle ? `&bh=${encodeURIComponent(banner.handle)}` : "";
	const logoParams = logo && logo.handle ? `&lh=${encodeURIComponent(logo.handle)}` : "";
	const hideImpactParams = hideCharity ? `&hc=${encodeURIComponent(hideCharity)}` : ``;
	const tickerBackgroundColorParams = ticketBackgroundColor ? `&tbc=${encodeURIComponent(ticketBackgroundColor)}` : ``;
	const fullUrl = `${getConfig(
		"TICKETING_URL"
	)}/api/emails/get-ticket-preview/${eventId}?w=true&token=${encodeURIComponent(token)}&tm=${encodeURIComponent(
		ticketMessage
	)}${bannerParams}${logoParams}${hideImpactParams}${tickerBackgroundColorParams}`;
	const wrap = css`
		max-width: 580px;
		width: 100%;
		height: 500px;
		padding: 0;
		overflow: hidden;
		overflow-y: scroll;
		@media (max-width: 540px) {
			overflow: scroll;
		}
	`;
	const iframe = css`
		width: 910px;
		height: 1200px;
		border: 0;
		-ms-transform: scale(0.55);
		-moz-transform: scale(0.55);
		-o-transform: scale(0.55);
		-webkit-transform: scale(0.55);
		transform: scale(0.55);
		-ms-transform-origin: 0 0;
		-moz-transform-origin: 0 0;
		-o-transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	`;

	return (
		<div className={wrap}>
			<iframe title="Preview ticket" src={fullUrl} frameBorder="0" className={iframe} />
		</div>
	);
};
/**
 * Provides ticket line setup for users.
 *
 * @param {object} props
 * @returns custom ticket line options
 */

class Ticket extends Component {
	constructor(props) {
		super(props);
		this.state = { showPreview: false };
	}
	togglePreviewModal = () => {
		this.setState({ showPreview: !this.state.showPreview });
	};
	render() {
		/*eslint-disable*/
		const {
			currentEvent,
			updateTicketLineColor,
			ticketMessage,
			banner,
			hideCharity,
			ticketLogo,
			ticketBackgroundColor
		} = this.props;
		/*eslint-enable*/
		const { showPreview } = this.state;
		const colourText = getLocalisedText("colour");
		return (
			<div className={wrapper}>
				<div className={formWrapper}>
					<div className={logoWrapper}>
						<Field
							name="messages.ticketLogo"
							label="Add logo to header"
							component={ImageUploadField}
							uploadDescription="(Recommended logo size: ratio 2:1, max 10mb)"
							aspectRatio={2}
							canvaEnabled={false}
						/>
					</div>
					<hr />

					<Field
						label={`Ticket background ${colourText}`}
						description={`This ${colourText} will appear on the print ticket and digital ticket`}
						name="theme.ticketBackgroundColor"
						component={ColourField}
						marginTop={16}
						onChange={updateTicketLineColor}
						style={{ width: 60, height: 36 }}
					/>

					<hr />

					<Field
						name="messages.banner"
						label="Custom banner image on ticket"
						component={ImageUploadField}
						uploadDescription="(Recommended logo size: ratio 2:1, max 10mb)"
						aspectRatio={2}
					/>
				</div>
				<div>
					<TicketPreview {...this.props} />
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center"
						}}
					>
						<LegacyButton
							icon={{ left: true, name: "preview" }}
							type="action"
							flavor="minimal"
							onClick={this.togglePreviewModal}
							ariaLabel="Preview Ticket"
						>
							<u>Preview ticket</u>
						</LegacyButton>
					</div>
				</div>
				<Modal
					title="Ticket Preview"
					open={showPreview}
					onCancel={this.togglePreviewModal}
					destroyOnClose
					footer={
						<LegacyButton onClick={this.togglePreviewModal} ariaLabel="Close">
							Close
						</LegacyButton>
					}
				>
					{/* JEN-14649 disabled until v2 version is created  */}
					<p>Previews are currently not available. We’re building something new!</p>{" "}
					<p>To preview, we recommend making a free order</p>
					{/*<Preview
            eventId={currentEvent.event._id}
            currentEvent={currentEvent}
            ticketMessage={ticketMessage}
            banner={banner}
            logo={ticketLogo}
            hideCharity={hideCharity}
            ticketBackgroundColor={ticketBackgroundColor}
          />
        */}
				</Modal>
			</div>
		);
	}
}

export default Ticket;
