import { TaxSettings } from "@hx/utilities";
import { Tabs } from "@/ui/antd";
import { Fragment } from "react";
import { Field } from "redux-form";
import { checkLength, checkNumber, required, url } from "../utils/Validators";
import { InputNumberField, RadioField, SendTicketsField, SwitchField } from "./Fields";
import TaxExemptFormInput from "./TaxExemptFormInput";
import CustomQrDataField from "./fields/CustomQrDataField";
import LegacyInputField from "./fields/LegacyInputField";
import InfoTitle from "./table/InfoTitle";
import { OpenHelpHandler } from "@/utils/Help";
import UniqueAdditionalQuestionAnswers from "./UniqueAdditionalQuestionAnswers";

const urlRequired = required("A url to redirect to is required");
const urlValid = url("URL must be valid");
const redirectMessageLength = checkLength("Redirect message character limit is 250", 250, "<=");
const minTimeoutLength = checkNumber("Timeout must be greater than 5", 5, ">");
const maxTimeoutLength = checkNumber("Timeout must be less than 60", 60, "<");

type EventSettingsFieldsProps = {
	isAdmin: boolean;
	showSendTicketPerTicket: boolean;
	sendTickets: boolean;
	afterCheckoutRedirectEnabled: boolean;
	uniqueAdditionalQuestionAnswerFields?: any;
	enableCustomQrData?: boolean;
	disableAdditionalParameters: boolean;
	formName: string;
	currentEvent?: any;
	userLocation?: string;
	path?: string;
};

const EventSettingsFields = ({
	isAdmin,
	path = "settings",
	currentEvent,
	showSendTicketPerTicket,
	sendTickets,
	afterCheckoutRedirectEnabled,
	uniqueAdditionalQuestionAnswerFields,
	enableCustomQrData,
	disableAdditionalParameters,
	formName,
	userLocation
}: EventSettingsFieldsProps) => {
	const showTicketSettings = !(!sendTickets || !showSendTicketPerTicket);

	let taxName = "Sales tax";
	if (currentEvent && currentEvent.event) {
		const taxInfo = TaxSettings.getEventTaxInfo(currentEvent.event.salesTaxSettings, currentEvent.event.location);
		taxName = taxInfo.name;
	}

	return (
		<div>
			<Tabs
				defaultActiveKey="0"
				items={[
					{
						children: (
							<Fragment>
								<SendTicketsField
									path={path}
									currentEvent={currentEvent}
									showSendTicketPerTicket={showTicketSettings}
								/>
								<hr />
								<Field
									name={`${path}.showPricesBeforeSalesTax`}
									label={`Show prices before ${taxName}`}
									description={`Display the price range of tickets before ${taxName} is added`}
									component={SwitchField}
								/>
								<hr />
								<Field
									label="Show Tickets Left"
									name={`${path}.ticketsRemainingDisplay`}
									tooltip="Show the tickets remaining "
									description="Display number of tickets remaining on the select ticket page. For example 10 tickets left."
									component={SwitchField}
								/>
								<hr />
								<Field
									id="disallowRefundFees"
									name={`${path}.disallowRefundFees`}
									component={SwitchField}
									label="Allow users / customers to refund fees"
									description="Users and customers will be able to refund fees when issuing / requesting refunds"
									invert
								/>
								<hr />
								<Field
									id="maximumTicketsPerOrder"
									name={`${path}.maximumTicketsPerOrder`}
									component={InputNumberField}
									label="Maximum tickets per order"
									description="Set a maximum number of tickets (of any type) that can be purchased per order."
									allowsNullValue={true}
								/>
								<Field
									id="minimumTicketsPerOrder"
									name={`${path}.minimumTicketsPerOrder`}
									component={InputNumberField}
									label="Minimum tickets per order"
									description="Set a minimum number of tickets (of any type) that can be purchased per order."
									allowsNullValue={true}
									min={1}
								/>
								<hr />
								<UniqueAdditionalQuestionAnswers
									path={path}
									uniqueAdditionalQuestionAnswerFields={uniqueAdditionalQuestionAnswerFields}
									currentEvent={currentEvent}
								/>
							</Fragment>
						),
						key: "0",
						label: "Tickets"
					},
					{
						children: (
							<Fragment>
								<Field
									label="Allow orders to be edited"
									name={`${path}.allowUserEdit`}
									tooltip="Allow orders to be edited"
									description="Let customers update their details after purchase."
									component={SwitchField}
								/>
								<hr />
								<Field
									id="showOrganiserMailListOptIn"
									name={`${path}.showOrganiserMailListOptIn`}
									component={SwitchField}
									label="Enable host's mailing list opt in"
									description="Customers will have the option to join the host's mailing list on the enter details page when purchasing a ticket"
								/>
								<hr />
								<Field
									id="disableRemarketing"
									name={`${path}.disableRemarketing`}
									component={SwitchField}
									invert={true}
									label="Enable abandon cart notifications"
									description="Sends a reminder to customers who do not complete an order."
								/>
							</Fragment>
						),
						key: "1",
						label: "Orders"
					},
					{
						children: (
							<Fragment>
								<Field
									label="Hide business/individual question"
									name={`${path}.hideIsBusinessQuestion`}
									tooltip="Hide business/individual question"
									description="Hide the 'Are you purchasing as a business or individual?' Question"
									component={SwitchField}
								/>
								<hr />
								<Field
									id="customTimeout"
									name={`${path}.customTimeout`}
									component={InputNumberField}
									label="Custom timeout"
									description="Adjust the amount of time (minutes) that users have to checkout"
									validate={[minTimeoutLength, maxTimeoutLength]}
								/>
								<hr />
								<Field
									id="mobileRequired"
									name={`${path}.mobileRequired`}
									component={SwitchField}
									label="Mobile Required"
									description="Customers are required to enter a mobile number"
								/>
								<hr />
								<Field
									label='Hide "Add to calendar" button'
									name={`${path}.hideAddToCalendar`}
									description="Remove the option for users to add the event to their calendar"
									component={SwitchField}
								/>
								<hr />
								<Field
									label="Hide confetti"
									name={`${path}.hideConfetti`}
									description="Removes a blast of confetti on the order complete page"
									component={SwitchField}
								/>
								<hr />
								<Field
									label="Date picker"
									name={`${path}.datePickerLayout`}
									description="Recurring event date picker layout"
									tooltip="Depending  on your events frequency, date picker will either appear as a calendar picker or a dropdown."
									component={RadioField}
									vertical
									options={[
										{
											label: (
												<InfoTitle
													style={{}}
													title="Automatically"
													tooltip="Depending  on your events frequency, the checkout date picker will either appear as a calendar picker or a dropdown."
												/>
											),
											value: "auto"
										},
										{
											label: "Calendar picker",
											value: "calendar"
										},
										{
											label: "Dropdown",
											value: "dropdown"
										}
									]}
								/>
								<hr />
								<Field
									id="requireEmailConfirmation"
									label="Email Confirmation Required"
									name={`${path}.requireEmailConfirmation`}
									description="Customers are required to re-enter their email for validation"
									component={SwitchField}
								/>
								<hr />
								<Field
									id="afterCheckoutRedirect.enabled"
									name={`${path}.afterCheckoutRedirect.enabled`}
									component={SwitchField}
									label="Redirect buyer after checkout is complete"
									description="Enable redirecting the buyer after checkout is complete"
								/>
								{afterCheckoutRedirectEnabled ? (
									<>
										<Field
											id="afterCheckoutRedirect.url"
											name={`${path}.afterCheckoutRedirect.url`}
											component={LegacyInputField}
											label="URL to redirect to"
											required
											validate={[urlRequired, urlValid]}
										/>
										<Field
											id="afterCheckoutRedirect.message"
											name={`${path}.afterCheckoutRedirect.message`}
											component={LegacyInputField}
											label="Redirect message"
											description="Custom message to display before the redirect. Max characters 250."
											validate={redirectMessageLength}
										/>
										<Field
											id="afterCheckoutRedirect.disableAdditionalParameters"
											name={`${path}.afterCheckoutRedirect.disableAdditionalParameters`}
											component={SwitchField}
											label="Disable additional parameters"
										/>
										{disableAdditionalParameters ? null : (
											<div style={{ overflowX: "scroll", marginBottom: 24 }}>
												<p>The following paramaters are added to the redirect URL for your reference</p>
												<table style={{ marginBottom: 12, fontSize: 14, minWidth: 800 }} className="full-width-table">
													<thead style={{ background: "#f9f9fa" }}>
														<tr>
															<th>Parameter</th>
															<th>Information</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>ordername</td>
															<td>The buyer's short 8 character order reference id, for example 09N88FOB </td>
														</tr>
														<tr>
															<td>firstname</td>
															<td>The buyer's first name</td>
														</tr>
														<tr>
															<td>lastname</td>
															<td>The buyer's last name</td>
														</tr>
														<tr>
															<td>email</td>
															<td>The buyer's email</td>
														</tr>
														<tr>
															<td>orderid</td>
															<td>The system's order id for use with our API</td>
														</tr>
														<tr>
															<td>eventid</td>
															<td>The system's event id for use with our API</td>
														</tr>
														<tr>
															<td>eventdateid</td>
															<td>The system's event date id for use with our API</td>
														</tr>
														<tr>
															<td>numberoftickets</td>
															<td>The number of tickets associated with the order</td>
														</tr>
													</tbody>
												</table>
											</div>
										)}
									</>
								) : null}
								<hr />
								<Field
									id="isSkipTicketsEnabled"
									name={`${path}.isSkipTicketsEnabled`}
									component={SwitchField}
									label="Enable Skip to Add-ons"
									description="If the event has an add-on available for purchase without a ticket, allow the buyer to skip to the add-ons page."
								/>
							</Fragment>
						),
						key: "2",
						label: "Checkout"
					},
					...(isAdmin
						? [
								{
									children: (
										<Fragment>
											<Field
												id="mailList"
												name={`${path}.mailList`}
												component={SwitchField}
												label="Enable mailing list opt in (Admin Only)"
												description="Customers will have the option to join the Humanitix mailing list on the enter details page when purchasing a ticket"
											/>

											<hr />
											<Field
												id="featured"
												name={`${path}.featured`}
												component={SwitchField}
												label="Featured event (Admin Only)"
												description="This event always will be shown on the trending events"
											/>
											<hr />
											<Field
												id="featuredPriority"
												name={`${path}.featuredPriority`}
												component={InputNumberField}
												label="Featured event priority (Admin Only)"
												description="Highest number will be shown at first on the trending events"
											/>
											<hr />
											<Field
												id="disallowTrending"
												name={`${path}.disallowTrending`}
												component={SwitchField}
												label="Disallow Trending (Admin Only)"
												description="This event won't be shown on the trending events"
											/>
											<hr />
											<Field
												id="disableHumanitixGTM"
												name={`${path}.disableHumanitixGTM`}
												component={SwitchField}
												label="Remove Humanitix GTM from event Page (Admin Only)"
												description="Removes our GTM injection from the ticketing page"
											/>
											<hr />
											<Field
												id="removeImpactCommunication"
												name={`${path}.removeImpactCommunication`}
												component={SwitchField}
												label="Remove Humanitix impact communication (Admin Only)"
												description="Remove Humanitix impact communication from ticketing and emails"
											/>
											<hr />
											<CustomQrDataField
												path={path}
												currentEvent={currentEvent}
												enableCustomQrData={enableCustomQrData}
											/>
											<hr />
											{(!currentEvent || currentEvent.event.location === "US") && (
												<>
													<TaxExemptFormInput path={path} formName={formName} />
													<hr />
												</>
											)}

											<Field
												id="enableThreeDS"
												name={`${path}.enableThreeDS`}
												component={SwitchField}
												label="Enable 3ds for braintree payment gateway (Admin Only)"
												description="Orders will potentially be challenged based on fraud potential"
											/>
											<hr />
											<Field
												id="allowTicketAndMerchSwaps"
												name={`${path}.allowTicketAndMerchSwaps`}
												component={SwitchField}
												label="Enable ticket and merchandise swaps"
												description="Users and customers will be able to swap tickets and merchandise between event dates within a recurring event after purchase. Note that swapping items into another event listing is not supported"
											/>
											{currentEvent?.event?.location === "AU" && (
												<>
													<hr />
													<Field
														id="isInsuranceEnabled"
														name={`${path}.isInsuranceEnabled`}
														component={SwitchField}
														label="Enable ticket refund protection"
														description={
															<>
																Powered by{" "}
																<a href="https://www.xcover.com/en" target="_blank" rel="noreferrer">
																	XCover
																</a>
																, buyers will have the option to purchase refund protection on their ticket purchase
																during checkout. XCover manage all refund claims directly.{" "}
																<a onClick={OpenHelpHandler(9187932)}>Learn more</a>
															</>
														}
													/>
												</>
											)}

											<hr />

											<Field
												id="isSecondaryTicketsEnabled"
												name={`${path}.isSecondaryTicketsEnabled`}
												component={SwitchField}
												label="Enable secondary tickets"
												description={<>Enable secondary tickets for the user (ALPHA FEATURE - DONT TOUCH )</>}
											/>

											<hr />
											<Field
												id="showAllSecondaryOnSkip"
												name={`${path}.showAllSecondaryOnSkip`}
												component={SwitchField}
												label="Disable conditionality when skipping to secondary tickets"
												description={
													<>
														All tickets regardless of conditionality will be shown when skipping to secondary tickets
														(ALPHA FEATURE - DONT TOUCH )
													</>
												}
											/>
											<hr />

											{currentEvent?.event?.location === "AU" || userLocation === "AU" ? (
												<Field
													id="invoicePayLinkOptIn"
													name={`${path}.invoicePayLinkOptIn`}
													component={SwitchField}
													label="Enable invoice pay link"
													description={
														<>
															<p>
																Enable sending direct payment links on invoice and order confirmation emails for invoice
																payments.
															</p>
														</>
													}
												/>
											) : null}
											<hr />
											<Field
												id="paymentPlansOptIn"
												name={`${path}.paymentPlansOptIn`}
												component={SwitchField}
												label="Enable payment plans"
												description={
													<>
														<p>
															Enable this feature ONLY if it has been approved by Sam, Ryan, or Adam for this event.
														</p>
													</>
												}
											/>
											<hr />
										</Fragment>
									),
									key: "3",
									label: "Admin"
								}
						  ]
						: [])
				]}
			/>
		</div>
	);
};

export default EventSettingsFields;
