import AuthService from "../../AuthService";

const HostMenu = [
	{
		label: "Hosts",
		path: "/console/admin/hosts",
		key: "admin-hosts"
	}
];

const Hosts = () => {
	return AuthService.isAdmin() ? HostMenu : [];
};

export default Hosts;
