import * as payoutActions from "@/state/actions/newPayoutActions";

export type Breakdown = {
	salesViaHumanitixGateways: number;
	salesViaAfterpay: number;
	salesViaZip: number;
	salesViaBoxOfficeCard: number;
	feeRebates: number;
	absorbedHumanitixFees: number;
	boxOfficeCashFees: number;
	refunds: number;
	payByInvoiceFees: number;
	zipFees: number;
	afterpayFees: number;
	earnings: number;
	payout: number;
	customSalesTax: number;
	totalAbsorbedFees: number;
	taxOnAbsorbedFees: number;
	credits: number;
	positiveAdjustments: number;
	negativeAdjustments: number;
	paid: number;
	due: number;
	selfServeAmount: number;
	percentPaid: number;
	owed: number;
};

export type PayoutNote = {
	note: string;
	userName: string;
	userId: string;
	deleted?: boolean;
};

export interface DateBreakdown extends Breakdown {
	dateId: string;
	displayDate: string;
	startDate: Date;
	maturityDate: string;
	status: string;
}

enum AccountHolderTypes {
	Individual = "individual",
	Business = "business"
}

enum BankAccountTypes {
	Checking = "checking",
	Savings = "savings"
}

export type BankAccount = {
	location?: Location;
	name?: string;
	firstName?: string;
	lastName?: string;
	companyName?: string;
	default: boolean;
	deleted?: boolean;
	encodedAccount?: string;
	anonymisedAccount?: string[];
	accountHolderType?: AccountHolderTypes;
	bankAccountType?: BankAccountTypes;
	address?: { address: string; addressTwo?: string; city: string; postalCode: string; state: string };
	bankName?: string;
	swiftCode?: string;
	IBAN?: string;
	bsb?: string;
	number: string;
	updatedAt: Date;
};

export interface PaymentsWithDisplayDate {
	_id: string;
	transactionId?: string;
	paid?: boolean;
	paidBy?: string;
	paidAt?: Date;
	clearedAt?: Date;
	reversed?: boolean;
	reversedAt?: Date;
	massPaymentId?: string;
	awaitingMassPayment?: boolean;
	amount: number;
	userId?: string;
	eventDateId?: string;
	userName: string;
	notes?: string;
	date: string;
	removed: boolean;
	credit?: boolean;
	adjustment?: boolean;
	bankAccount?: BankAccount;
	payoutReference?: string;
	createdAt?: Date;
	processedType: "auto" | "manual" | "self-serve" | "auto-self-serve";
}

export type PayoutHeaderInformation = {
	name: string;
	email: string;
	mobile: string;
	bankAccount?: BankAccount;
	eventId: string;
	isUserMarkedAsFraud: boolean;
	isEventFlagged: boolean;
	isAutoPayoutEnabled: boolean;
	isEventTriggeringFraud: boolean;
	isUserMissingTaxInfo: boolean;
	fraudChecks: any | null;
	trackRecord: any | null;
	isUserVerified: boolean;
	isBankAccountValid: boolean;
	preventAutoPayout: boolean;
	preventPayout: boolean;
	selfServiceSettings: ISelfServiceSetting | null;
	userBankAccountIdUpdatedAt: string | null;
};

export interface ISelfServiceSetting {
	percent: number;
	cap?: number;
	authorId: string;
	authorName: string;
	note: string;
	createdAt: string;
}

type PayoutInformation = {
	header: PayoutHeaderInformation;
	eventBreakdown: Breakdown;
	notes: PayoutNote[];
	datesBreakdown: DateBreakdown[];
	creditsOrAdjustments: PaymentsWithDisplayDate[];
	payments: PaymentsWithDisplayDate[];
	datesTableColumns: string[];
};

export type EventPayout = {
	loading: boolean;
	error: string | null;
	payoutInformation: PayoutInformation | null;
};

export type NewPayoutReducerState = {
	eventPayout: EventPayout;
	filters: {
		hideNilPayouts: boolean;
	};
};

const initialState: NewPayoutReducerState = {
	eventPayout: {
		loading: false,
		error: null,
		payoutInformation: null
	},
	filters: {
		hideNilPayouts: true
	}
};

interface PayoutErrorAction {
	type: typeof payoutActions.PAYOUT_ERROR;
	error: string;
}

interface PayoutInformationAction {
	type:
		| typeof payoutActions.GET_EVENT_PAYOUT_PAGE_SUCCESS
		| typeof payoutActions.TOGGLE_FLAGGED_FOR_EVENT_SUCCESS
		| typeof payoutActions.ADD_PAYMENTS_SUCCESS
		| typeof payoutActions.ADD_CREDIT_SUCCESS
		| typeof payoutActions.ADD_ADJUSTMENT_SUCCESS
		| typeof payoutActions.UPDATE_STATUS_SUCCESS
		| typeof payoutActions.EDIT_PAYMENT_SUCCESS
		| typeof payoutActions.DELETE_PAYMENT_SUCCESS
		| typeof payoutActions.TOGGLE_FRAUD_CHECK_SUCCESS;
	result: PayoutInformation;
}

interface PayoutNoteAction {
	type: typeof payoutActions.ADD_NOTE_SUCCESS | typeof payoutActions.DELETE_NOTE_SUCCESS;
	result: PayoutNote[];
}

interface PayoutBooleanAction {
	type: typeof payoutActions.TOGGLE_AUTO_PAYOUT_FOR_EVENT_SUCCESS | typeof payoutActions.TOGGLE_HIDE_NIL_PAYOUTS;
	result: boolean;
}

interface PayoutRequestAction {
	type:
		| typeof payoutActions.GET_EVENT_PAYOUT_PAGE_REQUEST
		| typeof payoutActions.TOGGLE_FLAGGED_FOR_EVENT_REQUEST
		| typeof payoutActions.ADD_PAYMENTS_REQUEST
		| typeof payoutActions.ADD_CREDIT_REQUEST
		| typeof payoutActions.ADD_ADJUSTMENT_REQUEST
		| typeof payoutActions.UPDATE_STATUS_REQUEST
		| typeof payoutActions.EDIT_PAYMENT_REQUEST
		| typeof payoutActions.DELETE_PAYMENT_REQUEST
		| typeof payoutActions.DELETE_NOTE_REQUEST
		| typeof payoutActions.ADD_NOTE_REQUEST
		| typeof payoutActions.TOGGLE_AUTO_PAYOUT_FOR_EVENT_REQUEST
		| typeof payoutActions.GET_PAYMENT_RECEIPT_REQUEST
		| typeof payoutActions.TOGGLE_FRAUD_CHECK_REQUEST
		| typeof payoutActions.TOGGLE_FRAUD_CHECK_REQUEST;
}

interface PayoutSuccessWithNoResultAction {
	type: typeof payoutActions.GET_PAYMENT_RECEIPT_SUCCESS;
}

export default (
	state = initialState,
	action:
		| PayoutErrorAction
		| PayoutNoteAction
		| PayoutInformationAction
		| PayoutBooleanAction
		| PayoutRequestAction
		| PayoutSuccessWithNoResultAction
) => {
	switch (action.type) {
		case payoutActions.PAYOUT_ERROR:
			return {
				...state,
				eventPayout: { loading: false, error: action.error, payoutInformation: null }
			};

		//GET_EVENT_PAYOUT_PAGE
		case payoutActions.GET_EVENT_PAYOUT_PAGE_SUCCESS:
			return {
				...state,
				eventPayout: { loading: false, error: false, payoutInformation: action.result }
			};
		case payoutActions.GET_EVENT_PAYOUT_PAGE_REQUEST:
			return { ...state, eventPayout: { loading: true, error: false, payoutInformation: null } };

		//TOGGLE_AUTO_PAYOUT_FOR_EVENT
		case payoutActions.TOGGLE_AUTO_PAYOUT_FOR_EVENT_SUCCESS:
			if (state.eventPayout.payoutInformation) {
				state.eventPayout.payoutInformation.header.isAutoPayoutEnabled = action.result;
			}
			return { ...state, eventPayout: { ...state.eventPayout, loading: false } };
		case payoutActions.TOGGLE_AUTO_PAYOUT_FOR_EVENT_REQUEST:
			return { ...state, eventPayout: { ...state.eventPayout, loading: true } };

		//TOGGLE_FLAGGED_FOR_EVENT
		case payoutActions.TOGGLE_FLAGGED_FOR_EVENT_SUCCESS:
			return { ...state, eventPayout: { loading: false, error: false, payoutInformation: action.result } };
		case payoutActions.TOGGLE_FLAGGED_FOR_EVENT_REQUEST:
			return { ...state, eventPayout: { ...state.eventPayout, loading: true } };

		//TOGGLE_HIDE_NIL_PAYOUTS
		case payoutActions.TOGGLE_HIDE_NIL_PAYOUTS:
			return { ...state, filters: { ...state.filters, hideNilPayouts: action.result } };

		//ADD_NOTE
		case payoutActions.ADD_NOTE_SUCCESS:
			if (state.eventPayout.payoutInformation) {
				state.eventPayout.payoutInformation.notes = action.result;
			}
			return { ...state, eventPayout: { ...state.eventPayout, loading: false } };
		case payoutActions.ADD_NOTE_REQUEST:
			return { ...state, eventPayout: { ...state.eventPayout, loading: true } };

		//DELETE_NOTE
		case payoutActions.DELETE_NOTE_SUCCESS:
			if (state.eventPayout.payoutInformation) {
				state.eventPayout.payoutInformation.notes = action.result;
			}
			return { ...state, eventPayout: { ...state.eventPayout, loading: false } };
		case payoutActions.DELETE_NOTE_REQUEST:
			return { ...state, eventPayout: { ...state.eventPayout, loading: true } };

		case payoutActions.ADD_PAYMENTS_SUCCESS:
		case payoutActions.ADD_CREDIT_SUCCESS:
		case payoutActions.ADD_ADJUSTMENT_SUCCESS:
		case payoutActions.UPDATE_STATUS_SUCCESS:
		case payoutActions.EDIT_PAYMENT_SUCCESS:
		case payoutActions.DELETE_PAYMENT_SUCCESS:
		case payoutActions.TOGGLE_FRAUD_CHECK_SUCCESS:
			return {
				...state,
				eventPayout: { loading: false, error: false, payoutInformation: action.result }
			};
		case payoutActions.ADD_PAYMENTS_REQUEST:
		case payoutActions.ADD_CREDIT_REQUEST:
		case payoutActions.ADD_ADJUSTMENT_REQUEST:
		case payoutActions.UPDATE_STATUS_REQUEST:
		case payoutActions.EDIT_PAYMENT_REQUEST:
		case payoutActions.DELETE_PAYMENT_REQUEST:
		case payoutActions.TOGGLE_FRAUD_CHECK_REQUEST:
			return { ...state, eventPayout: { loading: true, error: false, payoutInformation: null } };

		case payoutActions.GET_PAYMENT_RECEIPT_REQUEST:
			return { ...state, eventPayout: { ...state.eventPayout, loading: true } };

		case payoutActions.GET_PAYMENT_RECEIPT_SUCCESS:
			return { ...state, eventPayout: { ...state.eventPayout, loading: false } };
		default:
			return state;
	}
};
