import { CollectionModules } from "@/features/collections/configPanel/eventsTab/CollectionModules";
import { EventGroupEditor } from "@/features/collections/configPanel/eventsTab/EventGroupEditor";
import { EventGroupItemRow } from "@/features/collections/configPanel/eventsTab/EventGroupItemRow";
import { useEvents } from "@/hooks/useEvents";
import { useHostProfiles } from "@/hooks/useHostProfiles";
import { useTags } from "@/hooks/useTags";
import { useAppSelector } from "@/state/hooks";
import { Button, FormItemRow, FormLabel, ImageUploaderResult, RadioGroup } from "@/ui";
import { Form, FormInstance, Select, Switch } from "@/ui/antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Collection } from "@hx/console";
import { useWatch } from "antd/lib/form/Form";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { CollectionURLParams } from "@/features/collections/editor/CollectionEditorModal";
import { Location } from "@hx/locations";

type ConfigPanelEventsProps = {
	form: FormInstance<Collection>;
	open: boolean;
};

export const ConfigPanelEvents: FC<ConfigPanelEventsProps> = ({ form, open }) => {
	const formValues = useWatch([], form);
	const user = useAppSelector((state) => state.auth.user);
	const params = useParams<CollectionURLParams>();

	const location: Location = params.location?.toUpperCase() || user.location;

	const [activeGroupUUID, setActiveGroupUUID] = useState<string | null>(null);
	const [isGroupEditorOpen, setIsGroupEditorOpen] = useState(false);

	const addEvents = useEvents(formValues?.events.selectedEvents ?? [], { filters: { location } });
	const featuredEvents = useEvents(formValues?.events.featuredEvents ?? [], { filters: { location } });
	const tags = useTags(formValues?.events.tags ?? []);
	const hostProfiles = useHostProfiles(formValues?.events.hostProfiles ?? []);

	useEffect(() => {
		return () => {
			addEvents.reset();
			featuredEvents.reset();
			hostProfiles.reset();
			tags.reset();
		};
	}, []);

	const handleCreateEventGroup = () => {
		setActiveGroupUUID(null);
		setIsGroupEditorOpen(true);
	};

	const handleEditEventGroup = (uuid: string) => {
		setActiveGroupUUID(uuid);
		setIsGroupEditorOpen(true);
	};

	const featuredEventsContent = (
		<>
			<p style={{ margin: 0 }}>Add any owned events to feature them in your collection.</p>
			<Form.Item
				label={<FormLabel noMargin>Add events</FormLabel>}
				name={["events", "featuredEvents"]}
				style={{ marginBottom: 0 }}
			>
				<Select
					filterOption={false}
					mode="multiple"
					notFoundContent={featuredEvents.noContent}
					onChange={() => featuredEvents.search("", null, true)}
					onSearch={featuredEvents.search}
					options={featuredEvents.options}
					placeholder="Search your events"
					value={formValues?.events.featuredEvents}
				/>
			</Form.Item>
		</>
	);

	const eventGroupContent = (
		<>
			<p>Group relevant events together under a single heading.</p>
			<Form.List name={["events", "groups"]}>
				{(fields, { remove }) => (
					<>
						{fields.map((field, index) => {
							const group = formValues?.events.groups[index];
							return (
								<Form.Item key={field.key} style={{ marginBottom: 0 }}>
									<EventGroupItemRow
										key={field.key}
										title={group?.name}
										image={group?.bannerImage as ImageUploaderResult}
										onEdit={() => handleEditEventGroup(group?.uuid)}
										onDelete={() => remove(index)}
									/>
								</Form.Item>
							);
						})}
						<Button onClick={handleCreateEventGroup} iconLeft={<PlusOutlined />} variant="tertiary">
							New event group
						</Button>
					</>
				)}
			</Form.List>
		</>
	);

	const eventListingContent = (
		<>
			<p>Add events you would like to show in the collection.</p>
			{/* SHOW BY */}
			<Form.Item name={["events", "showBy"]}>
				<RadioGroup
					options={[
						{ label: "All events", value: "all" },
						{ label: "Selected events", value: "selected" },
						{ label: "Custom", value: "custom" }
					]}
					value={formValues?.events.showBy}
				/>
			</Form.Item>
			{/* SELECTED EVENTS */}
			<Form.Item
				hidden={formValues?.events.showBy !== "selected"}
				label={<FormLabel>Add events</FormLabel>}
				name={["events", "selectedEvents"]}
				rules={[
					{
						message: "At least one event is required",
						required: formValues?.events.showBy === "selected",
						type: "array"
					}
				]}
			>
				<Select
					filterOption={false}
					mode="multiple"
					notFoundContent={addEvents.noContent}
					onChange={addEvents.handleChange}
					onFocus={() => addEvents.search("", null, true)}
					onSearch={addEvents.search}
					options={addEvents.options}
					placeholder="Search your events"
					value={formValues?.events.selectedEvents}
				/>
			</Form.Item>
			{/* HOST PROFILES */}
			<Form.Item
				hidden={formValues?.events.showBy !== "custom"}
				label={<FormLabel>Include host profile(s)</FormLabel>}
				name={["events", "hostProfiles"]}
			>
				<Select
					filterOption={false}
					mode="multiple"
					notFoundContent={hostProfiles.noContent}
					onChange={hostProfiles.handleChange}
					onFocus={() => hostProfiles.search("", null, true)}
					onSearch={hostProfiles.search}
					options={hostProfiles.options}
					placeholder="Search your host profiles"
					value={formValues?.events.hostProfiles}
				/>
			</Form.Item>

			{/* TAG BY */}
			<FormItemRow hidden={!user.isTagsEnabled || formValues?.events.showBy !== "custom"}>
				<FormLabel
					tooltip={`Include all of: Only include events which contain all the specified tags.
					Any of: Include any events that include at least one of the specified tags.`}
				>
					Tags
				</FormLabel>
				<Form.Item name={["events", "tagBy"]} noStyle style={{ display: "flex", flexDirection: "column" }}>
					<Select
						style={{ minWidth: "180px", flexShrink: 1 }}
						value={formValues?.events.tagBy}
						options={[
							{ label: "Includes all of", value: "includesAll" },
							{ label: "Any of", value: "includesAny" }
						]}
					/>
				</Form.Item>
			</FormItemRow>
			{/* INCLUDE TAGS */}
			<Form.Item hidden={!user.isTagsEnabled || formValues?.events.showBy !== "custom"} name={["events", "tags"]}>
				<Select
					filterOption={false}
					mode="tags"
					notFoundContent={tags.noContent}
					onChange={tags.handleChange}
					onFocus={() => tags.search("", null, true)}
					onSearch={tags.search}
					placeholder="Search or create tags"
					style={{ flexGrow: 1 }}
					value={tags.selectedTags.map((tag) => tag.value)}
					options={tags.options.map((tag) => {
						const resolvedLabel = tag.label === tag.value ? <LoadingOutlined /> : tag.label;
						return { value: tag.value, label: resolvedLabel };
					})}
				/>
			</Form.Item>

			{/* EVENT TABS */}
			<FormItemRow noMargin={formValues?.events.tabBy === "none"}>
				<FormLabel tooltip="Create navigational tabs by predefined criteria like Year, Month, Day, Tags, or Host profiles.">
					Show event tabs
				</FormLabel>
				<Switch
					checked={formValues?.events.tabBy !== "none"}
					onChange={(checked) => {
						if (checked) {
							form.setFieldsValue({ events: { tabBy: "month" } });
						} else {
							form.setFieldsValue({ events: { tabBy: "none" } });
						}
					}}
					style={{ margin: 0 }}
				/>
			</FormItemRow>
			{/* TAB EVENTS BY */}
			<FormItemRow hidden={formValues?.events.tabBy === "none"} noMargin>
				<FormLabel>Tab events by</FormLabel>
				<Form.Item name={["events", "tabBy"]} noStyle style={{ display: "flex", flexDirection: "column" }}>
					<Select
						style={{ minWidth: "140px", flexShrink: 1 }}
						value={formValues?.events.tabBy}
						options={[
							{ value: "year", label: "Year" },
							{ value: "month", label: "Month" },
							{ value: "day", label: "Day" },
							{ value: "tags", label: "Tags" },
							{ value: "hostProfiles", label: "Host profiles" }
						]}
					/>
				</Form.Item>
			</FormItemRow>
		</>
	);

	const handleRemoveFeaturedEvents = () =>
		form.setFieldsValue({
			events: { showFeaturedEvents: false, featuredEvents: [] }
		});

	const handleRemoveEventGroups = () =>
		form.setFieldsValue({
			events: { showEventGroups: false, groups: [] }
		});

	const handleRemoveEventListing = () =>
		form.setFieldsValue({
			events: {
				hostProfiles: [],
				privacyLevel: "public",
				selectedEvents: [],
				showBy: "all",
				showEventListing: false,
				tabBy: "none",
				tagBy: "includesAll",
				tags: []
			}
		});

	return (
		<div style={{ display: open ? "block" : "none" }}>
			<CollectionModules
				form={form}
				isVisible={open}
				items={[
					{
						contents: featuredEventsContent,
						description: "Shine a spotlight on your most important events.",
						isVisible: formValues?.events?.showFeaturedEvents,
						formPath: ["events", "showFeaturedEvents"],
						onAddModule: () => form.setFieldsValue({ events: { showFeaturedEvents: true } }),
						onRemoveModule: handleRemoveFeaturedEvents,
						title: "Featured events"
					},
					{
						contents: eventGroupContent,
						description: "Group relevant events together under a single heading.",
						isVisible: formValues?.events.showEventGroups,
						formPath: ["events", "showEventGroups"],
						onAddModule: () => form.setFieldsValue({ events: { showEventGroups: true } }),
						onRemoveModule: handleRemoveEventGroups,
						title: "Event groups"
					},
					{
						contents: eventListingContent,
						description: "Add events you would like to show in the collection.",
						isVisible: formValues?.events.showEventListing,
						formPath: ["events", "showEventListing"],
						onAddModule: () => form.setFieldsValue({ events: { showEventListing: true } }),
						onRemoveModule: handleRemoveEventListing,
						title: "Event listing"
					}
				]}
			/>
			<EventGroupEditor
				activeGroupUUID={activeGroupUUID}
				form={form}
				onClose={() => setIsGroupEditorOpen(false)}
				open={isGroupEditorOpen}
			/>
		</div>
	);
};
