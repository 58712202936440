import { getConfig } from "@/config";
import { dayjs } from "@/libs/dayjs";
import LocationService from "@/services/LocationService";
import { DatePicker, TimePicker } from "@/ui/antd";
import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import { connect } from "react-redux";
import { change } from "redux-form";
import InputWrapper from "./helpers/InputWrapper";

const styles = {
	DatePickerStyle: css({
		minWidth: 160,
		"@media(max-width: 360px)": {
			minWidth: 130,
			fontSize: "14px"
		}
	}),
	TimePickerStyle: css({
		minWidth: 140,
		"@media(max-width: 360px)": {
			minWidth: 130,
			fontSize: "14px"
		}
	})
};

const customProps = ({ input: { onChange, value }, displayFormat, ...props }) => {
	const tz = props.timezone || getConfig("TIMEZONE_DEFAULT");
	if (value !== "") {
		value = dayjs(value).tz(tz);
	} else {
		value = null;
	}
	return {
		input: {
			onChange: (date) => {
				const previousValue = value ? dayjs(value).tz(tz) : dayjs().tz(tz);
				let changeValue = "";
				if (dayjs(date).isSame(previousValue, "day")) {
					// If the date is the same as the previous value, we only want to change the time
					changeValue = dayjs(date).tz(tz, true).toISOString();
				} else {
					// If a new date is set, we want to keep the time the same as the previous value
					changeValue = dayjs(date)
						.set("hour", previousValue.hour())
						.set("minute", previousValue.minute())
						.tz(tz, true)
						.toISOString();
				}
				onChange(changeValue);
			},
			value
		},
		format: displayFormat
	};
};

const DateTimeField = (props) => {
	const disableMinutes = (disabledMinutes) => () => {
		const minutes = disabledMinutes
			? disabledMinutes
			: `1,2,3,4,6,7,8,9,11,12,13,14,16,17,18,19,21,22,23,24,26,27,28,29,31,32,33,34,36,
      37,38,39,41,42,43,44,46,47,48,49,51,52,53,54,56,57,58,59`;
		return minutes.split(",").map((m) => Number(m));
	};

	props = {
		...props,
		...customProps(props)
	};

	const {
		input,
		label,
		description,
		inline,
		required,
		timeOptions,
		meta: { touched, error },
		disabledMinutes,
		tooltip,
		tooltipIcon = false,
		tooltipIconOnClick,
		dispatch,
		...rest
	} = props;

	const value = input.value ? dayjs(input.value).tz(props.timezone) : null;

	const timeDefaults = {
		use12Hours: false,
		disabledMinutes: disableMinutes(disabledMinutes)
	};
	const timeProps = {
		...timeDefaults,
		...timeOptions
	};
	const isBrowser = typeof window !== "undefined";

	const onTimeChange = (time) => {
		if (time) {
			dispatch(change(props.formName, props.input.name, time.toISOString()));
		}
	};

	return (
		<InputWrapper
			label={label}
			description={description}
			touched={touched}
			error={error}
			id={input.name}
			inline={inline}
			tooltip={tooltip}
			required={required}
			tooltipIcon={tooltipIcon}
			tooltipIconOnClick={tooltipIconOnClick}
		>
			<DatePicker
				id={input.name}
				value={value}
				onChange={input.onChange}
				style={{
					marginRight: 6,
					maxWidth: isBrowser && window.innerWidth > 600 ? 160 : 120,
					width: "100%"
				}}
				className={styles.DatePickerStyle}
				format={HumaniDate.formats.datePicker(LocationService.getLocation(true))}
			/>
			<TimePicker
				value={value ? dayjs(value) : null}
				onCalendarChange={onTimeChange}
				{...rest}
				{...timeProps}
				style={{
					maxWidth: isBrowser && window.innerWidth > 600 ? 140 : 100,
					width: "100%"
				}}
				className={styles.TimePickerStyle}
				hideDisabledOptions
				format="h:mm a"
				use12Hours
				onChange={input.onChange}
			/>
		</InputWrapper>
	);
};

export default connect()(DateTimeField);
