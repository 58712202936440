import { EventOverviewTable, OverviewSummary, OverviewTable, SalesChart, TicketsSoldList } from "@/components/Charts";
import FBTracking from "@/components/FBTracking";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import Page from "@/components/Page";
import Welcome from "@/dashboard/Welcome";
import CreateFirstEvent from "@/dashboard/components/CreateFirstEvent";
import DashboardArticle from "@/dashboard/components/DashboardArticle";
import DownloadApp from "@/dashboard/components/DownloadApp";
import FeaturedHelpResource from "@/dashboard/components/FeaturedHelpResource";
import FeeAppliedModal from "@/dashboard/components/FeeAppliedModal";
import OrganiserTips from "@/dashboard/components/OrganiserTips";
import BigBanner from "@/events/event/appBanners/BigBanner";
import AuthService from "@/services/AuthService";
import { getDashboardMetrics, getHistoricalSales, getSales, getUsersChart } from "@/state/actions/metricActions";
import { Grid } from "@/ui";
import { getCurrencySymbol } from "@/utils/Format";
import { getUrlParameter } from "@/utils/Urls";
import { css } from "@emotion/css";
import { Col, Row } from "@/ui/antd";
import React from "react";
import { connect } from "react-redux";

const overviewTable = css({
	"@media (max-width: 600px)": {
		maxWidth: "initial"
	}
});

const userCol = css({
	borderTop: "1px solid #dedede",
	paddingTop: "1rem"
});

const pageStyle = css({
	paddingTop: "24px",
	padding: "24px calc(50% - 550px) 33px",
	position: "relative",
	display: "block",
	margin: "0 auto",
	"@media (max-width: 1128px)": {
		paddingLeft: "14px",
		paddingRight: "14px"
	}
});

const desktop = css({
	"@media(min-width: 768px)": {
		display: "none"
	}
});

const mobile = css({
	display: "none",
	"@media(min-width: 768px)": {
		display: "block"
	}
});

class DashboardComponent extends React.Component {
	constructor(props) {
		super(props);
		this.handleHistoricalSalesChange = this.handleHistoricalSalesChange.bind(this);
		this.handleSalesChange = this.handleSalesChange.bind(this);
		this.handleUsersChange = this.handleUsersChange.bind(this);
	}

	componentDidMount() {
		const { metrics } = this.props;

		// This is to avoid getting the dashboard metrics twice since onLocationChange always triggers on refresh, temporary
		// solution
		if (!metrics?.totalEvents) {
			this.props.getDashboardMetrics("today", "all");
		}
	}

	onLocationChange = () => {
		this.props.getDashboardMetrics("today", "all");
	};

	removeTestAccount = () => {
		AuthService.removeTestAccount();
	};

	handleHistoricalSalesChange(value) {
		this.props.getHistoricalSales(value);
	}

	handleSalesChange(value) {
		this.props.getSales(value);
	}

	handleUsersChange(value) {
		this.props.getUsersChart(value);
	}

	render() {
		const newUser = getUrlParameter("newuser");
		const isAdmin = AuthService.isAdmin();
		const lines = [
			{ label: "Tickets", key: "tickets", stroke: "#353337" },
			{ label: "Orders", key: "orders", stroke: "#57cfc7" },
			{ label: "Cancellations", key: "cancellations", stroke: "#e6a081" }
		];
		const revenueLines = [
			{ label: "Net Sales", key: "netSales", stroke: "#353337" },
			{ label: "Gross Sales", key: "grossSales", stroke: "#57cfc7" },
			{ label: "Refunds", key: "refunds", stroke: "#e6a081" }
		];
		const userLines = [{ label: "Users", key: "users", stroke: "#e6a081" }];
		const { loading, error, totalEvents, totalPublishedEvents } = this.props.metrics;

		const { auth } = this.props;

		if (auth.removeTestAccount) {
			this.removeTestAccount();
		}

		const permissions = auth.user.permissions || [];
		let appAccess = false;
		permissions.some((permission) => {
			if (
				permission &&
				permission.accepted &&
				permission.eventAccess &&
				permission.eventAccess.access &&
				permission.eventAccess.access.app &&
				permission.eventAccess.access.checkin
			) {
				appAccess = true;
				return true;
			}
			return false;
		});

		const showTips = totalEvents === 0 && auth.user.purpose === "organiser";
		const { organiserOnboardingInfo, firstName: userFirstname, category: userCategory } = auth.user;
		const { eventTypes } = organiserOnboardingInfo ? organiserOnboardingInfo : {};
		const emptyDashboardAction = showTips ? (
			<OrganiserTips eventTypes={eventTypes} userFirstname={userFirstname} userCategory={userCategory} />
		) : (
			<CreateFirstEvent totalEvents={totalEvents} />
		);

		const callToAction = appAccess ? <DownloadApp /> : emptyDashboardAction;

		const salesCharts = (
			<>
				<SalesChart
					key="sales"
					title="Historical orders chart"
					data="sales"
					lines={lines}
					metricState="dashboardMetrics"
					style={{ minHeight: 300 }}
					onChange={this.handleHistoricalSalesChange}
				/>
				{isAdmin ? (
					<SalesChart
						key="revenueSales"
						title="Historical sales chart"
						data="revenueSales"
						prefix={getCurrencySymbol()}
						lines={revenueLines}
						metricState="dashboardMetrics"
						style={{ minHeight: 300 }}
						onChange={this.handleSalesChange}
					/>
				) : null}
			</>
		);
		const locationSelector = (
			<LocationSelection componentName="dashboard" defaultLevel="user" onLocationChange={this.onLocationChange} />
		);
		let pageName = isAdmin ? (
			"Dashboard"
		) : (
			<Welcome metricState="dashboardMetrics" onLocationChange={this.onLocationChange} showTips={showTips} />
		);
		pageName = showTips ? null : pageName;

		return (
			<Page pageName={pageName} sibling={locationSelector} pageStyle={pageStyle}>
				<LoadErrorView loading={loading} error={error} retryAction={this.props.getDashboardMetrics}>
					{isAdmin ? (
						<Grid columnMin="100%">
							<Grid columnMin="400px">
								<OverviewTable title="Overview Today" metricState="dashboardMetrics" className={overviewTable} />
								<EventOverviewTable metricState="dashboardMetrics" left className={overviewTable} />
							</Grid>
							{salesCharts}
							<Row>
								<Col span={24}>
									<SalesChart
										title="Users"
										data="users"
										lines={userLines}
										metricState="dashboardMetrics"
										style={{ minHeight: 250 }}
										onChange={this.handleUsersChange}
									/>
								</Col>
							</Row>
						</Grid>
					) : !loading ? (
						totalEvents === 0 || totalPublishedEvents === 0 ? (
							<>
								{callToAction}
								{newUser === "true" ? <FBTracking pageName="signedup" /> : null}
								<FeeAppliedModal />
							</>
						) : (
							<div className={userCol}>
								<Row gutter={12}>
									<Col xs={24} sm={24} md={16} lg={16} xl={16}>
										<OverviewSummary metricState="dashboardMetrics" />
										<div className={desktop}>
											<DashboardArticle articleType="featuredArticle" />
										</div>
										<OverviewTable metricState="dashboardMetrics" />
										<TicketsSoldList metricState="dashboardMetrics" />
										{salesCharts}
									</Col>
									<Col xs={24} sm={24} md={8} lg={8} xl={8}>
										<div className={mobile}>
											<DashboardArticle articleType="featuredArticle" />
										</div>
										<DashboardArticle articleType="featuredHelpResource" />
										<DashboardArticle articleType="featuredContent" />
										<FeaturedHelpResource />
									</Col>
								</Row>
								<BigBanner />
							</div>
						)
					) : null}
				</LoadErrorView>
			</Page>
		);
	}
}

const mapStateToProps = (state) => ({
	metrics: state.dashboardMetrics,
	auth: state.auth
});

const mapDispatchToProps = { getDashboardMetrics, getHistoricalSales, getSales, getUsersChart };

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
