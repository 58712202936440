import { ForgotPassword } from "@/auth/ForgotPassword";
import { Login } from "@/auth/Login";
import { MicrosoftAuth } from "@/auth/MicrosoftAuth";
import { ResetPassword } from "@/auth/ResetPassword";
import { Signin } from "@/auth/Signin";
import { Signup } from "@/auth/Signup";
import { VerifyAccount } from "@/auth/VerifyAccount";
import { VerifyEmail } from "@/auth/VerifyEmail";
import Authorize from "@/authorization/Authorize";
import { BrowserRouter } from "@/BrowserRouter";
import HubspotTracking from "@/components/HubspotTracking";
import MarkerIO from "@/components/MarkerIO";
import { getConfig } from "@/config";
import Console from "@/console/Console";
import { ConnectStripe } from "@/events/event/payments/ConnectStripe";
import history from "@/history";
import NotFound from "@/NotFound";
import AuthService from "@/services/AuthService";
import { useAppSelector } from "@/state/hooks";
import store from "@/state/store";
import { WinkLoader } from "@/ui";
import ConnectHubspot from "@/users/hubspot/ConnectHubspot";
import { ConnectMailchimp } from "@/users/mailchimp/ConnectMailchimp";
import OnboardingOrganiser from "@/users/onboarding/Onboarding";
import { ConnectSalesforce } from "@/users/salesforce/ConnectSalesforce";
import { initialize } from "@hx/analytics";
import "antd/dist/reset.css";
import { FC, useEffect, useMemo, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";

type PrivateRouteProps = {
	element: JSX.Element;
	authorized?: boolean | null;
};

const PrivateRoute: FC<PrivateRouteProps> = ({ element, authorized }) => {
	const user = useAppSelector((state) => state.auth?.user);
	const location = useLocation();

	const requiresVerification = authorized && user && user.emailValid === false && user.emailVerified === false;
	const pathIsNotAtVerify =
		!location.pathname.includes("verify-account") && !location.pathname.includes("verify-email");

	return (
		<>
			{authorized === false && (
				<Navigate
					to={{
						pathname: "/signin",
						search: `?from=${encodeURIComponent(location.pathname + location.search)}`
					}}
					state={{ from: location }}
				/>
			)}
			{authorized && requiresVerification && pathIsNotAtVerify && <Navigate to="/verify-account" />}
			{authorized && element}
		</>
	);
};

type AppProps = {
	store?: typeof store;
};

const App: FC<AppProps> = ({ store }) => {
	const [authorized, setAuthorized] = useState<boolean | null>(null);

	useEffect(() => {
		AuthService.setup(store);
		setAuthorized(AuthService.authorized);
		AuthService.authEmitter.addListener("change", (authorized: boolean) => {
			setAuthorized(authorized);
		});
		initialize(getConfig("USERFLUX_KEY"), "console");
	}, []);

	const winkLoader = useMemo(() => <WinkLoader fullscreen loading={!AuthService.userChecked} />, [authorized]);

	return (
		<BrowserRouter history={history}>
			{winkLoader}
			{AuthService.userChecked && (
				<>
					<MarkerIO />
					<HubspotTracking />
					<Routes>
						<Route path="/" element={<Navigate to="/console/dashboard" />} />
						<Route path="/signin" element={<Signin />} />
						<Route path="/login" element={<Login />} />
						<Route path="/signup" element={<Signup />} />
						<Route path="/forgot-password" element={<ForgotPassword />} />
						<Route path="/account-management" element={<ResetPassword />} />
						<Route path="/authorize" element={<Authorize />} />
						<Route path="/sso/microsoft" element={<MicrosoftAuth />} />
						<Route
							path="/verify-account/*"
							element={<PrivateRoute authorized={authorized} element={<VerifyAccount />} />}
						/>
						<Route
							path="/verify-email/*"
							element={<PrivateRoute authorized={authorized} element={<VerifyEmail />} />}
						/>
						<Route
							path="/onboarding-organiser/:step"
							element={<PrivateRoute authorized={authorized} element={<OnboardingOrganiser />} />}
						/>
						<Route path="/console/*" element={<PrivateRoute authorized={authorized} element={<Console />} />} />
						<Route
							path="/connect-stripe"
							element={<PrivateRoute authorized={authorized} element={<ConnectStripe />} />}
						/>
						<Route
							path="/connect-salesforce"
							element={<PrivateRoute authorized={authorized} element={<ConnectSalesforce />} />}
						/>
						<Route
							path="/connect-hubspot"
							element={<PrivateRoute authorized={authorized} element={<ConnectHubspot />} />}
						/>
						<Route
							path="/connect-mailchimp"
							element={<PrivateRoute authorized={authorized} element={<ConnectMailchimp />} />}
						/>
						<Route path="*" element={<NotFound />} />
					</Routes>
				</>
			)}
		</BrowserRouter>
	);
};

export default App;
