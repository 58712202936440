import P from "@/components/text/P";
import { notification } from "@/ui/antd";
import { reset } from "redux-form";
import OrdersService from "../../services/OrdersService";
import FileDownload from "../../utils/FileDownload";
import { getLocationFromState } from "./locationActions";

export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";

export const GET_GLOBAL_REFUND_AVAILABLE_FUNDS_ERROR = "GET_GLOBAL_REFUND_AVAILABLE_FUNDS_ERROR";
export const GET_GLOBAL_REFUND_AVAILABLE_FUNDS_REQUEST = "GET_GLOBAL_REFUND_AVAILABLE_FUNDS_REQUEST";
export const GET_GLOBAL_REFUND_AVAILABLE_FUNDS_SUCCESS = "GET_GLOBAL_REFUND_AVAILABLE_FUNDS_SUCCESS";

export const GET_REFUND_AVAILABLE_FUNDS_ERROR = "GET_REFUND_AVAILABLE_FUNDS_ERROR";
export const GET_REFUND_AVAILABLE_FUNDS_REQUEST = "GET_REFUND_AVAILABLE_FUNDS_REQUEST";
export const GET_REFUND_AVAILABLE_FUNDS_SUCCESS = "GET_REFUND_AVAILABLE_FUNDS_SUCCESS";

const OPEN_REFUNDS_MODAL_REQUEST = "OPEN_REFUNDS_MODAL_REQUEST";
const OPEN_REFUNDS_MODAL_SUCCESS = "OPEN_REFUNDS_MODAL_SUCCESS";
export const REFUND_ORDER_REQUEST = "REFUND_ORDER_REQUEST";
export const REFUND_ORDER_SUCCESS = "REFUND_ORDER_SUCCESS";
export const REFUND_ORDER_ERROR = "REFUND_ORDER_ERROR";
export const CLEAR_REFUND = "CLEAR_REFUND";

export const REQUEST_REFUND_ORDER_REQUEST = "REQUEST_REFUND_ORDER_REQUEST";
export const REQUEST_REFUND_ORDER_SUCCESS = "REQUEST_REFUND_ORDER_SUCCESS";
export const REQUEST_REFUND_ORDER_ERROR = "REQUEST_REFUND_ORDER_ERROR";
export const CLEAR_REQUEST_REFUND = "CLEAR_REQUEST_REFUND";

export const SAVE_ORDER_REQUEST = "SAVE_ORDER_REQUEST";
export const SAVE_ORDER_SUCCESS = "SAVE_ORDER_SUCCESS";
export const SAVE_ORDER_ERROR = "SAVE_ORDER_ERROR";

export const OPEN_RESEND_ORDER = "OPEN_RESEND_ORDER";
export const CLOSE_RESEND_ORDER = "CLOSE_RESEND_ORDER";

export const SEND_ORDER_REQUEST = "SEND_ORDER_REQUEST";
export const SEND_ORDER_SUCCESS = "SEND_ORDER_SUCCESS";
export const SEND_ORDER_ERROR = "SEND_ORDER_ERROR";

export const SEND_ATTENDEE_TICKETS_REQUEST = "SEND_ATTENDEE_TICKETS_REQUEST";
export const SEND_ATTENDEE_TICKETS_COMPLETE = "SEND_ATTENDEE_TICKETS_COMPLETE";

export const FIND_ORDER_REQUEST = "FIND_ORDER_REQUEST";
export const FIND_ORDER_SUCCESS = "FIND_ORDER_SUCCESS";
export const FIND_ORDER_ERROR = "FIND_ORDER_ERROR";

export const FIND_ANSWERED_ADDITIONAL_QUESTIONS_REQUEST = "FIND_ANSWERED_ADDITIONAL_QUESTIONS_REQUEST";
export const FIND_ANSWERED_ADDITIONAL_QUESTIONS_SUCCESS = "FIND_ANSWERED_ADDITIONAL_QUESTIONS_SUCCESS";
export const FIND_ANSWERED_ADDITIONAL_QUESTIONS_ERROR = "FIND_ANSWERED_ADDITIONAL_QUESTIONS_ERROR";

export const GET_ORDER_CSV_REQUEST = "GET_ORDER_CSV_REQUEST";
export const GET_ORDER_CSV_SUCCESS = "GET_ORDER_CSV_SUCCESS";
export const GET_ORDER_CSV_ERROR = "GET_ORDER_CSV_ERROR";

export const INVOICE_DOWNLOAD_REQUEST = "INVOICE_DOWNLOAD_REQUEST";
export const INVOICE_DOWNLOAD_SUCCESS = "INVOICE_DOWNLOAD_SUCCESS";
export const INVOICE_DOWNLOAD_ERROR = "INVOICE_DOWNLOAD_ERROR";

export const OPEN_SWAP_MODAL_REQUEST = "OPEN_SWAP_MODAL_REQUEST";
export const OPEN_SWAP_MODAL_SUCCESS = "OPEN_SWAP_MODAL_SUCCESS";

export const CLOSE_SWAP_MODAL = "CLOSE_SWAP_MODAL";

export const SWAP_TICKET_REQUEST = "SWAP_TICKET_REQUEST";
export const SWAP_TICKET_SUCCESS = "SWAP_TICKET_SUCCESS";
export const SWAP_TICKET_ERROR = "SWAP_TICKET_ERROR";

export const EVENT_DATE_CAPACITY_REQUEST = "EVENT_DATE_CAPACITY_REQUEST";
export const EVENT_DATE_CAPACITY_SUCCESS = "EVENT_DATE_CAPACITY_SUCCESS";
export const EVENT_DATE_CAPACITY_ERROR = "EVENT_DATE_CAPACITY_ERROR";
export const EVENT_DATE_CAPACITY_CLEAR = "EVENT_DATE_CAPACITY_CLEAR";

const HUBSPOT_SYNC_REQUEST = "HUBSPOT_SYNC_REQUEST";
const HUBSPOT_SYNC_SUCCESS = "HUBSPOT_SYNC_SUCCESS";
const HUBSPOT_SYNC_ERROR = "HUBSPOT_SYNC_ERROR";

export const CLEAR_SWAP = "CLEAR_SWAP";

export const openResendOrderModal = (eventId, order) => {
	return (dispatch) => {
		dispatch({ type: OPEN_RESEND_ORDER, eventId, order });
	};
};

export const closeResendOrderModal = () => {
	return (dispatch) => {
		dispatch({ type: CLOSE_RESEND_ORDER });
	};
};

export const openGiftCardsRefundsModal = (order) => {
	return (dispatch) => {
		dispatch({ type: OPEN_REFUNDS_MODAL_REQUEST });
		const fundsAvailable = order.giftCards.reduce((hasFundsAvailable, giftCard) => {
			return hasFundsAvailable || giftCard.remainingAmount > 0;
		}, false);
		dispatch({
			type: OPEN_REFUNDS_MODAL_SUCCESS,
			fundsAvailable
		});
	};
};

export const getOrder = (id, eventId, token) => {
	return async (dispatch) => {
		dispatch({ type: GET_ORDER_REQUEST });
		try {
			const order = await OrdersService.get(id, eventId, token);
			dispatch({ type: GET_ORDER_SUCCESS, order });
		} catch (err) {
			dispatch({ type: GET_ORDER_ERROR, error: err });
		}
	};
};

export const getGlobalRefundFundsAvailable = (orderId) => {
	return (dispatch) => {
		dispatch({ type: GET_GLOBAL_REFUND_AVAILABLE_FUNDS_REQUEST });
		OrdersService.getGlobalRefundFundsAvailable(orderId)
			.then(({ globalFundsAvailable }) => {
				dispatch({ type: GET_GLOBAL_REFUND_AVAILABLE_FUNDS_SUCCESS, globalFundsAvailable });
			})
			.catch((err) => {
				dispatch({ type: GET_GLOBAL_REFUND_AVAILABLE_FUNDS_ERROR, error: err });
			});
	};
};

export const getRefundFundsAvailable = (orderId) => {
	return (dispatch) => {
		dispatch({ type: GET_REFUND_AVAILABLE_FUNDS_REQUEST });
		OrdersService.getRefundFundsAvailable(orderId)
			.then(({ fundsAvailable }) => {
				dispatch({ type: GET_REFUND_AVAILABLE_FUNDS_SUCCESS, fundsAvailable });
			})
			.catch((err) => {
				dispatch({ type: GET_REFUND_AVAILABLE_FUNDS_ERROR, error: err });
			});
	};
};

export const sendOrder = (orderId, forceSendTickets, emailTo = false) => {
	return (dispatch) => {
		const location = getLocationFromState(false);
		dispatch({ type: SEND_ORDER_REQUEST, orderId });
		OrdersService.sendOrder(orderId, forceSendTickets, emailTo, location)
			.then(() => {
				dispatch({ type: SEND_ORDER_SUCCESS, orderId });
				notification.success({
					message: "Sent",
					description: `Order has been sent`
				});
			})
			.catch((err) => {
				dispatch({ type: SEND_ORDER_ERROR, orderId });
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to send tickets";
				notification.error({ message: "Error", description: message });
			});
	};
};

export const sendAttendeeTickets = (orderId, ticketIds = []) => {
	return (dispatch) => {
		const location = getLocationFromState(false);
		dispatch({ type: SEND_ATTENDEE_TICKETS_REQUEST, orderId });
		OrdersService.sendAttendeeTickets(orderId, ticketIds, location)
			.then(() => {
				dispatch({ type: SEND_ATTENDEE_TICKETS_COMPLETE, orderId });
				notification.success({
					message: "Sent",
					description: `Tickets have been sent to selected attendees`
				});
			})
			.catch((err) => {
				dispatch({ type: SEND_ATTENDEE_TICKETS_COMPLETE, orderId });
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to send tickets to attendees";
				notification.error({ message: "Error", description: message });
			});
	};
};

const userSendOrder = (
	orderId,
	forceSendTickets,
	token,
	emailTo = false,
	emailAttendees = false,
	ticketIds = [],
	resendOrder = false
) => {
	return (dispatch) => {
		dispatch({ type: SEND_ORDER_REQUEST, orderId });
		OrdersService.userSendOrder(orderId, forceSendTickets, token, emailTo, emailAttendees, ticketIds, resendOrder)
			.then(() => {
				dispatch({ type: SEND_ORDER_SUCCESS, orderId });
				notification.success({
					message: "Sent",
					description: `Order has been sent`
				});
			})
			.catch((err) => {
				dispatch({ type: SEND_ORDER_ERROR, orderId });
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to send tickets";
				notification.error({ message: "Error", description: message });
			});
	};
};

export const refundOrder = (orderId, refundData, formName) => {
	return async (dispatch) => {
		dispatch({ type: REFUND_ORDER_REQUEST });
		const refundResult = await OrdersService.refund(orderId, refundData);
		await getOrder(orderId)(dispatch);
		getRefundFundsAvailable(orderId)(dispatch);

		if (refundResult.success) {
			notification.success({
				message: "Refund success"
			});
			dispatch({ type: REFUND_ORDER_SUCCESS });
			dispatch(clearRefundRequest());
			dispatch(reset(formName));
		} else {
			notification.error({ message: "Error", description: "Refund cannot be completed" });
			dispatch({ type: REFUND_ORDER_ERROR, error: { message: refundResult.errorMsg } });
		}
	};
};

export const refundGlobalOrder = (orderId, refundData) => {
	return (dispatch) => {
		dispatch({ type: REFUND_ORDER_REQUEST });
		OrdersService.globalRefund(orderId, refundData)
			.then(() => {
				notification.success({
					message: "Refund success"
				});
				getOrder(orderId)(dispatch);
				dispatch({ type: REFUND_ORDER_SUCCESS });
				dispatch(clearRefundRequest());
			})
			.catch((err) => {
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Refund failed to process";
				notification.error({ message: "Error", description: message });
				dispatch({ type: REFUND_ORDER_ERROR, error: { message } });
			});
	};
};

export const clearRefundRequest = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_REQUEST_REFUND });
	};
};

// This orders used on the basic information to check whether user can delete dates and additional questions or not
export const findOrders = (query, page = 1) => {
	return (dispatch) => {
		const location = getLocationFromState(true);
		dispatch({ type: FIND_ORDER_REQUEST, page });
		const cleanQuery = { ...query };
		if (cleanQuery.eventDateIds && !cleanQuery.eventDateIds.length) {
			delete cleanQuery.eventDateIds;
		}
		OrdersService.find(cleanQuery, page, location)
			.then((result) => {
				dispatch({
					type: FIND_ORDER_SUCCESS,
					orders: result.orders,
					count: result.count
				});
			})
			.catch((err) => {
				dispatch({ type: FIND_ORDER_ERROR, error: err });
			});
	};
};

export const findAnsweredAdditionalQuestions = (eventId) => {
	return (dispatch) => {
		dispatch({ type: FIND_ANSWERED_ADDITIONAL_QUESTIONS_REQUEST });
		OrdersService.findAnsweredAdditionalQuestions(eventId)
			.then((result) => {
				dispatch({
					type: FIND_ANSWERED_ADDITIONAL_QUESTIONS_SUCCESS,
					answeredQuestions: result
				});
			})
			.catch((err) => {
				dispatch({
					type: FIND_ANSWERED_ADDITIONAL_QUESTIONS_ERROR,
					error: err
				});
			});
	};
};

export const saveOrder = (
	id,
	orderData,
	eventId,
	token,
	resendOrder,
	emailAttendees,
	emailTo = false,
	ticketIds = []
) => {
	return (dispatch) => {
		dispatch({ type: SAVE_ORDER_REQUEST, order: orderData });
		OrdersService.update(id, orderData, eventId, token)
			.then((order) => {
				if (resendOrder || emailAttendees) {
					dispatch(userSendOrder(id, true, token, emailTo, emailAttendees, ticketIds, resendOrder));
				}
				notification.success({
					message: "Saved",
					description: "Order has been saved"
				});
				dispatch({ type: SAVE_ORDER_SUCCESS, order });
			})
			.catch((err) => {
				notification.error({
					message: "Error",
					description: `Order failed to save`
				});
				dispatch({ type: SAVE_ORDER_ERROR, error: err });
			});
	};
};

export const downloadInvoice = (orderId, orderName) => {
	const fileName = `invoice_${orderName}`;
	return (dispatch) => {
		dispatch({ type: INVOICE_DOWNLOAD_REQUEST, orderId });
		OrdersService.downloadInvoice(orderId)
			.then((data) => {
				dispatch({ type: INVOICE_DOWNLOAD_SUCCESS, orderId });
				FileDownload.saveAs(data, fileName);
			})
			.catch((err) => {
				dispatch({ type: INVOICE_DOWNLOAD_ERROR, orderId });
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to download";
				notification.error({ message: "Error", description: message });
			});
	};
};

export const openSwapModal = () => {
	return (dispatch) => {
		dispatch({ type: OPEN_SWAP_MODAL_REQUEST });
		dispatch({
			type: OPEN_SWAP_MODAL_SUCCESS
		});
	};
};

export const closeSwapModal = () => {
	return (dispatch) => {
		dispatch({ type: CLOSE_SWAP_MODAL });
	};
};

export const swapTicket = (orderId, values, formName) => {
	return (dispatch) => {
		dispatch({ type: SWAP_TICKET_REQUEST });
		OrdersService.swapTicket(orderId, values)
			.then((res) => {
				const ticketSize = Object.keys(values.tickets || {}).length;
				const merchSize = Object.values(values.merchandise || {}).length;
				notification.success({
					message: "Swapped",
					description: (
						<>
							{ticketSize && (
								<P>
									{ticketSize} ticket{ticketSize > 1 ? "s" : ""} has been swapped
								</P>
							)}

							{merchSize && (
								<P>
									{merchSize} add-on{merchSize > 1 ? "s" : ""} has been swapped
								</P>
							)}
						</>
					)
				});

				dispatch({ type: SWAP_TICKET_SUCCESS, order: res.currentOrder });
				dispatch(closeSwapModal());
				dispatch(reset(formName));
			})
			.catch((err) => {
				dispatch({ type: SWAP_TICKET_ERROR });
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to swap";
				notification.error({ message: "Error", description: message });
			});
	};
};

export const clearSwap = (formName) => {
	return (dispatch) => {
		dispatch({ type: CLEAR_SWAP });
		dispatch(reset(formName));
	};
};

export const hubspotSync = (orderId) => {
	return async (dispatch) => {
		dispatch({ type: HUBSPOT_SYNC_REQUEST });
		try {
			const result = await OrdersService.hubspotSync(orderId);
			dispatch({ type: HUBSPOT_SYNC_SUCCESS, event: result });
			notification.success({
				message: "Success",
				description: "Your order has been synced with HubSpot"
			});
			dispatch(getOrder(orderId));
		} catch (err) {
			notification.error({
				message: "Error",
				description: "Order failed to sync to HubSpot, please try again later or contact support"
			});
			dispatch({ type: HUBSPOT_SYNC_ERROR, error: err });
		}
	};
};

export const forceCompleteOrder = (orderId) => {
	return async (dispatch) => {
		dispatch({ type: SAVE_ORDER_REQUEST });
		try {
			const result = await OrdersService.forceComplete(orderId);
			dispatch({ type: SAVE_ORDER_SUCCESS, order: result });
			notification.success({
				message: "Success",
				description: "Your order has been force completed, refunds wont be possible"
			});
		} catch (err) {
			notification.error({
				message: "Error",
				description: "Order failed to complete"
			});
			dispatch({ type: SAVE_ORDER_ERROR, error: err });
		}
	};
};

export const getEventDateCapacity = (eventId, eventDateId) => {
	return async (dispatch) => {
		dispatch({ type: EVENT_DATE_CAPACITY_REQUEST });
		try {
			const result = await OrdersService.getEventDateCapacity(eventId, eventDateId);
			dispatch({ type: EVENT_DATE_CAPACITY_SUCCESS, eventDateCapacity: result });
		} catch (err) {
			dispatch({ type: EVENT_DATE_CAPACITY_ERROR, error: err });
		}
	};
};

export const clearEventDateCapacity = () => {
	return async (dispatch) => {
		dispatch({ type: EVENT_DATE_CAPACITY_CLEAR });
	};
};
