import { ContentBlock } from "@/components/AppBlocks";
import { CheckboxField, InputNumberField, RadioField, SelectField, SwitchField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import { Loader } from "@/components/Loader";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import Label from "@/components/text/Label";
import { H3 } from "@/components/text/Text";
import AuthService from "@/services/AuthService";
import { useAppSelector } from "@/state/hooks";
import { trpc } from "@/trpc";
import { checkLength } from "@/utils/Validators";
import type { EstimateOrStartMassRefundInput, MassRefundOptionsInput } from "@hx/console/massRefunds";
import { Alert, notification } from "@/ui/antd";
import { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { Field, formValueSelector, reduxForm } from "redux-form";

const ITEM_STATUS_COMPLETE = "complete";
const ITEM_STATUS_CANCELLED = "cancelled";

const ticketRefundTypes = [
	{ label: "All tickets", value: "all" },
	{ label: "Select ticket types", value: "select" }
];

const addonRefundTypes = [
	{ label: "All add-ons", value: "all" },
	{ label: "Selected add-ons", value: "select" }
];

const validateTypeSelected = checkLength("Please select a type", 1, ">=");

type MassRefundFormComponentProps = {
	change: any;
	handleSubmit: any;
	onNextStep: any;
	setIsLoading: any;
	setMassRefundData: any;
};

const MassRefundFormComponent: FC<MassRefundFormComponentProps> = ({
	change,
	handleSubmit,
	onNextStep,
	setIsLoading,
	setMassRefundData
}) => {
	const currentEvent = useAppSelector((state) => state.currentEvent);

	const selectedEventDateIds = useAppSelector((state) => selector(state, "eventDateIds"));
	const selectedPaymentGateways = useAppSelector((state) => selector(state, "gateways"));

	const {
		fees: refundDonationsFees,
		includeCancelled: refundCancelledDonations,
		percent: refundDonationsPercent,
		refund: refundDonations,
		type: refundDonationsType
	} = useAppSelector((state) => selector(state, "donations"));

	const {
		cancel: refundMerchandiseCancel,
		fees: refundMerchandiseFees,
		ids: selectedMerchandiseTypeIds,
		includeCancelled: refundCancelledMerchandises,
		percent: refundMerchandisePercent,
		refund: refundMerchandise,
		type: refundMerchandiseUnit
	} = useAppSelector((state) => selector(state, "merchandise"));

	const {
		cancel: refundTicketTypesCancel,
		fees: refundTicketTypesFees,
		ids: selectedTicketTypeIds,
		includeCancelled: refundCancelledTickets,
		percent: refundTicketTypesPercent,
		refund: refundTicketTypes,
		type: refundTicketTypesUnit
	} = useAppSelector((state) => selector(state, "ticketTypes"));

	const ticketsRefundType = useAppSelector((state) => selector(state, "ticketsRefundType"));
	const merchandiseRefundType = useAppSelector((state) => selector(state, "merchandiseRefundType"));

	const { event } = currentEvent;
	const { ticketTypes = [], packagedTickets = [], merchandise = [], dates = [], settings } = event ?? {};

	const eventId = event?._id ?? "";

	const mutation = trpc.massRefunds.estimateMassRefund.useMutation({
		onError: (error) => {
			notification.error({
				description: error.message ?? "Failed to preview refund",
				message: "Error"
			});
		},
		onSuccess: (data) => {
			setMassRefundData(data);
			setIsLoading(false);
		}
	});

	const previewRefunds = (values: MassRefundOptionsInput) => {
		const { customMessage, eventDateIds, donations, gateways, merchandise, reason, sendEmails, ticketTypes } = values;

		const data: EstimateOrStartMassRefundInput = {
			eventId,
			options: {
				customMessage,
				eventDateIds,
				donations: {
					amount: donations.amount,
					fees: donations.fees,
					percent: donations.percent,
					refund: donations.refund,
					type: donations.type
				},
				gateways,
				merchandise: {
					amount: merchandise.amount,
					cancel: merchandise.cancel,
					fees: merchandise.fees,
					ids: merchandise.ids,
					includeCancelled: merchandise.includeCancelled,
					percent: merchandise.percent,
					refund: merchandise.refund,
					type: merchandise.type
				},
				reason,
				sendEmails,
				ticketTypes: {
					amount: ticketTypes.amount,
					cancel: ticketTypes.cancel,
					fees: ticketTypes.fees,
					ids: ticketTypes.ids,
					includeCancelled: ticketTypes.includeCancelled,
					percent: ticketTypes.percent,
					refund: ticketTypes.refund,
					type: ticketTypes.type
				}
			}
		};
		setIsLoading(true);
		mutation.mutate(data);
		onNextStep();
	};

	const tickets = ticketTypes?.concat(packagedTickets).filter((ticket) => !ticket.isDonation && !ticket.deleted);

	const merchandiseOptions = merchandise.map((merch) => ({
		label: merch.itemName,
		value: merch._id
	}));

	const ticketOptions = tickets.map((ticket) => ({ label: ticket.name, value: ticket._id }));

	const refundAmountTypes = [
		{ label: "%", value: "percent" },
		{ label: "$", value: "amount" }
	];

	const selectableDates = dates
		? dates
				.filter((date) => {
					return !date.deleted;
				})
				.map((date) => {
					return {
						label: `${date.display?.date} ${date.display?.time}`,
						value: date._id
					};
				})
		: [];

	const paymentGatewaysReponse = trpc.paymentGateways.getPaymentGateways.useQuery();
	const gateways = paymentGatewaysReponse.data?.responseData.paymentGateways ?? [];

	const handleGetItemsError = (error: any) => {
		notification.error({
			description: error.message ?? "An error occurred while fetching mass refund data.",
			message: "Error"
		});
	};

	const ticketAffectedResponse = trpc.massRefunds.getTicketsAffected.useQuery(
		{
			cancel: refundTicketTypesCancel,
			eventDateIds: selectedEventDateIds.length ? selectedEventDateIds : undefined,
			eventId,
			fees: refundTicketTypesFees,
			itemStatus: [ITEM_STATUS_COMPLETE, ...(refundCancelledTickets ? [ITEM_STATUS_CANCELLED] : [])],
			itemTypeIds: selectedTicketTypeIds.length ? selectedTicketTypeIds : undefined,
			paymentGateways: selectedPaymentGateways?.length ? selectedPaymentGateways : undefined
		},
		{
			enabled: false,
			onError: handleGetItemsError
		}
	);

	const {
		data: ticketData,
		isLoading: isAffectedTicketsLoading,
		refetch: fetchTicketsAffected
	} = ticketAffectedResponse;

	const addOnsAffectedResponse = trpc.massRefunds.getAddOnsAffected.useQuery(
		{
			eventDateIds: selectedEventDateIds.length ? selectedEventDateIds : undefined,
			eventId,
			fees: refundMerchandiseFees,
			itemStatus: [ITEM_STATUS_COMPLETE, ...(refundCancelledMerchandises ? [ITEM_STATUS_CANCELLED] : [])],
			paymentGateways: selectedPaymentGateways?.length ? selectedPaymentGateways : undefined,
			itemTypeIds: selectedMerchandiseTypeIds.length ? selectedMerchandiseTypeIds : undefined
		},
		{
			enabled: false,
			onError: handleGetItemsError
		}
	);

	const { data: addOnData, isLoading: isAffectedAddOnsLoading, refetch: fetchAddOnsAffected } = addOnsAffectedResponse;

	const donationsAffectedResponse = trpc.massRefunds.getDonationsAffected.useQuery(
		{
			eventDateIds: selectedEventDateIds.length ? selectedEventDateIds : undefined,
			eventId,
			fees: refundDonationsFees,
			itemStatus: [ITEM_STATUS_COMPLETE, ...(refundCancelledDonations ? [ITEM_STATUS_CANCELLED] : [])],
			paymentGateways: selectedPaymentGateways?.length ? selectedPaymentGateways : undefined
		},
		{
			enabled: false,
			onError: handleGetItemsError
		}
	);

	const {
		data: donationData,
		isLoading: isAffectedDonationsLoading,
		refetch: fetchDonationsAffected
	} = donationsAffectedResponse;

	useEffect(() => {
		fetchTicketsAffected();
		fetchAddOnsAffected();
		fetchDonationsAffected();
	}, [
		selectedEventDateIds,
		selectedPaymentGateways,
		fetchTicketsAffected,
		fetchAddOnsAffected,
		fetchDonationsAffected
	]);

	useEffect(() => {
		fetchTicketsAffected();
	}, [
		fetchTicketsAffected,
		refundCancelledTickets,
		refundTicketTypes,
		refundTicketTypesCancel,
		refundTicketTypesFees,
		selectedTicketTypeIds
	]);

	useEffect(() => {
		fetchAddOnsAffected();
	}, [
		fetchAddOnsAffected,
		refundMerchandise,
		selectedMerchandiseTypeIds,
		refundCancelledMerchandises,
		refundMerchandiseFees,
		refundMerchandiseCancel
	]);

	useEffect(() => {
		fetchDonationsAffected();
	}, [fetchDonationsAffected, refundDonations, refundDonationsFees, refundCancelledDonations]);

	const isAdmin = AuthService.isAdmin();

	const ticketCount = ticketData?.responseData?.count ?? 0;
	const addOnCount = addOnData?.responseData?.count ?? 0;
	const donationCount = donationData?.responseData?.count ?? 0;

	const isPreviewButtonDisabled =
		(!refundTicketTypes || ticketCount === 0) &&
		(!refundMerchandise || addOnCount === 0) &&
		(!refundDonations || donationCount === 0);

	return (
		<form onSubmit={handleSubmit(previewRefunds)}>
			<>
				<Field
					component={SelectField}
					description="For which event date(s)"
					label="Select event date(s) affected"
					mode="multiple"
					name="eventDateIds"
					options={selectableDates}
					placeholder="All"
					style={{ width: "100%" }}
				/>

				{isAdmin && (
					<Field
						component={SelectField}
						description="Only orders processed by these gateways will be refunded"
						label="Gateways (Admin only)"
						mode="multiple"
						name="gateways"
						options={gateways.map((gateway) => ({ label: gateway.name, value: gateway.id }))}
						placeholder="All"
						style={{ width: "100%" }}
					/>
				)}

				<ContentBlock>
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<H3>Tickets</H3>
						<Field name="ticketTypes.refund" component={SwitchField} />
					</div>
					{refundTicketTypes && (
						<>
							<hr />
							<Field
								button
								component={RadioField as any}
								defaultValue="all"
								label="Select refund type"
								name="ticketsRefundType"
								onChange={(_, value) => {
									if (value === "all") change("ticketTypes.ids", []);
								}}
								options={ticketRefundTypes}
							/>
							{ticketsRefundType === "select" && (
								<Field
									component={SelectField}
									label="For which ticket type(s)"
									mode="multiple"
									name="ticketTypes.ids"
									options={ticketOptions}
									required
									validate={validateTypeSelected}
								/>
							)}
							<div>
								<Label>Refund amount per ticket</Label>
								{refundTicketTypesUnit === "percent" ? (
									<Field name="ticketTypes.percent" component={InputNumberField} max={100} min={0} inline />
								) : (
									<Field name="ticketTypes.amount" component={InputNumberField} min={0} inline />
								)}
								<Field
									button
									component={RadioField}
									defaultValue="percent"
									inline
									name="ticketTypes.type"
									options={refundAmountTypes}
									style={{ paddingLeft: 5 }}
								/>
								<br />
							</div>
							<Field component={CheckboxField} inline label="Cancel tickets" labelAfter name="ticketTypes.cancel" />
							<Alert
								message={
									<>
										<b>Note: Cancelled tickets are excluded from all email campaigns.</b>
										<br />
										<>Consider notifying your ticket buyers before initiating this cancellation.</>
										<br />
										<Link to="/console/comms/email-campaigns" target="_blank" rel="noopener noreferrer">
											Click here to notify all buyers in advance
										</Link>
									</>
								}
								type="warning"
								showIcon
							/>
							<br />
							<Field
								component={CheckboxField}
								inline
								label="Refund previously cancelled tickets"
								labelAfter
								name="ticketTypes.includeCancelled"
							/>
							<br />
							{refundTicketTypesUnit === "percent" &&
								refundTicketTypesPercent === 100 &&
								(isAdmin || !settings?.disallowRefundFees) && (
									<>
										<Field
											component={CheckboxField}
											inline
											label="Refund booking fees"
											labelAfter
											name="ticketTypes.fees"
										/>
										<br />
									</>
								)}
							<Loader isLoading={isAffectedTicketsLoading}>
								<hr />
								<b>
									{ticketCount} tickets to be refunded {refundTicketTypesCancel && " & cancelled"}
								</b>
							</Loader>
						</>
					)}
				</ContentBlock>

				<ContentBlock>
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<H3>Add-Ons</H3>
						<Field name="merchandise.refund" component={SwitchField} />
					</div>
					{refundMerchandise && (
						<>
							<hr />
							<Field
								button
								component={RadioField as any}
								defaultValue="all"
								label="Select add-on type(s) to refund"
								name="merchandiseRefundType"
								onChange={(_, value) => {
									if (value === "all") change("merchandise.ids", []);
								}}
								options={addonRefundTypes}
							/>
							{merchandiseRefundType === "select" && (
								<Field
									component={SelectField}
									label="For which add-on(s)"
									mode="multiple"
									name="merchandise.ids"
									options={merchandiseOptions}
									required
									validate={validateTypeSelected}
								/>
							)}

							<div>
								<Label>Refund amount per add-on</Label>
								{refundMerchandiseUnit === "percent" ? (
									<Field name="merchandise.percent" component={InputNumberField} max={100} min={0} inline />
								) : (
									<Field name="merchandise.amount" component={InputNumberField} min={0} inline />
								)}
								<Field
									button
									component={RadioField}
									defaultValue="percent"
									inline
									name="merchandise.type"
									options={refundAmountTypes}
									style={{ paddingLeft: 5 }}
								/>
								<br />
							</div>

							<Field component={CheckboxField} inline label="Cancel add-ons" labelAfter name="merchandise.cancel" />
							<br />

							{refundMerchandiseUnit === "percent" &&
								refundMerchandisePercent === 100 &&
								(isAdmin || !settings?.disallowRefundFees) && (
									<>
										<Field
											component={CheckboxField}
											inline
											label="Refund booking fees"
											labelAfter
											name="merchandise.fees"
										/>
										<br />
									</>
								)}
							<Field
								component={CheckboxField}
								inline
								label="Refund previously cancelled add-ons"
								labelAfter
								name="merchandise.includeCancelled"
							/>
							<br />
							<hr />
							<Loader isLoading={isAffectedAddOnsLoading}>
								<b>
									{addOnCount} add-ons to be refunded {refundMerchandiseCancel && " & cancelled"}
								</b>
							</Loader>
						</>
					)}
				</ContentBlock>

				<ContentBlock>
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<H3>Donations</H3>
						<Field name="donations.refund" component={SwitchField} />
					</div>
					{refundDonations && (
						<>
							<hr />
							<div>
								<Label>Refund amount per donation</Label>
								{refundDonationsType === "percent" ? (
									<Field name="donations.percent" component={InputNumberField} max={100} min={0} inline />
								) : (
									<Field name="donations.amount" component={InputNumberField} min={0} inline />
								)}
								<Field
									button
									component={RadioField}
									defaultValue="percent"
									inline
									name="donations.type"
									options={refundAmountTypes}
									style={{ paddingLeft: 5 }}
								/>
								<br />
							</div>

							{refundDonationsType === "percent" &&
								refundDonationsPercent === 100 &&
								(isAdmin || !settings?.disallowRefundFees) && (
									<Field
										component={CheckboxField}
										inline
										label="Refund booking fees"
										labelAfter
										name="donations.fees"
									/>
								)}
							<hr />
							<Loader isLoading={isAffectedDonationsLoading}>
								<b>{donationCount} donations to be refunded</b>
							</Loader>
						</>
					)}
				</ContentBlock>
			</>
			<FormBar>
				<div style={{ display: "flex", flexDirection: "row-reverse" }}>
					<span>
						<LegacyButton
							ariaLabel="Refund"
							disabled={isPreviewButtonDisabled}
							htmlType="submit"
							style={isPreviewButtonDisabled ? { pointerEvents: "none" } : undefined}
							type="primary"
						>
							Generate preview
						</LegacyButton>
					</span>
				</div>
			</FormBar>
		</form>
	);
};

const formName = "massRefundForm";

const initialCreateMassRefundFormValues: MassRefundOptionsInput & {
	ticketsRefundType: "all" | "select";
	merchandiseRefundType: "all" | "select";
} = {
	customMessage: undefined,
	eventDateIds: [],
	donations: {
		amount: 0,
		fees: false,
		percent: 100,
		refund: false,
		type: "percent"
	},
	gateways: [],
	merchandise: {
		amount: 0,
		cancel: false,
		fees: false,
		ids: [],
		includeCancelled: false,
		percent: 100,
		refund: false,
		type: "percent"
	},
	reason: undefined,
	sendEmails: false,
	ticketTypes: {
		amount: 0,
		cancel: false,
		fees: false,
		ids: [],
		includeCancelled: false,
		percent: 100,
		refund: false,
		type: "percent"
	},
	ticketsRefundType: "all",
	merchandiseRefundType: "all"
};

const selector = formValueSelector("massRefundForm");

const FormWithRedux = reduxForm<unknown, any, string>({
	destroyOnUnmount: false,
	form: formName,
	initialValues: initialCreateMassRefundFormValues,
	onSubmitFail: () => null,
	touchOnBlur: true,
	touchOnChange: true
})(MassRefundFormComponent);

export default FormWithRedux;
