import icon from "@/assets/menu/ic_promote.svg";
import { MenuItem } from "@/services/menuService/MenuService";
import { Badge, Tooltip } from "@/ui/antd";

export const promote = (): MenuItem[] => {
	const children: MenuItem[] = [
		{
			key: "promote-promotional-hub",
			label: "Promotional hub",
			path: "/console/promote/promotional-hub/social-media"
		},
		{
			key: "promote-global-discount-codes",
			label: "Global discount codes",
			path: "/console/promote/global-discount-codes"
		},
		{
			key: "promote-tours",
			label: "Tours",
			path: "/console/promote/tours"
		},
		{
			key: "promote-collections",
			label: (
				<span>
					Collections
					<Tooltip title="New! Share all your events in one place.">
						<Badge
							count="New"
							offset={[0, -2]}
							style={{
								backgroundColor: "transparent",
								borderColor: "var(--error)",
								color: "var(--error)",
								fontWeight: "bold",
								scale: "0.7"
							}}
							title=""
						/>
					</Tooltip>
				</span>
			),
			path: "/console/promote/collections"
		},
		{
			children: [
				{
					key: "promote-gift-cards-gift-cards",
					label: "Gift cards",
					path: "/console/promote/gift-cards"
				},
				{
					key: "promote-gift-cards-page",
					label: "Gift cards page",
					path: "/console/promote/gift-cards/page"
				}
			],
			key: "promote-gift-cards",
			label: "Gift cards",
			path: "/console/promote/gift-cards",
			subMenu: true
		},
		{
			key: "promote-add-ons",
			label: "Add-ons",
			path: "/console/promote/add-ons"
		}
	];

	return [
		{
			children,
			icon,
			key: "promote",
			label: "Promote",
			path: "/console/promote/global-discount-codes",
			subMenu: true
		}
	];
};
