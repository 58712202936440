import {
	CLOSE_MODAL,
	STORE_PREVIEW_DATA_ERROR,
	STORE_PREVIEW_DATA_REQUEST,
	STORE_PREVIEW_DATA_SUCCESS
} from "../actions/previewActions";

const initialState = {
	error: false,
	modalOpen: false
};
export default (state = initialState, action) => {
	switch (action.type) {
		case CLOSE_MODAL:
			return {
				...state,
				modalOpen: false
			};
		case STORE_PREVIEW_DATA_REQUEST:
			return {
				...state,
				error: false
			};
		case STORE_PREVIEW_DATA_SUCCESS:
			return {
				...state,
				error: false,
				modalOpen: true
			};
		case STORE_PREVIEW_DATA_ERROR:
			return {
				...state,
				error: true,
				modalOpen: false
			};
		default:
			return state;
	}
};
