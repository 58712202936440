import { notification } from "@/ui/antd";
import GatewaysService from "../../services/GatewaysService";
import { SAVE_EVENT_SUCCESS } from "../actions/eventActions";
import { SAVE_USER_SUCCESS } from "../actions/userActions";

export const OPEN_INVOICING_MODAL = "OPEN_INVOICING_MODAL";
export const CLOSE_INVOICING_MODAL = "CLOSE_INVOICING_MODAL";

export const GATEWAYS_UPDATE_REQUEST = "GATEWAYS_UPDATE_REQUEST";
export const GATEWAYS_UPDATE_SUCCESS = "GATEWAYS_UPDATE_SUCCESS";
export const GATEWAYS_UPDATE_ERROR = "GATEWAYS_UPDATE_ERROR";

export const openInvoicingModal = () => {
	return (dispatch) => {
		dispatch({ type: OPEN_INVOICING_MODAL });
	};
};

export const closeInvoicingModal = () => {
	return (dispatch) => {
		dispatch({ type: CLOSE_INVOICING_MODAL });
	};
};

export const updateGateways = (type, id, paymentOptions, location) => {
	return (dispatch) => {
		dispatch({ type: GATEWAYS_UPDATE_REQUEST });
		GatewaysService.update(type, id, paymentOptions, location)
			.then((updatedDoc) => {
				if (type === "event") {
					dispatch({ type: SAVE_EVENT_SUCCESS, event: updatedDoc });
				} else {
					dispatch({ type: SAVE_USER_SUCCESS, user: updatedDoc });
				}

				dispatch({
					type: GATEWAYS_UPDATE_SUCCESS,
					updatedDoc
				});
				notification.success({ message: "Updated", description: "Gateways updated" });
			})
			.catch((err) => {
				const message = err.response?.data?.error || "Gateways failed to update";
				notification.error({ message: "Error", description: message });
				dispatch({ type: GATEWAYS_UPDATE_ERROR, error: err });
			});
	};
};
