"use strict";
var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  ConsoleAction: () => ConsoleAction,
  EventAction: () => EventAction,
  EventView: () => EventView,
  ItemType: () => ItemType,
  LinkTrackingType: () => LinkTrackingType,
  WebsiteAction: () => WebsiteAction,
  addLinkTracking: () => addLinkTracking,
  disable: () => disable,
  getAnonymousId: () => getAnonymousId,
  getSessionId: () => getSessionId,
  identify: () => identify,
  initialize: () => initialize,
  trackConsoleAction: () => trackConsoleAction,
  trackEventView: () => trackEventView,
  trackOrderComplete: () => trackOrderComplete,
  trackOrderCreated: () => trackOrderCreated,
  trackTicketsSelected: () => trackTicketsSelected,
  trackWebsiteAction: () => trackWebsiteAction
});
module.exports = __toCommonJS(src_exports);

// src/types.ts
var LinkTrackingType = {
  hxHomePage: {
    hxchl: "mkt-hme"
  },
  hxSearchPage: {
    hxchl: "mkt-sch"
  },
  hxLocationPage: {
    hxchl: "mkt-loc"
  },
  abandonedCartEmail: {
    hxchl: "amk-eml-abd",
    hxref: "1-0-0"
  },
  eventQRCode: {
    hxchl: "hex-qrc",
    hxref: "evt"
  },
  eventTicketsQRCode: {
    hxchl: "hex-qrc",
    hxref: "slt"
  },
  hostFollowerEmail: {
    hxchl: "amk-eml-hfn",
    hxref: "1-0-0"
  },
  hostProfileEventLink: {
    hxchl: "hex-pfl"
  },
  collectionPage: {
    hxchl: "hex-col"
  }
};
var EventView = {
  eventPageView: "eventPageView",
  ticketSectionView: "ticketSectionView",
  addOnsSectionView: "addOnsSectionView",
  orderDetailsView: "orderDetailsView",
  seatingSectionView: "seatingSectionView",
  afterCheckoutView: "afterCheckoutView",
  completePageView: "completePageView",
  manageOrderView: "manageOrderView"
};
var EventAction = {
  orderCreated: "orderCreated",
  ticketsSelected: "ticketsSelected",
  orderComplete: "orderComplete"
  // db-streams
};
var ConsoleAction = {
  hostSignUp: "hostSignUp",
  hostLogin: "hostLogin",
  eventCreated: "eventCreated"
};
var WebsiteAction = {
  salesEnquiry: "salesEnquiry"
};
var ItemType = {
  Ticket: "ticket",
  AddOn: "addOn",
  Donation: "donation",
  Insurance: "insurance"
};

// src/linkTracking.ts
function addLinkTracking(type, url, pageRef) {
  const linkParams = type;
  const params = [
    {
      name: "hxchl",
      value: linkParams.hxchl
    }
  ];
  if (pageRef) {
    params.push({
      name: "hxref",
      value: pageRef
    });
  } else if (linkParams.hxref) {
    params.push({
      name: "hxref",
      value: linkParams.hxref
    });
  }
  if (params.length) {
    const emailTrackingUrl = new URL(url);
    params.forEach((param) => {
      emailTrackingUrl.searchParams.append(param.name, param.value);
    });
    return emailTrackingUrl.toString();
  }
  return url;
}
__name(addLinkTracking, "addLinkTracking");

// src/userfluxController.ts
var import_browser_or_node = require("browser-or-node");
var UserFluxClient;
var UserFlux;
var enabled = true;
async function initialize(key, application) {
  try {
    if (import_browser_or_node.isNode) {
      UserFlux = await import("@userflux/backend-js");
      UserFluxClient = new UserFlux.default(key, {
        autoEnrich: false,
        defaultProperties: {
          application
        }
      });
      return true;
    } else {
      UserFlux = await import("@userflux/browser-js");
      UserFluxClient = UserFlux.default;
      UserFluxClient.initialize(key, {
        autoCapture: [],
        allowCookies: true,
        autoEnrich: true,
        trackSession: true,
        defaultProperties: {
          application
        },
        customQueryParamsToCollect: [
          "hxchl",
          "hxref"
        ]
      });
      window._UserFluxClient = UserFluxClient;
      return true;
    }
  } catch (err) {
    console.warn("error initialize tracking", err);
    return false;
  }
}
__name(initialize, "initialize");
function getUserFluxSync() {
  if (import_browser_or_node.isNode) {
    return UserFluxClient;
  }
  if (window._UserFluxClient) {
    return window._UserFluxClient;
  }
  return null;
}
__name(getUserFluxSync, "getUserFluxSync");
function waitForUserFlux(resolve) {
  const uf = getUserFluxSync();
  if (uf) {
    resolve(uf);
    return;
  } else {
    setTimeout(() => waitForUserFlux(resolve), 30);
  }
}
__name(waitForUserFlux, "waitForUserFlux");
function getUserFlux() {
  return new Promise((resolve) => {
    if (!enabled) {
      resolve({
        track: /* @__PURE__ */ __name(() => {
        }, "track"),
        identify: /* @__PURE__ */ __name(() => {
        }, "identify"),
        getAnonymousId: /* @__PURE__ */ __name(() => {
        }, "getAnonymousId")
      });
    }
    waitForUserFlux(resolve);
  }).catch((e) => {
    console.warn("error getUserFlux", e);
  });
}
__name(getUserFlux, "getUserFlux");
function getUserFluxSDK() {
  return UserFlux.default;
}
__name(getUserFluxSDK, "getUserFluxSDK");
function getAnonymousId() {
  try {
    const uf = getUserFluxSync();
    const anonymousId = uf.getAnonymousId();
    return anonymousId;
  } catch (err) {
    console.warn("error getAnonymousId", err);
  }
  return void 0;
}
__name(getAnonymousId, "getAnonymousId");
function getSessionId() {
  try {
    const uf = getUserFluxSync();
    const sessionId = uf.getSessionId();
    return sessionId;
  } catch (err) {
    console.warn("error getSessionId", err);
  }
  return void 0;
}
__name(getSessionId, "getSessionId");
async function identify(identifyData) {
  try {
    const uf = await getUserFlux();
    uf.identify({
      userId: identifyData.email,
      anonymousId: import_browser_or_node.isNode ? identifyData.anonymousId : uf.getAnonymousId(),
      properties: identifyData,
      enrichDeviceData: true,
      enrichLocationData: true
    });
  } catch (err) {
    console.warn("error identify", err);
  }
}
__name(identify, "identify");
function disable() {
  if (import_browser_or_node.isNode) {
    throw new Error("disable is not supported in node");
  }
  enabled = false;
}
__name(disable, "disable");

// src/console.ts
async function trackConsoleAction(action, data) {
  try {
    const uf = await getUserFlux();
    await uf.track({
      event: action,
      properties: {
        ...data
      }
    });
  } catch (err) {
    console.warn("error tracking console data", err);
  }
}
__name(trackConsoleAction, "trackConsoleAction");

// src/website.ts
async function trackWebsiteAction(action, data) {
  try {
    const uf = await getUserFlux();
    await uf.track({
      event: action,
      properties: {
        ...data
      }
    });
  } catch (err) {
    console.warn("error tracking console data", err);
  }
}
__name(trackWebsiteAction, "trackWebsiteAction");

// src/events.ts
async function trackEventView(eventName, eventData, orderId, eventDateId) {
  try {
    const uf = await getUserFlux();
    uf.track({
      event: eventName,
      properties: {
        eventId: eventData.id,
        hostId: eventData.hostId,
        userId: eventData.userId,
        ...eventData.classification,
        location: eventData.locationCode,
        currency: eventData.currency,
        orderId,
        eventDateId
      },
      enrichDeviceData: true,
      enrichLocationData: true,
      enrichPageProperties: true,
      enrichReferrerProperties: true,
      enrichUTMProperties: true
    });
  } catch (err) {
    console.warn("error tracking event view", err);
  }
}
__name(trackEventView, "trackEventView");
async function trackTicketsSelected(data) {
  try {
    const uf = await getUserFlux();
    await uf.track({
      event: EventAction.ticketsSelected,
      properties: {
        ...data
      }
    });
  } catch (err) {
    console.warn("error tracking tickets selected", err);
  }
}
__name(trackTicketsSelected, "trackTicketsSelected");
function getIds(anonymousId, sessionId) {
  const ids = {};
  if (anonymousId) {
    ids.anonymousId = anonymousId;
  }
  if (sessionId) {
    ids.sessionId = sessionId;
  }
  return ids;
}
__name(getIds, "getIds");
async function trackOrderCreated(data, anonymousId, sessionId) {
  try {
    const uf = await getUserFlux();
    const timestamp = getUserFluxSDK().isoTimestampToEpoch(String(data.createdAt));
    if (anonymousId || sessionId) {
      await uf.track({
        ...getIds(anonymousId, sessionId),
        event: EventAction.orderCreated,
        timestamp,
        properties: {
          ...data
        }
      });
    }
  } catch (err) {
    console.warn("error tracking order created", err);
  }
}
__name(trackOrderCreated, "trackOrderCreated");
async function trackOrderComplete(data, anonymousId, sessionId) {
  try {
    const uf = await getUserFlux();
    const timestamp = getUserFluxSDK().isoTimestampToEpoch(String(data.completedAt));
    await uf.track({
      userId: data.email,
      ...getIds(anonymousId, sessionId),
      event: EventAction.orderComplete,
      timestamp,
      properties: {
        ...data
      }
    });
  } catch (err) {
    console.warn("error tracking order complete", err);
  }
}
__name(trackOrderComplete, "trackOrderComplete");
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  ConsoleAction,
  EventAction,
  EventView,
  ItemType,
  LinkTrackingType,
  WebsiteAction,
  addLinkTracking,
  disable,
  getAnonymousId,
  getSessionId,
  identify,
  initialize,
  trackConsoleAction,
  trackEventView,
  trackOrderComplete,
  trackOrderCreated,
  trackTicketsSelected,
  trackWebsiteAction
});
