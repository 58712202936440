import {
	AUTO_PASS_RECAPTCHA,
	SET_USER,
	SIGN_UP_ERROR,
	SIGN_UP_REFRESH,
	SIGN_UP_REQUEST,
	SIGN_UP_SUCCESS,
	UPDATE_USER_SUCCESS,
	ON_USER_EMAIL_VERIFIED
} from "../actions/authActions";
import { SAVE_USER_SUCCESS } from "../actions/userActions";

export type AuthReducerState = {
	user: any;
	loggedIn: boolean;
	loading: boolean;
	error: boolean;
	recaptchaPass: boolean;
};

type AuthReducerAction = {
	type:
		| typeof SET_USER
		| typeof UPDATE_USER_SUCCESS
		| typeof SAVE_USER_SUCCESS
		| typeof SIGN_UP_ERROR
		| typeof SIGN_UP_REQUEST
		| typeof SIGN_UP_SUCCESS
		| typeof SIGN_UP_REFRESH
		| typeof AUTO_PASS_RECAPTCHA
		| typeof ON_USER_EMAIL_VERIFIED;

	user?: any;
	error?: Error;
	recaptchaPass?: boolean;
};

const initialState: AuthReducerState = {
	user: null,
	loggedIn: false,
	loading: false,
	error: false,
	recaptchaPass: false
};

export default (state = initialState, action: AuthReducerAction) => {
	switch (action.type) {
		case SET_USER:
			return {
				...state,
				user: action.user
			};
		case AUTO_PASS_RECAPTCHA:
			return {
				...state,
				recaptchaPass: action.recaptchaPass
			};
		case UPDATE_USER_SUCCESS:
			return {
				...state,
				user: action.user,
				loggedIn: true,
				loading: false
			};
		case SAVE_USER_SUCCESS: {
			if (state?.user?.id === action?.user?.id) {
				return {
					...state,
					user: action.user
				};
			}
			return state;
		}
		case SIGN_UP_REQUEST:
			return {
				...state,
				error: false,
				loading: true
			};
		case SIGN_UP_ERROR:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case SIGN_UP_REFRESH:
			return {
				...state,
				loading: false,
				error: false
			};
		case SIGN_UP_SUCCESS:
			return {
				...state,
				loggedIn: true,
				loading: false,
				user: action.user
			};
		case ON_USER_EMAIL_VERIFIED: {
			return {
				...state,
				user: {
					...state.user,
					emailVerified: true
				}
			};
		}
		default:
			return state;
	}
};
