import { notification } from "@/ui/antd";
import MailchimpService from "../../services/MailchimpService";
import { updateUser } from "../../state/actions/userActions";

export const MAILCHIMP_GET_AUDIENCES_REQUEST = "MAILCHIMP_GET_AUDIENCES_REQUEST";
export const MAILCHIMP_GET_AUDIENCES_SUCCESS = "MAILCHIMP_GET_AUDIENCES_SUCCESS";
export const MAILCHIMP_GET_AUDIENCES_ERROR = "MAILCHIMP_GET_AUDIENCES_ERROR";

export const MAILCHIMP_TEST_REQUEST = "MAILCHIMP_TEST_REQUEST";
export const MAILCHIMP_TEST_SUCCESS = "MAILCHIMP_TEST_SUCCESS";
export const MAILCHIMP_TEST_ERROR = "MAILCHIMP_TEST_ERROR";

export const getToken = (code, userId, navigate) => {
	MailchimpService.getToken(code, userId)
		.then(() => {
			notification.success({
				message: "Authenticated",
				description: "Your account is connected with Mailchimp"
			});
		})
		.catch((err) => {
			const message =
				err && err.response && err.response.data && err.response.data.error
					? err.response.data.error
					: "Failed to connect with Mailchimp";
			notification.error({
				message: "Authenticate Error",
				description: message
			});
		})
		.finally(() => {
			navigate(`/console/account/advanced/integrations/mailchimp`);
		});
};

export const testConnection = (userId) => {
	return (dispatch) => {
		dispatch({ type: MAILCHIMP_TEST_REQUEST });
		MailchimpService.testConnection(userId)
			.then(() => {
				dispatch({ type: MAILCHIMP_TEST_SUCCESS });
			})
			.catch((err) => {
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to connect to Mailchimp";
				notification.error({
					message: "Connection Error",
					description: message
				});
				dispatch({ type: MAILCHIMP_TEST_ERROR });
			});
	};
};

export const getAudiences = (userId) => {
	return (dispatch) => {
		dispatch({ type: MAILCHIMP_GET_AUDIENCES_REQUEST });
		MailchimpService.getAudiences(userId)
			.then(() => {
				dispatch({ type: MAILCHIMP_GET_AUDIENCES_SUCCESS });
				updateUser(userId)(dispatch);
			})
			.catch((err) => {
				const message =
					err && err.response && err.response.data && err.response.data.error
						? err.response.data.error
						: "Failed to fetch audiences from Mailchimp";
				notification.error({
					message: "Authenticate Error",
					description: message
				});
				dispatch({ type: MAILCHIMP_GET_AUDIENCES_ERROR });
			});
	};
};
