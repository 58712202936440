import { ContentBlock } from "@/components/AppBlocks";
import Page from "@/components/Page";
import Breadcrumb from "@/components/Breadcrumb";
import { FC, useEffect, useState } from "react";
import { Button } from "@/ui";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { PaymentPlanForm } from "./form";
import { PaymentPlanSettingSchema } from "@hx/console";
import { trpc } from "@/trpc";
import { Flex, message, Modal, Table, Tag, Tooltip } from "@/ui/antd";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { GET_EVENT_SUCCESS } from "@/state/actions/eventActions";

type PaymentPlansPageProps = {
	eventId: string;
};

export const PaymentPlansPage: FC<PaymentPlansPageProps> = ({ eventId }) => {
	const { data: fetchedPaymentPlans, refetch } = trpc.paymentPlans.getAllByEventId.useQuery({ eventId });
	const paymentPlanDeletionProcedure = trpc.paymentPlans.deletePlan.useMutation();
	const dispatch = useAppDispatch();
	const { event } = useAppSelector((state) => state.currentEvent || {});

	const [selectedPlan, setSelectedPlan] = useState<PaymentPlanSettingSchema | null>(null);
	// The modal for naming the plan. Only appears when creating a new plan
	const [isCreateModalOpen, toggleCreateModal] = useState<boolean>(false);
	// The drawer contains the form for creating/editing a plan
	const [isDrawerOpen, toggleDrawer] = useState<boolean>(false);

	useEffect(() => {
		// ---- update event document in redux store with fresh paymentPlansSettings ---
		if (event) {
			dispatch({ type: GET_EVENT_SUCCESS, event: { ...event, paymentPlansSettings: fetchedPaymentPlans } });
		}
	}, [fetchedPaymentPlans]);

	useEffect(() => {
		//hack to refetch data when a new plan is created
		if (!isDrawerOpen) {
			refetch();
		}
	}, [isDrawerOpen]);

	const handleEditClicked = (plan: PaymentPlanSettingSchema) => {
		setSelectedPlan(plan);
		toggleDrawer(true);
	};

	const handleDelete = async (plan: PaymentPlanSettingSchema) => {
		const response = await paymentPlanDeletionProcedure.mutateAsync({ eventId, paymentPlanId: String(plan._id) });
		if (response.success) {
			message.success("Payment plan deleted");
			refetch();
		} else {
			message.error("Could not delete payment plan");
		}
	};

	const handleDeleteClicked = (plan: PaymentPlanSettingSchema) => {
		Modal.confirm({
			type: "confirm",
			title: "Delete plan",
			content: `Do you really wish to delete "${plan.name}"?`,
			onOk: async () => await handleDelete(plan),
			okText: "Yes",
			okType: "danger",
			cancelText: "No"
		});
	};

	return (
		<Page
			pageName="Payment plans"
			breadcrumb={
				<Breadcrumb
					prevPath={`/console/my-events/${eventId}/payments/gateways`}
					prevTitle="Gateways"
					currTitle="Payment plans"
				/>
			}
		>
			<ContentBlock>
				<p style={{ maxWidth: "700px" }}>
					Offer buyers the ability to pay off their order in instalments. Hosts have the flexibility to control deposit
					amounts, instalment frequencies, due dates, cancellation terms etc.
				</p>
				<Button
					iconLeft={<PlusOutlined />}
					onClick={() => {
						setSelectedPlan(null);
						toggleCreateModal(true);
					}}
					variant="tertiary"
				>
					New plan
				</Button>
				<PaymentPlanForm
					uiController={{ isCreateModalOpen, toggleCreateModal, isDrawerOpen, toggleDrawer }}
					initialValues={selectedPlan}
					eventId={eventId}
					eventTimezone={event?.timezone || "Australia/Sydney"}
				/>
				{event?.paymentPlansSettings?.length ? (
					<Table
						dataSource={event.paymentPlansSettings}
						columns={[
							{
								title: "Plan",
								dataIndex: "name",
								key: "plan",
								width: "auto"
							},
							{
								title: "Status",
								dataIndex: "enabled",
								key: "status",
								width: 134,
								render: (enabled: boolean) =>
									enabled ? (
										<Tag color="#DCF2E7" style={{ border: "none", color: "#005B35", borderRadius: 4 }}>
											Active
										</Tag>
									) : (
										<Tag color="#c9c8c8" style={{ border: "none", color: "#454444", borderRadius: 4 }}>
											Inactive
										</Tag>
									)
							},
							{
								dataIndex: "actions",
								key: "actions",
								width: 72,
								render: (_, record: PaymentPlanSettingSchema) => {
									return (
										<Flex>
											<Tooltip title="Edit payment plan">
												<Button
													onClick={() => handleEditClicked(record)}
													iconOnly
													variant="text"
													aria-label="Edit payment plan"
												>
													<EditOutlined />
												</Button>
											</Tooltip>
											<Tooltip title="Delete payment plan">
												<Button
													onClick={() => handleDeleteClicked(record)}
													iconOnly
													variant="text"
													aria-label="Delete payment plan"
												>
													<DeleteOutlined />
												</Button>
											</Tooltip>
										</Flex>
									);
								}
							}
						]}
						rowKey="_id"
						rowClassName={(record) => {
							return record?._id || "";
						}}
						locale={{ emptyText: "No payment plans yet" }}
						scroll={{ x: "max-content" }}
						pagination={false}
						style={{ marginTop: 24 }}
					/>
				) : null}
			</ContentBlock>
		</Page>
	);
};
