import GoogleMap from "react-google-map";
import mapMarker from "@/assets/map-marker.svg";
import { getCountryMapPosition } from "@/utils/Countries";

const Map = ({ googleMaps, eventLocation, currentLocation }) => {
	const countryPosition = getCountryMapPosition(currentLocation);
	const latLng = eventLocation && eventLocation.latLng ? [eventLocation.latLng[0], eventLocation.latLng[1]] : [0, 0];
	let center = {
		...countryPosition
	};
	let markerPos = false;
	if (eventLocation && eventLocation.latLng) {
		markerPos = { lat: latLng[0], lng: latLng[1] };
		center = { lat: latLng[0], lng: latLng[1] };
	}
	const zoom = markerPos ? 16 : countryPosition.zoom;

	const markers = markerPos
		? [
				{
					icon: mapMarker,
					position: markerPos
				}
		  ]
		: [];
	const options = {
		gestureHandling: "cooperative"
	};
	return center && !isNaN(center.lat) ? (
		<div style={{ height: 240 }}>
			<GoogleMap
				googleMaps={googleMaps}
				coordinates={markers}
				center={center}
				zoom={zoom}
				autoFitBounds
				boundsOffset={0.0005}
				options={options}
			/>
		</div>
	) : null;
};
export default Map;
