const Home = () => {
	return [
		{
			label: "Home",
			path: "/console/dashboard",
			key: "home"
		}
	];
};

export default Home;
