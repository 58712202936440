import DatesListDisplay from "@/components/DatesListDisplay";
import SharedIndicator from "@/components/SharedIndicator";
import { H3, P } from "@/components/text/Text";
import { getConfig } from "@/config";
import ArchiveEventModal from "@/events/event/header/ArchiveEventModal";
import DeleteModal from "@/events/event/header/DeleteModal";
import DuplicateModal from "@/events/event/header/DuplicateModal";
import { EventPreviewButton } from "@/events/event/header/EventPreviewButton";
import { EventSharingButton } from "@/events/event/header/EventSharingButton";
import NotifyFollowersModal from "@/events/event/header/NotifyFollowersModal";
import OnlineEventModal from "@/events/event/header/OnlineEventModal";
import { PublishButton } from "@/events/event/header/PublishButton";
import TransferEventModal from "@/events/event/header/TransferEventModal";
import { useViewport } from "@/hooks/useViewport";
import AuthService from "@/services/AuthService";
import {
	hubspotSync,
	openArchiveModal,
	openNotifyFollowersModal,
	openTransferEventModal,
	publishToggle,
	saveEvent
} from "@/state/actions/eventActions";
import { getOrganiser } from "@/state/actions/organiserActions";
import { sendUserBankDetailsEmail, sendUserVerificationEmail } from "@/state/actions/payoutActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Flex } from "@/ui/antd";
import { Button } from "@/ui";
import { Card } from "@/ui";
import { Dropdown } from "@/ui";
import { DropdownItem } from "@/ui";
import { getPublishMessage } from "@/utils/Events";
import styled from "@emotion/styled";
import { DotsThreeVertical } from "@phosphor-icons/react";
import copy from "copy-text-to-clipboard";
import { MouseEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const StyledRow = styled.div`
	flex: 1;
	display: flex;
	justify-content: space-between;
`;

export const EventHeader = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { isMobile } = useViewport();

	const { currentEvent, currentUser, transferEventState, followerCount } = useAppSelector((state) => ({
		currentEvent: state.currentEvent,
		currentUser: state.auth,
		transferEventState: state.currentEvent.transferEventState,
		followerCount: state.organisers.selected.followerCount
	}));

	const [deleteOpen, setDeleteOpen] = useState(false);
	const [duplicateOpen, setDuplicateOpen] = useState(false);

	useEffect(() => {
		if (currentEvent.event?.organiserId) {
			dispatch(getOrganiser(currentEvent.event.organiserId));
		}
	}, [currentEvent.event?.organiserId, dispatch]);

	if (!currentEvent.event) return null;

	const closeDelete = () => setDeleteOpen(false);

	const closeDuplicate = () => setDuplicateOpen(false);

	const isAdmin = AuthService.isAdmin();
	const referralId =
		isAdmin && currentEvent?.event?.referral?.referrerId ? currentEvent.event.referral.referrerId : false;

	const markedAsSoldOutLabel = currentEvent.event.markedAsSoldOut ? "Remove Sold Out" : "Show Sold Out";
	const isHubspotAppEnabled = currentEvent?.event?.metadata?.hubspot?.enabled;

	const handleActionClick = (e: MouseEvent<HTMLButtonElement>, { key }: { key: string }) => {
		switch (key) {
			case "copyUrl":
				copy(`${getConfig("TICKETING_URL")}/${currentEvent.event?.slug}`);
				break;
			case "publish":
				dispatch(publishToggle(currentEvent));
				break;
			case "duplicate":
				setDuplicateOpen(true);
				break;
			case "archive":
				dispatch(openArchiveModal());
				break;
			case "delete":
				setDeleteOpen(true);
				break;
			case "stopSales":
				dispatch(saveEvent(currentEvent.event?._id, { suspendSales: true }));
				break;
			case "resumeSales":
				dispatch(saveEvent(currentEvent.event?._id, { suspendSales: false }));
				break;
			case "markedAsSoldOut":
				dispatch(saveEvent(currentEvent.event?._id, { markedAsSoldOut: !currentEvent.event?.markedAsSoldOut }));
				break;
			case "userVerifyEmail":
				dispatch(sendUserVerificationEmail(currentEvent.event?._id));
				break;
			case "userBankEmail":
				dispatch(sendUserBankDetailsEmail(currentEvent.event?._id));
				break;
			case "transferEvent":
				dispatch(openTransferEventModal(currentEvent.event?._id));
				break;
			case "hubspotSync":
				dispatch(hubspotSync(currentEvent.event?._id));
				break;
			case "notifyfollowers":
				dispatch(openNotifyFollowersModal());
				break;
			case "resetTaxCache":
				dispatch(saveEvent(currentEvent.event?._id, { "usTaxCache.schemaVersion": 0 }));
				break;
			case "viewReferrer":
				navigate(`/console/admin/users/${referralId}`);
				break;
			default:
				break;
		}
	};

	type MenuItem = {
		label: string | JSX.Element;
		key: string;
		href?: string;
		to?: string;
	};

	const menuItems: MenuItem[] = [
		{ label: "Copy URL", key: "copyUrl" },
		isAdmin && {
			key: "viewUser",
			label: "View user",
			to: `/console/admin/users/${currentEvent.event?.userId}`
		},
		isAdmin &&
			currentEvent?.eventUser?.user &&
			!currentEvent.eventUser.user.verified && {
				label: "Send user verification email (Payout)",
				key: "userVerifyEmail"
			},
		isAdmin &&
			currentEvent?.event?.paymentOptions &&
			!currentEvent.event.paymentOptions.userBankAccountId && {
				label: "Send user bank account email (Payout)",
				key: "userBankEmail"
			},
		referralId && {
			label: "View referrer",
			key: "viewReferrer"
		},
		{ label: "Duplicate event", key: "duplicate" },
		{ label: currentEvent.event.isArchived ? "Unarchive event" : "Archive event", key: "archive" },
		!currentEvent.event.suspendSales
			? { label: "Stop sales", key: "stopSales" }
			: { label: "Resume sales", key: "resumeSales" },
		{ label: markedAsSoldOutLabel, key: "markedAsSoldOut" },
		!currentEvent.event.published && { label: <span style={{ color: "#c0212c" }}>Delete event</span>, key: "delete" },
		isAdmin && { label: "Transfer event", key: "transferEvent" },
		isAdmin && currentEvent?.event?.location === "US" && { label: "Reset tax cache", key: "resetTaxCache" },
		isAdmin && {
			key: "viewAuditLogs",
			label: "View audit logs",
			to: `/console/admin/audit-logs?page=1&status=all&query=${currentEvent.event?._id}`
		},
		isHubspotAppEnabled && isAdmin && { label: "HubSpot sync", key: "hubspotSync" },
		currentEvent?.event?.organiserId && followerCount > 0 && { label: "Notify my followers", key: "notifyfollowers" }
	].filter(Boolean);

	const menuItemButtons = (
		<>
			{menuItems.map((item, index) => {
				if (item) {
					return (
						<DropdownItem
							href={item.href ?? undefined}
							key={index}
							onClick={(e) => handleActionClick(e, { key: item.key })}
							to={item.to ?? undefined}
							variant="text"
						>
							{item.label}
						</DropdownItem>
					);
				}
			})}
		</>
	);

	if (!currentEvent.event) {
		return null;
	}

	const eventCurrency = isAdmin ? currentEvent.event.currency : false;
	const publishMsg = getPublishMessage(currentEvent.event);

	return (
		<Card>
			<StyledRow>
				<H3 style={{ fontSize: 20, marginRight: "6px", flex: 1 }}>{currentEvent.event.name}</H3>
				<div>
					<SharedIndicator user={currentUser.user.id} eventOwner={currentEvent.event.userId} sharedObjectName="event" />
					<P>
						{referralId ? (
							<span>
								<Link to={`/console/admin/users/${referralId}`}>Referred event</Link>
								{" | "}
							</span>
						) : null}
						{publishMsg}
					</P>
				</div>
			</StyledRow>
			<Flex justify="space-between" vertical={isMobile} gap="sm">
				{currentEvent.event.timezone && currentEvent.event.location && (
					<div>
						<DatesListDisplay
							dates={currentEvent.event.dates ?? []}
							timezone={currentEvent.event.timezone}
							location={currentEvent.event.location}
							onDateLinkClicked={() => navigate(`/console/my-events/${currentEvent.event?._id}/info/recurring-dates`)}
						/>
						{eventCurrency ? <div>{`Currency: ${eventCurrency}`}</div> : null}
					</div>
				)}
				<Flex align="center" gap="sm">
					<EventPreviewButton />
					<PublishButton />
					<EventSharingButton event={currentEvent.event} />
					<Dropdown items={menuItemButtons} placement="bottomRight">
						<Button iconOnly variant="text">
							<DotsThreeVertical size={18} weight="bold" />
						</Button>
					</Dropdown>
				</Flex>
			</Flex>
			{deleteOpen && <DeleteModal visible={deleteOpen} handleCancel={closeDelete} />}
			<ArchiveEventModal event={currentEvent.event} />
			{duplicateOpen && <DuplicateModal visible={duplicateOpen} handleCancel={closeDuplicate} />}
			{transferEventState.modalOpen && <TransferEventModal />}
			<OnlineEventModal />
			<NotifyFollowersModal />
		</Card>
	);
};
