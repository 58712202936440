import { css } from "@emotion/css";
import { locationDataByCountryCode } from "@hx/locations";
import { Select } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Label } from "../components/text/Text";
import AuthService from "../services/AuthService";
import IconReference from "../services/IconReference";
import { onOverrideLocationSet } from "../state/actions/locationActions";

const currencyLabel = css({
	margin: "8px 8px 0 0",
	"@media(max-width: 700px)": {
		display: "none"
	}
});
const locationOptions = Object.values(locationDataByCountryCode).map((option) => {
	return {
		value: option.location,
		label: option.currency
	};
});

class LocationSelection extends Component {
	componentDidMount() {
		const { locations, defaultLevel, onOverrideLocationSet } = this.props;

		const locationLevel = defaultLevel === "user" ? "userLevelLocation" : "eventLevelLocation";
		if (!locations.overrideLocation) {
			const defaultValue = locations[locationLevel];
			onOverrideLocationSet(defaultValue);
		}
	}

	componentDidUpdate(prevProps) {
		const {
			locations: { overrideLocation },
			onLocationChange
		} = this.props;
		if (onLocationChange && overrideLocation !== prevProps.locations.overrideLocation) {
			onLocationChange(overrideLocation);
		}
	}

	handleLocationChange = (value) => {
		const { onOverrideLocationSet } = this.props;
		onOverrideLocationSet(value);
	};

	render() {
		const { locations, user, overrideLocations } = this.props;
		const locationValue = locations.overrideLocation || user.location;

		const selectionKey = locationValue ? locationValue.split("-")[0] : "";
		let availableOptions;
		if (overrideLocations) {
			availableOptions = overrideLocations;
		} else {
			availableOptions = AuthService.isAdmin()
				? locationOptions
				: locationOptions.filter(({ value }) => user.accessibleLocations[value]);
		}

		if (availableOptions.length <= 1) {
			return null;
		}
		const selectOptions = availableOptions.map(({ label, value }) => {
			const iconName = `${value.toLowerCase()}_flag`;
			const icon = IconReference[iconName];

			const renderedLabel = (
				<div
					style={{
						backgroundImage: `url(${icon}`,
						backgroundSize: "24px auto",
						backgroundPosition: "left center",
						backgroundRepeat: "no-repeat",
						paddingLeft: 30
					}}
				>
					{label}
				</div>
			);

			return {
				label: renderedLabel,
				value
			};
		});

		return (
			<div style={{ display: "flex" }}>
				<div className={currencyLabel}>
					<Label htmlFor="currency">Currency:</Label>
				</div>
				<Select
					id="currency"
					onChange={this.handleLocationChange}
					defaultValue={selectionKey}
					value={locationValue}
					popupMatchSelectWidth={false}
					style={{ width: 180, height: 40 }}
					data-cy="location-selector"
					options={selectOptions}
				/>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		locations: state.locations,
		user: state.auth.user
	}),
	{ onOverrideLocationSet }
)(LocationSelection);
