import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import { BankAccounts } from "@hx/utilities";
import { Col, Progress, Row, Table, Tooltip } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import errorIcon from "@/assets/ic_error.svg";
import successIcon from "@/assets/ic_success-circle.svg";
import { ContentBlock } from "@/components/AppBlocks";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import LocationSelection from "@/components/LocationSelection";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import TableActions from "@/components/table/TableActions";
import { H2 } from "@/components/text/Text";
import AuthService from "@/services/AuthService";
import LocationService from "@/services/LocationService";
import { getUserReferral, managePayment, removePayment } from "@/state/actions/referralActions";
import { getUser } from "@/state/actions/userActions";
import { currency, fullName } from "@/utils/Format";
import DeleteModal from "./DeleteModal";
import PayoutModal from "./PayoutModal";

const TableElementWrapper = ({ record, children }) => {
	const wrapper = css({
		textDecoration: record.removed ? "line-through" : "none"
	});
	return <div className={wrapper}>{children}</div>;
};

/**
 * Referral payout page.
 * Organizers can only see the payment list, but admin users can add payments.
 *
 * @class Payouts
 * @extends {Component}
 */
class Payouts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			showDeleteModal: false,
			payment: null
		};
		this.handlePayment = this.handlePayment.bind(this);
		this.load = this.load.bind(this);
	}

	componentWillMount() {
		this.load();
	}

	load() {
		const { getUser, getUserReferral, currentUser } = this.props;
		const userId = this.props.userId || AuthService.user.id;
		getUserReferral(userId);
		if (!currentUser.user || currentUser.user.id !== userId) {
			getUser(userId);
		}
	}

	handlePayment() {
		const { userId, editForm, managePayment, auth } = this.props;
		const { user } = auth;
		const paymentInfo = editForm;
		paymentInfo.values.date = new HumaniDate(
			undefined,
			"guess",
			LocationService.getLocation(true)
		).formatting.dateTime();
		paymentInfo.values.userName = `${user.firstName} ${user.lastName}`;
		managePayment(userId, paymentInfo.values);
		this.setState({ showModal: false });
	}

	openDeleteModal = (payment) => {
		this.setState({ showDeleteModal: true, payment });
	};

	render() {
		const { showModal, payment, showDeleteModal } = this.state;
		const { userReferrals = {}, userId, currentUser, overrideLocation, removePayment } = this.props;
		const { loading, error, referral } = userReferrals;
		const bankAccount =
			overrideLocation && referral?.bankAccountId && currentUser.user
				? currentUser.user.bankAccounts.find((bankAccount) => {
						return String(bankAccount._id) === String(referral.bankAccountId);
				  })
				: false;

		const user = currentUser && currentUser.user ? currentUser.user : null;
		const image = user?.verified ? successIcon : errorIcon;
		const imageAlt = user?.verified ? "user verified" : "user not verified";
		const userVerifiedIcon = AuthService.isAdmin() ? (
			<Tooltip title={imageAlt}>
				<img src={image} width="24" height="24" style={{ marginLeft: 6 }} alt={imageAlt} />
			</Tooltip>
		) : (
			""
		);
		const userInfo = user ? (
			<div>
				{fullName(user.firstName, user.lastName)}
				{userVerifiedIcon}
				<br />
				{user.email}
				<br />
				{user.mobile}
			</div>
		) : null;
		let paymentSettingsUnavailable = true;
		let paymentInfo = (
			<div>Please set up payment information in the "Payment settings" menu under "Ambassador program"</div>
		);

		if (bankAccount) {
			const bankAccountParts = BankAccounts.getDisplayParts(
				bankAccount.bsb,
				bankAccount.number,
				bankAccount.location,
				bankAccount.name
			);
			paymentInfo = bankAccountParts.map((part, index) => (
				<div key={index}>
					{part}
					<br />
				</div>
			));
			paymentSettingsUnavailable = false;
		} else if (!bankAccount && AuthService.isAdmin()) {
			paymentInfo = <div>User has no payment settings, please contact them to confirm payout settings.</div>;
		}
		const paidOutAmount =
			referral?.payouts?.reduce((acc, payout) => {
				return acc + payout.amount;
			}, 0) || 0;
		const availableForPayout = referral?.validForPayout || 0;

		const remainingAmount = availableForPayout - paidOutAmount;

		const percentage = referral?.amountEarned || 0 > 0 ? Math.floor((paidOutAmount / availableForPayout) * 100) : 0;
		const totalLabel = loading ? null : (
			<div style={{ paddingBottom: 20, width: "100%" }}>
				<div style={{ textAlign: "left", marginTop: 12 }}>
					<table style={{ width: "100%", maxWidth: 360, marginBottom: 20 }}>
						<tbody>
							<tr>
								<th>Total Earned</th>
								<td>{currency(referral?.amountEarned || 0)}</td>
							</tr>
							<tr>
								<th>Total Available for Payout</th>
								<td>{currency(availableForPayout)}</td>
							</tr>
							<tr>
								<th>Total Paid Out</th>
								<td>{currency(paidOutAmount)}</td>
							</tr>
							<tr>
								<th>Total Remaining for Payout</th>
								<td>{currency(remainingAmount)}</td>
							</tr>
						</tbody>
					</table>

					<H2 style={{ paddingBottom: 4 }}>
						Total payments:
						<span style={{ color: "#353337" }}> {currency(paidOutAmount)}</span> /{" "}
						<span style={{ color: "#353337" }}>{currency(availableForPayout)}</span>
					</H2>
					<div className="payout-progress">
						<Progress
							style={{ width: "50%", display: "block", marginBottom: 20 }}
							strokeWidth={8}
							strokeColor="#353337"
							percent={percentage > 100 ? 100 : percentage}
						/>
					</div>
					<H2>
						Remaining: <span style={{ color: "#353337" }}>{currency(remainingAmount)}</span>
					</H2>
				</div>
			</div>
		);

		const transactionId = {
			title: "Transaction Id",
			dataIndex: "transactionId",
			key: "transactionId",
			render: (transactionId, record) => {
				return <TableElementWrapper record={record}>{transactionId}</TableElementWrapper>;
			}
		};

		const transactionAmount = {
			title: "Amount",
			dataIndex: "amount",
			key: "amount",
			render: (amount, record) => {
				return <TableElementWrapper record={record}>{currency(amount)}</TableElementWrapper>;
			}
		};

		const payersName = {
			title: "Paid By",
			dataIndex: "userName",
			key: "userName",
			render: (userName, record) => {
				return <TableElementWrapper record={record}>{userName}</TableElementWrapper>;
			}
		};

		const datePaid = {
			title: "Date Paid",
			dataIndex: "date",
			key: "date",
			render: (date, record) => {
				return (
					<TableElementWrapper record={record}>
						{new HumaniDate(date, "guess", LocationService.getLocation(true)).formatting.dateTime()}
					</TableElementWrapper>
				);
			}
		};

		const notes = {
			title: "Notes",
			dataIndex: "notes",
			key: "notes",
			render: (notes, record) => {
				return <TableElementWrapper record={record}>{notes}</TableElementWrapper>;
			}
		};

		const reversed = {
			title: "Reversed",
			width: 100,
			dataIndex: "reversedAt"
		};

		const togglePayment = {
			title: "Action",
			key: "delete",
			dataIndex: "removed",
			render: (removed, record) => {
				if (removed) {
					return null;
				}
				return (
					<TableActions>
						<IconButton
							icon="delete"
							onClick={() => this.openDeleteModal(record)}
							tooltip="Delete"
							showTooltip
							ariaLabel="Delete"
						/>
					</TableActions>
				);
			}
		};

		let columns = [
			AuthService.isAdmin() ? transactionId : null,
			transactionAmount,
			AuthService.isAdmin() ? payersName : null,
			datePaid,
			AuthService.isAdmin() ? notes : null,
			AuthService.isAdmin() ? reversed : null,
			AuthService.isAdmin() ? togglePayment : null
		];

		columns = columns.filter((value) => value !== null);
		const title = userId ? "" : "Referral Payouts";
		const locationSelector = (
			<LocationSelection componentName="referralDashboard" defaultLevel="user" onLocationChange={this.load} />
		);
		return (
			<>
				{title !== "Referral Payouts" ? locationSelector : null}
				<LoadErrorView loading={loading} error={error} retryAction={() => this.load()}>
					<ContentBlock error={paymentSettingsUnavailable && AuthService.isAdmin()}>
						<PayoutModal
							save={this.handlePayment}
							remainingAmount={remainingAmount}
							visible={showModal}
							payment={payment}
							handleCancel={() => this.setState({ showModal: false })}
						/>
						<DeleteModal
							open={showDeleteModal}
							handleCancel={() => this.setState({ showDeleteModal: false })}
							deleteFunction={removePayment}
							userId={userId}
							payment={payment}
						/>
						<Row>
							<Col xs={24} sm={24} md={12} lg={12} xl={12}>
								{userInfo}
							</Col>
							<Col xs={24} sm={24} md={8} lg={8} xl={8}>
								{paymentInfo}
							</Col>
						</Row>
						{totalLabel}
						<H2>Payments</H2>
						<div style={{ minWidth: "100%", overflowX: "auto" }}>
							<Table
								columns={columns}
								pagination={false}
								scroll={{ x: 800 }}
								dataSource={referral?.payouts}
								rowKey="_id"
							/>
						</div>

						{AuthService.isAdmin() ? (
							<ButtonsBar>
								<LegacyButton
									style={{ marginTop: 12 }}
									type="action"
									onClick={() => this.setState({ showModal: true, payment: null })}
									icon={{ name: "plus", left: true }}
									ariaLabel="Add Payment"
									disabled={paymentSettingsUnavailable}
								>
									Add Payment
								</LegacyButton>
							</ButtonsBar>
						) : null}
					</ContentBlock>
				</LoadErrorView>
			</>
		);
	}
}

export default connect(
	(state) => ({
		overrideLocation: state.locations.overrideLocation,
		userReferrals: state.userReferrals,
		currentUser: state.currentUser,
		editForm: state.form.addPayment,
		payments: state.payments,
		salesByDate: state.eventMetrics.salesByDate,
		auth: state.auth
	}),
	{
		getUser,
		getUserReferral,
		managePayment,
		removePayment
	}
)(Payouts);
