import icon from "@/assets/menu/ic_reports.svg";

const Reports = () => {
	const children = [
		{ path: "/console/reports/attendee-details", label: "Attendee details", key: "Attendee details" },
		{ path: "/console/reports/orders", label: "Orders", key: "Orders" },
		{ path: "/console/reports/events", label: "Event summary", key: "Event summary" },
		{
			path: "/console/reports/earnings-by-ticket-type",
			label: "Earnings by ticket type",
			key: "Earnings by ticket type"
		},
		{ path: "/console/reports/discounts", label: "Discounts", key: "Discounts" },
		{ path: "/console/reports/access-codes", label: "Access codes", key: "Access codes" },
		{ path: "/console/reports/top-purchasers", label: "Top purchasers", key: "Top purchasers" },
		{ path: "/console/reports/payouts", label: "Payouts", key: "Payouts" },
		{
			path: "/console/reports/event-sales-update",
			label: "Event sales update",
			key: "Event sales update"
		},
		{ path: "/console/reports/guest-list", label: "Guest list", key: "Guest list" },
		{ path: "/console/reports/paid-by-invoice", label: "Paid by invoice", key: "Paid by invoice" },
		{ path: "/console/reports/gift-cards", label: "Gift cards", key: "Gift cards" },
		{ path: "/console/reports/add-ons", label: "Add-ons", key: "Add-ons" },
		{ path: "/console/reports/additional-donations", label: "Additional donations", key: "Additional donations" }
	];

	return [
		{
			label: "Reports",
			key: "reports",
			path: "/console/reports",
			cols: 3,
			children,
			icon
		}
	];
};

export default Reports;
