import { css } from "@emotion/css";
import { Col, DatePicker, Row, Select } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import {
	getDashboardOverview,
	updateDashboardChannel,
	updateDashboardCustomDateRange,
	updateDashboardCustomDateRangePicked,
	updateDashboardPeriod
} from "../../state/actions/metricActions";
import { currency } from "../../utils/Format";
import { LoadingSpinner } from "../AppBlocks";
import IconButton from "../IconButton";
import { H2, P } from "../text/Text";
import Widget from "./Widget";

const RangePicker = DatePicker.RangePicker;

class OverviewTable extends Component {
	constructor(props) {
		super(props);
		this.onDatePickerChange = this.onDatePickerChange.bind(this);
	}

	componentDidMount() {
		const { metrics, getDashboardOverview } = this.props;

		// This is to avoid getting the dashboard metrics twice since onLocationChange always triggers on refresh, temporary
		// solution
		if (metrics?.overview?.totalEarnings === undefined) {
			getDashboardOverview(metrics.period, metrics.channel);
		}
	}

	onDatePickerChange(period) {
		const { getDashboardOverview, updateDashboardCustomDateRange, metrics } = this.props;

		getDashboardOverview(period, metrics.channel);
		updateDashboardCustomDateRange(period);
	}

	handleChannelChange = (channel) => {
		const { getDashboardOverview, updateDashboardChannel, metrics } = this.props;

		getDashboardOverview(metrics.period, channel);
		updateDashboardChannel(channel);
	};

	handlePeriodChange = (period) => {
		const { getDashboardOverview, updateDashboardPeriod, updateDashboardCustomDateRangePicked, metrics } = this.props;

		const isCustomRangePicked = period === "customDate";
		updateDashboardPeriod(period);
		updateDashboardCustomDateRangePicked(isCustomRangePicked);

		if (!isCustomRangePicked) {
			getDashboardOverview(period, metrics.channel);
		}
	};

	render() {
		const { metricState, title, location, metrics } = this.props;
		const { overview, loading } = metrics.overviewByDate || {};
		const {
			ticketsSold,
			ticketsCancelled,
			ticketOrders,
			ticketSales,
			addOnSales,
			additionalDonations,
			feeRebates,
			absorbedFees,
			absorbedHumanitixFees,
			absorbedZipFees,
			absorbedAfterpayFees,
			refunds,
			salesTaxHeld,
			totalEarnings
		} = overview || {};

		const labelStyle = css({
			display: "flex",
			justifyContent: "space-between",
			marginBottom: 16,
			h2: {
				display: "inline",
				"&:nth-child(2)": {
					color: "#353337"
				}
			}
		});

		const headerWrapper = css({
			display: "flex",
			flexDirection: "column"
		});

		const earningsFilterWrapper = css({
			display: "flex",
			justifyContent: "flex-end",
			flexWrap: "wrap",
			gap: 8
		});

		const rowBorderStyle = "solid 1px #dedede";

		const netSaleTitle = (
			<div className={headerWrapper}>
				<div className={labelStyle}>
					<div>
						<H2>Your earnings: </H2>
						<H2>{currency(totalEarnings || 0)}</H2>
					</div>
					<div className={earningsFilterWrapper}>
						<Select
							size="default"
							style={{ fontSize: 14, width: 140 }}
							value={metrics.channel}
							onChange={(value) => this.handleChannelChange(value)}
							options={[
								{ label: "All channels", value: "all" },
								{ label: "Online sales", value: "online" },
								{ label: "Manual sale", value: "manual" },
								{ label: "Box Office", value: "boxOffice" }
							]}
						/>
						<Select
							size="default"
							style={{ fontSize: 14, width: 140 }}
							value={metrics.period}
							onChange={(value) => this.handlePeriodChange(value)}
							options={[
								{ label: "Today", value: "today" },
								{ label: "Yesterday", value: "yesterday" },
								{ label: "Last 7 days", value: "week" },
								{ label: "Last 30 days", value: "month" },
								{ label: "Custom Date", value: "customDate" }
							]}
						/>
					</div>
				</div>
				<div>
					{metrics.isCustomRangePicked ? (
						<RangePicker
							onChange={this.onDatePickerChange}
							value={metrics.customDateRange}
							style={{ marginBottom: 10, paddingBottom: 10 }}
						/>
					) : null}
				</div>
			</div>
		);

		return (
			<Widget
				title={title}
				customTitle={netSaleTitle}
				metricState={metricState}
				{...this.props}
				style={{ minHeight: 393 }}
			>
				{loading ? (
					<LoadingSpinner size={30} />
				) : (
					<div style={{ height: "100%" }}>
						<Row gutter={8} style={{ marginBottom: 16 }} key="3boxes">
							<Col className="headerColBackground" span={8} key="tickets">
								<div>
									<P>Tickets</P>
									<P
										style={{
											marginTop: "2px",
											fontWeight: 600,
											fontSize: "18px"
										}}
									>
										{ticketsSold}
									</P>
								</div>
							</Col>
							<Col className="headerColBackground" span={8} key="orders">
								<div>
									<P>Orders</P>
									<P
										style={{
											marginTop: "2px",
											fontWeight: 600,
											fontSize: "18px"
										}}
									>
										{ticketOrders}
									</P>
								</div>
							</Col>
							<Col className="headerColBackground" span={8} key="cancelled">
								<div>
									<P>Cancelled</P>
									<P
										style={{
											marginTop: "2px",
											fontWeight: 600,
											fontSize: "18px"
										}}
									>
										{ticketsCancelled}
									</P>
								</div>
							</Col>
						</Row>
						<Row className="tableRowBackground">
							<Col span={12}>
								<P style={{ textAlign: "left" }}>Ticket sales{location === "US" ? " (incl. sales tax)" : ""}</P>
							</Col>
							<Col span={12}>
								<P style={{ textAlign: "right", fontWeight: 600 }}>{currency(ticketSales || 0)}</P>
							</Col>
						</Row>
						{location !== "US" ? (
							<Row className="tableRowBackground">
								<Col span={12}>
									<P style={{ textAlign: "left" }}>Add-on sales</P>
								</Col>
								<Col span={12}>
									<P style={{ textAlign: "right", fontWeight: 600 }}>{currency(addOnSales || 0)}</P>
								</Col>
							</Row>
						) : null}
						<Row className="tableRowBackground">
							<Col span={12}>
								<P style={{ textAlign: "left" }}>Additional donations</P>
							</Col>
							<Col span={12}>
								<P style={{ textAlign: "right", fontWeight: 600 }}>{currency(additionalDonations || 0)}</P>
							</Col>
						</Row>
						{feeRebates ? (
							<Row className="tableRowBackground">
								<Col span={12}>
									<P style={{ textAlign: "left" }}>Fee rebates</P>
								</Col>
								<Col span={12}>
									<P style={{ textAlign: "right", fontWeight: 600 }}>{currency(feeRebates || 0)}</P>
								</Col>
							</Row>
						) : null}
						<Row className="tableRowBackground" style={{ borderTop: rowBorderStyle }}>
							<Col span={12}>
								{absorbedZipFees || absorbedAfterpayFees ? (
									<P>
										Absorbed fees{" "}
										<IconButton
											icon="info_hover"
											tooltip={
												<div>
													<b>Fee breakdown:</b>
													<br />
													<b>Humanitix fees:</b> {currency(absorbedHumanitixFees || 0)}
													<br />
													<b>Zip fees:</b> {currency(absorbedZipFees || 0)}
													<br />
													<b>Afterpay fees:</b> {currency(absorbedAfterpayFees || 0)}
													<br />
												</div>
											}
											showTooltip
											small
											ariaLabel="Fee breakdown"
										/>
									</P>
								) : (
									<P>Absorbed fees</P>
								)}
							</Col>
							<Col span={12}>
								<P>
									<b>{currency(absorbedFees || 0)}</b>
								</P>
							</Col>
						</Row>
						<Row className="tableRowBackground">
							<Col span={12}>
								<P style={{ textAlign: "left" }}>Refunds</P>
							</Col>
							<Col span={12}>
								<P style={{ textAlign: "right", fontWeight: 600 }}>{currency(refunds || 0)}</P>
							</Col>
						</Row>
						{salesTaxHeld ? (
							<Row className="tableRowBackground">
								<Col span={12}>
									<P style={{ textAlign: "left" }}>Sales Tax Held</P>
								</Col>
								<Col span={12}>
									<P style={{ textAlign: "right", fontWeight: 600 }}>{currency(salesTaxHeld || 0)}</P>
								</Col>
							</Row>
						) : null}
						<Row className="tableRowBackground" style={{ borderTop: rowBorderStyle, borderBottom: rowBorderStyle }}>
							<Col span={12}>
								<P style={{ textAlign: "left", fontWeight: 600 }}>Total earnings</P>
							</Col>
							<Col span={12}>
								<P style={{ textAlign: "right", fontWeight: 600 }}>{currency(totalEarnings || 0)}</P>
							</Col>
						</Row>
					</div>
				)}
			</Widget>
		);
	}
}

export default connect(
	(state, ownProps) => ({
		metrics: state[ownProps.metricState],
		search: state.search,
		location: state.locations.overrideLocation
	}),
	{
		getDashboardOverview,
		updateDashboardPeriod,
		updateDashboardChannel,
		updateDashboardCustomDateRange,
		updateDashboardCustomDateRangePicked
	}
)(OverviewTable);
