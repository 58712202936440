import { withRouterHooks } from "@/hoc/withRouterHooks";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Modal, notification } from "@/ui/antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router";
import { Link } from "react-router-dom";
import Payout from "../account/referralProgram/Payout";
import LoadErrorView from "../components/LoadErrorView";
import MenuLayout from "../components/MenuLayout";
import Page from "../components/Page";
import PageActions from "../components/PageActions";
import UserEvents from "../components/UserEvents";
import { H1 } from "../components/text/Text";
import Organisers from "../organisers/Organisers";
import AuthService from "../services/AuthService";
import {
	clearUser,
	disableUser,
	enableUser,
	forgotPassword,
	getUser,
	manuallyVerifyEmail,
	openTransferAccount
} from "../state/actions/userActions";
import DefaultGateways from "../users/DefaultGateways";
import AdminPermissions from "./AdminPermissions";
import Authorization from "../authorization/Authorization";
import { BankAccountsList } from "./BankAccountsList";
import { DefaultSettings } from "./DefaultSettings";
import FeesForm from "./FeesForm";
import ReferralSettings from "./ReferralSettings";
import TransferAccountModal from "./TransferAccountModal";
import User from "./User";
import UserDisableModal from "./UserDisableModal";
import UserMenu from "./UserMenu";
import getUserMenu from "./UserMenuItems";
import SelfServePayoutSettings from "./selfServePayouts/SelfServePayoutSettings";

const { confirm } = Modal;

const UserContainer = (props) => {
	const {
		getUser,
		clearUser,
		params,
		navigate,
		forgotPassword,
		currentUser = {},
		disableUser,
		enableUser,
		openTransferAccount,
		manuallyVerifyEmail
	} = props;
	const { user } = currentUser;

	const [disableModalOpen, toggleDisableModal] = useState(false);

	useEffect(() => {
		load();
		//https://www.ibrahima-ndaw.com/blog/replace-component-lifecycle-with-useEffect/
		return () => {
			clearUser();
		};
	}, []);

	const load = () => {
		const userId = params?.userId;
		getUser(userId);
	};

	const confirmResendEmail = () => {
		confirm({
			content: "Are you sure you want to send a forgotten password email?",
			onOk() {
				forgotPassword(currentUser.user.email);
			}
		});
	};

	const actionClicked = (key) => {
		switch (key) {
			case "forgot-password":
				confirmResendEmail();
				break;
			case "assume-identity":
				assumeIdentity();
				break;
			case "manually-verify-email":
				manuallyVerifyEmailAction();
				break;
			case "open-disable-enable-modal":
				toggleDisableModal(true);
				break;
			case "transfer-account":
				openTransferAccount();
				break;
			default:
				break;
		}
	};

	const assumeIdentity = () => {
		AuthService.signOut();
		AuthService.assumeIdentity(user.id);
		/**
		 * redirect to login screen to ensure that
		 * all redux form data is set for the user of choice
		 */
		navigate("/signin");
		notification.success({
			message: "Success",
			description: "Please re-sign in with your admin account to assume the user identity"
		});
	};

	const manuallyVerifyEmailAction = () => {
		manuallyVerifyEmail(currentUser.user.id, navigate);
	};

	const userId = params?.userId;
	const userMenuItems = getUserMenu(userId);
	const userFirstName = user && user.firstName ? user.firstName : "";
	const userLastName = user && user.lastName ? user.lastName : "";
	const userLocation = user?.location || "AU";

	let menuItems = [];

	if (currentUser?.user) {
		const isUser = currentUser.user.role === "user";
		menuItems = [
			...(isUser
				? [
						{ key: "assume-identity", label: "Assume Identity" },
						{ key: "transfer-account", label: "Transfer Account" }
				  ]
				: []),
			...(!currentUser.user.emailVerified
				? [
						{
							key: "manually-verify-email",
							label: " Manually Verify Email"
						}
				  ]
				: []),
			{
				key: "forgot-password",
				label: "Forgot password"
			},
			{
				key: "open-disable-enable-modal",
				label: currentUser.user.firebaseEnabled ? "Disable user" : "Enable user"
			},
			{
				key: "view-audit-logs",
				label: <Link to={`/console/admin/audit-logs?page=1&status=all&query=${user._id}`}>View audit logs</Link>
			}
		];
	}

	return (
		<LoadErrorView loading={currentUser.loading} error={currentUser.error} retryAction={load}>
			<TransferAccountModal />
			<UserDisableModal
				open={disableModalOpen}
				toggle={toggleDisableModal}
				currentUser={currentUser}
				enableUser={enableUser}
				disableUser={disableUser}
			/>
			<MenuLayout>
				<UserMenu title="" menuItems={userMenuItems} />

				<Page type="Account">
					<div style={{ width: "100%", maxWidth: 1000, margin: "0 auto" }}>
						<PageActions>
							<div style={{ float: "right" }}>
								<Dropdown menu={{ items: menuItems, onClick: ({ key }) => actionClicked(key) }} trigger={["click"]}>
									<Button size="large" className="ant-dropdown-link" aria-label="Actions">
										Actions
										<DownOutlined style={{ marginLeft: 4 }} />
									</Button>
								</Dropdown>
							</div>
						</PageActions>
						<H1>
							{userFirstName} {userLastName}
						</H1>
					</div>

					<div style={{ width: "100%", maxWidth: 1000, margin: "0 auto" }}>
						<div style={{ height: "100%" }}>
							<Routes>
								<Route path="" element={<User userId={userId} />} />
								<Route path="fees" element={<FeesForm userId={userId} />} />
								<Route path="default-settings" element={<DefaultSettings userId={userId} />} />
								<Route path="default-gateways" element={<DefaultGateways userId={userId} />} />
								<Route path="bank-accounts" element={<BankAccountsList userId={userId} />} />
								<Route path="user-events" element={<UserEvents userId={userId} />} />
								<Route path="user-hosts" element={<Organisers userId={userId} adminView={false} />} />
								<Route path="referral-payout" element={<Payout userId={userId} />} />
								<Route path="referral-settings" element={<ReferralSettings userId={userId} />} />
								<Route path="admin-permissions" element={<AdminPermissions userId={userId} />} />
								<Route
									path="self-serve-payout-settings"
									element={<SelfServePayoutSettings userId={userId} level="user" />}
								/>
								<Route
									path="permissions"
									element={user ? <Authorization isAdmin={true} userId={userId} userLocation={userLocation} /> : null}
								/>
							</Routes>
						</div>
					</div>
				</Page>
			</MenuLayout>
		</LoadErrorView>
	);
};

export default connect(
	(state) => ({
		currentUser: state.currentUser,
		enableReinitialize: true
	}),
	{
		getUser,
		clearUser,
		forgotPassword,
		manuallyVerifyEmail,
		disableUser,
		enableUser,
		openTransferAccount
	}
)(withRouterHooks(UserContainer));
