import { ContentBlock } from "@/components/AppBlocks";
import IconButton from "@/components/IconButton";
import H2 from "@/components/text/H2";
import AuthService from "@/services/AuthService";
import { ReverseData, deletePayment } from "@/state/actions/newPayoutActions";
import { PaymentsWithDisplayDate } from "@/state/reducers/newPayoutReducer";
import { ReduxState } from "@/state/store";
import { currency } from "@/utils/Format";
import { css } from "@emotion/css";
import { HumaniDate } from "@hx/dates";
import { Dropdown, Popconfirm, Table } from "@/ui/antd";
import { ColumnType } from "antd/lib/table";
import { useState } from "react";
import { connect } from "react-redux";
import AdjustmentModal from "../modals/AdjustmentModal";
import CreditModal from "../modals/CreditModal";

const strikethroughClass = css`
	text-decoration: line-through;
`;
const columns = (
	onModalOpen: (paymentId: string, modalName: string) => void,
	deletePayment: (eventId: string, paymentId: string, data: ReverseData) => void,
	eventId: string
) => [
	{
		title: "Event date",
		key: "eventDate",
		dataIndex: "eventDate",
		fixed: "left"
	},
	{
		title: "Type",
		key: "adjustment",
		dataIndex: "adjustment",
		render: (value: boolean) => (value ? "Adjustment" : "Credit")
	},
	{
		title: "Transaction id",
		key: "transactionId",
		dataIndex: "transactionId",
		admin: true
	},
	{
		title: "Amount",
		key: "amount",
		dataIndex: "amount",
		render: (value: number) => currency(value)
	},
	{
		title: "Created by",
		key: "userName",
		dataIndex: "userName",
		admin: true
	},
	{
		title: "Date",
		key: "date",
		dataIndex: "createdAt",
		render: (value: string) => new HumaniDate(value, "guess").formatting.dateTime()
	},
	{
		title: "Notes",
		key: "notes",
		dataIndex: "notes",
		admin: true
	},
	{
		title: "Actions",
		key: "actions",
		fixed: "right",
		render: (payment: PaymentsWithDisplayDate) => {
			if (payment.removed) return null;
			return (
				<Dropdown
					menu={{
						items: [
							{
								key: `delete-${payment._id}`,
								label: (
									<Popconfirm
										title={`Are you sure you want to delete this ${payment.adjustment ? "adjustment" : "credit"}?`}
										onConfirm={() => deletePayment(eventId, payment._id, { reversed: false })}
										okText="Yes"
										cancelText="No"
									>
										Delete
									</Popconfirm>
								)
							},
							{
								key: `edit-${payment._id}`,
								label: (
									<div
										onClick={() =>
											payment.adjustment ? onModalOpen(payment._id, "adjustment") : onModalOpen(payment._id, "credit")
										}
									>
										Edit
									</div>
								)
							}
						]
					}}
					trigger={["click"]}
				>
					<IconButton icon="see_more" ariaLabel="actions" dataCy={`credit-or-adjustment-actions-${payment._id}`} />
				</Dropdown>
			);
		},
		admin: true
	}
];

const CreditsOrAdjustments = ({
	creditsOrAdjustments,
	deletePayment,
	eventId
}: IInjectedFunctions & IInjectedProps) => {
	const isAdmin = AuthService.isAdmin();
	const [focusedId, setFocusedId] = useState<string | null>(null);
	const [openedModelName, setOpenModelName] = useState("none");
	const onModalClose = () => {
		setFocusedId(null);
		setOpenModelName("none");
	};
	const onModalOpen = (focusedId: string, modalName: string) => {
		setFocusedId(focusedId);
		setOpenModelName(modalName);
	};

	if (!eventId) return null;

	return (
		<>
			<CreditModal
				isOpen={openedModelName === "credit"}
				close={onModalClose}
				isEdit={true}
				creditId={focusedId}
				focusedDate={null}
			/>
			<AdjustmentModal
				isOpen={openedModelName === "adjustment"}
				close={onModalClose}
				isEdit={true}
				adjustmentId={focusedId}
			/>
			<ContentBlock>
				<H2>Credits / Adjustments</H2>
				<Table
					rowClassName={(row) => (row.removed ? strikethroughClass : "")}
					dataSource={creditsOrAdjustments?.sort((a, b) =>
						new HumaniDate(a.createdAt).toDate() > new HumaniDate(b.createdAt).toDate() ? -1 : 1
					)}
					columns={
						columns(onModalOpen, deletePayment, eventId).filter(
							(col) => isAdmin || !col.admin
						) as ColumnType<PaymentsWithDisplayDate>[]
					}
					scroll={{ x: "max-content" }}
				/>
			</ContentBlock>
		</>
	);
};

interface IInjectedProps {
	creditsOrAdjustments?: PaymentsWithDisplayDate[];
	eventId?: string;
}

interface IInjectedFunctions {
	deletePayment: (eventId: string, paymentId: string, data: ReverseData) => void;
}

export default connect(
	(state: ReduxState) => {
		const creditsOrAdjustments = state.payouts.eventPayout.payoutInformation?.creditsOrAdjustments;
		return {
			creditsOrAdjustments,
			eventId: state.payouts.eventPayout.payoutInformation?.header.eventId
		};
	},
	{ deletePayment }
)(CreditsOrAdjustments);
