import { Checkbox, Empty, Table } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import emptyIcon from "@/assets/ic_empty.svg";
import LoadErrorView from "@/components/LoadErrorView";
import { P } from "@/components/text/Text";
import { pricePreview } from "@/state/actions/eventActions";
import { currency } from "@/utils/Format";

class PreviewPrice extends Component {
	componentDidMount() {
		this.loadPreview();
	}

	loadPreview = () => {
		const { pricePreview, currentEvent } = this.props;
		const { event = {} } = currentEvent;
		pricePreview(event._id);
	};

	render() {
		const { currentEvent, packagedTickets = false } = this.props;
		const { pricePreviewState = {}, event } = currentEvent;
		const { loading = true, error = false, tableData } = pricePreviewState;

		const data = packagedTickets
			? tableData?.data?.filter((row) => row.isPackage)
			: tableData?.data?.filter((row) => !row.isPackage);

		const columns = [
			{
				title: packagedTickets ? "Package" : "Ticket type",
				dataIndex: "name",
				key: "name",
				render: (value, record) => `${value}${record.isDonation ? " (donation)" : ""}`
			},
			{
				title: packagedTickets ? "Package price" : "Ticket price",
				dataIndex: "price",
				key: "price",
				render: (value) => currency(value)
			},
			...(tableData?.columns?.passedOnFee
				? [
						{
							title: "Fees (passed on)",
							dataIndex: "passedOnFee",
							key: "passedOnFee",
							render: (value) => currency(value)
						}
				  ]
				: []),
			...(tableData?.columns?.absorbedFee
				? [
						{
							title: "Fees (absorbed)",
							dataIndex: "absorbedFee",
							key: "absorbedFee",
							render: (value) => currency(value)
						}
				  ]
				: []),
			...(tableData?.columns?.absorbedFee
				? [
						{
							title: "Tax",
							dataIndex: "tax",
							key: "tax",
							render: (value) => currency(value)
						}
				  ]
				: []),
			...(tableData?.columns?.passedOnCustomTax
				? [
						{
							title: "Custom tax (passed on)",
							dataIndex: "passedOnCustomTax",
							key: "passedOnCustomTax",
							render: (value) => currency(value)
						}
				  ]
				: []),
			...(tableData?.columns?.absorbedCustomTax
				? [
						{
							title: "Custom tax (absorbed)",
							dataIndex: "absorbedCustomTax",
							key: "absorbedCustomTax",
							render: (value) => currency(value)
						}
				  ]
				: []),
			{
				title: "Earnings",
				dataIndex: "earnings",
				key: "earnings",
				render: (value) => currency(value)
			},
			{
				title: "Buyer total",
				dataIndex: "total",
				key: "total",
				render: (value) => <b>{currency(value)}</b>
			}
		];
		return (
			<>
				<LoadErrorView loading={loading} error={error}>
					<P>
						These prices are what your ticket buyers will see at checkout. They are calculated based on the applied fee
						structure. You can customise whether to pass on or absorb fees in “Payments and Fees” once your event has
						been saved as a draft.
					</P>
					{event.paymentOptions &&
					event.paymentOptions.feeChargeSettings &&
					event.paymentOptions.feeChargeSettings.type === "tip" ? (
						<P>
							<Checkbox
								onChange={(e) => {
									const checked = e.target.checked;
									this.loadPreview(checked);
								}}
							>
								Show prices with fees being tipped
							</Checkbox>{" "}
						</P>
					) : null}
					{tableData?.data?.length ? (
						<Table
							pagination={false}
							columns={columns}
							dataSource={data.filter((row) => !row.isDonation).concat(data.filter((row) => row.isDonation))}
							rowKey="key"
							rowClassName={(record) => {
								return record.isDonation ? "highlight-row" : "";
							}}
						/>
					) : (
						<div>
							<Empty image={emptyIcon} description={<b>No tickets have been created yet.</b>} />
						</div>
					)}
				</LoadErrorView>
			</>
		);
	}
}

export default connect(
	(state) => {
		return {
			currentEvent: state.currentEvent,
			auth: state.auth,
			newEvent: state && state.form && state.form.newEvent ? state.form.newEvent.values : {}
		};
	},
	{
		pricePreview
	}
)(PreviewPrice);
