import { ContentBlock } from "@/components/AppBlocks";
import { Artists } from "@/components/Artists";
import { KeywordsField, LegacyInputField, RadioField, SelectField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import H2 from "@/components/text/H2";
import P from "@/components/text/P";
import { getConfig } from "@/config";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import withSaveCheck from "@/hoc/withSaveCheck";
import { dayjs } from "@/libs/dayjs";
import IconReference from "@/services/IconReference";
import { getSharedAccounts } from "@/state/actions/authorizationActions";
import { createEvent, updateEventBasics } from "@/state/actions/eventActions";
import { Alert } from "@/ui/antd";
import { maxArrayLength, required } from "@/utils/Validators";
import { checkCategoryMayHaveArtists } from "@hx/event-classification";
import { Currency, locationDataByCountryCode } from "@hx/locations";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import { wrapperMenu, wrapperNoMenu } from "../utils/commonStyles";
import { checkInWizard } from "../utils/eventCreationUtils";
import Classification from "./Classification";
import DateRows from "./DateRows";
import Location from "./Location";
import OwnershipModal from "./OwnershipModal";

const formName = "basics";

let optionsWithStyles = Object.values(locationDataByCountryCode).map((option) => {
	const currency = option.currency;
	const iconName = `${option.location.toLowerCase()}_flag`;
	const icon = IconReference[iconName];
	const label = (
		<div
			style={{
				backgroundImage: `url(${icon})`,
				backgroundPosition: "left center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "24px auto",
				paddingLeft: 30
			}}
		>
			{currency}
		</div>
	);
	return { value: option.location, label, dataCy: currency };
});

const initDates = [
	{
		startDate: dayjs().set("hours", 19).set("minutes", 0).set("seconds", 0).add(7, "days").toISOString(),
		endDate: dayjs().set("hours", 23).set("minutes", 0).set("seconds", 0).add(7, "days").toISOString()
	}
];

const nameRequired = required("A name is required");
const maxKeywordsLength = maxArrayLength(10);

function Basics(props) {
	const {
		touch,
		isNewEvent,
		event,
		dispatch,
		change,
		handleSubmit,
		createEvent,
		eventRecurring,
		updateEventBasics,
		loading,
		eventLocation,
		timezone,
		getSharedAccounts,
		sharedAccounts,
		createFor,
		dates,
		classification,
		user,
		artists,
		isPublic
	} = props;
	// JEN-12857 filter out MYR if it is not already accessible to the user
	optionsWithStyles = optionsWithStyles.filter((option) => {
		if (locationDataByCountryCode[option.value].currency === Currency.MYR) {
			return user?.accessibleLocations[option.value];
		}
		return true;
	});

	const { _id, location, currency } = event ?? {};
	const inWizard = checkInWizard(event);

	useEffect(() => {
		getSharedAccounts();
	}, []);

	useEffect(() => {
		if (sharedAccounts.length > 0 && isNewEvent && !ownerShipModalOpened) {
			toggleShowOwnerShipModal(true);
			toggleOwnerShipModalOpened(true);
		}
	}, [sharedAccounts]);

	const [showOwnerShipModal, toggleShowOwnerShipModal] = useState(false);
	const [ownerShipModalOpened, toggleOwnerShipModalOpened] = useState(false);

	const usersToCreateFor = sharedAccounts?.map((account) => ({
		value: account.id,
		label: `${account.firstName} ${account.lastName}`
	}));

	const changeFieldValue = (field, value) => {
		dispatch(change(field, value));
	};

	const save = (values) => {
		if (isNewEvent && !_id) {
			createEvent(values);
		} else {
			updateEventBasics(_id, values);
		}
	};

	return (
		<form onSubmit={handleSubmit(save)}>
			<div className={inWizard ? wrapperNoMenu : wrapperMenu}>
				<LoadErrorView loading={loading}>
					<OwnershipModal
						createFor={createFor}
						isOpen={showOwnerShipModal}
						closeFunction={() => toggleShowOwnerShipModal(false)}
						usersToCreateFor={usersToCreateFor}
					/>
					<ContentBlock>
						<H2>Event basics</H2>
						<P>First let’s start with some basics to create a draft</P>
						<Field
							name="name"
							label="Name"
							autocomplete="nope"
							description="What is your event called?"
							component={LegacyInputField}
							validate={nameRequired}
							required
						/>

						<hr />
						<H2>Date &amp; time</H2>
						<FieldArray
							name="dates"
							dates={dates ?? []}
							component={DateRows}
							touch={touch}
							changeFieldValue={changeFieldValue}
							timezone={timezone}
							location={location}
							eventRecurring={eventRecurring}
							newEvent={isNewEvent}
						/>
						<hr />
						<Location
							eventLocation={eventLocation}
							changeFieldValue={changeFieldValue}
							currentLocation={location}
							currency={currency}
						/>

						<hr />
						<H2>Privacy</H2>
						<Field
							name="public"
							description={
								<>
									Your event <b>will {isPublic ? "" : "not "}be listed</b> on{" "}
									<a href={getConfig("WEBSITE_URL")}>Humanitix.com</a> and your embedded widgets.
								</>
							}
							component={RadioField}
							button
							options={[
								{
									label: "Public",
									value: true
								},
								{
									label: "Private",
									value: false
								}
							]}
						/>

						{isPublic && (
							<>
								<hr />
								<H2>Discoverability</H2>
								<P>Help people find your event on the Humanitix marketplace.</P>
								<Classification classification={classification} changeFieldValue={changeFieldValue} />

								<FieldArray
									name="keywords"
									label="Keywords"
									description="Get found by people searching for these terms"
									component={KeywordsField}
									validate={maxKeywordsLength}
									format={(value) => value.trim().toLowerCase()}
									changeFieldValue={changeFieldValue}
								/>

								{classification?.category && checkCategoryMayHaveArtists(classification.category) && (
									<>
										<hr />
										<H2>Artists</H2>
										<FieldArray
											selectedArtists={artists}
											name="artists"
											label="Tag artists performing at this event"
											component={Artists}
										/>
										<Alert
											style={{ margin: "12px 0" }}
											message={
												<>
													Only tag artists who are physically performing at your event.
													<ul>
														<li>Do not tag artists who are represented by tribute acts.</li>
														<li>
															Do not tag artists whose music is being played at your event, but who are not physically
															present.
														</li>
													</ul>
												</>
											}
											type="info"
										/>
									</>
								)}
							</>
						)}
						<hr />
						<H2>Currency</H2>
						<Field
							name="location"
							component={SelectField}
							style={{ maxWidth: 300, width: "100%" }}
							options={optionsWithStyles}
						/>
					</ContentBlock>
					<FormBar>
						<LegacyButton
							htmlType="submit"
							style={{
								float: "right"
							}}
							type="primary"
							ariaLabel="Save"
							dataCy="next-step"
						>
							Save &#38; continue
						</LegacyButton>
					</FormBar>
				</LoadErrorView>
			</div>
		</form>
	);
}
const selector = formValueSelector(formName);

// Decorate with redux-form
const ReduxConnectedComponent = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true
})(Basics);

const Connected = connect(
	(state, ownProps) => {
		const loading = state.currentEvent.loading;
		const isNewEvent = ownProps?.location?.pathname?.includes("/new/");

		const newEventInitialValues = {
			dates: initDates,
			timezone: dayjs.tz.guess(),
			currency: locationDataByCountryCode[state.auth.user?.location].currency,
			isRecurring: false,
			public: true,
			eventLocation: {
				type: "toBeAnnounced"
			},
			createFor: "myself",
			location: state.auth.user?.location
		};
		let currentEventValues = {};
		let event = null;

		const sharedAccounts = (state && state.authorization && state.authorization.sharedAccounts) || [];
		if (state.currentEvent?.event) {
			event = state.currentEvent.event;

			currentEventValues = {
				name: event.name,
				isRecurring: event.isRecurring || (event && event.dates && event.dates.length > 1),
				dates: event.dates,
				timezone: event.timezone,
				eventLocation: event.eventLocation,
				location: event.location,
				ticketSold: event.ticketSold,
				keywords: event.keywords,
				classification: event.classification,
				artists: event.artists,
				public: event.public
			};
		}
		return {
			isNewEvent,
			loading,
			user: state.auth.user,
			initialValues: isNewEvent && !event ? newEventInitialValues : currentEventValues,
			event: isNewEvent && !event ? newEventInitialValues : event,
			eventRecurring: selector(state, "isRecurring"),
			eventLocation: selector(state, "eventLocation"),
			timezone: selector(state, "timezone"),
			createFor: selector(state, "createFor"),
			dates: selector(state, "dates"),
			sharedAccounts,
			keywords: selector(state, "keywords"),
			classification: selector(state, "classification"),
			artists: selector(state, "artists"),
			isPublic: selector(state, "public")
		};
	},
	{ createEvent, updateEventBasics, getSharedAccounts }
)(withSaveCheck(ReduxConnectedComponent, formName));

export default withRouterHooks(Connected);
