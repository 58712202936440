import { ContentBlock } from "@/components/AppBlocks";
import IconButton from "@/components/IconButton";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import H2 from "@/components/text/H2";
import AuthService from "@/services/AuthService";
import { addNote, deleteNote } from "@/state/actions/newPayoutActions";
import { PayoutNote } from "@/state/reducers/newPayoutReducer";
import { ReduxState } from "@/state/store";
import { DateFormats, HumaniDate } from "@hx/dates";
import { Input, Modal, Popconfirm, Table } from "@/ui/antd";
import { useRef, useState } from "react";
import { connect } from "react-redux";

const Notes = ({ notes, eventId, deleteNote, addNote }: IInjectedFunctions & IInjectedProps) => {
	const isAdmin = AuthService.isAdmin();

	const [open, setOpen] = useState(false);

	if (!isAdmin || !notes || !eventId) return null;

	const noteInput = useRef<any>(null);

	const userFirebaseId = AuthService.getFirebaseId();

	const submitForm = () => {
		if (!noteInput.current.resizableTextArea.textArea.value) return setOpen(false);
		const note = noteInput.current.resizableTextArea.textArea.value;
		noteInput.current.resizableTextArea.textArea.value = null;
		addNote(eventId, note);
	};

	return (
		<>
			<Modal title="Add note" open={open} onCancel={() => setOpen(false)} okText="Add note" onOk={submitForm}>
				<Input.TextArea ref={noteInput} />
			</Modal>
			<ContentBlock>
				<H2>Notes</H2>
				<Table
					dataSource={notes.filter((note) => !note.deleted)}
					columns={[
						{
							title: "Note",
							dataIndex: "note",
							key: "note"
						},
						{
							title: "Date",
							dataIndex: "createdAt",
							key: "date",
							render: (value) => new HumaniDate(value, "guess").formatting.date(DateFormats.Pretty)
						},
						{
							title: "Author",
							dataIndex: "userName",
							key: "author"
						},
						{
							title: "Actions",
							key: "actions",
							render: (record) => {
								if (record.userId !== userFirebaseId) return null;
								return (
									<Popconfirm
										title="Are you sure you want to delete this note?"
										onConfirm={() => deleteNote(eventId, record._id)}
										okText="Yes"
										cancelText="No"
									>
										<IconButton icon="delete" ariaLabel="delete note" dataCy="deleteNote" />
									</Popconfirm>
								);
							}
						}
					]}
				/>
				<LegacyButton
					icon={{ name: "plus", left: true }}
					type="action"
					ariaLabel="Add Note"
					dataCy="addNote"
					onClick={() => setOpen(true)}
					style={{ margin: "0 auto" }}
				>
					Add note
				</LegacyButton>
			</ContentBlock>
		</>
	);
};

interface IInjectedProps {
	notes?: PayoutNote[];
	eventId?: string;
}

interface IInjectedFunctions {
	addNote: (eventId: string, note: string) => void;
	deleteNote: (eventId: string, noteId: string) => void;
}

export default connect(
	(state: ReduxState): IInjectedProps => ({
		notes: state.payouts.eventPayout.payoutInformation?.notes,
		eventId: state.payouts.eventPayout.payoutInformation?.header.eventId
	}),
	{ addNote, deleteNote }
)(Notes);
