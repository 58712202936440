import AuthService from "../../AuthService";

const UserMenu = [
	{
		label: "Users",
		path: "/console/admin/users",
		key: "admin-users"
	}
];

const User = () => {
	return AuthService.isAdmin() ? UserMenu : [];
};

export default User;
