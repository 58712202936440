import { identify, trackConsoleAction, ConsoleAction } from "@hx/analytics";
import { getConfig } from "@/config";
import { setUser } from "@/state/actions/authActions";
import { fullName } from "@/utils/Format";
import { onConversationsAPIReady } from "@/utils/Hubspot";
import ax from "axios";
import { EventEmitter } from "fbemitter";
import { initializeApp } from "firebase/app";
import {
	User as FirebaseUser,
	confirmPasswordReset,
	createUserWithEmailAndPassword,
	fetchSignInMethodsForEmail,
	getAuth,
	getIdToken,
	sendPasswordResetEmail,
	signInWithCustomToken,
	signInWithEmailAndPassword
} from "firebase/auth";
import axios from "./AxiosWrapper";
import UsersService from "./UsersService";

const TOKEN_REFRESH_INTERVAL_MINUTES = 15;

type UserDetails = {
	id: string;
	firstName: string;
	lastName: string;
	mobile: string;
	email: string;
	token: string;
	state: string;
	location: string;
	role: string;
	recaptchaVerificationCode: string | number;
	referralCode?: string | number;
	referralEmail?: string | number;
	organization?: any;
	purpose?: any;
	adSourceId?: string | number;
	uid?: string | number;
	adminPermissions?: string[];
};

class AuthService {
	private static instance: AuthService | null = null;

	public authorized: boolean | null;
	private token: string | null;
	private role: string | null;
	private user: any;
	private signingUp: boolean;
	private assumingIdentity: boolean;
	public userChecked: boolean;
	public authEmitter: EventEmitter;
	private tokenRefreshDate: Date;

	private constructor() {
		this.authorized = null;
		this.token = null;
		this.role = null;
		this.user = null;
		this.signingUp = false;
		this.assumingIdentity = false;
		this.authEmitter = new EventEmitter();
		this.tokenRefreshDate = new Date();
		this.userChecked = false;
	}

	public static getInstance(): AuthService {
		if (!AuthService.instance) {
			AuthService.instance = AuthService.createInstance();
		}
		return AuthService.instance;
	}

	private static createInstance(): AuthService {
		return new AuthService();
	}

	public authChange(value: boolean): void {
		if (this.user && this.user.email) {
			const user = this.user;
			onConversationsAPIReady(user.email);
			this.initUserGuiding(user);
			this.initIntercom(user);
			this.trackLogin(user);
		}
		this.authorized = value;
		this.userChecked = true;
		this.authEmitter.emit("change", this.authorized);
	}

	private initUserGuiding(user: any): void {
		try {
			window.userGuiding.identify(user.id, {
				created_at: user.createdAt,
				location: user.location
			});
		} catch (err) {
			return;
		}
	}

	private trackLogin(user: any) {
		// re identify the user so events are linked
		identify({
			email: user.email,
			location: user.location.toUpperCase()
		});
		trackConsoleAction(ConsoleAction.hostLogin, {
			userId: user.id,
			location: user.location.toUpperCase()
		});
	}

	private initIntercom(user: any): void {
		try {
			if (this.assumingIdentity) {
				window.Intercom("shutdown");
			} else {
				window.Intercom("boot", {
					api_base: "https://api-iam.intercom.io",
					app_id: "uj6ijwgj",
					name: fullName(user.firstName, user.lastName),
					email: user.email,
					user_hash: user.intercomHash,
					created_at: user.createdAt,
					"User Verified": user.verified
				});
			}
		} catch (err) {
			return;
		}
	}

	public isAdmin(): boolean {
		return this.role === "admin" && !this.assumingIdentity;
	}

	public hasReferralProgram(): boolean {
		return (this.userChecked && this.user.isReferralProgramEnabled) || this.isAdmin();
	}

	public hasEventInTheUS(): boolean {
		return (this.userChecked && this.user.accessibleLocations.US) || this.isAdmin();
	}

	public hasTagAccess(): boolean {
		return this.userChecked && this.user.isTagsEnabled;
	}

	public hasEventImportAccess(): boolean {
		return this.userChecked && this.user.isEventImportEnabled;
	}

	public setup(store: any): void {
		const firebaseConfig = {
			apiKey: getConfig("FIREBASE_API_KEY"),
			authDomain: getConfig("FIREBASE_AUTH_DOMAIN"),
			databaseURL: getConfig("FIREBASE_DATABASE_URL"),
			projectId: getConfig("FIREBASE_PROJECT_ID")
		};

		initializeApp(firebaseConfig);
		this.initIdentity();
		const firebaseAppSetup = (): void => {
			getAuth().onAuthStateChanged(
				(user: FirebaseUser | null) => {
					if (this.signingUp) {
						this.signingUp = false;
						return;
					}
					if (user) {
						user.getIdToken().then((token) => {
							this.setTokenToHeaders(token);
							this.setUserRole().then((userData: any) => {
								store.dispatch(setUser(userData));
								AuthService.getInstance().authChange(!!user);
							});
						});
					} else {
						AuthService.getInstance().authChange(!!user);
					}
				},
				() => null,
				() => null
			);
		};

		firebaseAppSetup();
	}

	public signOut(): void {
		AuthService.getInstance().authChange(false);
		getAuth().signOut();
	}

	public signIn(email: string, password: string): Promise<any> {
		if (email) {
			onConversationsAPIReady(email);
		}

		return new Promise((resolve, reject) => {
			signInWithEmailAndPassword(getAuth(), email, password)
				.then(() => {
					const user = getAuth().currentUser;
					if (!user) throw new Error("no user logged in");
					return getIdToken(user, true);
				})
				.then((token) => {
					return AuthService.getInstance().setTokenToHeaders(token);
				})
				.then(() => {
					return AuthService.getInstance().setUserRole();
				})
				.then((user) => {
					resolve(user);
					AuthService.getInstance().authChange(true);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	public async checkRefresh(): Promise<string | null> {
		const now = new Date();
		const diffMs = now.getTime() - AuthService.getInstance().tokenRefreshDate.getTime();
		const diffMins = ((diffMs % 86400000) % 3600000) / 60000;
		if (diffMins > TOKEN_REFRESH_INTERVAL_MINUTES) {
			return AuthService.getInstance().refresh();
		}
		return AuthService.getInstance().token;
	}

	public async refresh(): Promise<string> {
		const user = getAuth().currentUser;
		if (!user) throw new Error("no user logged in");

		const token = await getIdToken(user, true);
		AuthService.getInstance().setTokenToHeaders(token);
		return token;
	}

	public async checkEmailStatus(email: string): Promise<{
		exists: boolean;
		isUts: boolean;
	}> {
		const existantConfig = {
			exists: false,
			isUts: email.indexOf("uts.edu.au") > -1
		};
		const providers = await fetchSignInMethodsForEmail(getAuth(), email);
		existantConfig.exists = providers.some((prov) => prov === "password");
		return existantConfig;
	}

	public setUserRole(): Promise<any> {
		return new Promise((resolve, reject) => {
			UsersService.getUser()
				.then((userData: any) => {
					AuthService.getInstance().user = userData.data;
					AuthService.getInstance().role = userData.data.role;
					resolve(userData.data);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	private initIdentity(): void {
		try {
			const userId = localStorage.getItem("x-identity");
			if (userId) {
				this.assumeIdentity(userId);
			}
			// eslint-disable-next-line no-empty
		} catch (err) {}
	}

	public assumeIdentity(userId: string): void {
		axios.defaults.headers.common["x-identity"] = userId;
		this.assumingIdentity = true;
		try {
			localStorage.setItem("x-identity", userId);
			// eslint-disable-next-line no-empty
		} catch (err) {}
	}

	public discardIdentity(): void {
		delete axios.defaults.headers.common["x-identity"];
		this.assumingIdentity = false;
		try {
			localStorage.removeItem("x-identity");
			// eslint-disable-next-line no-empty
		} catch (err) {}
	}

	private setTokenToHeaders(token: string): void {
		AuthService.getInstance().token = token;
		AuthService.getInstance().tokenRefreshDate = new Date();
		axios.defaults.headers.common["x-token"] = token;
		axios.defaults.headers.post["x-token"] = token;
		return;
	}

	public launchMicrosoftSignIn(email: string, signInConfig: any): void {
		const consoleUrl = getConfig("CONSOLE_URL");
		const redirectUrl = encodeURIComponent(`${consoleUrl}/sso/microsoft`);
		const loginUrl =
			`https://login.microsoftonline.com/${signInConfig.tenant}/oauth2/authorize?` +
			`client_id=${signInConfig.client_id}` +
			`&response_type=code` +
			`&redirect_uri=${redirectUrl}` +
			`&response_mode=query` +
			`&state=${email}&login_hint=${email}`;
		window.location.href = loginUrl;
	}

	public async microsoftSignIn(params: any): Promise<any> {
		const response = await ax.post("/public-api/microsoft/authorise", params);
		const userData = response.data;
		const auth = getAuth();
		const firebaseUser = await signInWithCustomToken(auth, userData.token);
		this.setTokenToHeaders(userData.token);
		const isNew = userData.matchedUserIds.indexOf(firebaseUser.user.uid) === -1;

		if (isNew) {
			const config = { ...userData.config };
			delete userData.config;
			delete userData.matchedUserIds;
			const userDetails = {
				id: firebaseUser.user.uid,
				...userData,
				role: "user",
				...config.userSetting,
				location: "AU"
			};

			delete userDetails.token;
			// create the user
			await UsersService.create(userDetails.uid, userDetails);
		}
		const currentUser = await this.setUserRole();
		this.authChange(true);
		return { user: currentUser, isNewUser: userData.isNew };
	}

	public async signUp(user: {
		email: string;
		password: string;
		firstName: string;
		lastName: string;
		mobile: string;
		state: string;
		token: string;
		location: string;
		recaptchaVerificationCode: string | number;
		referralCode?: string | number;
		referralEmail?: string | number;
		charitySignUpId?: string | number;
		organization?: any;
		purpose?: any;
		adSourceId?: string | number;
	}): Promise<any> {
		this.signingUp = true;
		const firebaseUser = await createUserWithEmailAndPassword(getAuth(), user.email, user.password);
		const userDetails: UserDetails = {
			id: firebaseUser.user.uid,
			firstName: user.firstName,
			lastName: user.lastName,
			mobile: user.mobile,
			email: user.email,
			token: user.token,
			state: user.state,
			location: user.location,
			role: "user", // we dont ever want a default role set to anything but user
			recaptchaVerificationCode: user.recaptchaVerificationCode
		};
		if (user.referralCode) {
			userDetails.referralCode = user.referralCode;
		}
		if (user.referralEmail) {
			userDetails.referralEmail = user.referralEmail;
		}
		if (user.organization) {
			userDetails.organization = user.organization;
		}
		if (user.purpose) {
			userDetails.purpose = user.purpose;
		}
		if (user.adSourceId) {
			userDetails.adSourceId = user.adSourceId;
		}
		const token = await firebaseUser.user.getIdToken();
		this.setTokenToHeaders(token);
		const createdResult = await UsersService.create(userDetails.uid, userDetails);
		if (createdResult.error) {
			throw new Error(createdResult.error.message);
		}
		this.signingUp = false;
		await this.signIn(user.email, user.password);
		return createdResult.user;
	}

	public async removeTestAccount(): Promise<void> {
		const user = getAuth().currentUser;
		if (user) {
			user.delete().then(
				async function () {
					await UsersService.delete(user.uid);
					return;
				},
				function () {
					throw new Error("Invalid user value");
				}
			);
		}
	}

	public forgotPassword(email: string): Promise<void> {
		return sendPasswordResetEmail(getAuth(), email);
	}

	public resetPassword(code: string, email: string): Promise<void> {
		return confirmPasswordReset(getAuth(), code, email);
	}

	public async update(userData: any): Promise<any> {
		const user = await UsersService.userUpdate(userData);
		this.user = user;
		return user;
	}

	public getToken = () => this.token;
	public getFirebaseId = () => this.user.id;
	public isVerified = () => this.user.verified;
	public isFraudSuspect = () => this.user.fraudulentUser;
	public isAssumingIdentity = () => this.assumingIdentity;
	public showInsuranceColumn = () => this.user.showInsuranceColumn || this.isAdmin();
	public isResaleAllowed = () => this.user.verified || this.isAdmin();

	public hasAdminPermission = (permissionToCheck: "editPermissions" | "payouts" | "taxInfo" | "selfServicePayouts") => {
		if (this.user.role === "admin" && this.user?.adminPermissions?.includes(permissionToCheck)) {
			return true;
		}
		return false;
	};
}

export default AuthService.getInstance();
