import { locationDataByCurrency } from "@hx/locations";
import { Component } from "react";
import { connect } from "react-redux";
import { Field } from "redux-form";
import AuthService from "../../services/AuthService";
import SelectField from "./SelectField";

const countries = Object.values(locationDataByCurrency).map((option) => {
	return {
		value: option.location,
		label: option.country
	};
});
class CountryField extends Component {
	componentDidUpdate(oldProps) {
		if (oldProps.location && oldProps.location !== this.props.location) {
			if (this.props.onLocationChange) {
				this.props.onLocationChange();
			}
		}
	}

	render() {
		const { user, enabledCountries = [] } = this.props;
		const countriesList = AuthService.isAdmin()
			? countries
			: countries.filter(({ value }) => user.accessibleLocations[value]);
		const availableCountries =
			enabledCountries.length === 0
				? countriesList
				: countriesList.filter((country) => enabledCountries.includes(country.value));
		if (countries.length <= 1) {
			return null;
		}
		return (
			<Field
				name="location"
				label="Currency"
				component={SelectField}
				style={{ maxWidth: 300, width: "100%" }}
				options={availableCountries.map((country) => {
					return {
						label: locationDataByCurrency[country.value],
						value: country.value
					};
				})}
			/>
		);
	}
}

export default connect(
	(state) => ({
		user: state.auth.user
	}),
	{}
)(CountryField);
