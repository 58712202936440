import { css } from "@emotion/css";
import appStore from "@/assets/app_store_badge.svg";
import googlePlay from "@/assets/google_play_badge.svg";
import scanningApp from "@/assets/ic_scanning_app.svg";
import { H2, P } from "@/components/text/Text";

/**
 * This is a app banner showing the bottom of basic information
 *
 * @param {null} props
 * @returns banner
 */
const BigBanner = () => {
	const container = css`
		background: #fff;
		width: 100%;
		max-width: 100%;
		min-height: 210px;
		padding: 0px 40px 0px 30px;
		display: flex;
		justify-content: space-between;
		border-radius: 4px;
		box-shadow: 0 1px 10px 0 rgba(9, 30, 66, 0.13);
		@media (max-width: 600px) {
			flex-direction: column;
			padding: 0px 12px 14px 12px;
		}
	`;
	const scanningAppCss = css`
		width: 203px;
		height: 190px;
		margin-top: 10px;
		margin-right: 60px;
		@media (max-width: 600px) {
			display: none;
			width: 213px;
			height: 205px;
			margin: 13px auto 0pc auto;
		}
	`;

	const scanningAppCssMobile = css`
		width: 328px;
		height: 319px;
		display: none;
		margin-top: 26px;
		@media (max-width: 600px) {
			width: 213px;
			height: 205px;
			margin: 13px auto 0pc auto;
			display: block;
		}
	`;

	const text = css`
		flex: 3;
		padding-top: 30px;
		@media (max-width: 600px;) {
			padding-top: 12px;
		}
	`;

	const h2 = css`
		@media (max-width: 600px) {
			margin-bottom: 12px;
		}
	`;

	const firstP = css`
		max-width: 320px;
	`;

	const badge = css`
		margin-top: 0px;
		margin-right: 14px;
		display: inline-block;
		@media (max-width: 600px) {
			height: 38px;
			width: 112px;
			margin-right: 12px;
		}
	`;

	return (
		<div className={container}>
			<img src={scanningApp} className={scanningAppCssMobile} alt="scanning_app" />
			<img src={scanningApp} className={scanningAppCss} alt="scanning_app" />
			<div className={text}>
				<H2 className={h2}>Reduce wait time at the door</H2>
				<P className={firstP}>
					Scan tickets fast and welcome your attendees with our free scanning app. Manage your event on the day from the
					app.
				</P>
				<div style={{ marginTop: 25 }}>
					<a
						href="https://itunes.apple.com/app/humanitix-console/id1363849196?mt=8"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={appStore} className={badge} alt="app_store" />
					</a>
					<a
						href="https://play.google.com/store/apps/details?id=com.humanitix.organiser"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img src={googlePlay} className={badge} alt="google_play" />
					</a>
				</div>
			</div>
		</div>
	);
};

export default BigBanner;
