import { CheckboxField, DateTimeField, RecaptchaField, SwitchField } from "@/components/Fields";
import LoadErrorView from "@/components/LoadErrorView";
import H5 from "@/components/text/H5";
import Label from "@/components/text/Label";
import P from "@/components/text/P";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { dayjs } from "@/libs/dayjs";
import AuthService from "@/services/AuthService";
import { closePublish, publish, unpublish } from "@/state/actions/eventActions";
import { Button, ResponsiveModal } from "@/ui";
import { Alert, Flex } from "@/ui/antd";
import { lastOfType } from "@/utils/CssUtils";
import { HelpIds, OpenHelpHandler } from "@/utils/Help";
import { getPublishWarnings } from "@/utils/Publish";
import { required } from "@/utils/Validators";
import { css } from "@emotion/css";
import { Component } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form";

const warningListItem = css({
	marginBottom: 10,
	[lastOfType()]: {
		marginBottom: 15
	}
});

const scheduledDateRequired = required("A scheduled date is required");
const recaptchaRequired = required("Please prove you are human");
const dateInFuture = (value, allValues, props) => {
	const now = dayjs().tz(props.currentEvent.event.timezone);
	const date = dayjs(value).tz(props.currentEvent.event.timezone);
	if (date.isBefore(now)) return "Scheduled date must be in the future";
};

const NotifyFollowers = ({ isVerifiedUser, currentEvent, followerCount }) => {
	if (!isVerifiedUser)
		return (
			<>
				<Label>Notify followers</Label>
				<P>
					Please verify your account to send follower notifications, submit a request{" "}
					<a onClick={OpenHelpHandler(HelpIds.verifyToPublish)}>here</a>. You can send a notification later via the
					"..." button.
				</P>
			</>
		);

	if (currentEvent.event.notifyFollowers)
		return (
			<>
				<Label>Notify followers</Label>
				<P>Your followers have already been notified for this event and cannot be notified again.</P>
			</>
		);

	if (!currentEvent.event.public)
		return (
			<>
				<Label>Notify followers</Label>
				<P>Follower notifications are only available for public events. This event is private.</P>
			</>
		);

	return (
		<Field
			label="Notify my followers"
			name="notifyFollowers"
			component={SwitchField}
			inline
			description={
				<>
					You have {followerCount} follower{followerCount > 1 && "s"}. You can send a notification later via the "..."
					button.
				</>
			}
		/>
	);
};

class PublishModal extends Component {
	constructor(props) {
		super(props);
		this.publishEvent = this.publishEvent.bind(this);
		this.unpublishEvent = this.unpublishEvent.bind(this);
	}

	publishEvent(values) {
		const { closePublish, publish, currentEvent } = this.props;
		const scheduledPublishDate = values.scheduled ? values.scheduledPublishDate : undefined;
		const recaptchaVerificationCode = values.recaptchaVerificationCode ? values.recaptchaVerificationCode : undefined;
		publish(currentEvent.event._id, scheduledPublishDate, values.notifyFollowers, recaptchaVerificationCode);
		closePublish();
	}

	unpublishEvent() {
		const { closePublish, unpublish, currentEvent } = this.props;
		unpublish(currentEvent.event._id);
		closePublish();
	}

	render() {
		const {
			closePublish,
			handleSubmit,
			currentEvent,
			scheduled,
			acknowledgeWarnings,
			followerCount,
			isVerifiedUser,
			navigate
		} = this.props;

		const publishWarnings = getPublishWarnings(currentEvent.event, closePublish, navigate);
		return (
			<ResponsiveModal
				open={currentEvent.publish.publishModalOpen}
				header={<b>Publish Event</b>}
				closable
				onCancel={closePublish}
				width="600px"
				footer={
					<Flex justify="space-between" gap="sm" wide>
						<Button aria-label="Cancel" key="cancel" onClick={closePublish} variant="ghost">
							Cancel
						</Button>
						<Flex gap="sm">
							{currentEvent.event.scheduledPublishDate && (
								<Button
									aria-label="Unschedule"
									disabled={currentEvent.loading || currentEvent.publish.check.loading}
									key="submit"
									loading={currentEvent.loading}
									onClick={this.unpublishEvent}
									outlined
									variant="danger"
								>
									Unschedule
								</Button>
							)}
							<Button
								aria-label={scheduled ? "Schedule" : "Publish now"}
								dataCy={scheduled ? "Schedule" : "Publish now"}
								disabled={publishWarnings.length && !acknowledgeWarnings}
								key="submit"
								loading={currentEvent.loading}
								onClick={handleSubmit(this.publishEvent)}
								variant="primary"
							>
								{scheduled ? "Schedule" : "Publish now"}
							</Button>
						</Flex>
					</Flex>
				}
			>
				<LoadErrorView loading={currentEvent.loading || currentEvent.publish.check.loading}>
					<form onSubmit={handleSubmit(this.publishEvent)}>
						{publishWarnings.length ? (
							<Alert
								showIcon
								type="warning"
								message={
									<H5 style={{ marginBottom: 8 }}>We advise you resolve the following issues before publishing:</H5>
								}
								description={
									<>
										<ul style={{ paddingLeft: 20 }}>
											{publishWarnings.map(({ warning, possibleRisk }, index) => (
												<div className={warningListItem} key={`publish-warning-${index}`}>
													<li>{warning}</li>
													{possibleRisk}
												</div>
											))}
										</ul>
										<Field
											label="I accept these risks and publish anyway"
											name="acknowledgeWarnings"
											component={CheckboxField}
											inline
											labelAfter
										/>
									</>
								}
								style={{ marginBottom: 8 }}
							/>
						) : null}
						<Field
							label="Publish later"
							name="scheduled"
							component={SwitchField}
							description="Schedule event to be published at a specific date and time"
						/>
						{scheduled && (
							<Field
								label="Scheduled at"
								name="scheduledPublishDate"
								component={DateTimeField}
								placeholder="Date"
								timeOptions={{
									placeholder: "Time"
								}}
								timezone={currentEvent.event.timezone}
								displayFormat="Do MMM YYYY"
								validate={[scheduledDateRequired, dateInFuture]}
								required
								formName={formName}
							/>
						)}
						{currentEvent.event.organiserId && followerCount > 0 ? (
							<NotifyFollowers
								isVerifiedUser={isVerifiedUser}
								currentEvent={currentEvent}
								followerCount={followerCount}
							/>
						) : null}
						{currentEvent.publish.check.verification &&
						currentEvent.publish.check.verification.result === "captchaRequired" ? (
							<Field name="recaptchaVerificationCode" component={RecaptchaField} validate={recaptchaRequired} />
						) : null}
					</form>
				</LoadErrorView>
			</ResponsiveModal>
		);
	}
}

// Decorate with redux-form
const formName = "publishEvent";
const selector = formValueSelector(formName);
PublishModal = reduxForm({
	form: formName,
	enableReinitialize: true
})(PublishModal);

export default connect(
	(state) => {
		const currentEvent = state.currentEvent;
		const isVerifiedUser = AuthService.isVerified();
		return {
			currentEvent,
			isVerifiedUser,
			scheduled: selector(state, "scheduled"),
			acknowledgeWarnings: selector(state, "acknowledgeWarnings"),
			notifyFollowers: selector(state, "notifyFollowers"),
			followerCount: state.organisers.selected.followerCount,
			initialValues: {
				scheduled: !!state.currentEvent.event.scheduledPublishDate,
				scheduledPublishDate: state.currentEvent.event.scheduledPublishDate,
				notifyFollowers: state.currentEvent.event.scheduledPublishDate
					? state.currentEvent.event.notifyFollowers
					: false
			}
		};
	},
	{ closePublish, publish, unpublish }
)(withRouterHooks(PublishModal));
