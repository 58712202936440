import { css } from "@emotion/css";
import React from "react";
import HelpButton from "./HelpButton";
import { H1 } from "./text/Text";

const PageHeading = ({
	title,
	helpButton,
	children
}: {
	title: string | JSX.Element;
	helpButton?: any;
	children?: React.ReactNode;
}) => {
	const wrapper = css({
		display: "flex"
	});

	return (
		<div className={wrapper}>
			<div style={{ flexGrow: 1 }}>
				<H1>{title}</H1>
			</div>
			<div style={{ display: "flex" }}>
				{children}
				{helpButton ? (
					<HelpButton
						buttonText="Help"
						title={helpButton.title}
						helpLink={helpButton.link}
						styles={{ float: "none", marginBottom: 14, marginLeft: 14 }}
						stylesMobile={{ marginBottom: 9 }}
					/>
				) : null}
			</div>
		</div>
	);
};

export default PageHeading;
