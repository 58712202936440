import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Modal } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { LoadingSpinner } from "@/components/AppBlocks";
import LinkButton from "@/components/buttons/LinkButton";
import AuthService from "@/services/AuthService";
import { markPaid, markSent, refundUnpaidInvoice, removePayment, removeSent } from "@/state/actions/invoiceActions";
import {
	downloadInvoice,
	hubspotSync,
	openResendOrderModal,
	openSwapModal,
	forceCompleteOrder
} from "@/state/actions/orderActions";
import { strComparisonCaseIgnore } from "@/utils/Format";
import { withRouterHooks } from "@/hoc/withRouterHooks";

const { confirm } = Modal;

class OrderActions extends Component {
	constructor(props) {
		super(props);
		this.actionClicked = this.actionClicked.bind(this);
	}
	actionClicked(evt, order) {
		const {
			openResendOrderModal,
			downloadInvoice,
			markPaid,
			removePayment,
			markSent,
			removeSent,
			eventId,
			refundUnpaidInvoice,
			openSwapModal,
			navigate,
			hubspotSync,
			forceCompleteOrder
		} = this.props;

		switch (evt.key) {
			case "send-order":
				openResendOrderModal(eventId, order);
				break;
			case "send-invoice":
				openResendOrderModal(eventId, order);
				break;
			case "download-invoice":
				downloadInvoice(order._id, order.orderName);
				break;
			case "mark-paid":
				confirm({
					content: "Are you sure you want to mark this invoice as paid?",
					onOk() {
						markPaid(order._id);
					}
				});
				break;
			case "remove-payment":
				removePayment(order._id);
				break;
			case "mark-sent":
				markSent(order._id);
				break;
			case "remove-sent":
				removeSent(order._id);
				break;
			case "refund":
				if (order.eventId) navigate(`/console/my-events/${order.eventId}/orders/${order._id}/refund`);
				else navigate(`/console/promote/gift-cards/order/${order._id}/refund`);
				break;
			case "remove-invoice":
				confirm({
					content: "Are you sure you want to remove this invoice?",
					onOk() {
						refundUnpaidInvoice(order._id, true);
					}
				});
				break;
			case "swap":
				openSwapModal();
				break;
			case "hubspotSync":
				hubspotSync(order._id);
				break;

			case "force-complete":
				confirm({
					content: "Are you sure you want to force complete this order?",
					onOk() {
						forceCompleteOrder(order._id);
					}
				});
				break;
			default:
				break;
		}
	}
	render() {
		const {
			eventId,
			event,
			order,
			actionsLoading,
			swapModalPresent,
			useButton,
			hideEdit,
			hideView,
			refundModalOpen,
			additionalItems
		} = this.props;
		const isAdmin = AuthService.isAdmin();
		const refundMsg = "Cancel/refund...";

		const showResendInvoice =
			order &&
			strComparisonCaseIgnore(order.orderStatus, "Complete") &&
			strComparisonCaseIgnore(order.paymentGateway, "Invoice") &&
			!order.invoicePaid &&
			event &&
			event.paymentOptions &&
			event.paymentOptions.invoiceInfo &&
			!event.paymentOptions.invoiceInfo.sendManualInvoice;

		const showDownloadInvoice = strComparisonCaseIgnore(order.orderStatus, "Complete");

		const showResendOrder = strComparisonCaseIgnore(order.orderStatus, "Complete");

		const showMarkAsSent =
			strComparisonCaseIgnore(order.orderStatus, "Complete") &&
			strComparisonCaseIgnore(order.paymentGateway, "Invoice") &&
			!order.invoiceSent &&
			!order.invoicePaid &&
			(isAdmin || !order.managedByHumanitix);

		const showRemoveSent =
			strComparisonCaseIgnore(order.orderStatus, "Complete") &&
			strComparisonCaseIgnore(order.paymentGateway, "Invoice") &&
			order.invoiceSent &&
			!order.invoicePaid &&
			(isAdmin || !order.managedByHumanitix);

		const showMarkAsPaid =
			strComparisonCaseIgnore(order.orderStatus, "Complete") &&
			strComparisonCaseIgnore(order.paymentGateway, "Invoice") &&
			!order.invoicePaid &&
			(isAdmin || !order.managedByHumanitix);

		const showRemovePayment =
			strComparisonCaseIgnore(order.orderStatus, "Complete") &&
			strComparisonCaseIgnore(order.paymentGateway, "Invoice") &&
			order.invoicePaid &&
			(isAdmin || !order.managedByHumanitix);
		const showRemoveInvoice =
			order &&
			strComparisonCaseIgnore(order.orderStatus, "Complete") &&
			strComparisonCaseIgnore(order.paymentGateway, "Invoice") &&
			!order.invoicePaid;

		const orderStatus = (order) => {
			if (order.orderStatus) {
				return order.orderStatus;
			}
			if (order.status) {
				return order.status.replace(/\w/, (w) => w.toUpperCase());
			}
			return "";
		};

		const menu = (order) => {
			order.orderStatus = orderStatus(order);
			const isHubspotAppEnabled = order?.metadata?.hubspot?.enabled;
			const items = [
				!hideView
					? { key: "view", label: <Link to={`/console/my-events/${eventId}/orders/${order._id}`}>View</Link> }
					: null,
				!hideEdit
					? { key: "edit", label: <Link to={`/console/my-events/${eventId}/orders/edit/${order._id}`}>Edit...</Link> }
					: null,
				swapModalPresent ? { key: "swap", label: "Swap..." } : null,
				showResendInvoice ? { key: "send-invoice", label: "Resend Invoice" } : null,
				showDownloadInvoice ? { key: "download-invoice", label: "Download Invoice" } : null,
				showResendOrder ? { key: "send-order", label: "Resend Order" } : null,
				{ key: "refund", label: refundMsg },
				showMarkAsSent ? { key: "mark-sent", label: "Mark as Sent" } : null,
				showRemoveSent ? { key: "remove-sent", label: "Remove Sent" } : null,
				showMarkAsPaid ? { key: "mark-paid", label: "Mark as Paid..." } : null,
				showRemovePayment ? { key: "remove-payment", label: "Remove Payment" } : null,
				showRemoveInvoice ? { key: "remove-invoice", label: "Remove Invoice..." } : null,
				isHubspotAppEnabled && isAdmin ? { key: "hubspotSync", label: "HubSpot Sync" } : null,
				order.status === "incomplete" && isAdmin ? { key: "force-complete", label: "Force complete" } : null,
				...(additionalItems ? additionalItems : [])
			];

			return items.filter((item) => !!item);
		};
		if (actionsLoading.indexOf(String(order._id)) !== -1 || refundModalOpen.loading) {
			return <LoadingSpinner size={24} />;
		}
		return (
			<div style={{ minWidth: 82 }}>
				<Dropdown
					menu={{
						items: menu(order),
						onClick: (event) => this.actionClicked(event, order)
					}}
					trigger={["click"]}
				>
					{!useButton ? (
						<LinkButton className="ant-dropdown-link" ariaLabel="Actions">
							Actions
							<DownOutlined style={{ marginLeft: 4 }} />
						</LinkButton>
					) : (
						<Button size="large" className="ant-dropdown-link" aria-label="Actions">
							Actions
							<DownOutlined style={{ marginLeft: 4 }} />
						</Button>
					)}
				</Dropdown>
			</div>
		);
	}
}

export default connect(
	(state) => ({
		actionsLoading: state.order.actionsLoading,
		refundModalOpen: state.order.refundModalOpen
	}),
	{
		openResendOrderModal,
		markPaid,
		removePayment,
		markSent,
		removeSent,
		downloadInvoice,
		refundUnpaidInvoice,
		openSwapModal,
		forceCompleteOrder,
		hubspotSync
	}
)(withRouterHooks(OrderActions));
