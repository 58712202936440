import { ContentBlock } from "@/components/AppBlocks";
import ApplyToAllSave from "@/components/ApplyToAllSave";
import { CheckboxField, LegacyInputField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import LoadErrorView from "@/components/LoadErrorView";
import { H1 } from "@/components/text/Text";
import Custom from "@/events/event/design/Custom";
import EventStyling from "@/events/event/design/EventStyling";
import AuthService from "@/services/AuthService";
import { applyStyleTheme } from "@/state/actions/applyToAllActions";
import { getEvent } from "@/state/actions/eventActions";
import { getStylingTemplate, saveStylingTemplate, updateStylingTemplate } from "@/state/actions/templateActions";
import { useAppSelector } from "@/state/hooks";
import type { AppDispatch, ReduxState as GlobalReduxState } from "@/state/store";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { required } from "@/utils/Validators";
import { Tabs } from "@/ui/antd";
import colorContrast from "color-contrast";
import { useCallback, useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Field, InjectedFormProps, change, formValueSelector, reduxForm } from "redux-form";
import { InjectedRouterHooksProps, withRouterHooks } from "@/hoc/withRouterHooks";

const defaultTheme = {
	ticketBackgroundColor: "#FFA680",
	background: { type: "color" },
	primaryColor: "#ffb18f",
	baseColor: "#353337",
	colorScheme: "light"
};

const formName = "styling";
const nameRequired = required("A template name is required");
const selector = formValueSelector(formName);

type StylingComponentProps = PropsFromRedux & InjectedFormProps & { dispatch: AppDispatch } & InjectedRouterHooksProps;

const StylingComponent = ({
	handleSubmit,
	isFromEvent,
	isExisting,
	dispatch,
	getEvent,
	getStylingTemplate,
	updateStylingTemplate,
	saveStylingTemplate,
	applyStyleTheme,
	params,
	location,
	navigate
}: StylingComponentProps) => {
	const isAdmin = AuthService.isAdmin();

	const currentUser = useAppSelector((state) => state.auth);
	const currentEvent = useAppSelector((state) => state.currentEvent);
	const templates = useAppSelector((state) => state.templates);
	const theme = useAppSelector((state) => selector(state, "theme"));

	const id = params?.id;
	const defaultTab = params?.tab || "settings";

	const { get } = currentEvent;
	const loading = get.loading || templates.loading;
	const error = get.error || templates.error;

	const [tab, updateTab] = useState(defaultTab);

	const loadData = useCallback(() => {
		if (isFromEvent) {
			getEvent(id);
		}
		if (isExisting) {
			getStylingTemplate(currentUser.user?.id, id);
		}
	}, [getEvent, getStylingTemplate, currentUser.user?.id, id, isExisting, isFromEvent]);

	useEffect(() => {
		loadData();
	}, []);

	const updatePrimaryTextColor = (e: any, primaryColor: any) => {
		const colorCode = ["#323232", "#FFFFFF"];
		let primaryTextColor;
		colorCode.forEach((color) => {
			if (colorContrast(primaryColor, color) >= 4.5) {
				primaryTextColor = color;
			}
		});
		dispatch(change(formName, "theme.primaryTextColor", primaryTextColor));
	};

	const updateFooterTextColor = (e: any, footerColor: string) => {
		const colorCode = ["#323232", "#FFFFFF"];
		let footerTextColor;
		colorCode.forEach((color) => {
			if (colorContrast(footerColor, color) >= 4.5) {
				footerTextColor = color;
			}
		});
		dispatch(change(formName, "theme.footerTextColor", footerTextColor));
	};

	const save = (values: any) => {
		const { isExisting } = location.state;

		const eventTheme = {
			...values.theme,
			ticketButtonText:
				values.theme.ticketButtonText === "Custom" && values.theme.customTicketButtonText
					? values.theme.customTicketButtonText
					: values.theme.ticketButtonText
		};

		const template = {
			_id: id,
			name: values.name,
			eventTheme: eventTheme,
			isDefaultTheme: values.isDefaultTheme,
			logoImage: values.logoImage
		};

		if (isExisting) {
			updateStylingTemplate(currentUser.user.id, id, template);
		} else {
			saveStylingTemplate(currentUser.user.id, template, navigate);
		}
	};

	const handleApplyStylingToAll = () => {
		dispatch(applyStyleTheme(templates.selectedTemplate._id, isAdmin ? currentUser?.user?.id : undefined));
	};

	return (
		<form onSubmit={handleSubmit(save)}>
			<H1>Styling</H1>
			<ContentBlock>
				<LoadErrorView loading={loading} error={error} renderChildren={false}>
					<Field
						name="name"
						label="Template name"
						required
						autocomplete="nope"
						description="Name your template so you can find it easier"
						component={LegacyInputField}
						style={{ width: "50%" }}
						validate={nameRequired}
					/>
					<Field
						name="isDefaultTheme"
						label="Set this template as default"
						component={CheckboxField}
						inline
						labelAfter
					/>
					<Tabs
						activeKey={tab}
						items={[
							{
								key: "settings",
								label: "Event page",
								children: <EventStyling theme={theme} formName={formName} />
							},
							{
								key: "custom",
								label: "Checkout",
								children: (
									<Custom
										theme={theme}
										formName={formName}
										updatePrimaryTextColor={updatePrimaryTextColor}
										updateFooterTextColor={updateFooterTextColor}
									/>
								)
							}
						]}
						onChange={updateTab}
					/>
				</LoadErrorView>
			</ContentBlock>
			<FormBar>
				<ApplyToAllSave onOk={handleApplyStylingToAll} />
			</FormBar>
		</form>
	);
};

type FormValues = {
	name?: string;
	isDefaultTheme?: boolean;
	theme: typeof defaultTheme;
	logoImage?: string;
};

const mapStateToProps = (state: GlobalReduxState, ownProps: InjectedRouterHooksProps) => {
	let initialValues: FormValues = {
		theme: defaultTheme
	};
	const { location } = ownProps;
	const { isFromEvent, isExisting } = location?.state ?? {};
	const { currentEvent, templates } = state;

	if (isFromEvent && currentEvent?.event) {
		initialValues.theme = { ...defaultTheme, ...state.currentEvent?.event?.theme };
	}

	if (isExisting && templates?.selectedTemplate) {
		const selectedTheme = templates.selectedTemplate;

		initialValues = {
			name: selectedTheme.name,
			isDefaultTheme: selectedTheme.isDefaultTheme,
			theme: { ...defaultTheme, ...selectedTheme.eventTheme },
			logoImage: selectedTheme.logoImage
		};
	}

	return {
		initialValues,
		isFromEvent,
		isExisting
	};
};

const mapDispatchToProps = {
	getEvent,
	getStylingTemplate,
	updateStylingTemplate,
	saveStylingTemplate,
	applyStyleTheme
};

const FormWithRedux = reduxForm<unknown, any, string>({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	onSubmitFail: () => {
		scrollToErrors();
	},
	enableReinitialize: true
})(StylingComponent);

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const Styling = withRouterHooks(connector(FormWithRedux));

export { Styling };
