import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import { dayjs } from "@/libs/dayjs";
import AdminReportService from "@/services/AdminReportService";
import MailchimpService from "@/services/MailchimpService";
import { Popconfirm, Table, Tooltip } from "@/ui/antd";
import { Component } from "react";
import { Link } from "react-router-dom";

class MailchimpReport extends Component {
	constructor(props) {
		super(props);
		this.load = this.load.bind(this);
	}
	state = {
		loading: true,
		logsLoading: true
	};

	componentDidMount() {
		this.load();
	}

	disconnect(userId) {
		MailchimpService.adminDisconnect(userId).then(this.load());
	}

	load() {
		this.setState({ loading: true }, () => {
			AdminReportService.getMailchimpOverviewReport()
				.then((report) => {
					this.setState({ loading: false, report });
				})
				.catch(() => {
					this.setState({ loading: false, error: "Oops" });
				});
		});
	}

	render() {
		return (
			<>
				<PageHeading title="Mailchimp Integration Overview" />
				<LoadErrorView loading={this.state.loading} retryAction={this.load} error={this.state.error}>
					{!this.state.loading && (
						<>
							<p>
								Total connected users: <strong>{this.state.report.connectedUsers.length}</strong>
							</p>
							<Table
								columns={[
									{
										title: "First Name",
										dataIndex: "firstName",
										key: "firstName"
									},
									{
										title: "Last Name",
										dataIndex: "lastName",
										key: "lastName"
									},
									{
										title: "Organisation",
										dataIndex: "organization",
										key: "organization"
									},
									{
										title: "Email",
										dataIndex: "email",
										key: "email",
										render: (email, user) => <Link to={`/console/admin/users/${user.id}`}>{email}</Link>
									},
									{
										title: "Selected Events",
										dataIndex: ["mailchimpSettings", "syncAll"],
										key: "mailchimpSettings.syncAll",
										render: (syncAll, user) => (
											<>
												{syncAll
													? "All events"
													: user.mailchimpSettings.selectedEventsWithNames.map((e) => (
															<Link
																title={e.name}
																style={{
																	display: "block",
																	textOverflow: "ellipsis",
																	whiteSpace: "nowrap",
																	maxWidth: 450,
																	overflow: "hidden"
																}}
																to={`/console/my-events/${e._id}`}
																key={e._id}
															>
																{e.name}
															</Link>
													  ))}
											</>
										)
									},
									{
										title: "Account name",
										dataIndex: ["mailchimpSettings", "accountName"],
										key: "mailchimpSettings.accountName"
									},
									{
										title: "Disconnect",
										render: (_, user) => {
											return (
												<Popconfirm
													title={
														<span>
															Disconnect <strong>{user.email}</strong>? <br />
															You won't be able to reconnect them!
														</span>
													}
													onConfirm={() => this.disconnect(user.id)}
													okText="Yes"
													cancelText="No"
												>
													{" "}
													<IconButton icon="disable" tooltip="Disconnect" showTooltip ariaLabel="Disconnect" />
												</Popconfirm>
											);
										}
									},
									{
										title: "Last synced",
										dataIndex: ["mailchimpSettings", "lastSyncedAt"],
										key: "mailchimpSettings.lastSyncedAt",
										render: (date) => {
											return date ? <Tooltip title={date}>{dayjs(date).fromNow()}</Tooltip> : "Never";
										}
									}
								]}
								dataSource={this.state.report.connectedUsers}
								locale={{ emptyText: "No results" }}
								rowKey="_id"
								scroll={{ x: "max-content" }}
								pagination={false}
							/>
							<hr />
						</>
					)}
				</LoadErrorView>
			</>
		);
	}
}

export default MailchimpReport;
