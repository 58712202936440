import { getConfig } from "@/config";
import { useAppSelector } from "@/state/hooks";
import { FC } from "react";
import { Route, Routes } from "react-router";
import TemplatesLandingPage from "../account/templates/TemplateLandingPage";
import AdditionalQuestions from "../account/templates/additionalQuestions/AdditionalQuestions";
import AdditionalQuestionsTemplates from "../account/templates/additionalQuestions/AdditionalQuestionsTemplates";
import { Styling } from "../account/templates/styling/Styling";
import StylingTemplates from "../account/templates/styling/StylingTemplates";
import TicketTypeTemplates from "../account/templates/ticketTypes/TicketTypeTemplates";
import TicketTypes from "../account/templates/ticketTypes/TicketTypes";
import Authorization from "../authorization/Authorization";
import MenuLayout from "../components/MenuLayout";
import Page from "../components/Page";
import Organiser from "../organisers/Organiser";
import Organisers from "../organisers/Organisers";
import Tags from "../tags/Tags";
import Alerts from "../users/Alerts";
import ApiKey from "../users/ApiKey";
import { BankAccountsList } from "../users/BankAccountsList";
import DefaultGateways from "../users/DefaultGateways";
import { DefaultPaymentSettings } from "../users/DefaultPaymentSettings";
import { DefaultSettings } from "../users/DefaultSettings";
import GlobalWidgets from "../users/GlobalWidgets";
import ProfileForm from "../users/ProfileForm";
import Hubspot from "../users/hubspot/Hubspot";
import IntegrationLandingPage from "../users/integrations/IntegrationLandingPage";
import { Integrations } from "../users/integrations/Integrations";
import ZapierIntegrations from "../users/integrations/ZapierIntegrations";
import Mailchimp from "../users/mailchimp/Mailchimp";
import Salesforce from "../users/salesforce/Salesforce";
import AccountMenu from "./AccountMenu";
import ReferralProgramLandingPage from "./referralProgram/ReferralProgramLandingPage";
import BankAccountAndTaxForm from "@/users/BankAccountAndTaxForm";

const Account: FC = () => {
	const user = useAppSelector((state) => state.auth.user);

	return (
		<div style={{ height: "100%" }}>
			<MenuLayout>
				<AccountMenu />
				<Page type="details">
					<Routes>
						<Route path="" element={<ProfileForm />} />

						<Route path="profile/*">
							<Route path="alerts" element={<Alerts />} />
							<Route path="default-setting" element={<DefaultSettings userId={user?.id} />} />
							<Route path="default-payment-setting" element={<DefaultPaymentSettings userId={user?.id} />} />
							<Route path="default-gateways" element={<DefaultGateways userId={user?.id} />} />
							<Route path="bank-accounts" element={<BankAccountsList userId={user?.id} />} />
							<Route path="bank-accounts/:bankAccountId" element={<BankAccountAndTaxForm userId={user?.id} />} />
						</Route>

						<Route path="authorization" element={<Authorization />} />

						<Route path="hosts/*">
							<Route path="" element={<Organisers />} />
							<Route path=":id/:tab" element={<Organiser />} />
						</Route>

						<Route path="advanced/*">
							<Route path="integrations/*">
								<Route path="" element={<IntegrationLandingPage />} />
								<Route path="default-tracking-ids" element={<Integrations userId={user?.id} />} />
								<Route path="zapier" element={<ZapierIntegrations userId={user?.id} />} />
								{getConfig("SALESFORCE_ENABLED") && (
									<Route path="salesforce" element={<Salesforce userId={user?.id} />} />
								)}
								<Route path="hubspot" element={<Hubspot userId={user?.id} />} />
								<Route path="mailchimp" element={<Mailchimp userId={user?.id} />} />
							</Route>

							<Route path="api-key" element={<ApiKey userId={user?.id} />} />
							<Route path="global-widgets" element={<GlobalWidgets />} />

							<Route path="tags" element={<Tags />} />

							{user.isReferralProgramEnabled || user.role === "admin" ? (
								<Route path="referral-program" element={<ReferralProgramLandingPage />} />
							) : null}

							<Route path="templates/*">
								<Route path="" element={<TemplatesLandingPage />} />
								<Route path="ticket-type-templates" element={<TicketTypeTemplates />} />
								<Route path="ticket-type-templates/:id" element={<TicketTypes />} />
								<Route path="additional-questions-templates" element={<AdditionalQuestionsTemplates />} />
								<Route path="additional-questions-templates/:id" element={<AdditionalQuestions />} />
								<Route path="styling-templates" element={<StylingTemplates />} />
								<Route path="styling-templates/:id" element={<Styling />} />
							</Route>
						</Route>
					</Routes>
				</Page>
			</MenuLayout>
		</div>
	);
};

export default Account;
