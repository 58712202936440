import { ContentBlock } from "@/components/AppBlocks";
import LoadErrorView from "@/components/LoadErrorView";
import H1 from "@/components/text/H1";
import { CollectionListItem } from "@/features/collections/CollectionListItem";
import { CollectionEditorModal } from "@/features/collections/editor/CollectionEditorModal";
import { CreateCollectionModal } from "@/features/collections/editor/CreateCollectionModal";
import { StorageKeys, useLocalStorage } from "@/hooks/useLocalStorage";
import { useAppSelector } from "@/state/hooks";
import { trpc } from "@/trpc";
import { Button, DesktopOnly } from "@/ui";
import { Badge, Flex, Tooltip } from "@/ui/antd";
import { LocationSelect } from "@/ui/LocationSelect";
import { HelpIds, OpenHelp } from "@/utils/Help";
import { PlusOutlined } from "@ant-design/icons";
import { Location } from "@hx/locations";
import { FC, useEffect, useState } from "react";
import { Outlet, Route, Routes, useNavigate, useParams } from "react-router";

export const CollectionsHome: FC = () => {
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const navigate = useNavigate();
	const user = useAppSelector((state) => state.auth.user);
	const [filterCollectionsByLocation, setFilterCollectionsByLocation] = useLocalStorage<Location>(
		StorageKeys.COLLECTIONS_LOCATION,
		user.location
	);
	const params = useParams();

	const getUserCollectionsSummaryProcedure = trpc.collections.getUserCollectionsSummary.useQuery(
		{ location: filterCollectionsByLocation },
		{
			enabled: !!filterCollectionsByLocation,
			networkMode: "always"
		}
	);
	const createCollectionProcedure = trpc.collections.createCollection.useMutation({
		networkMode: "always"
	});

	const collectionSummaries = getUserCollectionsSummaryProcedure.data;

	useEffect(() => {
		if (!params["*"]) {
			getUserCollectionsSummaryProcedure.refetch();
		}
	}, [filterCollectionsByLocation, isCreateModalOpen, params]);

	//good example
	const handleCreateCollection = async (newCollectionInfo: { name: string; location: Location }) => {
		const result = await createCollectionProcedure.mutateAsync(newCollectionInfo);
		if (result?.newCollection) {
			setIsCreateModalOpen(false);
			navigate(`${result.newCollection.location}/${result.newCollection._id}/events`);
		}
	};

	const isPageLoading =
		getUserCollectionsSummaryProcedure.isFetching || getUserCollectionsSummaryProcedure.isRefetching;
	const isPageError = getUserCollectionsSummaryProcedure.error
		? { title: "Something went wrong", message: "We couldn't load your collections. Try again soon." }
		: null;

	const retryAction = () => window.location.reload();

	const accessibleLocationsCount = Object.values(user.accessibleLocations).filter(Boolean).length;
	const showCurrencyLabel = accessibleLocationsCount > 1;

	const collectionCount = collectionSummaries?.collections?.length ?? 0;

	return (
		<>
			<Routes>
				<Route path=":location">
					<Route path=":collectionId/:tab" element={<CollectionEditorModal />} />
				</Route>
			</Routes>
			<LoadErrorView loading={isPageLoading} error={isPageError} retryAction={retryAction}>
				<Flex align="start" justify="space-between">
					<Flex align="start" gap="md" style={{ marginBottom: "var(--spacing-md)" }}>
						<H1>Collections</H1>
						<Tooltip title="New! Share all your events in one place.">
							<Badge
								count="New"
								offset={[0, 4]}
								style={{
									backgroundColor: "transparent",
									borderColor: "var(--error)",
									color: "var(--error)",
									fontWeight: "bold"
								}}
								title=""
							/>
						</Tooltip>
					</Flex>
					{showCurrencyLabel && (
						<Flex align="center" gap="sm">
							<DesktopOnly>
								<b>Currency</b>
							</DesktopOnly>
							{filterCollectionsByLocation && (
								<LocationSelect
									locations={Object.keys(user.accessibleLocations) as Location[]}
									onChange={(value) => setFilterCollectionsByLocation(value)}
									value={filterCollectionsByLocation}
								/>
							)}
						</Flex>
					)}
				</Flex>
				<ContentBlock>
					<p style={{ maxWidth: "700px" }}>
						Hosting multiple events? With Collections, you can effortlessly share all your events in one place, build
						excitement among your audience, and sell more tickets! Collections is ideal if you're organising a
						large-scale event with multiple sessions, launching a tour, running recurring events, or even an online
						course. Customise your Collection using tags and groups to show events by location, date or theme or
						handpick which events to display to help your audience to find exactly what they're looking for.{" "}
						<a onClick={() => OpenHelp(HelpIds.collections)} target="_blank" rel="noreferrer">
							Learn more.
						</a>
					</p>
					<Button iconLeft={<PlusOutlined />} onClick={() => setIsCreateModalOpen(true)} variant="tertiary">
						New collection
					</Button>
				</ContentBlock>
				{collectionCount ? (
					<>
						<br />
						<h3>
							<b>Your Collections</b>
						</h3>
					</>
				) : null}
				{collectionSummaries?.collections?.map((collection) => (
					<CollectionListItem collection={collection} key={collection._id} />
				))}
				<CreateCollectionModal
					onClose={() => setIsCreateModalOpen(false)}
					onSubmit={handleCreateCollection}
					open={isCreateModalOpen}
				/>
				<Outlet />
			</LoadErrorView>
		</>
	);
};
