import { BuyTicketsOrHelp } from "@/auth/components/BuyTicketsOrHelp";
import { LinkWrapper, NeedANewAccount } from "@/auth/components/Links";
import { TwoColumnPageWrap } from "@/auth/components/TwoColumnPageWrap";
import { H1, P } from "@/components/text/Text";
import AuthService from "@/services/AuthService";
import { afterLoginActions } from "@/state/actions/authActions";
import { useAppDispatch } from "@/state/hooks";
import { Button } from "@/ui/Button";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input, notification } from "antd";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";

const parseErrorMessage = (err: { code: string; message: string }) => {
	switch (err.code) {
		case "auth/user-not-found":
			return "This account does not exist. Please select Sign Up to create an account.";
		case "auth/wrong-password":
			return "The password is invalid.";
		case "auth/too-many-requests":
			return "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.";
		default:
			return err.message;
	}
};

export const Login: FC = () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const from = searchParams?.get("from");
	const email = searchParams?.get("email");

	const [loginForm] = Form.useForm();

	useEffect(() => {
		if (!email) {
			const path = `/signin`;
			const params = from ? `?from=${from}` : "";
			navigate(path, { state: params });
		}
	}, [email]);

	const handleSubmit = () => {
		setIsSubmitting(true);

		const formValues = loginForm.getFieldsValue(true);

		if (formValues.rememberMe) {
			localStorage.setItem("email", formValues.email);
		}
		return AuthService.signIn(formValues.email, formValues.password)
			.then((user) => {
				afterLoginActions(user, dispatch, navigate, from);
			})
			.catch((err) => {
				setIsSubmitting(false);

				const message = parseErrorMessage(err);
				notification.error({ message: "Oops!", description: message });
			});
	};

	return (
		<TwoColumnPageWrap>
			<H1>Login</H1>

			<P center>Enter your email address below to log in to an existing account or sign up with a new one.</P>

			<Form
				autoComplete="off"
				form={loginForm}
				initialValues={{
					rememberMe: true,
					email
				}}
				layout="vertical"
				style={{
					marginBottom: 48,
					marginTop: 24,
					width: "100%"
				}}
				onFinish={handleSubmit}
			>
				<Form.Item name="email" label={<b>Email Address</b>}>
					<Input prefix={<UserOutlined />} placeholder="Email address" disabled />
				</Form.Item>

				<Form.Item
					name="password"
					label={<b>Password</b>}
					required={false}
					rules={[
						{
							whitespace: true,
							type: "string",
							required: true,
							min: 1
						}
					]}
				>
					<Input prefix={<LockOutlined />} placeholder="Password" type="password" />
				</Form.Item>

				<div style={{ display: "flex", justifyContent: "space-between", marginTop: "-24px" }}>
					<Form.Item name="rememberMe" valuePropName="checked" required={false}>
						<Checkbox defaultChecked>Remember me</Checkbox>
					</Form.Item>
					<div style={{ marginTop: "4px" }}>
						<LinkWrapper>
							<Link to="/forgot-password">Forgot password</Link>
						</LinkWrapper>
					</div>
				</div>

				<Button
					aria-label="Log In"
					disabled={isSubmitting}
					fullWidth
					htmlType="submit"
					isLoading={isSubmitting}
					variant="primary"
				>
					Login
				</Button>
				<div style={{ marginTop: "8px" }}>
					<NeedANewAccount />
				</div>
			</Form>

			<BuyTicketsOrHelp />
		</TwoColumnPageWrap>
	);
};
