import { FullPageWrap, LoadingSpinner } from "@/components/AppBlocks";
import { H2 } from "@/components/text/Text";
import { getToken } from "@/state/actions/mailchimpActions";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { getUrlParameter } from "@/utils/Urls";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router";

export const ConnectMailchimp: FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const userId = useAppSelector((state) => state.auth.user?._id);

	const code = getUrlParameter("code");

	useEffect(() => {
		dispatch(getToken(code, userId, navigate));
	}, [code, userId, navigate, getToken]);

	return (
		<FullPageWrap>
			<div>
				<H2>
					<div style={{ textAlign: "center" }}>Connecting Mailchimp</div>
				</H2>
				<LoadingSpinner style={{ margin: "60px auto", display: "block" }} />
			</div>
		</FullPageWrap>
	);
};
