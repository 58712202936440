import { getConfig } from "@/config";
import { Button } from "@/ui";
import { css } from "@emotion/css";
import { DotsThreeVertical } from "@phosphor-icons/react";
import { Dropdown, Progress, Row } from "@/ui/antd";
import copy from "copy-text-to-clipboard";
import { Link } from "react-router-dom";
import AuthService from "@/services/AuthService";
import type { ReduxState as GlobalReduxState } from "@/state/store";
import { getEventSearchLink } from "@/utils/Events";
import { currency } from "@/utils/Format";
import DatesListDisplay from "@/components/DatesListDisplay";
import EventStatus from "@/components/EventStatus";
import MissingBankAccountIndicator from "@/components/MissingBankAccountIndicator";
import NoImage from "@/components/NoImage";
import Picture from "@/components/Picture";
import PrivateIndicator from "@/components/PrivateIndicator";
import SharedIndicator from "@/components/SharedIndicator";

const imgWrapper = css({
	display: "inline-block",
	margin: "30px 12px 30px 0px",
	width: 60,
	height: 60,
	borderRadius: 50,
	"@media(max-width: 800px)": {
		margin: "16px 0px 16px 0px"
	}
});

const dividerMobile = css`
	display: none;
	@media (max-width: 800px) {
		display: block;
		position: absolute;
		border-bottom: solid 1px #dedede;
		min-width: 100%;
		bottom: 60px;
		right: 0;
	}
`;

const navigationLinksWrapper = css({
	width: 134,
	position: "absolute",
	bottom: 16,
	height: 36,
	right: 20,
	display: "flex",
	justifyContent: "space-between",
	flexDirection: "row-reverse",
	"@media(max-width: 800px)": {
		position: "absolute",
		bottom: 12,
		right: 12
	},
	p: {
		display: "inline-block",
		margin: "0px 7px",
		color: "#dedede"
	}
});

const textWrapper = css`
	display: inline-block;
	vertical-align: middle;
	color: #323232;
	width: calc(100% - 210px);
	max-width: 263px;
	@media (max-width: 800px) {
		display: block;
		min-width: 100%;
	}
`;

const progressWrapper = css`
	display: inline-block;
	vertical-align: middle;
	margin-top: 16px;
	margin-left: 48px;
	@media (max-width: 850px) {
		margin-top: 0px;
		margin-left: 32px;
	}
	@media (max-width: 800px) {
		position: absolute;
		margin-left: 0px;
		bottom: 71.5px;
	}
`;

const progressInfo = css`
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.33px;
	color: #5a626c;
	@media (max-width: 800px) {
	}
`;

const netSaleWrapper = css`
	display: inline-block;
	margin-left: 48px;
	vertical-align: middle;
	height: 19px;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.33px;
	color: #5a626c;
	@media (max-width: 850px) {
		margin-left: 32px;
	}
	@media (max-width: 800px) {
		position: absolute;
		bottom: 90px;
		right: 12px;
	}
`;

const title = css`
	font-size: 16px;
	white-space: pre-wrap;
	font-weight: 600;
	max-width: 263px;
	overflow: hidden;
	max-height: 44px;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.37px;
	color: #323232;
	@media (max-width: 800px) {
		max-width: 100%;
		font-size: 14px;
		letter-spacing: -0.33px;
		color: #323232;
	}
`;

const date = css`
	font-size: 14px;
	max-width: 263px;
	height: 19px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.33px;
	color: var(--black-text);
	@media (max-width: 800px) {
		margin-bottom: 4px;
	}
`;

const imageBorderRadii = {
	topLeft: 50,
	topRight: 50,
	bottomLeft: 50,
	bottomRight: 50
};
const defaultWidth = 60;
const defaultHeight = 60;

const pickDataFromState = (state: Partial<GlobalReduxState>) => state.currentEvent?.event;
type EventState = ReturnType<typeof pickDataFromState>;

type EventRowInput = {
	toggleDuplicate: (eventName: string, eventId: string) => void;
	openTransferEventModal: (eventId: string) => void;
	user: any;
	toggleArchive: ({
		_id,
		name,
		isArchived,
		published,
		endDate
	}: {
		_id: string;
		name: string;
		isArchived: boolean;
		published: boolean;
		endDate: any;
	}) => void;
	minimal: boolean;
} & EventState;

const EventRow = ({
	name,
	dates,
	isArchived,
	bannerImage,
	featureImage,
	socialImage,
	totalCapacity,
	totalTicketsSold = 0,
	totalNetSales,
	published,
	_id,
	slug,
	referral,
	upcoming,
	isPrivate,
	toggleDuplicate,
	openTransferEventModal,
	endDate,
	userId,
	user,
	eventCreationSteps,
	paymentOptions,
	ticketTypes,
	toggleArchive,
	timezone,
	location,
	minimal
}: EventRowInput) => {
	const isAdmin = AuthService.isAdmin();

	const validDates = dates.filter((date: any) => !date.disabled && !date.deleted);
	const eventTotalCapacity = totalCapacity * validDates.length;

	const bg = !minimal ? "white" : "#F9F9FA";
	const row = css`
		padding: 0px 20px 0px 20px;
		position: relative;
		background-color: ${bg};
		margin-bottom: 12px;
		border-radius: 8px;
		min-height: 120px;
		height: 120px;
		transition: border 0.2s ease-in-out;

		&:hover {
			outline: var(--border1-hover);
		}

		@media (max-width: 800px) {
			height: 276px !important;
		}
	`;

	const statusBlock = css`
		display: block;
		margin-right: 0px;
		font-weight: 600;
		position: absolute;
		text-align: right;
		float: right;
		top: 16px;
		right: 20px;
		@media (max-width: 800px) {
			right: 12px;
		}
	`;

	const img = bannerImage ? bannerImage : socialImage ? socialImage : featureImage;

	const percentage = (totalTicketsSold / eventTotalCapacity) * 100;

	return (
		<Row
			gutter={2}
			className={row}
			style={{
				position: "relative",
				height: "auto",
				marginRight: 0,
				marginLeft: 0,
				zoom: 1,
				display: "block",
				boxSizing: "border-box"
			}}
		>
			<Link key={_id} to={getEventSearchLink(_id, eventCreationSteps)}>
				<div>
					<div className={imgWrapper}>
						{img ? (
							<Picture
								handle={img.handle}
								alt={name}
								borderRadii={imageBorderRadii}
								defaultWidth={defaultWidth}
								defaultHeight={defaultHeight}
							/>
						) : (
							<NoImage paddingTop="30px" borderRadii={imageBorderRadii} />
						)}
					</div>
					<div className={textWrapper}>
						<div className={title}>{name}</div>
						<div className={date}>
							<DatesListDisplay dates={dates} upcoming={upcoming} timezone={timezone} location={location} />
						</div>
					</div>
					{totalTicketsSold && eventTotalCapacity ? (
						<div className={progressWrapper}>
							<Progress
								style={{ width: "120px", display: "inline-block" }}
								strokeWidth={8}
								strokeColor="#57cfc7"
								percent={percentage > 100 ? 100 : percentage}
								showInfo={false}
							/>
							<div className={progressInfo}>
								{totalTicketsSold}/{eventTotalCapacity}
							</div>
						</div>
					) : null}
					<div className={netSaleWrapper}>{totalNetSales === null ? "" : currency(totalNetSales)}</div>
					<div className={statusBlock}>
						<MissingBankAccountIndicator eventId={_id} paymentOptions={paymentOptions} ticketTypes={ticketTypes} />
						<EventStatus isArchived={isArchived} published={published} endDate={endDate} />
						{user || isPrivate ? (
							<div>
								{user ? <SharedIndicator userId={user.id} ownerId={userId} sharedObjectName="event" /> : null}
								{isPrivate ? <PrivateIndicator /> : null}
							</div>
						) : null}
					</div>
				</div>
			</Link>
			<div className={dividerMobile} />
			<div className={navigationLinksWrapper}>
				{!eventCreationSteps || eventCreationSteps.complete ? (
					<Dropdown
						placement="bottomRight"
						menu={{
							items: [
								{
									key: "view",
									label: (
										<div
											onClick={() => {
												window.open(`${getConfig("TICKETING_URL")}/${slug}?token=${AuthService.getToken()}`);
											}}
										>
											<span>View</span>
										</div>
									)
								},
								{
									key: "edit",
									label: (
										<Link to={`/console/my-events/${_id}/info/basics`}>
											<span data-cy="event-edit-link">Edit</span>
										</Link>
									)
								},
								{
									key: "copy",
									label: (
										<div
											onClick={() => {
												copy(`${getConfig("TICKETING_URL")}/${slug}`);
											}}
										>
											<span>Copy URL</span>
										</div>
									)
								},
								{
									key: "duplicate",
									label: (
										<div
											onClick={() => {
												toggleDuplicate(name, _id);
											}}
										>
											<span>Duplicate event</span>
										</div>
									)
								},
								{
									key: "archive",
									label: (
										<div
											onClick={() => {
												toggleArchive({ name, _id, isArchived, published, endDate });
											}}
										>
											<span>{isArchived ? "Unarchive" : "Archive"} event</span>
										</div>
									)
								},
								...(isAdmin
									? [
											{
												key: "payouts",
												label: (
													<Link to={`/console/my-events/${_id}/payments/payouts`}>
														<span data-cy="event-payout-link">Payouts</span>
													</Link>
												)
											}
									  ]
									: []),
								...(referral && isAdmin
									? [
											{
												key: "referred",
												label: (
													<Link to={`/console/admin/users/${referral.referrerId}`}>
														<span>Referred event</span>
													</Link>
												)
											}
									  ]
									: []),
								...(isAdmin
									? [
											{
												key: "transfer",
												label: (
													<div onClick={() => openTransferEventModal(_id)}>
														<span>Transfer event</span>
													</div>
												)
											}
									  ]
									: [])
							]
						}}
					>
						<Button aria-label="More" iconOnly variant="text">
							<DotsThreeVertical size={22} weight="bold" />
						</Button>
					</Dropdown>
				) : null}
				<Link to={getEventSearchLink(_id, eventCreationSteps)}>
					<Button aria-label="Manage" dataCy="manage-event" variant="tertiary">
						Manage
					</Button>
				</Link>
			</div>
		</Row>
	);
};

export default EventRow;
