import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { reducer as formReducer } from "redux-form";

import { CLEAR_STATE } from "./actions/authActions";
import accessCodesReducer from "./reducers/accessCodesReducer";
import adminReportsAttendeesReducer from "./reducers/adminReports/attendeesReducer";
import adminReportsDatesReducer from "./reducers/adminReports/datesReducer";
import adminReportsEventsReducer from "./reducers/adminReports/eventsReducer";
import adminReportsFinanceReducer from "./reducers/adminReports/financeReducer";
import adminReportsUserReducer from "./reducers/adminReports/usersReducer";
import adminUtilityReducer from "./reducers/adminUtilityReducer";
import applyToAllReducer from "./slices/applyToAll";
import assetsReducer from "./reducers/assetsReducer";
import auditLogsReducer from "./reducers/auditLogsReducer";
import authReducer, { AuthReducerState } from "./reducers/authReducer";
import authorizationReducer, { AuthorizationReducerState } from "./reducers/authorizationReducer";
import bankAccountsReducer from "./reducers/bankAccountsReducer";
import boxOfficeReducers from "./reducers/boxOfficeReducers";
import canvaReducer, { CanvaReducerState } from "./reducers/canvaReducer";
import checkinReducer from "./reducers/checkInReducer";
import dashboardMetricReducer from "./reducers/dashboardMetricReducer";
import deleteEventReducer from "./reducers/deleteEventReducer";
import discountCodesReducer from "./reducers/discountCodesReducer";
import dropdownReducer, { DropdownReducerState } from "./reducers/dropdownReducer";
import emailCampaignsReducer from "./reducers/emailCampaignsReducer";
import emailLogsReducer from "./reducers/emailLogsReducer";
import eventListReducer, { EventListReducerState } from "./reducers/eventListReducer";
import eventMetricReducer from "./reducers/eventMetricReducer";
import eventReducer, { EventReducerState } from "./reducers/eventReducer";
import facebookReducer from "./reducers/facebookReducer";
import followersReducer from "./reducers/followersReducer";
import gatewaysReducer from "./reducers/gatewaysReducer";
import giftCardReducer from "./reducers/giftCardReducer";
import globalReportReducer, { GlobalReportReducerState } from "./reducers/globalReportReducer";
import locationReducer from "./reducers/locationReducer";
import mailchimpReducer from "./reducers/mailchimpReducer";
import massPayoutsReducer from "./reducers/massPayoutsReducer";
import merchandiseReducer from "./reducers/merchandiseReducer";
import newOrderReducer from "./reducers/newOrderReducer";
import newPayoutReducer, { NewPayoutReducerState } from "./reducers/newPayoutReducer";
import orderListReducer from "./reducers/orderListReducer";
import orderReducer from "./reducers/orderReducer";
import organiserReducer, { OrganiserReducerState } from "./reducers/organiserReducer";
import packagedTicketsReducer from "./reducers/packagedTicketsReducer";
import payoutReducer from "./reducers/payoutReducer";
import previewReducer from "./reducers/previewReducer";
import userReferralsReducer from "./reducers/referralReducer";
import reportReducer from "./reducers/reportReducer";
import salesforceReducer from "./reducers/salesforceReducer";
import scanningMessageReducer from "./reducers/scanningMessageReducer";
import schedulerReducer from "./reducers/schedulerReducer";
import searchReducer, { SearchReducerState } from "./reducers/searchReducer";
import seatingMapReducer from "./reducers/seatingMapReducer";
import seatingMapTicketReducer from "./reducers/seatingMapTicketReducer";
import seatingMapUndoReducer from "./reducers/seatingMapUndoReducer";
import tagReducer from "./reducers/tagReducer";
import templateReducer from "./reducers/templateReducer";
import ticketSaleStatusReducer from "./reducers/ticketSaleStatusReducer";
import tourReducer, { TourReducerState } from "./reducers/tourReducer";
import userEventListReducer from "./reducers/userEventListReducer";
import userReducer, { UserReducerState } from "./reducers/userReducer";
import usersListReducer from "./reducers/usersListReducer";
import virtualEventHubReducer from "./reducers/virtualEventHubReducer";
import waitlistReducer from "./reducers/waitlistReducer";

const adminReports = combineReducers({
	attendees: adminReportsAttendeesReducer,
	dates: adminReportsDatesReducer,
	events: adminReportsEventsReducer,
	finance: adminReportsFinanceReducer,
	users: adminReportsUserReducer
});

const reducers = {
	accessCodes: accessCodesReducer,
	adminReports,
	adminUtilities: adminUtilityReducer,
	applyToAll: applyToAllReducer,
	assets: assetsReducer,
	auditLogs: auditLogsReducer,
	auth: authReducer,
	authorization: authorizationReducer,
	bankAccounts: bankAccountsReducer,
	boxOffice: boxOfficeReducers,
	canva: canvaReducer,
	checkIn: checkinReducer,
	currentEvent: eventReducer,
	currentUser: userReducer,
	dashboardMetrics: dashboardMetricReducer,
	deletingEvent: deleteEventReducer,
	discountCodes: discountCodesReducer,
	dropdown: dropdownReducer,
	emailCampaigns: emailCampaignsReducer,
	emailLogs: emailLogsReducer,
	eventMetrics: eventMetricReducer,
	events: eventListReducer,
	facebook: facebookReducer,
	followers: followersReducer,
	form: formReducer,
	gateways: gatewaysReducer,
	giftCard: giftCardReducer,
	globalReportState: globalReportReducer,
	locations: locationReducer,
	mailchimp: mailchimpReducer,
	massPayouts: massPayoutsReducer,
	merchandise: merchandiseReducer,
	newOrder: newOrderReducer,
	order: orderReducer,
	orders: orderListReducer,
	organisers: organiserReducer,
	packagedTickets: packagedTicketsReducer,
	payments: payoutReducer,
	payouts: newPayoutReducer,
	preview: previewReducer,
	reports: reportReducer,
	salesforce: salesforceReducer,
	scanningMessage: scanningMessageReducer,
	scheduler: schedulerReducer,
	search: searchReducer,
	seatingMap: seatingMapReducer,
	seatingMapTicket: seatingMapTicketReducer,
	seatingMapUndo: seatingMapUndoReducer,
	tags: tagReducer,
	templates: templateReducer,
	ticketSaleStatus: ticketSaleStatusReducer,
	tours: tourReducer,
	userEvents: userEventListReducer,
	userReferrals: userReferralsReducer,
	users: usersListReducer,
	virtualEventHub: virtualEventHubReducer,
	waitlist: waitlistReducer
};

const reducer = combineReducers(reducers);

const rootReducer = (state: any, action: any) => {
	if (action.type === CLEAR_STATE) {
		state = undefined;
	}
	return reducer(state, action);
};

const preloadedState = {};
const store = configureStore({
	reducer: rootReducer,

	// disable serializableCheck and immutableCheck because redux-toolkit throws an error due to our implementation of redux-form (make an event and see what happens)
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
	preloadedState
});

type StoreState = ReturnType<typeof store.getState>;
export type ReduxState = StoreState & {
	auth: AuthReducerState;
	authorization: AuthorizationReducerState;
	canva: CanvaReducerState;
	currentEvent: EventReducerState;
	currentUser: UserReducerState;
	dropdown: DropdownReducerState;
	events: EventListReducerState;
	globalReportState: GlobalReportReducerState;
	organisers: OrganiserReducerState;
	payouts: NewPayoutReducerState;
	search: SearchReducerState;
	tours: TourReducerState;
};

export type AppDispatch = typeof store.dispatch;

export default store;
