import icon from "@/assets/menu/ic_calendar.svg";

const Events = () => {
	const children = [
		{ path: "/console/my-events?filter=all", label: "All events", key: "allEvents" },
		{ path: "/console/my-events?filter=published", label: "Live events", key: "liveEvents" },
		{ path: "/console/my-events?filter=unpublished", label: "Draft events", key: "draftEvents" },
		{ path: "/console/my-events?filter=past", label: "Past events", key: "pastEvents" },
		{ path: "/console/my-events?filter=archived", label: "Archived events", key: "archivedEvents" }
	];

	return [
		{
			label: "Events",
			path: "/console/my-events",
			key: "events",
			children,
			icon
		}
	];
};

export default Events;
