import { FC } from "react";
import { Route, Routes as RouterRoutes } from "react-router";
import EmailCampaign from "../account/globalEmailCampaigns/EmailCampaign";
import EmailCampaigns from "../account/globalEmailCampaigns/EmailCampaigns";
import UnsubscriberList from "../account/globalEmailCampaigns/UnsubscriberList";
import Page from "../components/Page";
import AuthService from "../services/AuthService";

const Pages = () => (
	<Page type="details">
		<RouterRoutes>
			<Route path="email-campaigns" element={<EmailCampaigns />} />
			<Route path="email-campaigns/:emailId" element={<EmailCampaign />} />
			<Route path="email-campaigns/:emailId/:template" element={<EmailCampaign />} />
			<Route path="email-campaigns-unsubscriptions" element={<UnsubscriberList />} />
		</RouterRoutes>
	</Page>
);

const Comms: FC = () => {
	if (AuthService.isAdmin()) {
		return (
			<div style={{ height: "100%" }}>
				<Pages />
			</div>
		);
	}
	return <Pages />;
};

export default Comms;
