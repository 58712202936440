import { css } from "@emotion/css";
import { Component } from "react";
import { Else, If, Then } from "react-if";
import { connect } from "react-redux";
import { Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import { ContentBlock } from "@/components/AppBlocks";
import { LegacyInputField, InputNumberField, SelectField } from "@/components/Fields";
import FormBar from "@/components/FormBar";
import IconButton from "@/components/IconButton";
import LoadErrorView from "@/components/LoadErrorView";
import PageHeading from "@/components/PageHeading";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import ButtonsBar from "@/components/buttons/ButtonsBar";
import NoResults from "@/components/noResults/NoResults";
import ResponsiveTable from "@/components/table/ResponsiveTable";
import TableActions from "@/components/table/TableActions";
import { P } from "@/components/text/Text";
import withSaveCheck from "@/hoc/withSaveCheck";
import { withRouterHooks } from "@/hoc/withRouterHooks";
import { saveEvent } from "@/state/actions/eventActions";
import { HelpIds } from "@/utils/Help";
import { scrollToErrors } from "@/utils/ScrollToErrors";
import { required } from "@/utils/Validators";

const formName = "groupedCapacity";
const styles = {
	asterisk: css({
		color: "#c0212c"
	})
};

class CapacityGroups extends Component {
	constructor(props) {
		super(props);
		this.state = { expandedRowKeys: [] };
		this.save = this.save.bind(this);
	}

	save(values) {
		const { params, saveEvent } = this.props;
		const eventId = params?.eventId;
		saveEvent(eventId, { groupedCapacity: values.groupedCapacity });
	}

	createGroup(fields) {
		const group = {
			name: null,
			tickets: [],
			quantity: null,
			isManagedBySeatingMap: false
		};
		fields.push(group);
	}

	onExpand = (expanded, { key }) => {
		const tempKeys = this.state.expandedRowKeys;
		if (expanded) {
			tempKeys.push(key);
		} else {
			const index = tempKeys.indexOf(key);
			tempKeys.splice(index, 1);
		}
		this.setState({ expandedRowKeys: tempKeys });
	};

	render() {
		const { handleSubmit, currentEvent, groupedCapacity, dispatch } = this.props;
		const { expandedRowKeys } = this.state;
		return (
			<form onSubmit={handleSubmit(this.save)}>
				<PageHeading
					title="Grouped capacity"
					helpButton={{ title: "Help - Grouped capacity", link: HelpIds.groupedCapacity }}
				/>
				<LoadErrorView loading={currentEvent.save.loading} error={currentEvent.save.error} retryAction={this.save}>
					<ContentBlock>
						<P>
							Make sure you don't over-sell your days or sessions with grouped capacity. Set how many tickets can be
							sold across a group of tickets in your event. All tickets in the group will be displayed as sold out when
							the total number of ticket sold across the group reaches the group capacity.
						</P>
						<FieldArray
							name="groupedCapacity"
							component={CapaitcyGroup}
							ticketTypes={currentEvent.event.ticketTypes}
							packagedTickets={currentEvent.event.packagedTickets}
							groupedCapacity={groupedCapacity}
							createGroup={this.createGroup}
							dispatch={dispatch}
							onExpand={this.onExpand}
							expandedRowKeys={expandedRowKeys}
						/>
					</ContentBlock>
					<FormBar formLayout="horizontal">
						<LegacyButton
							style={{
								float: "right"
							}}
							type="primary"
							htmlType="submit"
							disabled={currentEvent.save.loading}
							ariaLabel="Save"
							size="large"
						>
							Save
						</LegacyButton>
					</FormBar>
				</LoadErrorView>
			</form>
		);
	}
}

class CapaitcyGroup extends Component {
	nameRequired = required("a name is required");
	capacityRequired = required("a capacity is required");

	render() {
		const { fields, groupedCapacity, ticketTypes, createGroup, onExpand, expandedRowKeys, dispatch } = this.props;
		let tickets = ticketTypes.filter((ticket) => !(ticket.disabled || ticket.deleted));
		const options = tickets.map((ticket) => {
			return { value: ticket._id, label: ticket.name };
		});
		const columns = [
			{
				title: (
					<span>
						<span className={styles.asterisk}>* </span> Name
					</span>
				),
				dataIndex: "name",
				key: "name",
				render: (_, __, index) => {
					return (
						<div
							className={css({
								"@media(max-width: 600px)": {
									float: "left",
									marginRight: 10,
									minWidth: "75vw"
								}
							})}
						>
							<Field
								inline
								name={`groupedCapacity[${index}].name`}
								component={LegacyInputField}
								placeholder="Name"
								validate={this.nameRequired}
							/>
						</div>
					);
				}
			},
			{
				title: "Tickets",
				dataIndex: "tickets",
				key: "tickets",
				render: (_, __, index) => {
					return (
						<div
							className={css({
								"@media(max-width: 600px)": {
									float: "left",
									marginRight: 10,
									minWidth: "75vw"
								}
							})}
						>
							<Field
								style={{ maxWidth: 180 }}
								mode="multiple"
								name={`groupedCapacity[${index}].tickets`}
								component={SelectField}
								options={options}
							/>
						</div>
					);
				}
			},
			{
				title: (
					<span>
						<span className={styles.asterisk}>* </span> Capacity
					</span>
				),
				dataIndex: "capacity",
				key: "capacity",
				render: (_, __, index) => {
					return (
						<div
							className={css({
								"@media(max-width: 600px)": {
									float: "left",
									marginRight: 10,
									minWidth: "75vw"
								}
							})}
						>
							<Field
								style={{ maxWidth: 180 }}
								mode="multiple"
								name={`groupedCapacity[${index}].quantity`}
								component={InputNumberField}
								validate={this.capacityRequired}
								allowsNullValue={true}
							/>
						</div>
					);
				}
			},
			{
				title: "Action",
				dataIndex: "",
				key: "x",
				render: (_, __, index) => (
					<div
						className={css({
							"@media(max-width: 600px)": {
								float: "left",
								marginRight: 10,
								minWidth: "75vw"
							}
						})}
					>
						<TableActions>
							<IconButton
								icon="delete"
								onClick={() => fields.remove(index)}
								tooltip="Delete"
								showTooltip
								ariaLabel="Delete"
							/>
						</TableActions>
					</div>
				)
			}
		];
		//pre render row
		if (groupedCapacity) {
			groupedCapacity.forEach((group, index) => {
				group.key = index;
			});
		}

		return (
			<div>
				<If condition={!groupedCapacity || !groupedCapacity.length}>
					<Then>
						<NoResults
							title=""
							message=""
							action={() => createGroup(fields, ticketTypes)}
							actionTxt="Create Group"
							secondary
							inverse
						/>
					</Then>
					<Else>
						{() => (
							<div style={{ width: "100%", overflowX: "auto" }}>
								<ResponsiveTable
									pagination={false}
									draggable={{ dispatch, formName, fieldName: "groupedCapacity" }}
									columns={columns}
									dataSource={groupedCapacity.filter((gc) => !gc.isManagedBySeatingMap)}
									rowKey="key"
									onExpand={onExpand}
									expandedRowKeys={expandedRowKeys}
								/>

								<ButtonsBar>
									<LegacyButton
										onClick={() => createGroup(fields, ticketTypes)}
										icon={{ name: "plus", left: true }}
										type="action"
										ariaLabel="Add Group"
									>
										Add group
									</LegacyButton>
								</ButtonsBar>
							</div>
						)}
					</Else>
				</If>
			</div>
		);
	}
}

CapacityGroups = reduxForm({
	form: formName,
	touchOnChange: true,
	touchOnBlur: true,
	enableReinitialize: true,
	onSubmitFail: () => {
		scrollToErrors();
	}
})(CapacityGroups);

const selector = formValueSelector(formName);
export default withRouterHooks(
	connect(
		(state) => ({
			initialValues: {
				groupedCapacity: state.currentEvent.event.groupedCapacity
			},
			currentEvent: state.currentEvent,
			groupedCapacity: selector(state, "groupedCapacity")
		}),
		{ saveEvent }
	)(withSaveCheck(CapacityGroups, formName))
);
