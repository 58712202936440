import { SingleColumnPageWrap } from "@/auth/components/SingleColumnPageWrap";
import { LoadingSpinner } from "@/components/AppBlocks";
import { H2, P } from "@/components/text/Text";
import { ON_USER_EMAIL_VERIFIED } from "@/state/actions/authActions";
import { useAppDispatch } from "@/state/hooks";
import { trpc } from "@/trpc";
import { getUrlParameter } from "@/utils/Urls";
import { notification } from "antd";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";

export const VerifyEmail: FC = () => {
	const navigate = useNavigate();
	const verifyEmailProcedure = trpc.users.verifyEmail.useMutation();
	const [isVerifying, setIsVerifying] = useState(false);
	const dispatch = useAppDispatch();

	const verifyAccount = () => {
		const token = getUrlParameter("t");
		const id = getUrlParameter("i");
		const email = getUrlParameter("e");

		if (!token || !id || !email) {
			notification.error({ message: "Failed", description: "Unable to verify email" });
			return;
		}

		setIsVerifying(true);
		verifyEmailProcedure
			.mutateAsync({ token, id, email })
			.then((response) => {
				notification.success({ message: "Success", description: response.message });
				dispatch({ type: ON_USER_EMAIL_VERIFIED });

				setTimeout(() => {
					navigate("/onboarding-organiser/1?newuser=true");
				}, 2000);
			})
			.catch((err) => {
				notification.error({ message: "Failed", description: err.message });
				setIsVerifying(false);
				setTimeout(() => navigate("/signin"), 5000);
			});
	};

	useEffect(() => {
		verifyAccount();
	}, []);

	return (
		<SingleColumnPageWrap showBuyTicketLink>
			<div style={{ textAlign: "center" }}>
				{isVerifying ? (
					<>
						<H2>Verifying your email address</H2>
						<div style={{ margin: "20px auto", width: 40 }}>
							<LoadingSpinner />
						</div>
					</>
				) : null}

				{verifyEmailProcedure.error ? (
					<>
						<H2>Oops! Something went wrong.</H2>
						<P style={{ maxWidth: "unset", width: "100%" }}>Try again or contact us if the issue continues.</P>
						<P style={{ maxWidth: "unset", width: "100%" }}>
							<b>We'll take you back to the Sign In page in a moment...</b>
						</P>
					</>
				) : null}
			</div>
		</SingleColumnPageWrap>
	);
};
