import { getConfig } from "@/config";
import AuthService from "@/services/AuthService";
import { HumaniDate } from "@hx/dates";

export const isEventFree = (event) => {
	const paidTickets = event.ticketTypes.filter((ticket) => {
		const price = getTicketMinPrice(ticket);
		return !ticket.disabled && !ticket.deleted && !ticket.isDonation && price > 0;
	});
	const paidPackagedTickets = event.packagedTickets
		? event.packagedTickets.filter((ticket) => {
				const price = getTicketMinPrice(ticket);
				return !ticket.disabled && !ticket.deleted && price > 0;
		  })
		: [];
	const donations = event.ticketTypes.filter((t) => !t.disabled && !t.deleted && t.isDonation);
	return !(paidTickets.length || paidPackagedTickets.length || donations.length);
};

const getTicketMinPrice = (ticket) => {
	if (ticket.priceOptions && ticket.priceOptions.enabled) {
		const values = ticket.priceOptions.options.map((opt) => opt.value);
		const min = Math.min(...values);
		return min ? min : 0;
	}
	if (ticket.priceRange && ticket.priceRange.enabled) {
		return ticket.priceRange.min ? ticket.priceRange.min : 0;
	}
	return ticket.price;
};

export const isEventNfpOrCharity = (event) => {
	return !!(
		event &&
		event.fees &&
		event.fees.type &&
		(event.fees.type === "notForProfit" || event.fees.type === "charity")
	);
};

export const getPublishText = (event) => {
	let publishTxt;
	if (event.published) {
		publishTxt = "Unpublish";
	} else if (event.scheduledPublishDate) {
		publishTxt = "Schedule";
	} else {
		publishTxt = "Publish";
	}
	return publishTxt;
};

export const getPublishMessage = (event) => {
	let publishMsg = event.published ? "This event is live" : "This event is a draft";
	if (event.isArchived) {
		publishMsg = "This event is archived";
	} else if (publishMsg === "This event is live" && Date.now() > Date.parse(event.endDate)) {
		publishMsg = "This event has passed";
	} else if (!event.published && event.scheduledPublishDate) {
		publishMsg = `This event is live on ${new HumaniDate(
			event.scheduledPublishDate,
			event.timezone,
			event.location
		).formatting.dateTime()}`;
	}

	if (event.suspendSales) {
		publishMsg = `${publishMsg}. Sales have been stopped`;
	} else if (event.markedAsSoldOut) {
		publishMsg = `${publishMsg}. Event marked as sold out`;
	}
	return publishMsg;
};

export const getEventSearchLink = (id, eventCreationSteps) => {
	const baseLink = `/console/my-events/${id}`;
	if (!eventCreationSteps || eventCreationSteps.complete) return baseLink;
	if (!eventCreationSteps.basics) return `${baseLink}/info/basics`;
	if (!eventCreationSteps.schedule) return `${baseLink}/info/recurring-dates`;
	if (!eventCreationSteps.details) return `${baseLink}/info/details`;
	if (!eventCreationSteps.ticketTypes) return `${baseLink}/info/ticket-types`;
	if (!eventCreationSteps.projects) return `${baseLink}/info/projects`;
	return baseLink;
};

export const getEventUrls = (event, forceExcludeTokens = false) => {
	const ticketingUrl = `${getConfig("TICKETING_URL")}/`;

	const eventPageUrl = `${ticketingUrl}${event?.slug}`;
	const selectTicketsUrl = `${ticketingUrl}${event?.slug}/tickets`;
	const publicResaleUrl = `${ticketingUrl}${event?.slug}/resale`;

	const excludeTokens = forceExcludeTokens || event.published;

	const tokenQuery = excludeTokens ? "" : `?token=${AuthService.getToken()}`;

	return {
		eventPage: eventPageUrl.concat(tokenQuery),
		selectTicketsPage: selectTicketsUrl.concat(tokenQuery),
		publicResaleUrl: publicResaleUrl
	};
};
