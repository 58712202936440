import { notification } from "@/ui/antd";
import FileDownload from "react-file-download";
import FollowerService from "../../services/FollowerService";
import Filename from "../../utils/Filename";
import { getOrganiser } from "./organiserActions";
import AuthService from "@/services/AuthService";

export const FOLLOWERS_FIND_REQUEST = "FOLLOWERS_FIND_REQUEST";
export const FOLLOWERS_FIND_SUCCESS = "FOLLOWERS_FIND_SUCCESS";
export const FOLLOWERS_FIND_ERROR = "FOLLOWERS_FIND_ERROR";

export const FOLLOWERS_CSV_REQUEST = "FOLLOWERS_CSV_REQUEST";
export const FOLLOWERS_CSV_SUCCESS = "FOLLOWERS_CSV_SUCCESS";
export const FOLLOWERS_CSV_ERROR = "FOLLOWERS_CSV_ERROR";

export const FOLLOWERS_IMPORT_OPEN = "FOLLOWERS_IMPORT_OPEN";
export const FOLLOWERS_IMPORT_CLOSE = "FOLLOWERS_IMPORT_CLOSE";

export const FOLLOWERS_UPLOAD_REQUEST = "FOLLOWERS_UPLOAD_REQUEST";
export const FOLLOWERS_UPLOAD_SUCCESS = "FOLLOWERS_UPLOAD_SUCCESS";
export const FOLLOWERS_UPLOAD_ERROR = "FOLLOWERS_UPLOAD_ERROR";

export const openImport = () => {
	return (dispatch) => {
		dispatch({ type: FOLLOWERS_IMPORT_OPEN });
	};
};
export const closeImport = () => {
	return (dispatch) => {
		dispatch({ type: FOLLOWERS_IMPORT_CLOSE });
	};
};

export const find = (page, organiserId, query) => {
	return (dispatch) => {
		dispatch({ type: FOLLOWERS_FIND_REQUEST });
		FollowerService.find(query, page, organiserId)
			.then((followers) => {
				dispatch({
					type: FOLLOWERS_FIND_SUCCESS,
					followers,
					page
				});
			})
			.catch((err) => {
				dispatch({ type: FOLLOWERS_FIND_ERROR, error: err });
			});
	};
};

export const download = (organiserId, query) => {
	const filename = Filename.generateFileName("Followers", ".csv");
	return (dispatch) => {
		dispatch({ type: FOLLOWERS_CSV_REQUEST });
		FollowerService.download(query, organiserId)
			.then((data) => {
				dispatch({ type: FOLLOWERS_CSV_SUCCESS });
				FileDownload(data, filename);
			})
			.catch(() => {
				dispatch({ type: FOLLOWERS_CSV_ERROR });
			});
	};
};

export const remove = (organiserId, followerId) => {
	return async (dispatch, getState) => {
		try {
			await FollowerService.remove(organiserId, followerId);
			const state = getState();
			dispatch(find(state.followers.list.page, organiserId));
			dispatch(getOrganiser(organiserId));
		} catch (error) {
			notification.error({ message: "Fail to delete follower" });
		}
	};
};

export const upload = (organiserId, followers) => {
	if (!AuthService.isAdmin()) {
		notification.warning({
			message: "Please contact Humanitix if you wish to import followers."
		});
		return;
	}

	return async (dispatch) => {
		dispatch({ type: FOLLOWERS_UPLOAD_REQUEST });
		try {
			const uploadResults = await FollowerService.upload(organiserId, followers);
			dispatch({ type: FOLLOWERS_UPLOAD_SUCCESS, results: uploadResults });
			dispatch(find(1, organiserId));
			dispatch(getOrganiser(organiserId));
		} catch (err) {
			console.error(err);
			dispatch({ type: FOLLOWERS_UPLOAD_ERROR });
			notification.error({ message: "Fail to import followers" });
		}
	};
};
