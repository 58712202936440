import { withRouterHooks } from "@/hoc/withRouterHooks";
import { css } from "@emotion/css";
import { Col, DatePicker, Row, Select } from "@/ui/antd";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
	getDashboardOverview,
	getDashboardSummary,
	updateDashboardChannel,
	updateDashboardCustomDateRange,
	updateDashboardCustomDateRangePicked,
	updateDashboardPeriod
} from "../../state/actions/metricActions";
import { getCurrencyFromLocation } from "../../utils/Format";
import { LoadingSpinner } from "../AppBlocks";
import EventRow from "../EventRow";
import { H2, H5, P } from "../text/Text";
import Widget from "./Widget";

const RangePicker = DatePicker.RangePicker;

const labelStyle = css({
	display: "flex",
	justifyContent: "space-between",
	marginBottom: 16,
	h2: {
		display: "inline",
		"&:nth-child(2)": {
			color: "#353337"
		}
	}
});

const headerWrapper = css({
	display: "flex",
	flexDirection: "column"
});

const overviewFilterWrapper = css({
	display: "flex",
	justifyContent: "flex-end",
	flexWrap: "wrap",
	gap: 8
});

const overviewSummaryTiles = css({
	backgroundColor: "#ffb18f",
	boxShadow: "0px 2px 4px rgba(9, 30, 66, 0.13)",
	borderRadius: 4,
	height: 170,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	textAlign: "center"
});

const overviewSummaryTileContainer = css({
	backgroundColor: "transparent",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center"
});

const numberStyling = css({
	fontSize: "2rem",
	fontWeight: "700",
	marginBottom: 0
});

class OverviewSummary extends Component {
	constructor(props) {
		super(props);
		this.onDatePickerChange = this.onDatePickerChange.bind(this);
	}

	componentDidMount() {
		const { getDashboardSummary, metrics } = this.props;

		getDashboardSummary(metrics.period);
	}

	onDatePickerChange(period) {
		const { getDashboardOverview, getDashboardSummary, updateDashboardCustomDateRange, metrics } = this.props;

		getDashboardSummary(period);
		getDashboardOverview(period, metrics.channel);
		updateDashboardCustomDateRange(period);
	}

	handleChannelChange = (channel) => {
		const { getDashboardOverview, getDashboardSummary, updateDashboardChannel, metrics } = this.props;

		getDashboardSummary(metrics.period);
		getDashboardOverview(metrics.period, channel);
		updateDashboardChannel(channel);
	};

	handlePeriodChange = (period) => {
		const {
			getDashboardOverview,
			getDashboardSummary,
			updateDashboardPeriod,
			updateDashboardCustomDateRangePicked,
			metrics
		} = this.props;

		const isCustomRangePicked = period === "customDate";
		updateDashboardPeriod(period);
		updateDashboardCustomDateRangePicked(isCustomRangePicked);

		if (!isCustomRangePicked) {
			getDashboardSummary(period);
			getDashboardOverview(period, metrics.channel);
		}
	};

	render() {
		const { metrics, title } = this.props;
		const { overviewByDate, summary } = metrics;
		const { loading: overviewLoading } = overviewByDate;
		const { ticketsSold, totalEarnings } = overviewByDate?.overview || {};
		const { numberOfUpcomingEvents, bestSellingEvent, latestDraftEvent } = summary || {};

		const numberFormatter = Intl.NumberFormat(
			{},
			{
				style: "currency",
				currency: getCurrencyFromLocation(),
				notation: "compact",
				currencyDisplay: "narrowSymbol"
			}
		);

		const yourOverviewTitleRow = (
			<div className={headerWrapper}>
				<div className={labelStyle}>
					<div>
						<H2>Your overview</H2>
					</div>
					<div className={overviewFilterWrapper}>
						<Select
							size="default"
							style={{ fontSize: 14, width: 140 }}
							defaultValue={metrics.channel}
							onChange={(value) => this.handleChannelChange(value)}
							options={[
								{ label: "All channels", value: "all" },
								{ label: "Online sales", value: "online" },
								{ label: "Manual sale", value: "manual" },
								{ label: "Box Office", value: "boxOffice" }
							]}
						/>
						<Select
							size="default"
							style={{ fontSize: 14, width: 140 }}
							defaultValue={metrics.period}
							onChange={(value) => this.handlePeriodChange(value)}
							options={[
								{ label: "Today", value: "today" },
								{ label: "Yesterday", value: "yesterday" },
								{ label: "Last 7 days", value: "week" },
								{ label: "Last 30 days", value: "month" },
								{ label: "Custom Date", value: "customDate" }
							]}
						/>
					</div>
				</div>
				<div>
					{metrics.isCustomRangePicked ? (
						<RangePicker
							onChange={this.onDatePickerChange}
							value={metrics.customDateRange}
							style={{ paddingBottom: 10 }}
						/>
					) : null}
				</div>
			</div>
		);

		const viewAllEventsUrl = `/console/my-events`;

		return (
			<div>
				<div>
					{yourOverviewTitleRow}
					<Row gutter={8} style={{ marginBottom: 16 }} key="3boxes">
						<Col span={8} key="events">
							<div className={overviewSummaryTiles} style={{ position: "relative" }}>
								<div className={overviewSummaryTileContainer}>
									{numberOfUpcomingEvents === undefined || overviewLoading ? (
										<LoadingSpinner size={48} />
									) : (
										<p className={numberStyling}>{numberOfUpcomingEvents}</p>
									)}
									<P>Upcoming events</P>
								</div>
								<div style={{ position: "absolute", bottom: 0 }}>
									<Link to={viewAllEventsUrl}>
										<P style={{ textDecoration: "underline" }}>View all events</P>
									</Link>
								</div>
							</div>
						</Col>
						<Col span={8} key="tickets">
							<div className={overviewSummaryTiles}>
								<div className={overviewSummaryTileContainer}>
									{overviewLoading ? <LoadingSpinner size={48} /> : <p className={numberStyling}>{ticketsSold}</p>}
									<P>Tickets sold</P>
								</div>
							</div>
						</Col>
						<Col span={8} key="earnings">
							<div className={overviewSummaryTiles}>
								<div className={overviewSummaryTileContainer}>
									{overviewLoading ? (
										<LoadingSpinner size={48} />
									) : (
										<p className={numberStyling}>{numberFormatter.format(totalEarnings)}</p>
									)}
									<P>Total earnings</P>
								</div>
							</div>
						</Col>
					</Row>
				</div>
				{bestSellingEvent ? (
					<Widget title={title} {...this.props}>
						<div>
							<H5 style={{ marginBottom: "0.625rem" }}>Best selling event in the last 7 days:</H5>
							<EventRow
								key={bestSellingEvent._id}
								{...bestSellingEvent}
								isPrivate={!bestSellingEvent.public}
								upcoming={false}
								toggleDuplicate={this.toggleDuplicate}
								toggleArchive={this.toggleArchive}
								minimal={true}
							/>
						</div>
					</Widget>
				) : null}
				{latestDraftEvent ? (
					<Widget title={title} {...this.props}>
						<div>
							<H5 style={{ marginBottom: "0.625rem" }}>Continue building your latest draft:</H5>
							<EventRow
								key={latestDraftEvent._id}
								{...latestDraftEvent}
								isPrivate={!latestDraftEvent.public}
								upcoming={false}
								toggleDuplicate={this.toggleDuplicate}
								toggleArchive={this.toggleArchive}
								minimal={true}
							/>
						</div>
					</Widget>
				) : null}
			</div>
		);
	}
}

export default withRouterHooks(
	connect(
		(state, ownProps) => ({
			metrics: state[ownProps.metricState],
			search: state.search,
			location: state.locations.overrideLocation
		}),
		{
			getDashboardOverview,
			getDashboardSummary,
			updateDashboardPeriod,
			updateDashboardChannel,
			updateDashboardCustomDateRange,
			updateDashboardCustomDateRangePicked
		}
	)(OverviewSummary)
);
