import AuthService from "@/services/AuthService";
import { Table } from "@/ui/antd";
import { Component } from "react";
import { Link } from "react-router-dom";
import withReportProvider from "../../hoc/withReportProvider";

import { css } from "@emotion/css";
import { ContentBlock, LoadingSpinner, ReportContent } from "../../components/AppBlocks";
import LoadErrorView from "../../components/LoadErrorView";
import SimplePagination from "../../components/SimplePagination";
import { LegacyButton } from "@/components/buttons/LegacyButton";
import OrderActions from "../../events/event/order/OrderActions";
import { ResendOrderModal } from "../../events/event/order/ResendOrderModal";
import ReportEmptyText from "../ReportEmptyText";
import ReportsFilter from "../ReportsFilter";

const cellStyle = css({
	maxWidth: "250px"
});

const paginationStyle = css({
	display: "flex",
	justifyContent: "flex-end",
	alignItems: "center",
	padding: "8px 0",
	position: "relative",
	top: -12
});

class Orders extends Component {
	defaultSearch = {
		query: ""
	};

	getFilterOptions() {
		const { userRole } = this.props;
		return [
			{
				key: "orderStatus",
				label: "Order status",
				options: [
					{ value: "complete", name: "Complete" },
					...(userRole === "admin"
						? [
								{ value: "all", name: "All" },
								{ value: "pending", name: "Pending" },
								{ value: "incomplete", name: "Incomplete" }
						  ]
						: []),
					{ value: "donations", name: "Donations" }
				]
			},
			{
				key: "financialStatus",
				label: "Financial status",
				options: [
					{ value: "free", name: "Free" },
					{ value: "notApplicable", name: "Not Applicable" },
					{ value: "partiallyRefunded", name: "Partially Refunded" },
					{ value: "refunded", name: "Refunded" },
					{ value: "paid", name: "Paid" }
				],
				mode: "multiple",
				placeholder: "All"
			},
			{
				key: "total",
				label: "Sales range",
				kind: "numberRange"
			}
		];
	}
	render() {
		const { results, loading, error, page, csvLoading, hasLoadedOnce } = this.props.reportState;
		const { load, loadPage, downloadCsv, search, userRole } = this.props;
		const columns = [
			{
				title: "Order ID",
				dataIndex: "orderName",
				width: 120,
				key: "orderName",
				fixed: "left",
				render: (orderName, record) => {
					const link = record.userId
						? `/console/promote/gift-cards/order/${record._id}`
						: `/console/my-events/${record.eventId}/orders/${record._id}`;
					return <Link to={link}>{orderName}</Link>;
				}
			},
			{
				title: "Event",
				dataIndex: "eventName",
				key: "eventName",
				render: (eventName, record) => {
					return (
						<div className={cellStyle}>
							<Link to={`/console/my-events/${record.eventId}`}>{eventName}</Link>
						</div>
					);
				}
			},
			{
				title: "Event Date",
				dataIndex: "date",
				key: "date"
			},
			{
				title: "First Name",
				dataIndex: "firstName",
				key: "firstName"
			},
			{
				title: "Last Name",
				dataIndex: "lastName",
				key: "lastName"
			},
			{
				title: "Email",
				dataIndex: "email",
				key: "email"
			},
			{
				title: "Mobile",
				dataIndex: "mobile",
				key: "mobile"
			},
			{
				title: "Order Date & Time",
				dataIndex: "orderDate",
				key: "orderDate"
			},
			{
				title: "Type",
				dataIndex: "orderType",
				key: "orderType"
			},
			{
				title: "Financial status",
				dataIndex: "financialStatus",
				key: "financialStatus"
			},
			{
				title: "Valid tickets",
				dataIndex: "completeTickets",
				key: "completeTickets"
			},
			{
				title: "Cancelled tickets",
				dataIndex: "cancelledTickets",
				key: "cancelledTickets"
			},
			{
				title: "Ticket sales",
				dataIndex: "ticketSales",
				key: "ticketSales"
			},
			{
				title: "Add-on sales",
				dataIndex: "addOnSales",
				key: "addOnSales"
			},
			{
				title: "Donation",
				dataIndex: "additionalDonations",
				key: "additionalDonations"
			},
			{
				title: "Fee rebate",
				dataIndex: "feeRebate",
				key: "feeRebate"
			},
			{
				title: "Humanitix passed-on fees",
				dataIndex: "humanitixFeePassedOn",
				key: "humanitixFeePassedOn"
			},
			{
				title: "Amex surcharge",
				dataIndex: "amexSurcharge",
				key: "amexSurcharge"
			},
			...(search.loc === "US"
				? [
						{
							title: "Custom Tax",
							dataIndex: "customTax",
							key: "customTax"
						}
				  ]
				: []),
			...(AuthService.showInsuranceColumn()
				? [
						{
							title: "Refund protection",
							dataIndex: "insurance",
							key: "insurance"
						}
				  ]
				: []),
			{
				title: "Paid",
				dataIndex: "paid",
				key: "paid"
			},
			{
				title: "Humanitix absorbed fees",
				dataIndex: "humanitixFeeAbsorbed",
				key: "humanitixFeeAbsorbed"
			},
			{
				title: "Zip fee (absorbed)",
				dataIndex: "zipFee",
				key: "zipFee"
			},
			{
				title: "Afterpay fee (absorbed)",
				dataIndex: "afterpayFee",
				key: "afterpayFee"
			},
			{
				title: "Refunds",
				dataIndex: "refunds",
				key: "refunds"
			},
			{
				title: "Your Earnings",
				dataIndex: "earnings",
				key: "earnings"
			},

			...(userRole === "admin"
				? [
						{
							title: "Your Payout",
							dataIndex: "payout",
							key: "payout"
						}
				  ]
				: []),
			{
				title: "Refunded fees",
				dataIndex: "refundedFees",
				key: "refundedFees"
			},
			{
				title: "Giftcard used",
				dataIndex: "giftcardCredit",
				key: "giftcardCredit"
			},
			{
				title: "Voucher used",
				dataIndex: "voucher",
				key: "voucher"
			},
			{
				title: "Discount code used",
				dataIndex: "discountCode",
				key: "discountCode"
			},
			{
				title: "Discount redeemed",
				dataIndex: "discountValue",
				key: "discountValue"
			},
			{
				title: "Tax on sales",
				dataIndex: "taxOnSales",
				key: "taxOnSales"
			},
			{
				title: "Tax on rebates",
				dataIndex: "taxOnRebates",
				key: "taxOnRebates"
			},
			{
				title: "Tax on booking fees",
				dataIndex: "taxOnBookingFees",
				key: "taxOnBookingFees"
			},
			{
				title: "Gateway",
				dataIndex: "paymentGateway",
				key: "paymentGateway"
			},
			{
				title: "Marketing Opt-in",
				dataIndex: "marketingOptIn",
				key: "marketingOptIn"
			},
			{
				title: "Notes",
				dataIndex: "notes",
				key: "notes"
			},
			{
				title: "Status",
				dataIndex: "orderStatus",
				key: "orderStatus"
			},
			{
				title: "Action",
				fixed: "right",
				key: "action",
				width: 100,
				render: (_, record) => {
					return <OrderActions eventId={record.eventId} order={record} />;
				}
			}
		];

		return (
			<LoadErrorView loading={loading} error={error} retryAction={load}>
				<ContentBlock style={{ padding: 0 }}>
					<ReportsFilter
						loadReport={load}
						firstDateName="orderDates"
						firstDateLabel="Order date range"
						filterOptions={this.getFilterOptions()}
					/>
					<ReportContent>
						<div>
							{results?.length > 0 && (
								<div className={paginationStyle}>
									<SimplePagination
										pageAction={loadPage}
										currentPage={page}
										style={{ textAlign: "right", margin: 0 }}
									/>

									<LegacyButton ariaLabel="export csv" onClick={csvLoading ? null : downloadCsv} type="action">
										{csvLoading ? <LoadingSpinner size={30} color="#353337" style={{ marginTop: 5 }} /> : "Export CSV"}
									</LegacyButton>
								</div>
							)}
						</div>

						<Table
							columns={columns}
							dataSource={results}
							loading={loading}
							locale={{ emptyText: <ReportEmptyText hasLoadedOnce={hasLoadedOnce} onClickCallback={load} /> }}
							rowKey="_id"
							scroll={{ x: "max-content" }}
							pagination={false}
						/>
						<SimplePagination pageAction={loadPage} currentPage={page} />
					</ReportContent>
				</ContentBlock>
				<ResendOrderModal />
			</LoadErrorView>
		);
	}
}

export default withReportProvider(Orders, "Orders", "order");
